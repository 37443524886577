import Navbar from 'routes/pages/home/components/navbar/navbar'
import './landing.scss'
import union from 'assets/images/invite/landing-union.png'
import landingmobile from 'assets/images/invite/invite-landing-mobile.png'
import { ReactComponent as LeftArrow } from "assets/icons/home/left-arrow.svg";

export const InviteLanding = () => {
    return (
        <div className="pmx-landing-invite">
            <img src={union} id='landing-union' alt="Union" />
            <img src={landingmobile} id='landing-banner' alt="Landing" />
            <Navbar />
            <div className="content">
                <h2>در ۵۰ درصد سود صرافی ما شریک باشید</h2>
                <p>دعوت از دوستان، یک روش سرمایه گذاری نوین بدون نیاز به منبع مالی برای کاربران است. برای کسب درآمد از این روش، فقط کافی است تا دوستان خود را به پی ام ایکسچنج دعوت کنید و تا ۳۰ درصد از کارمزد معاملات آن ها را به صورت آنی در کیف پول خود دریافت کنید.</p>
                <a href="/referral">
                    دعوت از دوستان <LeftArrow />
                </a>
            </div>
        </div>
    )
}