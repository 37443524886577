import './main.scss';
import {ReactComponent as AuthenticationIcon} from 'assets/icons/faq/authentication.svg'
import {ReactComponent as SwapIcon} from 'assets/icons/faq/swap.svg'
import {ReactComponent as SwapVerticalIcon} from 'assets/icons/faq/swap-vertical.svg'
import {ReactComponent as RefreshIcon} from 'assets/icons/faq/refresh.svg'
import {ReactComponent as GiftIcon} from 'assets/icons/faq/gift.svg'
import {ReactComponent as ExpandIcon} from 'assets/icons/faq/expand.svg'
import {ReactComponent as ArrowsIcon} from 'assets/icons/faq/arrows.svg'
import {ReactComponent as ChatIcon} from 'assets/icons/faq/chat.svg'
import {useEffect, useState} from 'react';
import {Collapse, CollapseProps, Divider} from 'antd';
import {current} from "@reduxjs/toolkit";

enum Category {
    Authentication,
    BuyAndSell,
    DepositAndWithdraw,
    TradeCurrencies,
    Referral
}

const AUTH_ITEMS: CollapseProps['items'] = [
    {
        key: '1',
        label: 'مدارک مورد نیاز برای احراز هویت چیست ؟',
        children: <p>برای احراز هویت در پی ام‌ایکس چنج باید یکی از موارد زیر استفاده کنید:<br/>
            شناسنامه جدید<br/>
            کارت ملی هوشمند<br/></p>,
    },
    {
        key: '2',
        label: 'پس از واریز وجه چقدر زمان خواهد برد که ارز به کیف پول ما ارسال شود ؟',
        children: <p>در شرایط عالی ارز به صورت آنی به شما تحویل خواهد شد<br/>
            این امر در ارزهای مختلف متفاوت می باشد
        </p>
    },
    {
        key: '3',
        label: 'آیا میتوانیم دو ارز را به یکدیگر تبدیل کنیم؟',
        children: <p>بله کاربران این امکان را دارند به صورت آنی دو رمزارز را به یکدیگر تبدیل کنند</p>
    }
];

const BUY_AND_SELL_ITEMS: CollapseProps['items'] = [
    {
        key: '1',
        label: 'آیا خرید نیاز به احراز هویت دارد؟',
        children: <p>بله، کاربران برای خرید مبالغ بالای ۵۰۰ هزار تومان باید به صورت تصویر با مدارک احراز هویت انجام دهند<br/>برای
            مبالغ کمتر از ۵۰۰ هزار تومان تنها نیاز به ثبت اطلاعات هویتی شما می‌باشد<br/>
            تذکر:
            مبالغ ذکر شده متغیر می باشد</p>
    },
    {
        key: '2',
        label: 'سقف خرید در پی ام ایکس چنج به چه صورت می باشد؟',
        children: <p>
            در پی ام ایکس چنج سه سطح کاربری وجود دارد:<br/>
            <br/>سطح برنزی:
            <br/>در این سطح امکان خرید ریالی وجود نداد و کاربر می‌تواند دو رمزارز را به یکدیگر تبدیل کند<br/>
            <br/>سطح نقره ای:
            <br/>۱:در این سطح کاربر می‌تواند تا سقف ۵۰۰ هزار تومان بدون ارسال مدارک و با ثبت اطلاعات هویتی خرید انجام
            دهد<br/>
            ۲: کاربران با انجام احراز هویت با مدارک هویتی می‌توانند تا سقف ۲۵ میلیون تومان در روز خرید انجام
            دهند.<br/><br/>
            برای اطلاعات کامل به قوانین مراجعه کنید
        </p>
    }
]

const DEPOSIT_AND_WITHDRAW_ITEMS: CollapseProps['items'] = [
    {
        key: '1',
        label: 'واریز و برداشت از پی ام ایکسچنج به چه صورت می‌باشد؟',
        children: (
            <p>
                واریز های ریالی تنها از طریق درگاه بانکی انجام میپذیرد<br/>

                برداشت ریالی از پی ام‌ایکسچنج به صورت آنی واریز می شود و لینک رسید در قسمت پیگیری سفارش کاربر درج می
                شود.<br/>
                زمان نشستن وجه به حساب کاربر بستگی به نوع بانک مقصد و سیکل پایا بانکی دارد.<br/>
            </p>
        )
    },
]

const TRADE_CURRENCIES_ITEMS: CollapseProps['items'] = [
    {
        key: '1',
        label: 'برای خرید و فروش رمز ارز ها کاربر به چه مواردی نیاز دارد؟',
        children: (
            <p>
                کاربران برای خرید رمز ارز از پی ام ایکسچنج نیاز به یک کیف پول دیجیتال دارند و در زمان خرید باید آدرس کیف
                پول را ثبت نمایند
            </p>
        )
    }
]

const REFERRAL_ITEMS: CollapseProps['items'] = [
    {
        key: '1',
        label: 'چگونه از طریق پی ام ایکسچنج درآمد زایی کنیم؟',
        children: (
            <p>
                شما با معرفی پی ام ایکسچنج به دوستان خود می توانید در کارمزد پی ام ایکسچنج سهیم باشید<br/>
                برای اطلاع بیشتر به قسمت کسب درآمد مراجعه کنید
            </p>
        )
    }
]


export const FAQMain = () => {
    const [currentCategory, setCurrentCategory] = useState<Category | null>(Category.Authentication);

    return (
        <div className="pmx-faq-main">
            <div className="faq-categories">
                <div className={`faq-category ${currentCategory === Category.Authentication ? 'active' : ''}`}
                     onClick={() => setCurrentCategory(Category.Authentication)}>
                    <AuthenticationIcon/>
                    <span>احراز هویت</span>
                </div>
                <div className={`faq-category ${currentCategory === Category.BuyAndSell ? 'active' : ''}`}
                     onClick={() => setCurrentCategory(Category.BuyAndSell)}>
                    <SwapIcon/>
                    <span>خرید و فروش</span>
                </div>
                <div className={`faq-category ${currentCategory === Category.DepositAndWithdraw ? 'active' : ''}`}
                     onClick={() => setCurrentCategory(Category.DepositAndWithdraw)}>
                    <SwapVerticalIcon/>
                    <span>واریز و برداشت</span>
                </div>
                <div className={`faq-category ${currentCategory === Category.TradeCurrencies ? 'active' : ''}`}
                     onClick={() => setCurrentCategory(Category.TradeCurrencies)}>
                    <RefreshIcon/>
                    <span>معامله ی رمزارز ها</span>
                </div>
                <div className={`faq-category ${currentCategory === Category.Referral ? 'active' : ''}`}
                     onClick={() => setCurrentCategory(Category.Referral)}>
                    <GiftIcon/>
                    <span>کسب درآمد</span>
                </div>
            </div>
            <Divider/>
            <div className="faq-items">
                {currentCategory === Category.Authentication &&
                    <Collapse items={AUTH_ITEMS} accordion expandIconPosition='end'
                              expandIcon={({isActive}) => <ExpandIcon
                                  style={{transform: `rotate(${isActive ? 180 : 0}deg)`}}/>}/>
                }
                {currentCategory === Category.BuyAndSell &&
                    <Collapse items={BUY_AND_SELL_ITEMS} accordion expandIconPosition='end'
                              expandIcon={({isActive}) => <ExpandIcon
                                  style={{transform: `rotate(${isActive ? 180 : 0}deg)`}}/>}/>
                }
                {currentCategory === Category.DepositAndWithdraw &&
                    <Collapse items={DEPOSIT_AND_WITHDRAW_ITEMS} accordion expandIconPosition='end'
                              expandIcon={({isActive}) => <ExpandIcon
                                  style={{transform: `rotate(${isActive ? 180 : 0}deg)`}}/>}/>
                }
                {currentCategory === Category.TradeCurrencies &&
                    <Collapse items={TRADE_CURRENCIES_ITEMS} accordion expandIconPosition='end'
                              expandIcon={({isActive}) => <ExpandIcon
                                  style={{transform: `rotate(${isActive ? 180 : 0}deg)`}}/>}/>
                }
                {currentCategory === Category.Referral &&
                    <Collapse items={REFERRAL_ITEMS} accordion expandIconPosition='end'
                              expandIcon={({isActive}) => <ExpandIcon
                                  style={{transform: `rotate(${isActive ? 180 : 0}deg)`}}/>}/>
                }
            </div>
            <Divider/>
            <section className="support">
                <p>جواب سوال خود را پیدا نکردید؟ <ArrowsIcon/></p>
                <a href="https://t.me/pmxsupport"
                   target="_blank"
                   rel="noreferrer"><ChatIcon/> ارتباط مستقیم با پشتیبانی</a>
            </section>
        </div>
    )
}