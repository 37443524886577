import * as React from "react";
import "./referral.scss";
import {Button, buttonType, Card} from "components";
import {AgreementText} from "./agreement-text/agreement-text";
import {Page} from "../../../../components/page/page";
import {ReferralInfo} from "./referral-info/referral-info";
import {ReferralCongratulations} from "./referral-congratulations/referral-congratulations";
import {ReferralTables} from "./tables/tables";
import {BankAccount, ReferralActions, ReferralInfo as MyReferralInfo} from "core/domain";
import {getMineReferralActions, getMineReferralInfo, postRegisterReferral,} from "core/repositores/referral";
import {useEffect, useState} from "react";
import {getAllBankAccounts} from "../../../../core/repositores/bank-account";

enum ReferralStages {
  LOADING,
  AGREEMENT,
  PANEL,
}

interface Props {}

export const Referral: React.FC<Props> = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [stage, setStage] = useState<ReferralStages>(ReferralStages.LOADING);

  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>(null);
  const [referralInfo, setReferralInfo] = useState<MyReferralInfo>();
  const [referralActions, setReferralActions] = useState<ReferralActions[]>();

  const onCheckboxClick = () =>
    !isChecked ? setIsChecked(true) : setIsChecked(false);

  const fetchAllData = () => {
    !referralInfo && getMineReferralInfo(setIsLoading, setReferralInfo);
    !referralActions && getMineReferralActions(setIsLoading, setReferralActions);
    !bankAccounts && getAllBankAccounts(setIsLoading, setBankAccounts);
  }

  useEffect(() => {
    fetchAllData()
  }, []);

  const handleAgreementSubmit = async () => {
    await postRegisterReferral(setButtonIsLoading, () =>
      window.location.reload(),
    );
  };

  React.useEffect(() => {
    referralInfo?.referralCode != null
      ? setStage(ReferralStages.PANEL)
      : setStage(ReferralStages.AGREEMENT);
  }, [referralInfo]);

  return (
    <Page className="pmx-referral" loading={isLoading}>
      {!isLoading && (stage === ReferralStages.AGREEMENT ? (
        <Card className="rules">
          <AgreementText />
          <div className="agree">
            <input
              type="checkbox"
              checked={isChecked}
              onClick={() => onCheckboxClick()}
            />
            <span>متن توافق را به صورت کامل خوانده ام تمامی قوانین آن را قبول دارم.</span>
          </div>
          <Button
              disabled={!isChecked}
            text="ثبت نام به عنوان همکار"
            type={buttonType.primary}
            onClick={handleAgreementSubmit}
            loading={buttonIsLoading}
          />
        </Card>
      ) : (
        <>
          <div className="main-row">
            {stage === ReferralStages.PANEL && (
              <>
                <ReferralCongratulations />
                <ReferralInfo referralInfo={referralInfo} referralActions={referralActions} bankAccounts={bankAccounts} isLoading={isLoading} />
              </>
            )}
          </div>
          {stage === ReferralStages.PANEL && <ReferralTables referralActions={referralActions} isLoading={isLoading} />}
        </>
      ))}
    </Page>
  );
};
