// local storage interface
import {
  KEY_ACCESS_TOKEN,
  KEY_DATE,
  KEY_EXPIRES_IN,
  KEY_IS_AUTHORIZED,
  KEY_LEVEL,
  KEY_PHONE,
  KEY_PROFILE_DATA,
  KEY_REFRESH,
  KEY_ROLES,
} from "core/constants/localStorageConstants";
import { Token } from "../domain/commons/common";

export const authToken = {
  key: KEY_ACCESS_TOKEN,
  get: function (): string | null {
    const data = localStorage.getItem(authToken.key);
    if (data) {
      return "Bearer " + data;
    }
    return null;
  },
  set: (value: Token) => {
    localStorage.setItem(authToken.key, JSON.stringify(value));
  },
  remove: () => {
    localStorage.removeItem(authToken.key);
  },
};
