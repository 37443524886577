import * as React from "react";

import "./balance-box.scss";
import {Button, buttonType} from "components";
import {ReactComponent as Plus} from "assets/icons/dashboard/plus.svg";

interface Props { }

export const BalanceBox: React.FC<Props> = () => {
  return (
      <div className="balance-box">
        <span className="title">موجودی کیف پول</span>
        <div className="balance">
            <p>0 تومان</p>
            <span>معادل 0 دلار</span>
        </div>
        <Button text="افزایش موجودی" type={buttonType.white} icon={<Plus />} />
      </div>
  );
};
