import { Enum } from "../commons/common";

export interface OrderDetail {
  orderId: string;
  trackingCode: string;
  sourceSymbol: string;
  destinationSymbol: string;
  sourceAmount: number;
  destinationAmount: number;
  fullName: string;
  state: Enum;
  creationDate: string;
  paymentDate: string;
  iBan: string;
  txid: string;
  voucher: string;
  voucherActivation: string;
  orderIdentifier: string;
  destinationMoneyAccount: string;
  digitalRecept: string;
  batchNumber: string;
  destinationWalletAddress: string;
  trackingCOde?: string;
}

export interface Order {
  orderId: string;
  trackingCode: string;
  sourceSymbol: string;
  destinationSymbol: string;
  sourceAmount: number;
  destinationAmount: number;
  state: Enum;
  creationDate: string;
  type: Enum;
  trackingCOde?: string;
}

export interface VerifyOrder {
  voucherCode: string,
  voucherActivationCode: string,
  amount: 0,
  paymentDate: string,
  bacthId: string,
  orderState: Enum,
}

export interface OrdersFilter {
  orderState: Enum;
}

export enum OrderState {
  Open = 0,
  Canceled,
  Rejected,
  Paying,
  Paid,
  WaitingForAcceptRecept,
  WaitingForWidthraw,
  Succeed,
  WaitingForSelfiePhoto,
  WaitingForSelfieAndTime,
  WaitingForTime,
  WaitingForConfirmTxid,
  WaitingForConfirmDocumentByAdmin,
  WaitingForConfirmDocumentByAdminAndTime,
}

export type OrderVerifyResult = {
  voucherCode: string;
  voucherActivationCode: string;
  amount: number;
  paymentDate: string;
  bacthId: string;
  orderState: Enum;
};

export interface Network {
  addressRegex: string;
  asset: string;
  depositDescription: string;
  depositEnabled: boolean;
  isWithdrawEnabled: boolean;
  isDefault: boolean;
  memoRegex: string;
  name: string;
  network: string;
  withdrawFee: number;
  withdrawMin: number;
  withdrawMax: number;
}

export type AddressWallet = {
  network: string;
  name: string;
  regex: string;
  depositEnabled: boolean;
  widthrawEnabled: boolean;
  memoRegex: string;
  widthrawFee: number;
  walletAddress: string;
  walletTag: string;
  qrCodeUrl: string;
};

export type DepositNetowrk = {
  addressWallets: AddressWallet[];
};

export interface CashInDetail {
  depositId: string,
  iban: string,
  bankName: string,
  owner: string,
  accountNumber: string
}