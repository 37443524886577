import "./referral-congratulations.scss";
import {Card} from "components";
import kasbedaramad1 from 'assets/images/referral/kasbedaramad1.png'
import kasbedaramad2 from 'assets/images/referral/kasbedaramad2.png'

export const ReferralCongratulations = () => {
  return (
    <Card className="congrats-card">
      <div className="images">
        <img src={kasbedaramad1} />
        <img src={kasbedaramad2} />
      </div>
      <p>
        <h4>تبریک! شما شریک تجاری ما هستید.</h4>
          از این لحظه ۳۰ درصد از سود صرافی برای شماست. در ازای هر تراکنش از کاربران زیر مجموعه،
          ۳۰ درصد از سود تراکنش دوستان شما به حساب کاربری شما واریز و امکان برداشت خواهید داشت.
      </p>
    </Card>
  );
};
