import * as React from "react";

import "./log-table.scss";
import { Card } from "components";
import { LoginHistory } from "core/domain/security/security";
import { getLoginHistory } from "core/repositores/security";
import moment from "jalali-moment";
import { TableDataSkeleton } from "components/table-data-skeleton/table-data-skeleton";

interface Props { }

export const LogTable: React.FC<Props> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loginHistory, setLoginHistory] = React.useState<LoginHistory>({ items: [] });

  const fetchLoginHistory = async () => {
    await getLoginHistory(setLoading, setLoginHistory)
  }

  React.useEffect(() => {
    fetchLoginHistory();
  }, []);

  return (
    <div className="table">
      <div className="logs">
        <Card title='تاریخچه ورود'>
          <table>
            <thead>
              <th className="desktop-show">ردیف</th>
              <th>دستگاه</th>
              <th>آی پی</th>
              <th>ساعت و تاریخ</th>
            </thead>
            <div className="divider"></div>
            <tbody>
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <React.Fragment key={index}>
                    {index !== 0 && <div className="divider"></div>}
                    <tr>
                      <td className="desktop-show">
                        <TableDataSkeleton />
                      </td>
                      <td>
                        <TableDataSkeleton width={100} />
                      </td>
                      <td>
                        <TableDataSkeleton width={80} />
                      </td>
                      <td>
                        <TableDataSkeleton width={60} />
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                loginHistory?.items?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    {index !== 0 && <div className="divider"></div>}
                    <tr>
                      <td className="desktop-show">{index + 1}</td>
                      <td><span className="en">{item.device}</span></td>
                      <td>{item.ip}</td>
                      <td>{moment.utc(item.createdDate).locale('fa').local().format("HH:mm:ss")} - {moment(item.createdDate).format("jYYYY/jMM/jDD")}</td>
                    </tr>
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};
