import {getViaAuth, responseValidator} from "../apis/api";
import {Profile} from "../domain";
import {API} from "../apis";
import {KEY_PROFILE_DATA} from "../constants/localStorageConstants";

export async function getUserProfile(
    setLoading: any
) {
  setLoading(true);
  await getViaAuth(API.user.profile).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      localStorage.setItem(KEY_PROFILE_DATA, JSON.stringify(response.data));
    } else {
      window.open('/login', '_self')
    }
  });
}

export function readProfile() {
  var profileData = localStorage.getItem(KEY_PROFILE_DATA);
  if (profileData) {
    var profileDataObj = JSON.parse(profileData);
    return profileDataObj as Profile;
  }
}
