import Navbar from 'routes/pages/home/components/navbar/navbar';
import './landing.scss';
import faqbanner from 'assets/images/faq/faqbanner.png';
import faqbannermobile from 'assets/images/faq/faqbanner-mobile.png';
import { ReactComponent as SearchIcon } from 'assets/icons/faq/search.svg';
import { Input } from 'antd';

export const FAQLanding = () => {
    return (
        <div className="pmx-faq-landing">
            <Navbar />
            <img id='banner' src={faqbanner} className='desktop-show' />
            <img id='banner' src={faqbannermobile} className='mobile-show'/>
            <div className="search-box">
                <h2 className='desktop-show'>سوالات پرتکرار</h2>
                <h2 className='mobile-show'>راهنمای استفاده از پی ام اکسچنج</h2>
                <Input 
                suffix={<SearchIcon />}
                placeholder='سوال یا عبارت مورد نظر خود را جست و جو کنید...'
                />
            </div>
        </div>
    )
}