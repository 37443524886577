import "./about.scss";
import { Divider } from "antd";
import { AboutLanding } from "./components/landing/landing";
import { Path } from "./components/path/path";
import { ContactUs } from "./components/contact-us/contact-us";
import { Socials } from "./components/socials/socials";
import { Licenses } from "./components/licenses/licenses";
import { Certifications } from "./components/certifications/certifications";
import { TimeLine } from "./components/timeline/timeline";
import { Partners } from "./components/partners/partners";
import { Footer } from "../home/components/footer/footer";
import { useState } from "react";
import { Navigation } from "../navigation/navigation";

interface Props {}

export const About = (props: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div className="pmx-about">
      <AboutLanding />
      <Path />
      <Divider />
      <ContactUs />
      <Divider />
      <Socials />
      <Divider />
      <Licenses />
      <Divider />
      {/*<Certifications />*/}
      {/*<Divider />*/}
      <TimeLine />
      <Divider />
      <Partners />
      <Footer />
    </div>
  );
};
