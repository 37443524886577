import './bonus-help.scss'
import register from 'assets/images/invite/register.png'
import link from 'assets/images/invite/link.png'
import share from 'assets/images/invite/share.png'
import withdraw from 'assets/images/invite/withdraw.png'
import dashedLine from 'assets/images/invite/dashed-line.png'
import dashedLineVertical from 'assets/images/invite/dashed-line-vertical.png'

export const BonusHelp = () => {
    return (
        <div className="pmx-bonus-help">
            <h3>چگونه پاداش بگیریم؟</h3>
            <div className="bonus-steps">
                <div className="step">
                    <img src={register} alt="Register"/>
                    <h4>1- ثبت نام</h4>
                    <p>در چند ثانیه تنها با یک شماره همراه که بنام خودتان است میتوانید ثبت نام کنید و وارد حساب کاربری
                        خود شوید.</p>
                </div>
                <img id='line' src={dashedLine} alt="Line"/>
                <img id='line-vertical' src={dashedLineVertical} alt="LineVertical"/>
                <div className="step">
                    <img src={link} alt="Link"/>
                    <h4>2- دریافت لینک</h4>
                    <p>حالا وارد پنل کاربری خود شده و در قسمت کسب درآمد، لینک اختصاصی دعوت خود یا کد دعوت را دریافت
                        کنید.</p>
                </div>
                <img id='line' src={dashedLine} alt="Line"/>
                <img id='line-vertical' src={dashedLineVertical} alt="LineVertical"/>
                <div className="step">
                    <img src={share} alt="Share"/>
                    <h4>3- اشتراک گذاری لینک</h4>
                    <p>لینک را بین دوستان خود در فضای‌مجازی به اشتراک بگذارید و برای راحتی در تبلیغات میتونید از بنرهای
                        موجود در پایین صفحه استفاده نمایید.</p>
                </div>
                <img id='line' src={dashedLine} alt="Line"/>
                <img id='line-vertical' src={dashedLineVertical} alt="LineVertical"/>
                <div className="step">
                    <img src={withdraw} alt="Withdraw"/>
                    <h4>4- برداشت سود</h4>
                    <p>بعد از اینکه دوستانی زیرمجموعه شما شدند و اقدام به تراکنش کردند میتونید در کمتر از ۵ دقیقه سودتون
                        رو از طریق حساب بانکی دریافت کنید.</p>
                </div>
            </div>
        </div>
    )
}