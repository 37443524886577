import { Badge, BadgeType, Button, buttonType } from "../index";
import * as React from "react";
import "./order-item.scss";
import { Order, OrderState } from "../../core/domain/orders/orders";
import moment from "jalali-moment";
import { digitSeparator } from "../../core/utils/decimalExtentions";
import { Divider } from "antd";
import { useEffect } from "react";

interface Props {
  onClickDetails?: () => void;
  orderItem: Order;
}

export const OrderItem: React.FC<Props> = ({ orderItem, onClickDetails }) => {
  const getBadgeType = () => {
    if (
      orderItem?.state?.id === OrderState.Succeed ||
      orderItem?.state?.id === OrderState.Paid
    )
      return BadgeType.success;
    else if (
      orderItem?.state?.id == OrderState.Canceled ||
      orderItem?.state?.id == OrderState.Rejected
    )
      return BadgeType.error;
    else return BadgeType.waiting;
  };

  return (
    <div className="pmx-order-item">
      <div className="head">
        <div className="pair">
          <div className="title">
            <span>جفت ارز</span>
            <p className="en">
              {orderItem.sourceSymbol}/{orderItem.destinationSymbol}
            </p>
          </div>
          <div className="icons">
            <img
              src={`/assets/coins/${orderItem.destinationSymbol.toLowerCase()}.svg`}
              alt=""
            />
            <img
              src={`/assets/coins/${orderItem.sourceSymbol.toLowerCase()}.svg`}
              alt=""
            />
          </div>
        </div>
        <div className="tracking-code mobile-show">
          <span>شناسه</span>
          <p className="text-overflow en">{orderItem.trackingCOde}</p>
        </div>
        <Badge text={orderItem.state.title} type={getBadgeType()} />
      </div>
      <Divider />
      <div className="data">
        <div className="col">
          <div>
            <span>مقدار مبداء</span>
            <p className={orderItem.sourceSymbol == "IRT" ? "" : "en"}>
              {orderItem.sourceSymbol == "IRT"
                ? `${digitSeparator(orderItem.sourceAmount) + " تومان"}`
                : `${orderItem.sourceAmount + " " + orderItem.sourceSymbol}`}
            </p>
          </div>
          <div>
            <span>مقدار مقصد</span>
            <p className={orderItem.destinationSymbol == "IRT" ? "" : "en"}>
              {orderItem.destinationSymbol == "IRT"
                ? `${digitSeparator(orderItem.destinationAmount) + " تومان"}`
                : `${
                    orderItem.destinationAmount +
                    " " +
                    orderItem.destinationSymbol
                  }`}
            </p>
          </div>
        </div>
        <div className="col ">
          <div className="desktop-show">
            <span>شناسه</span>
            <p className="text-overflow en">{orderItem.trackingCOde}</p>
          </div>
          <div>
            <span>نوع سفارش</span>
            <p>{orderItem.type.title}</p>
          </div>
        </div>
        <div className="col">
          <div>
            <span>زمان و تاریخ سفارش</span>
            <p>
              {moment
                .utc(orderItem.creationDate)
                .locale("fa")
                .local()
                .format("HH:mm:ss - jYYYY/jMM/jDD")}
            </p>
          </div>
          <Button
            type={buttonType.transparent}
            text="جزئیات"
            onClick={onClickDetails}
          />
        </div>
      </div>
    </div>
  );
};
