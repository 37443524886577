import "./payment-result.scss";
import paymentmask from "assets/images/home/paymentmask.png";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as TimerIcon} from "assets/icons/home/timer.svg";
import {verifyOrder,} from "../../../../../../../core/repositores/orders";
import {VerifyOrder,} from "../../../../../../../core/domain";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {setOrderData} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {RootState} from "../../../../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {KEY_IS_CUSTOMER} from "../../../../../../../core/constants/localStorageConstants";

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

const PaymentResult = ({state}) => {
    const [cardIsLoading, setCardIsLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState<number>(10);
    const [result, setResult] = useState<VerifyOrder>()
    const [displayText, setDisplayText] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const trackingCode = location.pathname.split("/")[location.pathname.split("/").length - 1];

    const dispatch = useDispatch();

    const onContinue = () => {
        window.open(`/tracking/${trackingCode}`, "_self");
        dispatch(setOrderData({...state.orderData, pmResult: null}))
    };

    const onBackClick = () => {
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.CALCULATOR,
                pmResult: null,
                tour: false,
            }),
        );
        navigate("/");
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (remainingTime <= 0) {
                window.open(`/tracking/${trackingCode}`, "_self");
            }
        }, remainingTime * 1000);

        const interval = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(interval);
                    clearTimeout(timer);
                    return 0;
                } else {
                    return prevTime - 1;
                }
            });
        }, 1000);

        return () => {
            clearInterval(interval);
            clearTimeout(timer);
        };
    }, [navigate, remainingTime]);

    async function onVerify() {
        verifyOrder(
            setCardIsLoading,
            {
                trackingCode: trackingCode,
            },
            setResult,
            () => {
                setDisplayText("پرداخت شما موفقیت آمیز نبود.")
            },
            (response: any) => {
                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).dataLayer.push({
                    'event': 'purchase',
                    'ecommerce': {
                        'transaction_id': trackingCode,
                        'value': response.data.amount,
                        'tax': 0,
                        'currency': 'TRY',
                        'origin_currency': 'IRT',
                        'destination_currency': response.data.symbol,
                        'items': [{
                            'item_name': 'Buy Currency',
                            'index': 0,
                            'price': response.data.amount,
                            'quantity': 1
                        }]
                    }
                });
                localStorage.setItem(KEY_IS_CUSTOMER, "true");
                setDisplayText("پرداخت شما موفقیت آمیز بود.")
            },
            () => {
            }
        );
    }

    useEffect(() => {
        const isReturningFromGateway = location.pathname.includes("verify");
        isReturningFromGateway && onVerify();
    }, [location]);

    return (
        <div className="pmx-payment-result-stage">
            <img className="payment-mask" src={paymentmask} alt="Payment-Mask"/>
            <div className="result-title">
                <h3>{displayText}</h3>
            </div>
            <div className="result-timer">
                <p>
          <span>
            <TimerIcon/>
              {remainingTime} ثانیه
          </span>{" "}
                    تا انتقال به صفحه ی وضعیت تراکنش و مشاهده ی جزییات سفارش
                </p>
            </div>

            <OrderCardButtons
                submitButton={{
                    text: "انتقال  به صفحه ی وضعیت تراکنش",
                    loading: false,
                    disabled: false,
                    onClick: onContinue,
                }}
                cancelButton={{
                    text: "بستن فاکتور",
                    onClick: onBackClick,
                }}
            />
        </div>
    );
};
export default connector(PaymentResult);
