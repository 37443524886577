import './partners.scss'
import zibal from 'assets/images/about/zibal.png'
import zarinpal from 'assets/images/about/zarinpal.png'
import jibit from 'assets/images/about/jibit.png'
import vandar from 'assets/images/about/vandar.png'
import kavenegar from 'assets/images/about/kavenegar.png'

export const Partners = () => {
    return (
        <div className="pmx-partners">
            <h3>همکاران تجاری</h3>
            <div className="partners">
                <div className="partner"><img src={zibal} /></div>
                <div className="partner"><img src={zarinpal} /></div>
                <div className="partner"><img src={jibit} /></div>
                <div className="partner"><img src={vandar} /></div>
            <div className="partner"><img src={kavenegar} /></div>
            </div>
        </div>
    )
}