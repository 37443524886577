import * as React from "react";
import "./chart.scss";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { ToFa } from "core/utils/decimalExtentions";
import { PmxComponent } from "core/components/PmxComponenet";

interface Props extends PmxComponent {
  data: number[];
};

export const Chart: React.FC<Props> = (props) => {
  const [data, setData] = React.useState<any[]>([]);
  const [status, setStatus] = React.useState("");

  React.useEffect(() => {
   
    props.data[props.data.length - 1] - props.data[props.data.length - 2] < 0
      ? setStatus("up")
      : setStatus("down");
    props.data.map((item) =>
      setData((data) => [
        ...data,
        { price: item - (90 * Math.min(...props.data)) / 100 },
      ])
    );
  }, []);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <div className='my-tooltip'>
          <p>{ToFa(props.data[label])} دلار</p>
        </div>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      {data && (
        <div className='pmx-price-chart'>
          <div className='chart'>
            <ResponsiveContainer
              width={150}
              height={35}
              className='chart-header'
            >
              <AreaChart
                data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <Tooltip content={<CustomTooltip />} />
                <defs></defs>
                <Area
                  dataKey='price'
                  type='monotone'
                  strokeWidth={1}
                  stackId='2'
                  strokeOpacity={0.7}
                  stroke={status === "down" ? "#1fcb81" : "#ff4e82"}
                  fill={status === "down" ? "#e0fff1" : "#ffcfdd"}
                  fillOpacity={1}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
