import Navbar from 'routes/pages/home/components/navbar/navbar';
import './landing.scss';
import termslanding from 'assets/images/terms/termsbanner.png'
import termslandingr from 'assets/images/terms/termsbannerr.png'
import {isDesktop, isMobile} from 'react-device-detect';

export const TermsLanding = () => {
    return (
        <div className="pmx-terms-landing">
            <Navbar />
            {isDesktop && <img alt='terms' id='banner' src={termslanding} className=''/>}
            {isMobile && <img alt='terms' id='banner' src={termslandingr} className=''/>}
            <div className="hint-box">
                <h2>قوانین و شرایط</h2>
                <p>مشاهده قوانین و مقرارات پیش از خرید و فروش کاملا توصیه می شود.</p>
            </div>

        </div>
    )
}
