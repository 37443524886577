import * as React from "react";

import "./price-card.scss";
import {Rate} from "../../../../../core/domain/rate/rate";
import {digitSeparator} from "../../../../../core/utils/decimalExtentions";
import {Button, buttonType} from "components";
import {useNavigate} from "react-router";

interface Props {
  rate: Rate;
}

export const PriceCard: React.FC<Props> = (props) => {

  const navigate = useNavigate()

  return (
    <div className="price-card">
      <div className="row">
        <div className="currency-info">
          <img src={`/assets/coins/${props.rate.symbol.toLowerCase()}.svg`} />
          <p>
            <span>{props.rate.name}</span>
            <span className="en">{props.rate.symbol}</span>
          </p>
        </div>
        <div className="price-info">
          <span className="buy-rate">نرخ خرید</span>
          <span className="rate-value">
            {digitSeparator(props.rate.sellPrice)} <span>تومان</span>
          </span>
        </div>
      </div>
      <div className="row">
        <Button type={buttonType.buy} text={`خرید ${props.rate.name}`} onClick={() => navigate("/")} />
      </div>
    </div>
  );
};
