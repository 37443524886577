import * as React from "react";
import "./slider.scss";
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Button, buttonType } from "components";
import robot from "assets/images/dashboard/robot.png";
import { ReactComponent as Right } from "assets/icons/dashboard/Arrow_Drop_Right.svg";
import { Navigation, Pagination } from "swiper/modules";

const customStyles = {
  marginRight: "16px",
  marginLeft: "16px",
};
export const DashboardSlider: React.FC = () => {
  const navigationNextRef = React.useRef<HTMLDivElement>(null);
  const navigationPrevRef = React.useRef<HTMLDivElement>(null);

  const swiper = React.useRef<SwiperProps>(null);

  const handlePrev = () => {
    if (!swiper.current) return;
    swiper.current.swiper.slidePrev();
  };

  const handleNext = () => {
    if (!swiper.current) return;
    swiper.current.swiper.slideNext();
  };
  const slides = [1];

  return (
    <div className="dashboard-slider">
      <Swiper
        modules={[Pagination, Navigation]}
        pagination
        navigation={{
          prevEl: navigationPrevRef?.current,
          nextEl: navigationNextRef?.current,
        }}
        // style={customStyles}
        ref={swiper}
        className="slider"
        direction="horizontal"
        slidesPerView={1}
        spaceBetween={20}
        dir="rtl"
        height={300}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide} className="slide">
            <div className="card">
              <div className="content">
                <h3>ربات تلگرام پی ام ایکسچنج</h3>
                <p>
                  میتوانید برای سهولت در معامله و دریافت قیمت ارز ها از ربات تلگرامی ما استفاده نمائید
                </p>
                <a href="https://t.me/PMxchange_bot"><Button type={buttonType.white} text="عضو ربات میشوم" /></a>
                
              </div>
              <img src={robot} />

              {/*<span className="right-ring"></span>*/}
              {/*<span className="left-ring"></span>*/}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div
        ref={navigationNextRef}
        className="next-navigation"
        onClick={handleNext}
      >
        <Right />
      </div>
      <div
        ref={navigationPrevRef}
        className="pre-navigation"
        onClick={handlePrev}
      >
        <Right />
      </div> */}
    </div>
  );
};
