import './instructions.scss'
import {ReactComponent as CheckedUser} from "assets/icons/help/checked-user.svg";
import {ReactComponent as Focus} from "assets/icons/help/focus.svg";
import {ReactComponent as Gift} from "assets/icons/help/gift.svg";
import {ReactComponent as Swap} from "assets/icons/help/swap.svg";
import {ReactComponent as Refresh} from "assets/icons/help/refresh.svg";
import {ReactComponent as LinkIcon} from 'assets/icons/help/blue-link.svg'
import {ReactComponent as LeftArrow} from 'assets/icons/help/left-arrow.svg'
import {ReactComponent as BookIcon} from 'assets/icons/help/book.svg'
import {BookOutlined, LoadingOutlined} from "@ant-design/icons"
import instructions from 'assets/json/instructions.json'

import {FC, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, buttonType} from 'components';
import {Divider, Spin} from 'antd';
import {Category, Content, ContentByEnName} from "../../../../../core/domain/content/content";
import {getContentByCategoryEnName, getContentByEnName} from "../../../../../core/repositores/content";

// enum CategoryEnum {
//     NONE,
//     AUTHENTICATION,
//     BUY_AND_SELL,
//     DEPOSIT_AND_WITHDRAWAL,
//     TRADE,
//     REFERRAL
// }

// const categoryHeaders = [
//     {
//         id: 0,
//         title: 'احراز هویت',
//         icon: <CheckedUser/>,
//         category: CategoryEnum.AUTHENTICATION
//     },
//     {
//         id: 1,
//         title: 'خرید و فروش',
//         icon: <Swap/>,
//         category: CategoryEnum.BUY_AND_SELL
//     },
//     {
//         id: 2,
//         title: 'واریز و برداشت',
//         icon: <Focus/>,
//         category: CategoryEnum.DEPOSIT_AND_WITHDRAWAL
//     },
//     {
//         id: 3,
//         title: 'معامله ی رمزارزها',
//         icon: <Refresh/>,
//         category: CategoryEnum.TRADE
//     },
//     {
//         id: 4,
//         title: 'کسب درآمد',
//         icon: <Gift/>,
//         category: CategoryEnum.REFERRAL
//     },
// ]

interface Props {
    categories: Category[]
    categoriesLoading: boolean
    topicContent: Content[]
    topicContentLoading: boolean
}

function isContentByEnName(content: Content | ContentByEnName): content is ContentByEnName {
    return (content as ContentByEnName).description !== undefined;
}


export const Instructions: FC<Props> = ({categories, categoriesLoading, topicContent, topicContentLoading}) => {
    const [currentCategory, setCurrentCategory] = useState<Category>()
    const [currentCategoryLoading, setCurrentCategoryLoading] = useState<boolean>(false)

    const [categoryContent, setCategoryContent] = useState<Content[]>()

    const [currentContent, setCurrentContent] = useState<ContentByEnName>()
    const [currentContentLoading, setCurrentContentLoading] = useState<boolean>(false)

    const handleCategorySelection = (item: Category) => {
        const onComplete = () => setCurrentCategory(item)
        getContentByCategoryEnName(setCurrentCategoryLoading, item.categoryEnTitle, setCategoryContent, onComplete)
    }

    const handleContentSelection = (item: Content) => {
        const onComplete = () => {
        }
        getContentByEnName(setCurrentContentLoading, item.contentEnTitle, setCurrentContent, onComplete)
    }

    return (
        <div className="pmx-instructions">
            {!currentContent ?
                <>
                    {!currentCategory &&
                        <div className={`instructions-categories`}>
                            <h3>دسته بندی ها</h3>
                            <Spin spinning={currentCategoryLoading}>
                                <Spin spinning={categoriesLoading}>
                                    <div className="categories">
                                        {categories?.map((item) => (
                                            <div
                                                key={item.categoryId}
                                                className={`instruction-category`}
                                                onClick={() => handleCategorySelection(item)}
                                            >
                                                <div className="category-icon"><BookOutlined/></div>
                                                <span>{item.categoryTitle}</span>
                                            </div>
                                        ))}
                                    </div>
                                </Spin>
                            </Spin>
                        </div>
                    }


                    {currentCategory &&
                        <>
                            <div className="category-header">
                                <h3>{categories.find((item) => item.categoryId === currentCategory.categoryId).categoryTitle}</h3>
                                <Button type={buttonType.outlined} text='بازگشت' icon={<LeftArrow/>}
                                        onClick={() => setCurrentCategory(undefined)}/>
                            </div>
                            <Divider/>
                            <div className="category-content">
                                <aside>
                                    <h4>دسته بندی ها</h4>
                                    <Spin spinning={currentCategoryLoading}>
                                        <div className="categories">
                                            {categories.map((item) =>
                                                <div key={item.categoryId}
                                                     className={`instruction-category ${currentCategory.categoryId === item.categoryId ? 'selected' : ''}`}
                                                     onClick={() => handleCategorySelection(item)}>
                                                    <div className="category-icon"><BookOutlined/></div>
                                                    <span>{item.categoryTitle}</span>
                                                    {currentCategory.categoryId === item.categoryId &&
                                                        <LeftArrow id='active-arrow'/>}
                                                </div>
                                            )}
                                        </div>
                                    </Spin>
                                </aside>
                                    <Spin spinning={currentContentLoading}>
                                <ul>
                                        {
                                            categoryContent.map((item) =>
                                                <li className='pmx-instruction'
                                                >
                                                    <span><BookIcon/>{item.contentTitle}</span>
                                                    <a onClick={() => handleContentSelection(item)}><LinkIcon/></a>
                                                </li>
                                            )
                                        }
                                </ul>
                                    </Spin>
                            </div>
                        </>
                    }

                    {!currentCategory &&
                        <div className="pmx-popular-instructions">
                            <h3>آموزش های پر بازدید</h3>
                            <Spin spinning={topicContentLoading || currentContentLoading}>
                                <ul>
                                    {topicContent?.map((item) =>
                                            <li className='pmx-instruction' key={item.contentId}>
                                                <span>{item.contentTitle}</span>
                                                <a
                                                    onClick={() => handleContentSelection(item)}>
                                                    <LinkIcon/>
                                                </a>
                                            </li>
                                    )}
                                </ul>
                            </Spin>
                        </div>
                    }
                </>
                :
                <>
                    <div className="help-header">
                        <h3>{currentCategory ? currentCategory.categoryTitle : currentContent.title}</h3>
                        <Button type={buttonType.outlined} text='بازگشت' icon={<LeftArrow/>} onClick={() => {
                            setCurrentContent(undefined)
                        }}/>
                    </div>
                    <Divider/>
                    <div className="help-content">
                        <Spin spinning={currentContentLoading}>
                            {currentContent && currentContent.description && (
                                <div dangerouslySetInnerHTML={{__html: currentContent.description}}/>
                            )}
                        </Spin>
                    </div>
                </>
            }
        </div>
    )
}
