import "./help.scss";
import faq from "assets/images/home/faq.png";
import referral from "assets/images/home/referral.png";
import instructions from "assets/images/home/instructions.png";
import { ReactComponent as HelpArrow } from "assets/icons/home/help-arrow.svg";
import { useNavigate } from "react-router";

interface Props {}

export const Help = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className="pmx-help">
      <div className="pmx-faq-card" onClick={() => navigate("/faq")}>
        <div className="bg">
          <img src={faq} />
        </div>
        <h4>
          سوالات پرتکرار <HelpArrow onClick={() => navigate("/faq")} />
        </h4>
        <p>
          از قسمت سوالات پر تکرار به تمامی سوالاتی که ممکن است در هر مرحله از
          استفاده از پی ام اکسچنج با آن رو به رو شوید دسترسی داشته باشید. ابتدا
          دسته بندی مورد نظر را انتخاب کنید و سپس به دنبال سوال مورد نظر خود
          بگردید.
        </p>
      </div>
      <div className="col">
        <div
          className="pmx-referral-card"
          onClick={() => navigate("/referral")}
        >
          <div className="bg">
            <img src={referral} />
          </div>
          <h4>کسب درآمد</h4>
          <HelpArrow onClick={() => navigate("/dashboard/referral")} />
        </div>
        <div
          className="pmx-instructions-card"
          onClick={() => navigate("/help")}
        >
          <div className="bg">
            <img src={instructions} />
          </div>
          <h4>راهنمای آموزش</h4>
          <HelpArrow onClick={() => navigate("/help")} />
        </div>
      </div>
    </div>
  );
};
