import {getIconPath} from "core/utils/getIconPath";
import "./mosts-of-last-24.scss";
import {Rate} from "../../../../../core/domain/rate/rate";
import React, {FC, useEffect, useState} from "react";
import {Spin} from "antd";
import {getLastMarkets, getTRXRate} from "../../../../../core/repositores/landing";
import {digitSeparator} from "../../../../../core/utils/decimalExtentions";
import {RootState} from "../../../../../core/redux/store";
import {connect, ConnectedProps} from "react-redux";
import uptrend from "../../../../../assets/icons/home/up-trend.png";
import downtrend from "../../../../../assets/icons/home/down-trend.png";
import chart from "../../../../../assets/icons/home/chart.png";
import {LastPrice} from "../../../../../core/domain/market/market";
import {
    OrderDetailsModalSkeleton
} from "../../../../../components/order-details-modal/order-details-modal-skeleton/order-details-modal-skeleton";
import {Last24Skeleton} from "./last-24-skeleton/last-24-skeleton";

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    rates: Rate[]
    ratesLoading: boolean
}

export const MostsOfLast24: FC<Props> = ({rates}) => {
    const [marketsIsLoading, setMarketsIsLoading] = useState(false);
    const [lastMarkets, setLastMarkets] = useState<LastPrice>()

    async function fetchData() {
        await getLastMarkets(setMarketsIsLoading, setLastMarkets)
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (

        <div className="pmx-last-24">
            <h3>
                ترین های ۲۴ ساعت گذشته
            </h3>
            <p>
                تابلو رهبران بازار کریپتو: با اطلاعات روز گذشته بازار به روز باشید و تحلیل کنید
            </p>
            <div className={'cards'}>
                <div className="stats-card">

                    <div className={'header'}>
                        <img src={uptrend}/>
                        <h4>
                            بیشترین رشد ها
                        </h4>
                    </div>
                    <div className="divider"/>
                    {(marketsIsLoading && !lastMarkets?.positiveGrowth) ?
                        <Last24Skeleton/> :
                        lastMarkets?.positiveGrowth?.map(market => <div
                            className={'currency'}>
                            <div className="currency-name">
                                <img src={getIconPath(market.symbol)}/>
                                <div className="col">
                                    {/*<span>{market.symbol}</span>*/}
                                    <span className="en">{market.symbol}</span>
                                </div>
                            </div>
                            <div
                                className={`changes ${
                                    market.changePercentage > 0 ? "bullish" : "bearish"
                                }`}
                            >
                                {market.changePercentage > 0 ? "↑" : "↓"}{" "}
                                ٪<span dir="ltr">{market.changePercentage}</span>
                                {market.changePercentage > 0 ? "+" : ""}{" "}
                            </div>
                        </div>)}

                </div>
                <div className="stats-card">
                    <div className={'header'}>
                        <img src={downtrend}/>
                        <h4>
                            بیشترین ریزش ها
                        </h4>
                    </div>
                    <div className="divider"/>
                    {(marketsIsLoading && !lastMarkets?.negativeGrowth) ?
                        <Last24Skeleton/> : lastMarkets?.negativeGrowth?.map(market => <div
                            className={'currency'}>
                            <div className="currency-name">
                                <img src={getIconPath(market.symbol)}/>
                                <div className="col">
                                    {/*<span>{market.symbol}</span>*/}
                                    <span className="en">{market.symbol}</span>
                                </div>
                            </div>
                            <div
                                className={`changes ${
                                    market.changePercentage > 0 ? "bullish" : "bearish"
                                }`}
                            >
                                {market.changePercentage > 0 ? "↑" : "↓"}{" "}
                                ٪<span dir="ltr">{market.changePercentage}</span>
                                {market.changePercentage > 0 ? "+" : ""}{" "}
                            </div>
                        </div>)}


                </div>
                <div className="stats-card">
                    <div className={'header'}>
                        <img src={chart}/>
                        <h4>
                            گران ترین ها </h4>
                    </div>
                    <div className="divider"/>
                    {marketsIsLoading && !lastMarkets?.mostExpensive ?
                        <Last24Skeleton/> : lastMarkets?.mostExpensive?.map(market => <div
                            className={'currency'}>
                            <div className="currency-name">
                                <img src={getIconPath(market.symbol)}/>
                                <div className="col">
                                    {/*<span>{market.symbol}</span>*/}
                                    <span className="en">{market.symbol}</span>
                                </div>
                            </div>
                            <div
                                className={`changes ${
                                    market.changePercentage > 0 ? "bullish" : "bearish"
                                }`}
                            >
                                {market.changePercentage > 0 ? "↑" : "↓"}{" "}
                                ٪<span dir="ltr">{market.changePercentage}</span>
                                {market.changePercentage > 0 ? "+" : ""}{" "}
                            </div>
                        </div>)}

                </div>

            </div>
        </div>
    );
};

export default connector(MostsOfLast24);
