import "./bank-account.scss";
import {SwiperProps} from "swiper/react";
import {ReactComponent as Plus} from "assets/icons/bank-account/Plus.svg";
import {ReactComponent as Trash} from "assets/icons/bank-account/Trash.svg";
import {ReactComponent as Warning} from 'assets/icons/bank-account/Warning.svg'
import {ReactComponent as LeftArrow} from 'assets/icons/bank-account/LeftArrow.svg'
import {detect_bank, formatCardNumber} from "core/utils/bankDetecter";
import {Button, buttonType, Card, Input, inputType, Modal} from "components";
import {BankAccount} from "../../../../core/domain/bank-account/bank-account";
import {createBankAccount, deleteBankAccount, getAllBankAccounts,} from "../../../../core/repositores/bank-account";
import {Page} from "../../../../components/page/page";
import {faNumToEnNum} from "../../../../core/utils/decimalExtentions";
import {Empty} from "antd";
import {FC, useEffect, useRef, useState} from "react";


interface Props { }

export const BankAccountManagement: FC<Props> = (props) => {
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>();
  const [selectedCard, setSelectedCard] = useState<BankAccount | null>(null);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [addButtonIsLoading, setAddButtonIsLoading] = useState(false);
  const [deleteButtonIsLoading, setDeleteButtonIsLoading] = useState<boolean>(false)
  const [cardNumberValue, setCardNumberValue] = useState<string>("");

  const [addCartModalIsOpen, setAddCartModalIsOpen] = useState(false);
  const [removeCartModalIsOpen, setRemoveCartModalIsOpen] =
    useState(false);

  const nextEl = useRef<HTMLDivElement>(null);
  const prevEl = useRef<HTMLDivElement>(null);
  const paginationEl = useRef<HTMLDivElement>(null);
  const swiper = useRef<SwiperProps>(null);

  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const openAddCartModal = () => {
    setAddCartModalIsOpen(true)
  };

  const closeAddCartModal = () => {
    setCardNumberValue("");
    setAddCartModalIsOpen(false);
  };

  const openRemoveCartModal = (selectedCard: BankAccount) => {
    setSelectedCard(selectedCard);
    setRemoveCartModalIsOpen(true);
  };

  const closeRemoveCartModal = () => setRemoveCartModalIsOpen(false);

  const onCardNumberChange = (value: string) =>
    setCardNumberValue(formatCardNumber(faNumToEnNum(value)));

  const onAfterCartAdd = () => {
    setAddCartModalIsOpen(false);
    fetchData();
  };

  const fetchData = async () => {
    await getAllBankAccounts(setPageIsLoading, setBankAccounts);
  };

  const onAddCartHandle = () => {
    createBankAccount(
      setAddButtonIsLoading,
      { cardNumber: cardNumberValue.replaceAll("-", "") },
      onAfterCartAdd,
    );
  };

  const onDeleteCardHandle = () => {
    deleteBankAccount(
        setDeleteButtonIsLoading,
        selectedCard.id,
        () => setRemoveCartModalIsOpen(false)
    )
  }

  useEffect(() => {
    !bankAccounts && fetchData();
  }, []);

  return (
    <Page loading={pageIsLoading} className="pmx-bank-account">
      <Modal
        title="افزودن کارت"
        className="add-cart-modal"
        isOpen={addCartModalIsOpen}
        setIsOpen={setAddCartModalIsOpen}
        onClose={closeAddCartModal}
      >
        <p>کارت خود را از کادر زیر برای پرداخت انتخاب و یا از طریق دکمه ی افزودن ، کارت خود را اضافه نمایید.</p>
        <Input
          placeholder="شماره کارت خود را وارد کنید"
          value={cardNumberValue}
          onChange={(e) => onCardNumberChange(e.target.value)}
          dir={cardNumberValue ? "ltr" : "rtl"}
          onPressEnter={onAddCartHandle}
          type={inputType.cardNumber}
          icon={
            detect_bank(cardNumberValue) && (
              <img
                src={`/banks/${detect_bank(cardNumberValue)?.bank_name}.svg`}
              />
            )
          }
        />
        <span><Warning />توجه داشته باشید شماره کارت وارد شده حتما باید به نام شما باشد</span>
        <div className="buttons">
          <Button
            type={buttonType.primary}
            text="افزودن کارت"
            disabled={addButtonIsLoading || cardNumberValue.length !== 19}
            onClick={onAddCartHandle}
            loading={addButtonIsLoading}
          />
          <Button
            type={buttonType.outlined}
            text="بازگشت"
            onClick={closeAddCartModal}
            icon={<LeftArrow />}
          />
        </div>
      </Modal>
      <Modal
        title="آیا از حذف کارت اطمینان دارید؟"
        className="remove-cart-modal"
        isOpen={removeCartModalIsOpen}
        setIsOpen={setRemoveCartModalIsOpen}
      >
        <p>در صورت حذف کارت، کارت شما حذف میشود اما امکان اضافه ی مجدد آن را دارید.</p>
        <div className="card-info">
          <span className="name-logo">
            <img
              className="logo"
              src={`banks/${selectedCard?.englishBankName?.toLowerCase()}.svg`}
              width={24}
              height={24}
            />
            {selectedCard?.bankName}
          </span>
          <span className="card-number">
            {selectedCard?.cardNumber}
          </span>

        </div>
        <div className="buttons">
          <Button type={buttonType.error} text="بله، حذف شود" loading={deleteButtonIsLoading} onClick={onDeleteCardHandle} />
          <Button
            type={buttonType.outlined}
            text="خیر، منصرف شدم"
            onClick={() => closeRemoveCartModal()}
          />
        </div>
      </Modal>
      <Card
        className="cards-list"
        title="حساب های بانکی"
        divider
        button={<Button type={buttonType.outlined} text="افزودن کارت" icon={<Plus />} onClick={() => openAddCartModal()} />}
      >
        {bankAccounts && bankAccounts.length > 0 ? (
          <>
            {bankAccounts.map((bank) => (
              <div className="card">
                <div className="bg">
                  <img
                      alt="logo"
                    className="logo"
                    src={`banks/${bank.englishBankName?.toLowerCase()}.svg`}
                  />
                </div>
                <div className="icons">
                  <img
                      alt="logo"
                    className="logo"
                    src={`banks/${bank.englishBankName?.toLowerCase()}.svg`}
                  />
                  {/*<div className="delete" onClick={() => openRemoveCartModal(bank)}>*/}
                  {/*  <Trash />*/}
                  {/*</div>*/}
                </div>
                <p>
                  {formatCardNumber(bank.cardNumber)
                    .split("-")
                    .map((e) => (
                      <span>{e}</span>
                    ))}
                </p>
                <div>
                  <span> </span>
                  <span className="en"> </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <Empty description="کارت بانکی یافت نشد." />
        )}

      </Card>
    </Page>
  );
};
