import {Button, buttonType} from "components";
import * as React from "react";
import {useEffect} from "react";
import {InputMaterial} from "components/input-material/input-material";

import "./register.scss";
import {Link, useNavigate} from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import {RegisterPageInputType,} from "core/domain/login/login";
import {ToEn} from "core/utils/decimalExtentions";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {verifyCode} from "../../../../core/repositores/login";
import {setUserData} from "../../../../core/redux/actions";
import {RootState} from "../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const Register: React.FC = () => {
  const [values, setValues] = React.useState<RegisterPageInputType>();
  const [pageIsLoading, setPageIsLoading] = React.useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
    let queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        var referralCode = queryParams.get('ref');
        if (referralCode) {
            setValues({...values, referal: referralCode})
        }
    }, [])
  const onVerifyHandle = (code?: string) => {
    verifyCode(
      setPageIsLoading,
      { referal: values.referal, code: code },
      (data) => dispatch(setUserData(data)),
      () => navigate("/dashboard"),
      () => null,
    );
  };
  return (
    <div className="pmx-login-register">
      <div className="title">
        <h1>تایید شماره موبایل</h1>
        <h3>کد تایید ارسال شده به شماره {localStorage.phone} را وارد کنید.</h3>
      </div>
      <div className="fields">
        <div className="code">
          <label>کد ارسال شده به شماره بالا را وارد کنید.</label>
          <ReactCodeInput
            className={`code-input`}
            fieldWidth={52}
            fieldHeight={52}
            values={values?.code.split("")}
            autoFocus={true}
            onChange={(value) => {
              setValues({ ...values, code: ToEn(value) });
            }}
            onComplete={(code) => onVerifyHandle(code)}
            fields={5}
          />
          <div className="timer">
            <CountdownCircleTimer
              isPlaying
              duration={60}
              onComplete={(time) => {}}
              size={50}
              colors={["#0072FE", "#ff4545"]}
              colorsTime={[60, 0.000001]}
              strokeWidth={10}
              trailStrokeWidth={0}
            ></CountdownCircleTimer>
          </div>
        </div>
          {
              queryParams.get('ref') !== null &&
              <InputMaterial
                  label="کد دعوت (اختیاری)"
                  placeholder="کد دعوت خود را وارد کنید"
                  value={values?.referal}
                  onChange={(e) => setValues({...values, referal: e.target.value})}
                  onPressEnter={onVerifyHandle}
              />}
      </div>

      <div className="buttons">
        <Button
          onClick={() => onVerifyHandle()}
          type={buttonType.primary}
          text="تایید شماره موبایل"
          loading={pageIsLoading}
          disabled={pageIsLoading || values?.code.split("").length !== 5}
        />
      </div>

      <Link to="/login">ویرایش شماره</Link>
    </div>
  );
};
export default connector(Register);

function useQuery() {
    throw new Error("Function not implemented.");
}

