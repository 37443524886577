import "./help.scss";
import {Divider} from "antd";
import {HelpLanding} from "./components/landing/landing";
import {Footer} from "../home/components/footer/footer";
import {ReactNode, useEffect, useState} from "react";
import {Instructions} from "./components/instructions/instructions";
import {getCategoriesByTopicEnName, getContentByTopicEnName} from "../../../core/repositores/content";
import {Category, Content} from "../../../core/domain/content/content";
import {TOPIC_HELP} from "../../../core/constants/content";

interface Props {
    children?: ReactNode;
}

export const Help = (props: Props) => {
    const [categories, setCategories] = useState<Category[]>()
    const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);

    const [topicContent, setTopicContent] = useState<Content[]>()
    const [topicContentLoading, setTopicContentLoading] = useState<boolean>(false)

    useEffect(() => {
        getCategoriesByTopicEnName(setCategoriesLoading, TOPIC_HELP, setCategories)
        getContentByTopicEnName(setTopicContentLoading, TOPIC_HELP, setTopicContent)
    }, []);

    return (
        <div className="pmx-help-page">
            <HelpLanding/>
            <Instructions
                categories={categories}
                categoriesLoading={categoriesLoading}
                topicContent={topicContent}
                topicContentLoading={topicContentLoading}
            />
            <Divider/>
            <Footer/>
        </div>
    );
};
