import React from "react";
import "./last-24-skeleton.scss";

export const Last24Skeleton: React.FC = () => {
    return (
        <div className="order-details-modal-skeleton">
            
            <div className="data-skeleton">
                {Array.from({length: 7}).map((_, index) => (
                    <div className="row-skeleton" key={index}>
                        <div className="skeleton-part"></div>
                        <div className="skeleton-part"></div>
                    </div>
                ))}
            </div>
        </div>
    );
};
