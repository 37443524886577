import "./authentication-skeleton.scss";
import { Card } from "components";
import { CardTypes } from "components";

export const AuthenticationSkeleton = () => {

  return (
    <div className='pmx-authentication-skeleton'>
      <Card
        className="skeleton-pros"
      >
        <div className="skeleton-head-row">
          <h4 className='skeleton-title'></h4>
          <div className='skeleton-row'>
            <span></span>
            <p></p>
          </div>
        </div>
        <p className="first-line"></p>
        <p className="second-line"></p>
        <p className="third-line"></p>
      </Card>
    </div>
  );
};
