import {Route, Routes} from "react-router-dom";
import {Login} from "./pages/login/login";
import "./pmx.scss";
import Dashboard from "./pages/dashboard/dashboard";
import DashboardIndexPage from "./pages/dashboard/index/dashboard-index-page";
import Authentication from "./pages/dashboard/authentication/authentication";
import {Orders} from "./pages/dashboard/orders/orders";
import {BankAccountManagement} from "./pages/dashboard/bank-account/bank-account";
import {Referral} from "./pages/dashboard/referral/referral";
import {Security} from "./pages/dashboard/security/security";
import Register from "./pages/login/register/register";
import {Home} from "./pages/home/home";
import {About} from "./pages/about/about";
import {FAQ} from "./pages/faq/faq";
import {Terms} from "./pages/terms/terms";
import {Help} from "./pages/help/help";
import {Invite} from "./pages/invite/invite";

export const Pmx: React.FC = () => {
  return (
    <div className="pmx">
      <Routes>
        <Route path="about" element={<About />} />

        <Route path="faq" element={<FAQ />} />

        <Route path="terms" element={<Terms />} />

        <Route path="help" element={<Help />} />

        <Route path="invite" element={<Invite />} />

        {/*<Route path="login">*/}
        {/*  <Route path="" element={<Login children={<Signin />} />} />*/}
        {/*  <Route path="code" element={<Login children={<LoginCode />} />} />*/}
        {/*  <Route path="password" element={<Login children={<Password />} />} />*/}
        {/*  <Route path="two-step" element={<Login children={<TwoStep />} />} />*/}
        {/*</Route>*/}
        <Route path="register" element={<Login children={<Register />} />} />
        <Route
          path="dashboard"
          element={<Dashboard children={<DashboardIndexPage />} />}
        />
        <Route
          path="authentication"
          element={<Dashboard children={<Authentication />} />}
        />
        <Route
          path="security"
          element={<Dashboard children={<Security />} />}
        />
        <Route path="orders" element={<Dashboard children={<Orders />} />} />
        <Route
          path="bank-account"
          element={<Dashboard children={<BankAccountManagement />} />}
        />
        <Route
          path="referral"
          element={<Dashboard children={<Referral />} />}
        />
        <Route index path="*" element={<Home />} />
      </Routes>
    </div>
  );
};
