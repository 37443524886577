// MobileMenu.tsx
import "./mobile-menu.scss";
import React from "react";
import MobileMenuItem from "../mobile-menu/mobile-menu-item/mobile-menu-item";
import {useLocation} from "react-router-dom";
import {ReactComponent as UserTesting} from "assets/icons/dashboard/mobile-menu/User_Testing.svg";
import {ReactComponent as CreditCard} from "assets/icons/dashboard/sidebar/Credit_Card.svg";
import {ReactComponent as Trophy} from "assets/icons/dashboard/sidebar/Trophy.svg";
import {ReactComponent as Lock} from "assets/icons/dashboard/mobile-menu/Lock.svg";
import useOnBlur from "core/utils/useOnBlur";

interface PropTypes {
    state?: any;
    items?: any[];
}

const dropdownItems = [
    {
        id: 1,
        title: "احراز هویت",
        icon: <UserTesting/>,
        link: "/authentication",
    },
    // {
    //   id: 2,
    //   title: "خرید و فروش",
    //   icon: <ShoppingCart />,
    //   link: "/create-order",
    // },
    // {
    //   id: 3,
    //   title: "سوابق",
    //   icon: <TextDocument />,
    //   link: "/orders",
    // },
    {
        id: 4,
        title: "اطلاعات بانکی",
        icon: <CreditCard/>,
        link: "/bank-account",
    },
    {
        id: 5,
        title: "کسب درآمد",
        icon: <Trophy/>,
        link: "/referral",
    },
    {
        id: 6,
        title: "امنیت",
        icon: <Lock/>,
        link: "/security",
    },
    // {
    //   id: 7,
    //   title: "خروج از حساب",
    //   icon: <Logout />,
    //   link: "#",
    // },
];

const MobileMenu: React.FC<PropTypes> = ({state, items}) => {
    const location = useLocation();
    const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
    const dropdownParentRef = React.useRef(null);

    useOnBlur(dropdownParentRef, () => {
        setDropdownIsOpen(false);
    });

    return (
        <>
            <div className={`mobile-dropdown ${dropdownIsOpen ? "open" : ""}`}>
                    {dropdownItems.map((item) => (
                        <MobileMenuItem
                            key={item.id}
                            text={item.title}
                            icon={item.icon}
                            link={item.link}
                            active={item.link === location.pathname}
                        />
                    ))}
                </div>
            <aside className={`pmx-mobile-menu ${dropdownIsOpen ? 'open' : ""}`} ref={dropdownParentRef}>
                <ul className="mobile-menu-list">
                    {items.map((menuItem, index) => (
                        <li key={index}>
                            {menuItem.isDrawer ? (
                                <div
                                    className={`mobile-menu-item ${dropdownIsOpen ? "open" : ""}`}
                                    onClick={() =>
                                        dropdownIsOpen
                                            ? setDropdownIsOpen(false)
                                            : setDropdownIsOpen(true)
                                    }
                                >
                                    {menuItem.icon}
                                    {menuItem.text}
                                </div>
                            ) : (
                                <MobileMenuItem
                                    text={menuItem.text}
                                    icon={menuItem.icon}
                                    link={menuItem.link}
                                    active={menuItem.link === location.pathname}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            </aside>
        </>
    );
};

export default MobileMenu;
