import * as React from "react";
import {useEffect, useState} from "react";

import "./dashboard-index-page.scss";
import {BalanceBox} from "./balance-box/balance-box";
import {InviteBox} from "./invite-box/invite-box";
import {PriceCard} from "./price-card/price-card";
import {LevelBox} from "./level-box/level-box";
import {DashboardSlider} from "./slider/slider";
import {DashboardRecentTransactions} from "./recent-transactions/recent-transactions";
import {Page} from "../../../../components/page/page";
import {RootState} from "../../../../core/redux/store";
import {connect, ConnectedProps} from "react-redux";
import {Rate} from "../../../../core/domain/rate/rate";
import {getMineOrder} from "../../../../core/repositores/orders";
import {Order, ReferralInfo} from "../../../../core/domain";
import {getMineReferralInfo} from "../../../../core/repositores/referral";
import {PriceCardSkeleton} from "./price-card/price-card-skeleton/price-card-skeleton";
import {getRates} from "../../../../core/repositores/landing";

const mapState = (state: RootState) => ({
  state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const DashboardIndexPage: React.FC<Props> = ({ state }) => {
  const [ratesIsLoading, setRatesIsLoading] = useState(false);
  const [rates, setRates] = useState<Rate[]>([
    {
      name: "تتر",
      symbol: "USDT",
      sellPrice: 0,
    },
    {
      name: 'پرفکت مانی',
      symbol: 'PM',
      sellPrice: 0
    }
  ]);
  const [isReferralInfoLoading, setIsReferralInfoLoading] =
    React.useState(true);
  const [referralInfo, setReferralInfo] = React.useState<ReferralInfo>();
  const [transactionsIsLoading, setTransactionsIsLoading] =
    React.useState(false);
  const [transactions, setTransactions] = React.useState<Order[]>([
    // {
    //   "orderId": "abc123",
    //   "trackingCode": "S638414269358875746",
    //   "sourceSymbol": "BTC",
    //   "destinationSymbol": "ETH",
    //   "sourceAmount": 0.5,
    //   "destinationAmount": 10.25,
    //   "state": { "id": 1, "title": "کنسل شده" },
    //   "creationDate": "2024-01-30T12:30:45Z",
    //   "type": { "id": 2, "title": "خرید" }
    // },
    // {
    //   "orderId": "def789",
    //   "trackingCode": "S638413343804608625",
    //   "sourceSymbol": "XRP",
    //   "destinationSymbol": "LTC",
    //   "sourceAmount": 75.75,
    //   "destinationAmount": 9500.00,
    //   "state": { "id": 2, "title": "لغو شده" },
    //   "creationDate": "2024-01-30T14:45:30Z",
    //   "type": { "id": 1, "title": "فروش" }
    // },
    // {
    //   "orderId": "ghi456",
    //   "trackingCode": "lmn789",
    //   "sourceSymbol": "ADA",
    //   "destinationSymbol": "DOT",
    //   "sourceAmount": 120.00,
    //   "destinationAmount": 80.50,
    //   "state": { "id": 3, "title": "در حال پرداخت" },
    //   "creationDate": "2024-01-30T10:15:20Z",
    //   "type": { "id": 3, "title": "خرید" }
    // },
    // {
    //   "orderId": "jkl012",
    //   "trackingCode": "stu345",
    //   "sourceSymbol": "ETH",
    //   "destinationSymbol": "BTC",
    //   "sourceAmount": 1.30,
    //   "destinationAmount": 2.75,
    //   "state": { "id": 7, "title": "پرداخت شده" },
    //   "creationDate": "2024-01-30T11:20:55Z",
    //   "type": { "id": 2, "title": "فروش" }
    // },
    // {
    //   "orderId": "mno789",
    //   "trackingCode": "vwx234",
    //   "sourceSymbol": "LTC",
    //   "destinationSymbol": "ADA",
    //   "sourceAmount": 0.80,
    //   "destinationAmount": 5.00,
    //   "state": { "id": 7, "title": "پرداخت شده" },
    //   "creationDate": "2024-01-30T13:05:40Z",
    //   "type": { "id": 1, "title": "خرید" }
    // },
    // {
    //   "orderId": "pqr345",
    //   "trackingCode": "ghi678",
    //   "sourceSymbol": "DOT",
    //   "destinationSymbol": "XRP",
    //   "sourceAmount": 500.00,
    //   "destinationAmount": 2.25,
    //   "state": { "id": 3, "title": "در حال پرداخت" },
    //   "creationDate": "2024-01-30T15:00:10Z",
    //   "type": { "id": 3, "title": "فروش" }
    // }
  ]);

  const fetchData = async () => {
    await getRates(setRatesIsLoading, setRates);
    await getMineReferralInfo(setIsReferralInfoLoading, setReferralInfo);
    await getMineOrder(setTransactionsIsLoading, setTransactions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className="pmx-dashboard-index-page">
      <div className="head-row">
        <BalanceBox />
        <InviteBox
          referralInfo={referralInfo}
          isLoading={isReferralInfoLoading}
        />
        <div className="prices">
          {ratesIsLoading ? (
            <>
              <PriceCardSkeleton />
              <PriceCardSkeleton />
            </>
          ) : (
            rates
                ?.filter((rate) => rate.symbol !== "CRYPTO" && rate.symbol !== "PM")
              .map((rate) => <PriceCard rate={rate} />)
              .slice(0, 2)
          )}
        </div>
        <LevelBox level={state?.profileData?.level} />
      </div>
      <DashboardSlider />
      <DashboardRecentTransactions
        transactions={transactions}
        isLoading={transactionsIsLoading}
      />
    </Page>
  );
};

export default connector(DashboardIndexPage);
