import { PasswordForm } from "./password-form/password-form";
import { TwoFAForm } from "./twofa-form/twofa-form";
import { LogTable } from "./log-table/log-table";
import "./security.scss";
import { Page } from "../../../../components/page/page";

interface Props { }

export const Security: React.FC<Props> = (props) => {
  return (
    <Page className="pmx-security">
      <div className="upper">
        <PasswordForm />
        <TwoFAForm />
      </div>
      <div>
        <LogTable />
      </div>
    </Page>
  );
};