import './hints.scss';
import { ReactComponent as TimerIcon } from 'assets/icons/terms/timer.svg'
import { ReactComponent as IdentityIcon } from 'assets/icons/terms/identity.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/terms/warning.svg'
import { ReactComponent as DebitCardIcon } from 'assets/icons/terms/debit-card.svg'
import { ReactComponent as RulesIcon } from 'assets/icons/terms/rules.svg'

export const Hints = () => {
    return (
        <div className="pmx-hints">
            <div className="hint">
                <div className="timer-icon">
                    <TimerIcon />
                </div>
                <h4>قانون ۷۲ ساعت</h4>
                <p>واریزی های ریالی با تشخیص کارشناس ۷۲ ساعت غیر قابل برداشت می باشد.</p>
            </div>
            <div className="hint">
                <div className="identity-icon">
                    <IdentityIcon />
                </div>
                <h4>مدارک هویتی</h4>
                <p>چنانچه خرید شما از سقف مجاز عبور کند شما ملزم به آپلود مدارک هستید.</p>
            </div>
            <div className="hint">
                <div className="warning-icon">
                    <WarningIcon />
                </div>
                <h4>عدم لغو سفارش</h4>
                <p>هیچ سفارشی امکان لغو ندارد و پس از ارسال مستندات تسویه خواهد شد.</p>
            </div>
            <div className="hint">
                <div className="card-icon">
                    <DebitCardIcon />
                </div>
                <h4>اجاره حساب</h4>
                <p>چنانچه حساب کاربری و بانکی خود را در اختیار دیگران قرار دهید  عواقب آن برعهده شما می باشد.</p>
            </div>
            <div className="hint">
                <div className="rules-icon">
                    <RulesIcon />
                </div>
                <h4>پذیرش قوانین</h4>
                <p>ثبت نام و ثبت سفارش به منزله پذیرفتن قوانین مجموعه پی ام ایکس چنج می باشد.</p>
            </div>
        </div>
    )
}