import './definitions.scss'

export const Definitions = () => {
    return (
        <div className="pmx-definitions">
            <h2>تعاریف</h2>
            <p>تعاریف مفهوم های به کار برده شده در وبسایت و پنل کاربری</p>

            <ul>
                <li>
                    <div className="definition-number">1</div>
                    <div className="col">
                        <h4>آدرس وب سایت</h4>
                        <p>تنها آدرس معتبر و رسمی مجموعه پی ام ایکسچنج به نشانی https://pmxchange.co می باشد و هر گونه آدرس دیگر فاقد اعتبار بوده و ارتباطی با این مجموعه ندارد.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">2</div>
                    <div className="col">
                        <h4>آدرس ربات تلگرامی</h4>
                        <p> آدرس آی دی @Pmxchange_bot ربات تلگرامی می باشد که توسط همین آدرس از طریق پیام رسان تلگرام قابل دسترس می باشد.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">3</div>
                    <div className="col">
                        <h4>کاربر</h4>
                        <p>شخصی است که بنابه اراده ی خود و بدون هیچ گونه اجبار از خدمات مجموعه پی ام ایکسچنج استفاده می نماید.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">4</div>
                    <div className="col">
                        <h4>ارز الکترونیکی</h4>
                        <p>یک ابزار مالی بدون بهره مانند پرفکت مانی،تتر، بیت کوین و ... است که مورد خرید فروش قرار می گیرد و از آن می توان در سایت های پذیرنده به عنوان یک حواله ی پولی استفاده کرد.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">5</div>
                    <div className="col">
                        <h4>والت</h4>
                        <p>منظور از والت همان کیف پول یا حساب بانکی ارزهای دیجیتال می باشد که جهت نگهداری، ارسال و دریافت ارزهای دیجیتال به کار می رود.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">6</div>
                    <div className="col">
                        <h4>آدرس والت</h4>
                        <p>منظور رشته ای از حروف یا اعداد لاتین است که توسط والت کاربر جهت دریافت ارز دیجیتال در اختیار او قرار می گیرد. این آدرس برای هر ارز دیجیتال ممکن است متفاوت باشد.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">7</div>
                    <div className="col">
                        <h4>روش پرداخت</h4>
                        <p>عبارت است از هر روشی که برای انتقال وجه در پی ام ایکسچنج مجاز تلقی می شود. پرداخت می تواند توسط ارز الکترونیکی یا انتقال بانکی حضوری یا اینترنتی یا توسط کارت های بانکی از درگاه بانکی پی ام ایکسچنج و یا دیگر روش های پرداخت باشد.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">8</div>
                    <div className="col">
                        <h4>هش تراکنش یا TXID</h4>
                        <p>نوعی پیگیری سفارش می باشد که در زمان فروش رمزارز توسط کاربر به پی ام ایکس چنج جهت تکمیل سفارش فروش ارسال شده و در صورت خرید رمزارز از پی ام ایکس چنج هش تراکنش در اختیار کاربر قرار می گیرد.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">9</div>
                    <div className="col">
                        <h4>سفارش تکمیل شده</h4>
                        <p>سفارشی می باشد که توسط کاربر در مجموعه پی ام ایکسچنج ثبت شده و انجام آن نیز در موعد مقرر (حداکثر ۳۰ ثانیه پس از مشاهده پیش فاکتور) صورت گرفته باشد.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">10</div>
                    <div className="col">
                        <h4>سفارش تایید نشده</h4>
                        <p>سفارشی می باشد که نیاز به احراز هویت با ارسال مدارک داشته ولی در زمان مقرر احراز هویت تایید نشده است؛ وضعیت این نوع سفارش بعد از تکمیل و تایید احراز هویت به انجام شده تغییر می کند.</p>
                    </div>
                </li>
                <li>
                    <div className="definition-number">11</div>
                    <div className="col">
                        <h4>سفارش باز</h4>
                        <p>سفارشی می باشد که پرداخت ارزدیجیتال از سمت کاربر با موفقیت انجام نشده و کاربر سفارش را تکمیل نکرده است.</p>
                    </div>
                </li>
            </ul>
        </div>
    )
}