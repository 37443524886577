import {Button, buttonType} from "../../../../../components";
import {ReactComponent as BackIcon} from "assets/icons/general/arrow-left-white.svg";
import {ReactComponent as Stopwatch} from "assets/icons/invite/stopwatch.svg";
import {ReactComponent as PlusCirlcle} from "assets/icons/invite/plus-circle.svg";
import {ReactComponent as UpCircle} from "assets/icons/invite/up-circle-outlined.svg";
import {ReactComponent as PercentShaped} from "assets/icons/invite/percent-shape-outlined.svg";
import {ReactComponent as SwiperRight} from 'assets/icons/home/swiper-right.svg'
import {ReactComponent as SwiperLeft} from 'assets/icons/home/swiper-left.svg'
import fastWithdrawlImg from "assets/images/invite/fast-withdrawl.png";
import bag from "assets/images/invite/bag.png";
import withdrawIncomeImg from "assets/images/invite/withdrawl-income.png";
import statsImg from "assets/images/invite/stats-icon.png";
import receiveProfitImg from "assets/images/invite/receive-profit.png";
import topReferralImg from "assets/images/invite/top-referral.png";
import percentImg from "assets/images/invite/percent.png";
import "./invite-friends.scss";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, EffectFade, Autoplay} from "swiper/modules";
import SwiperCore from "swiper";
import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/modules';
import {useRef} from 'react';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export const InviteFriends = () => {

    const swiperRef = useRef(null);

    const slideNext = () => {
        if (swiperRef?.current && swiperRef?.current?.swiper) {
            swiperRef?.current?.swiper?.slideNext();
        }
    };

    const slidePrev = () => {
        if (swiperRef?.current && swiperRef?.current?.swiper) {
            swiperRef?.current?.swiper?.slidePrev();
        }
    };

    return (
        <>
            <div className={"pmx-invite-friends"}>
                <div className={"title"}>
                    <h3>دوستانت رو دعوت کن؛</h3>
                    <h3>سود کارمزدش رو با ما شریک شو!</h3>
                </div>
                <div className={"overlay"}></div>
                <div className={"features"}>
                    <Button
                        type={buttonType.primary}
                        icon={<BackIcon/>}
                        text={"دعوت از دوستان"}
                    />
                    <div className={"feature-item"}>
                        <img src={fastWithdrawlImg}/>
                        <button>
                            <Stopwatch/>
                            <span>برداشت سریع</span>
                        </button>
                    </div>
                    <div className={"feature-item "}>
                        <img src={withdrawIncomeImg}/>
                        <button>
                            <PlusCirlcle/>
                            <span>۲۷،۸۳۴،۹۵۶ تومان برداشت درآمد:</span>
                        </button>
                    </div>
                    <div className={"feature-item "}>
                        <img src={statsImg}/>
                        <button className={"stats"}>
                            <div>
                                <UpCircle/>
                                <span>۵،۶۸۰،۳۴۰ تومان</span>
                            </div>
                            <div>
                                <div>
                                    <span>سهم شما:</span>
                                    <p>۲،۳۴۰،۱۷۰ تومان</p>
                                </div>
                                <div>
                                    <span>درصد شراکت:</span>
                                    <p>۲،۳۴۰،۱۷۰ تومان</p>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div className={"feature-item"}>
                        <img src={receiveProfitImg}/>
                        <button>
                            <PlusCirlcle/>
                            <span>۶۸۰،۵۰۰ تومان دریافت سود:</span>
                        </button>
                    </div>
                    <div className={"feature-item"}>
                        <img src={topReferralImg}/>
                        <button>
                            <PercentShaped/>
                            <span>بالاترین درصد مشارکت:</span>
                        </button>
                    </div>
                </div>
                <div className={"extra-profit-box"}>
                    <div className={"gradient-overlay"}></div>
                    <div className={"text-content"}>
                        <h3>سود ویژه 30 درصد</h3>
                        <p>
                            با جرات میتوانیم بگوییم تنها صرافی ایرانی هستیم که ۵۰ درصد سود خود
                            را با کاربرانمان به اشتراک گذاشته ایم. از هر تراکنش کاربران در زیر
                            مجموعه شما، مبلغ ۵ هزار تومان به کیف پول شما بصورت مادام العمر واریز
                            خواهد شد.
                        </p>
                    </div>
                    <img src={bag}/>
                </div>
            </div>
            <div className={"pmx-invite-friends-mobile"}>
                <div className={"upper"}>
                    <h3 className={"title"}>
                        دوستانت رو دعوت کن؛<br/> سود کارمزدش رو با ما شریک شو!
                    </h3>
                    <Button
                        type={buttonType.primary}
                        icon={<BackIcon/>}
                        text={"دعوت از دوستان"}
                    />

                    <Swiper
                        slidesPerView={1}
                        // pagination={{ clickable: true }}
                        ref={swiperRef}
                    >
                        <SwiperSlide>
                            <div className={"feature-item"}>
                                <img src={fastWithdrawlImg}/>
                                <button>
                                    <Stopwatch/>
                                    <span>برداشت سریع</span>
                                </button>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className={"feature-item "}>
                                <img src={withdrawIncomeImg}/>
                                <button>
                                    <PlusCirlcle/>
                                    <span>۲۷،۸۳۴،۹۵۶ تومان برداشت درآمد:</span>
                                </button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={"feature-item "}>
                                <img src={statsImg}/>
                                <button className={"stats"}>
                                    <div>
                                        <UpCircle/>
                                        <span>۵،۶۸۰،۳۴۰ تومان</span>
                                    </div>
                                    <div>
                                        <div>
                                            <span>سهم شما:</span>
                                            <p>۲،۳۴۰،۱۷۰ تومان</p>
                                        </div>
                                        <div>
                                            <span>درصد شراکت:</span>
                                            <p>۲،۳۴۰،۱۷۰ تومان</p>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={"feature-item"}>
                                <img src={receiveProfitImg}/>
                                <button>
                                    <PlusCirlcle/>
                                    <span>۶۸۰،۵۰۰ تومان دریافت سود:</span>
                                </button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={"feature-item"}>
                                <img src={topReferralImg}/>
                                <button>
                                    <PercentShaped/>
                                    <span>بالاترین درصد مشارکت:</span>
                                </button>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div
                        className="next-navigation"
                        onClick={slideNext}
                    >
                        <SwiperLeft/>
                    </div>
                    <div
                        className="pre-navigation"
                        onClick={slidePrev}
                    >
                        <SwiperRight/>
                    </div>
                </div>
                <div className={"extra-profit-box"}>
                    <h3>سود ویژه 30 درصد</h3>
                    <p>با جرات میتوانیم بگوییم تنها صرافی ایرانی هستیم که ۵۰ درصد سود خود را با کاربرانمان به اشتراک
                        گذاشته ایم. از هر تراکنش کاربران زیر مجموعه، ۵ هزار تومان به کیف پول شما بصورت دائم واریز خواهد
                        شد.</p>
                    <div className={"inner"}>
                        <img src={percentImg} alt={"Percent"} className={"percent-image"}/>
                        <img src={bag} alt={"Bag"} className={"bag-image"}/>
                    </div>
                </div>
            </div>
        </>
    );
};
