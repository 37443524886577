import {
  LoginBody,
  LoginResponse,
  Response,
  SendCodeBody,
  SendCodeResponse,
} from "../domain";
import { postGeneric, postViaAuth, responseValidator } from "../apis/api";
import { API } from "../apis";
import toast from "react-hot-toast";
import { getUserProfile } from "./profile";
import { setLocalStorageItems } from "core/utils/setLocalStorageItems";

export async function sendCode(
  setLoading: any,
  data: SendCodeBody,
  onComplete: (response: SendCodeResponse) => void,
  onError?: (response: Response<any>) => void,
) {
  setLoading(true);
  await postGeneric<SendCodeResponse>(API.user.sendCode, data).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        onComplete(response.data);
      } else onError && onError(response);
    },
  );
}

export async function verifyCode(
  setLoading: any,
  data: LoginBody,
  setData: (data: LoginResponse) => void,
  onComplete: () => void,
  onNullToken: () => void,
  onError?: (response: Response<any>) => void,
) {
  setLoading(true);
  await postGeneric<LoginResponse>(API.user.verifyCode, data).then(
    async (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        if (response.data.isTwoFactorEnabled === true) {
          onNullToken();
        } else {
          toast.success("ورود شما موفقیت آمیز بود.");
          setLocalStorageItems(response.data);
          setData(response.data);
          onComplete();
        }
      } else onError && onError(response);
    },
  );
}

export async function verifyTwoStepCode(
  setLoading: any,
  data: LoginBody,
  setData: (data: LoginResponse) => void,
  onComplete: () => void,
) {
  setLoading(true);
  await postGeneric<LoginResponse>(API.user.verifyTwoStepLogin, data).then(
    async (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        toast.success("ورود شما موفقیت آمیز بود.");
        setLocalStorageItems(response.data);
        setData(response.data);
        await getUserProfile(setLoading);
        onComplete();
      }
    },
  );
}

export async function setPasswordPost(
  setLoading: any,
  data: LoginBody,
  setData: (data: LoginResponse) => void,
  onComplete: () => void,
  onNullToken: () => void,
  onError?: (response: Response<any>) => void,
) {
  setLoading(true);
  await postViaAuth<LoginResponse>(API.user.setPassword, data).then(
    async (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        if (response.data.access_token === null) {
          onNullToken();
        } else {
          toast.success("ورود شما موفقیت آمیز بود.");
          setLocalStorageItems(response.data);
          setData(response.data);

          await getUserProfile(setLoading);
          onComplete();
        }
      } else onError && onError(response);
    },
  );
}
