import * as React from "react";
import {useEffect, useState} from "react";
import "./order-details-modal.scss";
import {Badge, BadgeType, Button, buttonType, Modal} from "../index";
import {Order, OrderDetail, OrderState} from "../../core/domain";
import moment from "jalali-moment";
import {digitSeparator} from "../../core/utils/decimalExtentions";
import {getOrderByTrackingCode} from "../../core/repositores/orders";
import {ReactComponent as Copy} from "../../assets/icons/dashboard/copy.svg";
import {copyToClipboard} from "../../core/utils/copyToClipboard";
import {OrderDetailsModalSkeleton} from "./order-details-modal-skeleton/order-details-modal-skeleton";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  order: Order;
}

export const OrderDetailsModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  order,
}) => {
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [orderData, setOrderData] = useState<OrderDetail>();

  const getBadgeType = () => {
    if (
      order?.state?.id === OrderState.Succeed ||
      order?.state?.id === OrderState.Paid
    )
      return BadgeType.success;
    else if (
      order?.state?.id == OrderState.Canceled ||
      order?.state?.id == OrderState.Rejected
    )
      return BadgeType.error;
    else return BadgeType.waiting;
  };

  const fetchData = async () => {
    await getOrderByTrackingCode(
      setModalIsLoading,
      setOrderData,
      order?.trackingCOde,
    );
  };

  useEffect(() => {
    !orderData && isOpen ? fetchData() : setOrderData(undefined);
  }, [isOpen]);

  return (
    <Modal
      title="جزئیات تراکنش"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="order-details-modal"
    >
      {modalIsLoading && !orderData ? (
        <OrderDetailsModalSkeleton />
      ) : (
        orderData && (
          <>
            <Badge text={orderData.state.title} type={getBadgeType()} />
            <div className="data">
              <div className="row">
                <span>ارز مبداء</span>
                <p className="en">{orderData.sourceSymbol}</p>
              </div>
              <div className="row">
                <span>ارز مقصد</span>
                <p className="en">{orderData.destinationSymbol}</p>
              </div>
              <div className="row">
                <span>مقدار مبداء</span>
                <p className={orderData.sourceSymbol === "IRT" ? "fa" : "en"}>
                  {orderData.sourceSymbol == "IRT"
                    ? digitSeparator(orderData.sourceAmount)
                    : orderData.sourceAmount}
                  {` `}
                  {orderData.sourceSymbol === "IRT"
                    ? "تومان"
                    : orderData.sourceSymbol}
                </p>
              </div>
              <div className="row">
                <span>مقدار مقصد</span>
                <p
                  className={
                    orderData.destinationSymbol === "IRT" ? "fa" : "en"
                  }
                >
                  {orderData.destinationSymbol == "IRT"
                    ? digitSeparator(orderData.destinationAmount)
                    : orderData.destinationAmount}{" "}
                  {` `}
                  {orderData.destinationSymbol === "IRT"
                    ? "تومان"
                    : orderData.destinationSymbol}
                </p>
              </div>
              <div className="row">
                <span>کد پیگیری</span>
                <p className="en copy">
                  <Copy
                    onClick={() =>
                      copyToClipboard(orderData?.trackingCode, "کد پیگیری")
                    }
                  />
                  {orderData?.trackingCode}
                </p>
              </div>
              <div className="row">
                <span>نوع سفارش</span>
                <p>
                  {orderData.trackingCode?.startsWith("B")
                    ? "خرید"
                    : orderData?.trackingCode?.startsWith("S")
                    ? "فروش"
                    : "معامله"}
                </p>
              </div>
              {orderData.destinationWalletAddress && (
                <div className="row">
                  <span>آدرس کیف پول مقصد</span>
                  <p className="en copy long-text">
                    {orderData.destinationWalletAddress}
                  </p>
                </div>
              )}
              {orderData.destinationMoneyAccount && (
                <div className="row">
                  <span>آدرس کیف پول پرفکت مانی</span>
                  <p className="en copy">{orderData.destinationMoneyAccount}</p>
                </div>
              )}
              {orderData.digitalRecept && (
                <div className="row">
                  <span>رسید دیجیتال</span>
                  <p className="en copy long-text">
                    <a
                      href={orderData.digitalRecept}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {orderData.digitalRecept}
                    </a>
                  </p>
                </div>
              )}
              {orderData.txid && (
                <div className="row">
                  <span>تی ایکس ایدی</span>
                  <p className="en copy long-text">
                    <Copy
                      onClick={() => copyToClipboard(orderData.txid, "هش")}
                    />
                    {orderData.txid}
                  </p>
                </div>
              )}
              {orderData.voucher && (
                <div className="row">
                  <span>کد ووچر</span>
                  <p className="en copy">
                    <Copy
                      onClick={() =>
                        copyToClipboard(orderData.voucher, "کد ووچر")
                      }
                    />
                    {orderData.voucher}
                  </p>
                </div>
              )}
              {orderData.voucherActivation && (
                <div className="row">
                  <span>کد فعالساز ووچر</span>
                  <p className="en copy">
                    <Copy
                      onClick={() =>
                        copyToClipboard(
                          orderData.voucherActivation,
                          "کد فعالساز ووچر",
                        )
                      }
                    />
                    {orderData.voucherActivation}
                  </p>
                </div>
              )}
              {orderData.batchNumber && (
                <div className="row">
                  <span>بچ نامبر</span>
                  <p className="en copy">
                    <Copy
                      onClick={() =>
                        copyToClipboard(orderData.batchNumber, "بچ نامبر")
                      }
                    />
                    {orderData.batchNumber}
                  </p>
                </div>
              )}
              <div className="row">
                <span>زمان و تاریخ سفارش</span>
                <p>
                  {moment
                      .utc(orderData.paymentDate ?? orderData.creationDate)
                    .locale("fa")
                    .local()
                    .format("HH:mm:ss - jYYYY/jMM/jDD")}
                </p>
              </div>
            </div>
          </>
        )
      )}
      <Button
        type={buttonType.primary}
        text="بازگشت"
        onClick={() => setIsOpen(false)}
      />
    </Modal>
  );
};
