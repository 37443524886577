import "./tracking-order.scss";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "core/redux/actions";
import {OrderCardStages} from "core/domain/landing/landing";
import {FC, useEffect, useRef, useState} from "react";
import {RootState} from "../../../../../../../core/redux/store";
import {ReactComponent as Back} from "assets/icons/general/back.svg";
import {ReactComponent as CopyIcon} from "assets/icons/home/copy.svg";
import {ReactComponent as WarningIcon} from "assets/icons/home/warning.svg";
import {ReactComponent as RefreshIcon} from "assets/icons/home/refresh.svg";
import {ReactComponent as UserIcon} from "assets/icons/home/user.svg";
import {ReactComponent as InfoIcon} from "assets/icons/home/info.svg";
import {ReactComponent as CloseIcon} from "assets/icons/home/close.svg";
import {ReactComponent as SuccessIcon} from "assets/icons/home/success.svg";
import {ReactComponent as FailureIcon} from "assets/icons/home/failure.svg";
import {ConfigProvider, message, Spin, Statistic, Tour, TourProps, Upload, UploadProps,} from "antd";
import {Badge, BadgeType, Button, buttonType} from "components";
import {postViaAuth, responseValidator} from "core/apis/api";
import paymentmask from "assets/images/home/paymentmask.png";
import transaction from "assets/images/home/transaction.png";
import selfieguide from "assets/images/home/selfie-guide.jpg";
import {__API} from "core/apis/api-routes";
import {Enum, OrderState} from "core/domain";
import {getOrderByTrackingCode} from "core/repositores/orders";
import {digitSeparator} from "core/utils/decimalExtentions";
import moment from "jalali-moment";
import {copyToClipboard} from "core/utils/copyToClipboard";
import {Link} from "react-router-dom";
import {toast} from "react-hot-toast";
import fa_IR from "antd/lib/locale/fa_IR";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import {PmPopupModal} from "./pm-popup-modal/pm-popup-modal";

interface Transaction {
  orderId?: string;
  trackingCode?: string;
  sourceSymbol?: string;
  destinationSymbol?: string;
  sourceAmount?: number;
  destinationAmount?: number;
  fullName?: string;
  state?: Enum;
  creationDate?: string;
  paymentDate?: string;
  iBan?: string;
  txid?: string;
  voucher?: string;
  voucherActivation?: string;
  orderIdentifier?: string;
  destinationMoneyAccount?: string;
  digitalRecept?: string;
  batchNumber?: string;
  destinationWalletAddress?: string;
  attachedMessage?: string;
}

enum Step {
  TRACKING_ORDER,
  TRANSACTION_INFO,
  SELFIE_GUIDE,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
}

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

const TrackingOrder: FC<Props> = ({ state }) => {
  const tour1 = useRef(null);
  const tour2 = useRef(null);
  const tour3 = useRef(null);
  const tour4 = useRef(null);
  const tour5 = useRef(null);
  const tour6 = useRef(null);
  const tour7 = useRef(null);
  const tour8 = useRef(null);
  const tour9 = useRef(null);
  const tour10 = useRef(null);
  const tour11 = useRef(null);
  const tour12 = useRef(null);
  const tour13 = useRef(null);

  const [infoLoading, setInfoLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [transactionInfo, setTransactionInfo] = useState<Transaction>();
  const [selfie, setSelfie] = useState<any>();
  const [step, setStep] = useState<Step>(Step.TRACKING_ORDER);
  const [popupModalOpen, setPopupModalOpen] = useState<boolean>(false);
  const { Countdown } = Statistic;
  const orderData = state.orderData;
  const dispatch = useDispatch();
  const URL = window.location;

  const tourSteps: TourProps["steps"] = [
    {
      title: "شماره پیگیری",
      description:
        "شماره پیگیری را در این قسمت وارد کنید؛ شماره پیگیری شامل 19 کاراکتر میباشد.",
      target: () => tour1.current,
    },
    {
      title: "پیگیری سفارش",
      description:
        "در صورتی که کد پیگیری را بصورت صحیح وارد کرده اید، از این قسمت اقدام به پیگیری سفارش نمایید.",
      target: () => tour2.current,
    },
  ];

  const transactionTourSteps: TourProps["steps"] = [
    {
      title: "ارز مبدا",
      description:
        "ارزی که در طرف اول معامله و جهت خرید ارز مقصد استفاده میشود.",
      target: () => tour3?.current,
    },
    {
      title: "ارز مقصد",
      description:
        "در صورتی که کد پیگیری را بصورت صحیح وارد کرده اید، از این قسمت اقدام به پیگیری سفارش نمایید.",
      target: () => tour4?.current,
    },
    {
      title: "کد پیگیری",
      description: "میتوانید با استفاده از این کد، سفارش خود را پیگیری کنید. ",
      target: () => tour5?.current,
    },
    {
      title: "تی ایکس آیدی (هش)",
      description: "با استفاده از این هش میتوانید تراکنش های رمزارزی خود را پیگیری کنید.",
      target: () => tour6?.current,
    },
    {
      title: "آدرس کیف پول مقصد",
      description: "آدرس کیف پول مقصد جهت انجام تراکنش.",
      target: () => tour7?.current,
    },
    {
      title: "بچ نامبر",
      description: "با استفاده از بچ نامبر میتوانید تراکنش های پرفکت مانی را اعتبار سنجی و پیگیری کنید.",
      target: () => tour8?.current,
    },
    {
      title: "رسید دیجیتالی",
      description: "با کلیک کردن روی لینک رسید دیجیتالی، میتوانید رسید تراکنش خود را مشاهده کنید. ",
      target: () => tour9?.current,
    },
    {
      title: "کد ووچر",
      description: "کد ووچر جهت خرید و استفاده از ووچر های پرفکت مانی استفاده میشود.",
      target: () => tour10?.current,
    },
    {
      title: "کد فعالساز ووچر",
      description: "کد فعالساز ووچر جهت فعالسازی و استفاده از ووچر های پرفکت مانی استفاده میشود.",
      target: () => tour11?.current,
    },
    {
      title: "آدرس کیف پول پرفکت مانی",
      description: "از آدرس کیف پول پرفکت مانی جهت ارسال و دریافت پرداخت ها و انتقال پرفکت مانی به حساب دیگران استفاده میشود.",
      target: () => tour12?.current,
    },
    {
      title: "زمان باقی مانده تا واریز",
      description: "پس از گذشت این زمان، واریز شما انجام خواهد شد.",
      target: () => tour13?.current,
    },
  ];

  const [trackingCode, setTrackingCode] = useState<string>(
    URL.pathname.split("/")[1] === "tracking"
      ? URL.pathname.split("/")[2]
      : orderData?.trackingCode,
  );

  const fetchData = async () => {
    if (
      (trackingCode?.toUpperCase().startsWith("B") ||
        trackingCode?.toUpperCase().startsWith("S") ||
        trackingCode?.toUpperCase().startsWith("T")) &&
      trackingCode?.length === 19
    ) {
      await getOrderByTrackingCode(
        setInfoLoading,
        setTransactionInfo,
        trackingCode,
        () => {
          setStep(Step.TRANSACTION_INFO)
        },
      );
    } else {
      toast.error("کد پیگیری وارد شده نامعتبر می باشد.");
    }
  };

  const onBackClick = () =>
    dispatch(
      setOrderData({
        stage: OrderCardStages.CALCULATOR,
        tour: false,
        phone: null,
      }),
    );

  const getBadgeType = () => {
    if (
      transactionInfo?.state?.id === OrderState.Succeed ||
      transactionInfo?.state?.id === OrderState.Paid
    )
      return BadgeType.success;
    else if (
      transactionInfo?.state?.id == OrderState.Canceled ||
      transactionInfo?.state?.id == OrderState.Rejected
    )
      return BadgeType.error;
    else return BadgeType.waiting;
  };

  const handleImageSubmit = async () => {
    setButtonLoading(true);
    const formData = new FormData();
    if (selfie != "") {
      formData.append("File", selfie);
      formData.append("TrackingCode", transactionInfo?.trackingCode);
    }

    await postViaAuth(__API.user.uploadSelfie, formData, true, {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        const progress = Math.round((loaded / total) * 100);
      },
    }).then((response: any) => {
      if (responseValidator(response.status)) fetchData();
    });
    setButtonLoading(false);
  };

  useEffect(() => {
    if (
      (trackingCode?.toUpperCase().startsWith("B") ||
        trackingCode?.toUpperCase().startsWith("S") ||
        trackingCode?.toUpperCase().startsWith("T")) &&
      trackingCode?.length === 19
    ) {
      fetchData();
    } else {
      setTrackingCode("");
      setStep(Step.TRACKING_ORDER);
    }
    dispatch(
      setOrderData({
        ...orderData,
        trackingCode: trackingCode,
      }),
    );
  }, []);

  useEffect(() => {
    if (transactionInfo?.destinationSymbol?.toUpperCase() === "PM") setPopupModalOpen(true)
  }, [transactionInfo]);

  const imageDraggerProps: UploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: ({ file }) => {
      if (file) {
        setSelfie(file);
        message.success("عکس با موفقیت انتخاب شد");
      }
    },
  };

  return (
    <div className="pmx-tracking-order-stage">
      <PmPopupModal isOpen={popupModalOpen} setIsOpen={setPopupModalOpen} />
      {step === Step.TRACKING_ORDER && (
        <>
          <div className="tracking-order">
            <h3>پیگیری سفارش</h3>
            <p>
              شماره پیگیری خود را برای مشاهده وضعیت سفارش خود در کادر زیر وارد
              کنید.
            </p>
            <div ref={tour1}>
              <AntdInput
                dir="rtl"
                maxLength={19}
                value={trackingCode}
                placeholder="شماره پیگیری  را وارد کنید..."
                onChange={(e) => setTrackingCode(e.target.value.toUpperCase())}
                onPressEnter={fetchData}
                className="tracking-input"
              />
            </div>
            <div className={"buttons"} ref={tour2}>
              <Button
                type={buttonType.primary}
                text={"پیگیری سفارش"}
                onClick={() => {
                  fetchData();
                }}
                disabled={infoLoading || trackingCode?.length !== 19}
                loading={infoLoading}
              />
              <Button
                icon={<Back />}
                type={buttonType.ordercard_outlined}
                text={"انصراف"}
                onClick={() =>
                  dispatch(
                    setOrderData({
                      ...orderData,
                      trackingCode: "",
                      tour: false,
                      stage: OrderCardStages.CALCULATOR,
                    }),
                  )
                }
              />
            </div>
            <ConfigProvider locale={fa_IR} direction={"rtl"}>
              <Tour
                steps={tourSteps}
                open={step === Step.TRACKING_ORDER ? orderData.tour : false}
                onClose={() =>
                  dispatch(setOrderData({ ...orderData, tour: false }))
                }
              />
            </ConfigProvider>
          </div>
        </>
      )}
      {step === Step.TRANSACTION_INFO && (
        <>
          <img className="payment-mask" src={paymentmask} alt="Payment-Mask" />
          <div className="transaction-title">
            <img src={transaction} alt="Transaction" />
            <h3>وضعیت تراکنش پیگیری شده</h3>
            <p>
              از طریق فرم زیر میتوانید به اطلاعات پرداخت از جمله صورت حساب ها و
              همچنین وضعیت لحظه ای تراکنش دسترسی داشته باشید.
            </p>
          </div>
          <Spin spinning={infoLoading}>
            {transactionInfo && (
              <div className="transaction-info">
                {transactionInfo?.sourceSymbol && (
                  <div className="transaction-row" ref={tour3}>
                    <span>ارز مبدا</span>
                    <span className="en">{transactionInfo?.sourceSymbol}</span>
                  </div>
                )}
                {transactionInfo?.destinationSymbol && (
                  <div className="transaction-row" ref={tour4}>
                    <span>ارز مقصد</span>
                    <span className="en">
                      {transactionInfo?.destinationSymbol}
                    </span>
                  </div>
                )}
                {transactionInfo?.sourceAmount && (
                  <div className="transaction-row">
                    <span>مقدار مبدا</span>
                    <span>
                      {digitSeparator(transactionInfo?.sourceAmount)}{" "}
                      {transactionInfo?.sourceSymbol === "IRT"
                        ? "تومان"
                        : "واحد"}
                    </span>
                  </div>
                )}
                {transactionInfo?.destinationAmount && (
                  <div className="transaction-row">
                    <span>مقدار مقصد</span>
                    <span>
                      {transactionInfo.destinationSymbol === "IRT" ?
                        digitSeparator(transactionInfo.destinationAmount.toFixed(0))
                        : digitSeparator(transactionInfo?.destinationAmount.toFixed(2))
                      }
                      &nbsp;
                      {transactionInfo?.destinationSymbol === "IRT"
                        ? "تومان"
                        : "واحد"}
                    </span>
                  </div>
                )}
                {transactionInfo?.paymentDate && (
                  <div className="transaction-row">
                    <span>تاریخ و ساعت</span>
                    <span>
                      {moment
                        .utc(transactionInfo?.paymentDate)
                        .locale("fa")
                        .local()
                        .format("jYYYY/jMM/jDD - HH:mm")}
                    </span>
                  </div>
                )}
                {transactionInfo?.state && (
                  <div className="transaction-row">
                    <span>وضعیت تراکنش</span>
                    <Badge
                      type={getBadgeType()}
                      text={transactionInfo?.state?.title}
                    />
                  </div>
                )}
                {transactionInfo?.trackingCode && (
                  <div className="transaction-row" ref={tour5}>
                    <span>کد پیگیری</span>
                    <span id="tracking-code" className="en copiable">
                      {transactionInfo?.trackingCode}{" "}
                      <CopyIcon
                        onClick={() =>
                          copyToClipboard(
                            transactionInfo?.trackingCode,
                            "کد پیگیری",
                          )
                        }
                      />
                    </span>
                  </div>
                )}
                {transactionInfo?.txid &&
                    <div className="transaction-row" ref={tour6}>
                      <span>تی ایکس آیدی</span>
                      <span id="tx-id" className="en copiable">
                      {transactionInfo?.txid}
                        <CopyIcon
                            onClick={() =>
                                copyToClipboard(transactionInfo?.txid, "هش")
                            }
                        />
                    </span>
                    </div>
                }
                {transactionInfo?.destinationWalletAddress && (
                  <div className="transaction-row" ref={tour7}>
                    <span>آدرس کیف پول مقصد</span>
                    <span className="en copiable">
                      {transactionInfo?.destinationWalletAddress}
                      <CopyIcon
                        onClick={() =>
                          copyToClipboard(
                            transactionInfo?.destinationWalletAddress,
                            "آدرس کیف پول مقصد",
                          )
                        }
                      />
                    </span>
                  </div>
                )}
                {transactionInfo?.digitalRecept && (
                  <div className="transaction-row" ref={tour9}>
                    <span>رسید دیجیتالی</span>
                    <Link
                      style={{ textDecoration: "none", cursor: "pointer" }}
                      to={transactionInfo.digitalRecept}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      جهت مشاهده کلیک کنید
                    </Link>
                  </div>
                )}
                {transactionInfo?.voucher && (
                  <div className="transaction-row" ref={tour10}>
                    <span>کد ووچر</span>
                    <span id="voucher" className="en copiable">
                      {transactionInfo?.voucher}
                      <CopyIcon
                        onClick={() =>
                          copyToClipboard(
                            transactionInfo?.voucher,
                            "کد ووچر",
                          )
                        }
                      />
                    </span>
                  </div>
                )}
                {transactionInfo?.voucherActivation && (
                  <div className="transaction-row" ref={tour11}>
                    <span>کد فعالساز ووچر</span>
                    <span id="voucher-activation" className="en copiable">
                      {transactionInfo?.voucherActivation}
                      <CopyIcon
                        onClick={() =>
                          copyToClipboard(
                            transactionInfo?.voucherActivation,
                            "کد فعالساز ووچر",
                          )
                        }
                      />
                    </span>
                  </div>
                )}
                {transactionInfo?.batchNumber && (
                  <div className="transaction-row" ref={tour8}>
                    <span>بچ نامبر</span>
                    <span className="en copiable">
                      {transactionInfo?.batchNumber}
                      <CopyIcon
                        onClick={() =>
                          copyToClipboard(
                            transactionInfo?.trackingCode,
                            "بچ نامبر",
                          )
                        }
                      />{" "}
                    </span>
                  </div>
                )}
                {transactionInfo?.destinationMoneyAccount && (
                  <div className="transaction-row" ref={tour12}>
                    <span>آدرس کیف پول پرفکت مانی</span>
                    <span className="en copiable">
                      {transactionInfo?.destinationMoneyAccount}
                      <CopyIcon
                        onClick={() =>
                          copyToClipboard(
                            transactionInfo?.destinationMoneyAccount,
                            "آدرس کیف پول پرفکت مانی",
                          )
                        }
                      />
                    </span>
                  </div>
                )}
                {transactionInfo?.state?.id === 8 ||
                  transactionInfo?.state?.id === 9 ||
                  transactionInfo?.state?.id === 12 ||
                  transactionInfo?.state?.id === 13 ||
                  transactionInfo?.state?.id === 2 ? (
                  <div className="photo-alert">
                    <WarningIcon />
                    {(transactionInfo?.state?.id === 8 ||
                      transactionInfo?.state?.id === 9) && (
                        <span>
                          برای دریافت ارز خریداری شده نیاز به احراز هویت از طریق
                          آپلود عکس میباشد.
                        </span>
                      )}
                    {(transactionInfo?.state?.id === 12 ||
                      transactionInfo?.state?.id === 13) && (
                        <span>لطفاً منتظر تایید مدرک توسط ادمین باشید.</span>
                      )}
                    {transactionInfo?.state?.id === 2 && (
                      <span>
                        تصویر شما توسط ادمین رد شده است. به دلیل:{" "}
                        {transactionInfo?.attachedMessage}
                      </span>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </Spin>
          {(transactionInfo?.state?.id === 8 ||
            transactionInfo?.state?.id === 9 ||
            transactionInfo?.state?.id === 2) && (
              <>
                <div className="transaction-image-upload">
                  <Upload.Dragger {...imageDraggerProps}>
                    <>
                      {selfie && (
                        <img
                          src={window.URL.createObjectURL(selfie)}
                          alt="Uploaded"
                          height={160}
                        />
                      )}
                      {!selfie && (
                        <div>
                          <UserIcon />
                          <p>تصویر خود را مطابق نمونه آپلود کنید</p>
                        </div>
                      )}
                    </>
                  </Upload.Dragger>
                </div>
                {selfie && (
                  <p id="retry" onClick={() => setSelfie(undefined)}>
                    انتخاب مجدد <RefreshIcon />
                  </p>
                )}
                <div
                  className="transaction-image-guide"
                  onClick={() => setStep(Step.SELFIE_GUIDE)}
                >
                  <InfoIcon />
                  مشاهده ی راهنما و نمونه تصویر سلفی
                </div>
              </>
            )}
          {transactionInfo?.state?.id === 9 ||
            transactionInfo?.state?.id === 10 ||
            transactionInfo?.state?.id === 13 ? (
            <div className="transaction-timer" ref={tour13}>
              <h5>زمان باقی مانده تا واریز</h5>
              <Countdown
                value={
                  new Date(transactionInfo?.paymentDate).getTime() +
                  3 * 24 * 60 * 60 * 1000
                }
              />
            </div>
          ) : (
            <></>
          )}
          {transactionInfo?.state?.id !== 2 &&
            transactionInfo?.state?.id !== 8 &&
            transactionInfo?.state?.id !== 9 ? (
            <Button
              text="بستن فاکتور"
              type={buttonType.primary}
              onClick={() => onBackClick()}
            />
          ) : (
            <></>
          )}
          {transactionInfo?.state?.id === 2 ||
            transactionInfo?.state?.id === 8 ||
            transactionInfo?.state?.id === 9 ? (
            <div className="buttons">
              <Button
                type={buttonType.primary}
                text={"آپلود تصویر و احراز هویت"}
                loading={buttonLoading}
                disabled={
                  buttonLoading || !selfie || !transactionInfo?.trackingCode
                }
                onClick={handleImageSubmit}
              />
              <Button
                icon={<Back />}
                type={buttonType.ordercard_outlined}
                text={"بستن فاکتور"}
                onClick={() => onBackClick()}
              />
            </div>

          ) : (
            <></>
          )}
          <ConfigProvider locale={fa_IR} direction={"rtl"}>
            <Tour
              open={state?.orderData?.tour && step === Step.TRANSACTION_INFO}
              onClose={() =>
                dispatch(setOrderData({ ...state.orderData, tour: false }))
              }
              steps={transactionTourSteps}
            />
          </ConfigProvider>
        </>
      )}
      {step === Step.SELFIE_GUIDE && (
        <div className="pmx-selfie-guide">
          <div className="selfie-guide-header">
            <h3>راهنمای آپلود تصویر سلفی</h3>
            <CloseIcon onClick={() => setStep(Step.TRANSACTION_INFO)} />
          </div>
          <img src={selfieguide} alt="Selfie-Guide" />
          <p>
            اینجانب....به شماره ملی....فرزند.... درصحت سلامت عقلی قوانین پی ام ایکس چنج  (pmxchange.co)  را به صورت کامل مطالعه کرده و مسئولیت تمام خرید و فروش هایی که از حساب کاربری و حساب های بانکی خود انجام می دهم را بر عهده می گیرم و متعهد می شوم حساب کاربری و حساب های بانکی خود را در اختیار شخص دیگری قرار نداده و تمامی خرید ها برای خودم می باشد، درغیر این صورت تمام عواقب از جمله پولشویی و فیشینگ را می‌پذیرم.<br/>
            همچنین از روش های کلاهبرداری جدید به اسم کاریابی و آگهی هایی که در فضای مجازی در این خصوص درج می شود مطلع بوده و در صورت انجام خرید برای این اشخاص تمامی عواقب پولشویی، رد مال و جبران خسارت را می پذیرم.<br/><br/>
              🔸 تصویر باید از کیفیت وضوح بالا برخوردار باشد.<br/>
              🔸 مشخصات کارت شناسایی و تصویر آن باید کاملا واضح و خوانا باشد.<br/>
              🔸استفاده از کارت شناسایی نامعتبر و قدیمی مجاز نمی باشد. شما میتوانید فقط از کارت ملی هوشمند، شناسنامه جدید استفاده کنید.<br/>
              🔸 عکس باید زاویه و فاصله مناسب داشته باشد.<br/>
              🔸 برعکس بودن مشخصات دست نوشته و کارت شناسایی در تصویر باعث رد شدن احراز شما میشود برای رفع آن از دوربین اصلی برای عکاسی استفاده کنید.<br/>
              🔻در صورتی که هر یک از موارد فوق رعایت نشود احراز هویت شما رد خواهد شد.
          </p>
          <Button
            text="بستن راهنما"
            type={buttonType.primary}
            onClick={() => setStep(Step.TRANSACTION_INFO)}
          />
        </div>
      )}
      {step === Step.AUTHENTICATION_SUCCESS && (
        <div className="pmx-authentication-result">
          <img className="payment-mask" src={paymentmask} alt="Payment-Mask" />
          <SuccessIcon />
          <h3>تبریک، احراز هویت شما با موفقیت انجام شد!</h3>
          <p>
            احراز هویت شما با موفقیت به اتمام رسید و سطح شما به سطح برنزی ارتقا
            یافت، از این پس میتوانید به سادگی و با اطمینان خاطر سفارشات خود را
            تا سقف سطح کاربری خود ثبت و انجام دهید
          </p>
          <div className="buttons">
            <Button
              type={buttonType.primary}
              text={"تایید و ادامه"}
              loading={buttonLoading}
              disabled={
                buttonLoading || !selfie || !transactionInfo?.trackingCode
              }
              onClick={() => setStep(Step.TRANSACTION_INFO)}
            />
            <Button
              type={buttonType.ordercard_outlined}
              text={"بستن"}
              onClick={() => setStep(Step.TRANSACTION_INFO)}
            />
          </div>
        </div>
      )}
      {step === Step.AUTHENTICATION_FAILURE && (
        <div className="pmx-authentication-result">
          <img className="payment-mask" src={paymentmask} alt="Payment-Mask" />
          <FailureIcon />
          <h3>خطا، احراز هویت شما به مشکل خورد!</h3>
          <p>
            احراز هویت شما با موفقیت به اتمام رسید و سطح شما به سطح برنزی ارتقا
            یافت، از این پس میتوانید به سادگی و با اطمینان خاطر سفارشات خود را
            تا سقف سطح کاربری خود ثبت و انجام دهید
          </p>
          <div className="buttons">
            <Button
              type={buttonType.primary}
              text={"بازگشت به احراز هویت"}
              loading={buttonLoading}
              disabled={
                buttonLoading || !selfie || !transactionInfo?.trackingCode
              }
              onClick={() => setStep(Step.TRANSACTION_INFO)}
              icon={<Back style={{ stroke: "white" }} />}
            />
            <Button
              type={buttonType.ordercard_outlined}
              text={"بستن"}
              onClick={() => setStep(Step.TRANSACTION_INFO)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default connector(TrackingOrder);
