import {Button, buttonType, Input, inputType, Modal} from "components";
import "./enter-card.scss";
import {ReactComponent as PlusIcon} from "assets/icons/home/plus.svg";
import {ReactComponent as DownIcon} from "assets/icons/home/down.svg";
import {FC, Fragment, useEffect, useRef, useState} from "react";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "core/redux/actions";
import {OrderCardStages} from "core/domain/landing/landing";
import {BankAccount} from "core/domain";
import {createBankAccount, getAllBankAccounts,} from "core/repositores/bank-account";
import {detect_bank, formatCardNumber} from "core/utils/bankDetecter";
import {faNumToEnNum} from "core/utils/decimalExtentions";
import {ReactComponent as Warning} from "assets/icons/bank-account/Warning.svg";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {ConfigProvider, Divider, Spin, Tour, TourProps} from "antd";
import {RootState} from "core/redux/store";
import fa_IR from "antd/lib/locale/fa_IR";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

enum STEP {
  CHOOSE_CARD,
  ADD_CARD,
}

const EnterCard: FC<Props> = ({ state }) => {
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const [cardIsLoading, setCardIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState<STEP>(STEP.CHOOSE_CARD);
  const [bankAccountsModalIsOpen, setBankAccountsModalIsOpen] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>();
  const [cardNumber, setCardNumber] = useState("");
  const [selectedCard, setSelectedCard] = useState<BankAccount>();

  const tour1 = useRef(null);
  const tour2 = useRef(null);
  const tour3 = useRef(null);
  const tour4 = useRef(null);
  const tour5 = useRef(null);
  const tour6 = useRef(null);

  const chooseCardTourSteps: TourProps["steps"] = [
    {
      title: "انتخاب کارت بانکی",
      description: "کارت بانکی خود را از این بخش انتخاب نمایید.",
      target: () => tour1.current,
    },
    {
      title: "افزودن کارت بانکی",
      description:
        "در صورت نداشتن کارت بانکی یا جهت افزودن کارت های بیشتر، از این بخش اقدام نمایید.",
      target: () => tour2.current,
    },
    {
      title: "کد ملی",
      description: "از این بخش میتوانید اقدام به ادامه یا بازگشت نمایید.",
      target: () => tour3.current,
    },
  ];

  const addCardTourSteps: TourProps["steps"] = [
    {
      title: "وارد کردن شماره کارت",
      description: "شماره کارت بانکی 16 رقمی خود را وارد نمایید.",
      target: () => tour4.current,
    },
    {
      title: "توجه",
      description:
        "توجه داشته باشید شماره کارت وارد شده حتماً باید به نام شما باشد.",
      target: () => tour5.current,
    },
    {
      title: "افزودن کارت",
      description: "از این بخش میتوانید اقدام به افزودن کارت یا بازگشت نمایید.",
      target: () => tour6.current,
    },
  ];

  const fetchData = async () => {
    await getAllBankAccounts(
      setCardIsLoading,
      setBankAccounts,
      setSelectedCard,
    );
    bankAccounts && setSelectedCard(bankAccounts[0])
  };

  const onAddCardHandle = () => {
    createBankAccount(
      setButtonIsLoading,
      { cardNumber: cardNumber.replaceAll("-", "") },
      () => {
        setOrderData({ ...state.orderData, tour: false });
        setStep(STEP.CHOOSE_CARD);
        fetchData();
      },
    );
  };

  const onCardNumberChange = (value: string) =>
    setCardNumber(formatCardNumber(faNumToEnNum(value)));

  const dispatch = useDispatch();
  const onBackClick = () =>
    dispatch(
      setOrderData({
        ...state.orderData,
        stage: OrderCardStages.CALCULATOR,
        phone: null,
        tour: false,
      }),
    );

  const onNextStageClick = () =>
    dispatch(
      setOrderData({
        ...state.orderData,
        stage: OrderCardStages.RECEIVE_METHOD,
        bankAccount: selectedCard,
        tour: false
      }),
    );

  const onDeleteBankAccount = (id: string) => { };

  useEffect(() => {

    dispatch(setOrderData({...state.orderData, tour: false}));
    window.history.pushState(
        window.history.state,
        document.title,
        "/bankAccount"
    );

    dispatch(setOrderData({ ...state.orderData, tour: false }))
    !bankAccounts && fetchData();
  }, []);

  return (
    <>
      <Modal
        isOpen={bankAccountsModalIsOpen}
        setIsOpen={setBankAccountsModalIsOpen}
        className={"bankaccounts-modal"}
      >
        <ul className={"card-list"}>
          {bankAccounts &&
            bankAccounts.map((bank, index) => (
              <Fragment key={index}>
                {index !== 0 && <Divider type={"horizontal"} />}
                <li onClick={() => {setSelectedCard(bank); dispatch(setOrderData({...state.orderData, bankAccount: bank})); setBankAccountsModalIsOpen(false)}}>
                  <img
                    width={32}
                    height={32}
                    src={`banks/${bank?.englishBankName?.toLowerCase()}.svg`}
                    alt={"Bank Logo"}
                  />
                  <p>بانک {bank.bankName}</p>
                  <p className={"card-number"}>
                    {formatCardNumber(bank.cardNumber)
                      .split("-")
                      .map((e) => (
                        <span>{e}</span>
                      ))}
                  </p>
                </li>
              </Fragment>
            ))}
        </ul>
      </Modal>
      <div className="pmx-enter-card-stage">
        <Spin spinning={cardIsLoading}>
          {step === STEP.CHOOSE_CARD && (
            <>
              <div className="choose-card-content">
                <h3>انتخاب کارت</h3>
                <p>
                  کارت خود را از کادر زیر برای پرداخت انتخاب و یا از طریق دکمه ی
                  افزودن ، کارت خود را اضافه نمایید.
                </p>

                {bankAccounts && bankAccounts.length !== 0 && (
                  <div
                    className="card-row"
                    onClick={() => setBankAccountsModalIsOpen(true)}
                    ref={tour1}
                  >
                    <img
                      width={32}
                      height={32}
                      src={`banks/${selectedCard?.englishBankName?.toLowerCase()}.svg`}
                    />
                    <p>بانک {selectedCard?.bankName}</p>
                    <p className={"card-number"}>
                      {formatCardNumber(selectedCard?.cardNumber ?? "")
                        .split("-")
                        .map((e) => (
                          <span>{e}</span>
                        ))}
                    </p>
                    <div className="down-icon">
                      <DownIcon />
                    </div>
                  </div>
                )}
                <div ref={tour2}>
                  <Button
                    className="choose-button"
                    type={buttonType.ordercard_outlined}
                    text="افزودن کارت"
                    icon={<PlusIcon width={12} height={12} />}
                    onClick={() => setStep(STEP.ADD_CARD)}
                  />
                </div>

                <div ref={tour3}>
                  <OrderCardButtons
                    submitButton={{
                      text: "ادامه",
                      loading: buttonIsLoading,
                      disabled: buttonIsLoading || !selectedCard || !bankAccounts || bankAccounts.length === 0,
                      onClick: onNextStageClick,
                    }}
                    cancelButton={{
                      text: "بازگشت",
                      onClick: () => onBackClick(),
                    }}
                  />
                </div>
              </div>

              <ConfigProvider locale={fa_IR} direction={"rtl"}>
                <Tour
                  open={state?.orderData?.tour}
                  onClose={() =>
                    dispatch(setOrderData({ ...state.orderData, tour: false }))
                  }
                  steps={chooseCardTourSteps}
                />
              </ConfigProvider>
            </>
          )}
          {step === STEP.ADD_CARD && (
            <>
              <div className="add-card-content">
                <h3>افزودن کارت</h3>
                <p>
                  پس از اضافه نمودن شماره کارت خود، روی گزینه ی افزودن کارت
                  اقدام کنید
                </p>
                <div ref={tour4}>
                  <Input
                    placeholder="شماره کارت خود را وارد کنید..."
                    value={cardNumber}
                    onChange={(e) => onCardNumberChange(e.target.value)}
                    dir={cardNumber ? "ltr" : "rtl"}
                    onPressEnter={onAddCardHandle}
                    type={inputType.cardNumber}
                    icon={
                      detect_bank(cardNumber) && (
                        <img
                          src={`/banks/${detect_bank(cardNumber)
                            ?.bank_name}.svg`}
                        />
                      )
                    }
                  />
                </div>
                <span className="warning" ref={tour5}>
                  <Warning />
                  توجه داشته باشید شماره کارت وارد شده حتماً باید به نام شما
                  باشد
                </span>

                <div ref={tour6}>
                  <OrderCardButtons
                    submitButton={{
                      text: "افزودن کارت",
                      loading: buttonIsLoading,
                      disabled: buttonIsLoading,
                      onClick: onAddCardHandle,
                    }}
                    cancelButton={{
                      text: "بازگشت",
                      onClick: () => {
                        setCardNumber("");
                        setStep(STEP.CHOOSE_CARD);
                      },
                    }}
                  />
                </div>
              </div>
              <ConfigProvider locale={fa_IR} direction={"rtl"}>
                <Tour
                  open={state?.orderData?.tour}
                  onClose={() =>
                    dispatch(setOrderData({ ...state.orderData, tour: false }))
                  }
                  steps={addCardTourSteps}
                />
              </ConfigProvider>
            </>
          )}
        </Spin>
      </div>
    </>
  );
};

export default connector(EnterCard);
