import { Collapse } from 'antd'
import './faq.scss'
import { ReactComponent as ChevronDown } from 'assets/icons/invite/chevron_down.svg'

export const FAQ = () => {

    const collapseItems = [
        {
            key: "0",
            label: (
                <p>حداقل برداشت پاداش چه مقدار می باشد ؟</p>
            ),
            children: (
                <p>حداقل برداشت پاداش برای هر کاربر ۵۰ هزار تومان می باشد که با ثبت شبای بانکی برای کاربر واریز می شود</p>
            ),
        },
        {
            key: "1",
            label: (
                <p>آیا کاربر معرفی شده باید فعال باشد تا پاداش دریافت کنیم؟</p>
            ),
            children: (
                <p>بله هم معرف و هم شخص معرفی شده باید فعال بوده و خرید داشته باشند</p>
            )
        }
    ];

    return (
        <div className="pmx-frequently-asked-questions">
            <Collapse
                items={collapseItems}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                    <ChevronDown
                        style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }}
                    />
                )}
            />
        </div>
    )
}