import * as React from "react";
import "./page.scss";
import { Spin } from "antd";

interface Props {
  title?: string;
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
}

export const Page = (props: Props) => {
  return (
    <Spin spinning={props.loading ?? false}>
      <div className={`pmx-page ${props.className}`}>
        <div className="content">{props.children}</div>
      </div>
    </Spin>
  );
};
