import "./enter-wallet.scss";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "core/redux/actions";
import {OrderCardStages, Side} from "core/domain/landing/landing";
import {FC, useCallback, useEffect, useRef, useState} from "react";
import {RootState} from "../../../../../../../core/redux/store";
import {ReactComponent as DropdownIcon} from "assets/icons/home/dropdown.svg";
import {ReactComponent as Back} from "assets/icons/general/back.svg";
import {LoadingOutlined} from "@ant-design/icons";
import {ConfigProvider, Divider, message, Radio, RadioChangeEvent, Tour, TourProps} from "antd";
import {getViaAuth, postViaAuth, responseValidator} from "core/apis/api";
import {__API} from "core/apis/api-routes";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import {Network, PaginationList} from "../../../../../../../core/domain";
import toast from "react-hot-toast";
import {Button, buttonType} from "../../../../../../../components";
import {AddUserWalletBody, UserWalletResponse} from "core/domain/user-saved-wallets/user-saved-wallets";
import {addWallet, getMyWallets} from "../../../../../../../core/repositores/user-saved-wallets";
import {ReactComponent as PlusIcon} from 'assets/icons/general/plus.svg'
import {ReactComponent as BinIcon} from 'assets/icons/home/bin.svg'
import fa_IR from "antd/lib/locale/fa_IR";
import {RemoveWalletModal} from "./remove-wallet-modal/remove-wallet-modal";

enum STEP {
    ENTER_INFO,
    CHOOSE_NETWORK,
    ADD_WALLET,
    CHOOSE_WALLET
}

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

enum RADIO {
    MANUAL,
    SAVED
}

const EnterWallet: FC<Props> = ({state}) => {
    const orderData = state?.orderData;
    const [step, setStep] = useState<STEP>(STEP.ENTER_INFO);
    const [networks, setNetworks] = useState<Network[]>();
    const [selectedNetwork, setSelectedNetwork] = useState<Network>();
    const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
    const [chooseButtonIsLoading, setChooseButtonIsLoading] =
        useState<boolean>(false);
    const [destinationWallet, setDestinationWallet] = useState<string>();

    const [savedWallets, setSavedWallets] = useState<PaginationList<UserWalletResponse>>();
    const [savedWalletsLoading, setSavedWalletsLoading] = useState(false);
    const [addButtonLoading, setAddButtonLoading] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState<UserWalletResponse>()

    const [newWalletTitle, setNewWalletTitle] = useState<string>();
    const [newWalletAddress, setNewWalletAddress] = useState<string>();

    const [selectedRadio, setSelectedRadio] = useState<RADIO>(RADIO.SAVED)
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false)

    const dispatch = useDispatch();

    const tour1 = useRef(null);
    const tour2 = useRef(null);
    const tour3 = useRef(null);

    const tourSteps: TourProps["steps"] = [
        {
            title: "انتخاب شبکه",
            description:
                "ابتدا از این بخش، شبکه ی آدرس کیف پول خود را انتخاب نمایید.",
            target: () => tour1.current,
        },
        {
            title: "وارد کردن آدرس کیف پول",
            description:
                "سپس آدرس کیف پول خود را مطابق با شبکه ای که انتخاب نمودید، وارد نمایید.",
            target: () => tour2.current,
        },
        {
            title: "تغییر شماره همراه",
            description: "از این بخش میتوانید اقدام به ارسال یا بازگشت نمایید.",
            target: () => tour3.current,
        },
    ];

    const fetchNetworks = async () => {
        setChooseButtonIsLoading(true);
        await getViaAuth(
            `${__API.market.getNetworks}/${
                orderData.destinationMarket?.symbol ?? "USDT"
            }/false`,
        ).then((response: any) => {
            if (responseValidator(response.status)) {
                setNetworks(response.data.networks);
                setSelectedNetwork(response.data.networks[0])
                fetchWallets()
            }
            setChooseButtonIsLoading(false);
        });
    };

    const fetchWallets = async () => {
        await getMyWallets(setSavedWalletsLoading, setSavedWallets, (e: any) => {
            handleChooseWallet(e.items[0])
        })
    }

    const createBuyOrder = async () => {
        setButtonIsLoading(true);
        const body = {
            sourceAmount: orderData?.sourceAmount,
            destinationMarket: orderData?.destinationMarket?.symbol,
            network: selectedNetwork?.network,
            destinationWallet: selectedRadio === RADIO.SAVED ? selectedWallet.walletAddress : destinationWallet,
            bankAccount: orderData?.bankAccount?.id,
        };

        if (
            new RegExp(selectedNetwork.addressRegex).test(destinationWallet) === false
        ) {
            toast.error("آدرس کیف پول وارد شده معتبر نیست");
            return;
        }

        await postViaAuth(__API.orders.createBuy, body).then((response: any) => {
            setButtonIsLoading(false);
            if (responseValidator(response.status)) {
                dispatch(
                    setOrderData({
                        ...state.orderData,
                        stage: OrderCardStages.PAYMENT_METHOD,
                        trackingCode: response.data.trackingCode,
                        fee: response.data.fee,
                        destinationWallet,
                        network: selectedNetwork.network,
                    }),
                );
            }
        });
    };

    const handleSubmit = () => {
        if (!selectedNetwork) return toast.error("شبکه انتقال ارز را انتخاب کنید");
        orderData.side === Side.Buy
            ? createBuyOrder()
            : dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.BILL_PREVIEW,
                    destinationWallet,
                    network: selectedNetwork.network,
                }),
            );
    };
    const onBackClick = () =>
        state.orderData.side === Side.Buy
            ? dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.RECEIVE_METHOD,
                    // phone: null,
                    // destinationWallet: null,
                    // network: null,
                    tour: false
                }),
            )
            : dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.CALCULATOR,
                    // phone: null,
                    // destinationWallet: null,
                    // network: null,
                    tour: false
                }),
            );

    const handleAddWallet = () => {
        const data: AddUserWalletBody = {
            walletAddress: newWalletAddress,
            network: selectedNetwork.network,
            label: newWalletTitle
        }
        const onComplete = async () => {
            toast.success("کیف پول جدید با موفقیت اضافه شد")
            await getMyWallets(setAddButtonLoading, setSavedWallets, () => setStep(STEP.CHOOSE_WALLET))
            // handleChooseWallet({
            //     walletAddress: newWalletAddress,
            //     network: selectedNetwork.network,
            //     label: newWalletTitle,
            //     createdOn: "",
            // })

        }
        addWallet(setAddButtonLoading, data, onComplete)
    }

    const handleChooseWallet = (selectedWallet: UserWalletResponse) => {
        setStep(STEP.ENTER_INFO)
        if (selectedWallet) {
            setSelectedWallet(selectedWallet)
            setDestinationWallet(selectedWallet.label)
        }
    }

    const handleRemoveWalletModal = (selectedWallet: UserWalletResponse) => {
        if (selectedWallet) {
        setSelectedWallet(selectedWallet)
        setRemoveModalOpen(true)
        }
    }

    const handleRemoveWalletConfirm = () => {
        message.success("کیف پول با موفقیت حذف شد")
        setRemoveModalOpen(false)
        fetchWallets()
    }

    const handleRadioChange = (e: RadioChangeEvent) => {
        setSelectedRadio(e.target.value);
        if (e.target.value === RADIO.MANUAL)
            setDestinationWallet('')
        else {
            setDestinationWallet(selectedWallet.walletAddress)
        }
    };

    const fetchNetworksMemo = useCallback(fetchNetworks, []);

    useEffect(() => {
        fetchNetworksMemo();
    }, [fetchNetworksMemo]);

    return (
        <div className="pmx-enter-wallet-stage">
            <RemoveWalletModal isOpen={removeModalOpen} setIsOpen={setRemoveModalOpen} item={selectedWallet} onComplete={handleRemoveWalletConfirm} />
            {step === STEP.ENTER_INFO && (
                <>
                    <div className="choose-wallet-content">
                        <h3>آدرس کیف پول</h3>
                        <p>
                            لطفا آدرس کیف پول خود را جهت دریافت مقدار{" "}
                            {orderData?.destinationAmount}{" "}
                            {orderData?.destinationMarket?.name} وارد نمایید یا از بین آدرس های ثبت شده انتخاب کنید.
                        </p>
                        {selectedNetwork && <p className="text-danger">
                            حداقل میزان برداشت در این
                            شبکه {selectedNetwork.withdrawFee + selectedNetwork.withdrawMin} {state.orderData.destinationMarket.name} میباشد.
                        </p>}
                        <div
                            className="choose-network-button"
                            onClick={() => setStep(STEP.CHOOSE_NETWORK)}
                            ref={tour1}
                        >
              <span>
                {selectedNetwork?.name ?? "شبکه انتقال ارز را انتخاب کنید"}
              </span>{" "}
                            {chooseButtonIsLoading ? <LoadingOutlined/> : <DropdownIcon/>}
                        </div>

                        <Radio.Group onChange={handleRadioChange} value={selectedRadio}>
                            <Radio value={RADIO.SAVED}>انتخاب از کیف پول های ثبت شده</Radio>
                            <Radio value={RADIO.MANUAL}>ورود دستی آدرس کیف پول</Radio>
                        </Radio.Group>

                        {selectedRadio === RADIO.MANUAL &&
                        <div ref={tour2}>
                            <AntdInput
                                value={destinationWallet ?? ""}
                                onChange={(e) => setDestinationWallet(e.target.value)}
                                placeholder={`آدرس کیف پول در شبکه ${
                                    selectedNetwork?.name ?? ""
                                } را وارد کنید...`}
                            />
                        </div>
                        }

                        {selectedRadio === RADIO.SAVED &&
                            <div ref={tour2}>
                                <AntdInput
                                    className={"saved-wallet-input"}
                                    value={selectedWallet?.label ?? ""}
                                    onClick={() => setStep(STEP.CHOOSE_WALLET)}
                                    suffix={savedWalletsLoading ? <LoadingOutlined/> :
                                        savedWallets && savedWallets.items && savedWallets.items.length !== 0 &&
                                        <DropdownIcon style={{cursor: 'pointer'}} onClick={() => setStep(STEP.CHOOSE_WALLET)}
                                        />}
                                />
                            </div>
                        }

                        <Button className={"add-wallet-button"} type={buttonType.ordercard_outlined}
                                text={"افزودن آدرس کیف پول"} icon={<PlusIcon/>}
                                onClick={() => setStep(STEP.ADD_WALLET)}/>

                        <div ref={tour3}>
                            <OrderCardButtons
                                submitButton={{
                                    text: "ارسال",
                                    loading: buttonIsLoading,
                                    disabled:
                                        buttonIsLoading ||
                                        !destinationWallet ||
                                        chooseButtonIsLoading,
                                    onClick: handleSubmit,
                                }}
                                cancelButton={{
                                    text: "بازگشت",
                                    onClick: () => onBackClick(),
                                }}
                            />
                        </div>
                    </div>
                    <ConfigProvider locale={fa_IR} direction={"rtl"}>
                        <Tour
                            open={state?.orderData?.tour}
                            onClose={() =>
                                dispatch(setOrderData({...state.orderData, tour: false}))
                            }
                            steps={tourSteps}
                        />
                    </ConfigProvider>
                </>
            )}
            {step === STEP.CHOOSE_NETWORK && (
                <>
                    <div className="choose-network-content">
                        <Button text={"بازگشت"} icon={<Back/>} type={buttonType.ordercard_outlined}
                                onClick={() => setStep(STEP.ENTER_INFO)}/>
                        <ul>
                            {networks &&
                                networks.map((network) => (
                                    <li
                                        onClick={() => {
                                            setSelectedNetwork(network);
                                            setStep(STEP.ENTER_INFO);
                                        }}
                                    >
                                        <span>{network.name.toUpperCase()} - {network.network.toUpperCase()}  </span>
                                        <Back/>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </>
            )}
            {step === STEP.ADD_WALLET &&
                <>
                    <div className={"add-wallet-content"}>
                        <h3>افزودن کیف پول</h3>
                        <p>
                            لطفا عنوان و آدرس کیف پول خود را جهت ذخبره برای استفاده ی مجدد وارد نمایید.
                        </p>
                        <AntdInput placeholder={"عنوان آدرس کیف پول"} value={newWalletTitle}
                                   onChange={(e) => setNewWalletTitle(e.target.value)}/>
                        <AntdInput placeholder={`آدرس کیف پول در شبکه ${selectedNetwork?.name}`}
                                   value={newWalletAddress} onChange={(e) => setNewWalletAddress(e.target.value)}/>
                        <OrderCardButtons
                            submitButton={{
                                text: "افزودن و ادامه",
                                loading: addButtonLoading,
                                disabled:
                                    savedWalletsLoading || addButtonLoading || !newWalletTitle || !newWalletAddress || !selectedNetwork,
                                onClick: handleAddWallet,
                            }}
                            cancelButton={{
                                text: "بازگشت",
                                onClick: () => setStep(STEP.ENTER_INFO),
                            }}
                        />
                    </div>
                </>
            }
            {step === STEP.CHOOSE_WALLET &&
                <>
                    <div className={"choose-wallet-content"}>
                        <h3>انتخاب آدرس کیف پول</h3>
                        <p>
                            لطفا آدرس کیف پول خود را انتخاب نمایید.
                        </p>

                        <ul>
                            {savedWallets?.items.map((item, i) => (
                                <>
                                    {i !== 0 && <Divider type={"horizontal"}/>}
                                    <li key={i} onClick={() => {
                                        setSelectedWallet(item);
                                        handleChooseWallet(item)
                                    }}
                                        className={`${item === selectedWallet ? "active" : ""}`}>
                                        <div>
                                            <p>{item.label}</p>
                                            <p className={"en"}>{item.walletAddress}</p>
                                        </div>
                                        <div onClick={(e) => {
                                            e.stopPropagation()
                                            handleRemoveWalletModal(item)
                                        }}>
                                            <BinIcon/>
                                        </div>
                                    </li>
                                </>
                            ))}
                        </ul>

                        <OrderCardButtons
                            submitButton={{
                                text: "انتخاب و ادامه",
                                loading: savedWalletsLoading,
                                disabled:
                                    savedWalletsLoading || !selectedWallet,
                                onClick: () => {
                                    selectedWallet && handleChooseWallet(selectedWallet)
                                },
                            }}
                            cancelButton={{
                                text: "بازگشت",
                                onClick: () => setStep(STEP.ENTER_INFO),
                            }}
                        />
                    </div>
                </>
            }
        </div>
    );
};

export default connector(EnterWallet);
