export const KEY_ACCESS_TOKEN = "accessToken";
export const KEY_REFRESH = "refresh";
export const KEY_DATE = "date";
export const KEY_EXPIRES_IN = "expires_in";
export const KEY_IS_AUTHORIZED = "isAuthorized";
export const KEY_IS_TWO_FACTOR_ENABLED = "isTwoFactorEnabled";
export const KEY_LEVEL = "level";
export const KEY_ROLES = "roles";
export const KEY_PHONE = "phone";
export const KEY_PROFILE_DATA = "profile";
export const KEY_IS_CUSTOMER = "isCustomer";
