import { Footer } from '../home/components/footer/footer'
import { Definitions } from './definitions/definitions'
import { Hints } from './hints/hints'
import { TermsLanding } from './landing/landing'
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions'
import './terms.scss'

export const Terms = () => {
    return (
        <div className="pmx-terms">
            <TermsLanding />
            <p className='pmx-main-conditions'>شرایط اساسی و اولیه استفاده کاربران از خدمات مجموعه موضوع توافق نامه ی حاضر تدوین اصول اولیه از قبیل حقوق، تکالیف و مسئولیت‌ها و نیز دستورالعمل‌های لازم جهت استفاده کاربر، از خدمات مجموعه پی ام ایکسچنج برای خرید و فروش ارزهای دیجیتال می باشد.<br /> ـ این شرایط برای استفاده از خدمات مجموعه پی ام ایکسچنج شامل وب سایت پی ام ایکسچنج به آدرس pmxchange.co و ربات تلگرامی با آیدی @Pmxchange_bot که از این پس در این موافقتنامه پی ام ایکسچنج نامیده می شود، تنظیم گردیده است. لازم به ذکر است که هم کاربران و هم مجموعه پی ام ایکسچنج ملزم به انجام تعهدات خود در قالب این موافقتنامه هستند. پی ام ایکسچنج تابع قوانین جمهوری اسلامی ایران بوده و از قوانین حاکم بر آن تبعیت می کند.</p>
            <Hints />
            <Definitions />
            <TermsAndConditions />
            <Footer />
        </div>
    )
}