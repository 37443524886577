import {FC, useEffect, useState} from "react";
import "./google-authentication.scss";
import {ToEn} from "../../../../../../../core/utils/decimalExtentions";
import {Button, buttonType} from "../../../../../../../components";
import {ReactComponent as Back} from "assets/icons/general/back.svg";
import {ReactComponent as Clock} from "assets/icons/home/clock.svg";
import ReactCodeInput from "react-verification-code-input";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import * as React from "react";
import {RootState} from "../../../../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData, setUserData,} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {sendCode, verifyCode, verifyTwoStepCode,} from "../../../../../../../core/repositores/login";
import {ReactComponent as InfoCircle} from "assets/icons/general/info-error.svg";
import {phoneRegex} from "../../../../../../../core/utils/regexs";
import {ConfigProvider, Spin, Tour, TourProps} from "antd";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import fa_IR from "antd/lib/locale/fa_IR";

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

const GoogleAuthenticationStage: FC<Props> = ({state}) => {
    const {orderData} = state;
    const [googleCode, setGoogleCode] = useState("");
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const tourSteps: TourProps['steps'] = [
        {
            title: 'وارد کردن کد',
            description: 'کد 6 رقمی را از سرویس Google Authenticator وارد نمایید.',
            target: () => tour1.current,
        },
        {
            title: 'ورود',
            description: 'در صورتی که کد 6 رقمی را وارد کرده اید، از این بخش میتوانید اقدام به تایید کد یا بازگشت نمایید.',
            target: () => tour2.current,
        },
    ]

    const tour1 = React.useRef(null)
    const tour2 = React.useRef(null)

    const dispatch = useDispatch();
    const onBackClick = () =>
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.ENTER_PHONE,
                phone: null,
                tour: false,
            }),
        );

    async function onLoginHandle(codeValue?: any) {
        const body = {
            code: codeValue ?? googleCode,
            phoneNumber: orderData?.phone,
        };

        await verifyTwoStepCode(
            setButtonIsLoading,
            body,
            (data) => dispatch(setUserData(data)),
            () =>
                dispatch(
                    setOrderData({
                        ...state.orderData,
                        stage: orderData.forgetPassword ? OrderCardStages.SET_PASSWORD : OrderCardStages.CALCULATOR,
                    tour: false,
                    }),
                ),
        );
    }

    return (
        <div className={"pmx-google-authentication-stage"}>
            <h3>ورود کد Google Authenticator</h3>
            <p className={"description"}>
                کد 6 رقمی را از سرویس Google Authenticator وارد نمایید.
            </p>
            <div className="code">
                <div ref={tour1}>
                    <ReactCodeInput
                        className={`code-input ${error?.length ? "error" : ""}`}
                        fieldWidth={91}
                        fieldHeight={80}
                        values={googleCode.split("")}
                        autoFocus={true}
                        onChange={(value) => {
                            setGoogleCode(ToEn(value));
                        }}
                        onComplete={(codeValue) => {
                            onLoginHandle(codeValue)
                        }}
                        fields={6}
                    />
                </div>
            </div>
            {error?.length && (
                <div className={"error-row"}>
                    <InfoCircle/>
                    <span>{error}</span>
                </div>
            )}
            <div ref={tour2}>
                <OrderCardButtons
                    submitButton={{
                        text: "تایید کد",
                        disabled: googleCode.split("").length !== 6 || buttonIsLoading,
                        loading: buttonIsLoading,
                        onClick: () => onLoginHandle(),
                    }}
                    cancelButton={{
                        text: "بازگشت",
                        onClick: () => onBackClick(),
                    }}
                />
            </div>

            <ConfigProvider locale={fa_IR} direction={"rtl"}>
                <Tour steps={tourSteps} open={state?.orderData?.tour}
                      onClose={() => dispatch(setOrderData({...state.orderData, tour: false}))}/>
            </ConfigProvider>
        </div>
    );
};

export default connector(GoogleAuthenticationStage);
