import {
    KEY_ACCESS_TOKEN,
    KEY_DATE,
    KEY_EXPIRES_IN,
    KEY_IS_AUTHORIZED,
    KEY_IS_CUSTOMER,
    KEY_IS_TWO_FACTOR_ENABLED,
    KEY_LEVEL,
    KEY_PHONE,
    KEY_PROFILE_DATA,
    KEY_REFRESH,
    KEY_ROLES,
} from "core/constants/localStorageConstants";
import moment from "jalali-moment";
import Cookies from "universal-cookie";

export function setLocalStorageItems(data) {
    localStorage.setItem(KEY_ACCESS_TOKEN, data?.access_token);
    data?.refresh_token && localStorage.setItem(KEY_REFRESH, data?.refresh_token);
    localStorage.setItem(KEY_DATE, moment().utc().toString());
    data?.expires_in &&
    localStorage.setItem(KEY_EXPIRES_IN, data?.expires_in.toString());
    localStorage.setItem(KEY_IS_AUTHORIZED, data?.isAuthorized?.toString());
    localStorage.setItem(KEY_LEVEL, data?.level);
    localStorage.setItem(KEY_IS_TWO_FACTOR_ENABLED, data?.isTwoFactorEnabled)
    data?.roles && localStorage.setItem(KEY_ROLES, data?.roles.join(","));
    data?.succeedPmCount > 3 && localStorage.setItem(KEY_IS_CUSTOMER, "true")
    const cookies = new Cookies();
    cookies.set('isUser', 'true', {path: '/'});

}

export function clearTokenFromLocalStorage() {
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem(KEY_EXPIRES_IN);
    localStorage.removeItem(KEY_DATE);
    localStorage.removeItem(KEY_IS_AUTHORIZED);
    localStorage.removeItem(KEY_PHONE);
    localStorage.removeItem(KEY_LEVEL);
    localStorage.removeItem(KEY_REFRESH);
    localStorage.removeItem(KEY_ROLES);
    localStorage.removeItem(KEY_PROFILE_DATA);
    localStorage.removeItem(KEY_IS_TWO_FACTOR_ENABLED)
}

export function isCustomer() {
    return localStorage.getItem(KEY_IS_CUSTOMER);

}
