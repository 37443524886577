import React, {useEffect, useState} from "react";
import "./tables.scss";
import {Badge, BadgeType, Card} from "components";
import {Enum, ReferralActions, ReferralWithdrawals} from "core/domain";
import {getActionTypesEnum, getMineReferralWithdrawals, getWithdrawalStatesEnum,} from "core/repositores/referral";
import moment from "jalali-moment";
import {detect_bank, formatCardNumber} from "core/utils/bankDetecter";
import {digitSeparator} from "core/utils/decimalExtentions";
import {Empty} from "antd";
import {TableDataSkeleton} from "components/table-data-skeleton/table-data-skeleton";
import Pagination from "components/pagination/pagination";
import {Link} from "react-router-dom";
import {ReactComponent as LeftArrow} from "assets/icons/referral/LeftArrow.svg";

interface ReferralTablesProps {
    referralActions: ReferralActions[],
    isLoading: boolean,
}

export const ReferralTables: React.FC<ReferralTablesProps> = (props: ReferralTablesProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [actionTypes, setActionTypes] = useState<Enum[]>();
    const [referralWithdrawals, setReferralWithdrawals] = useState<
        ReferralWithdrawals[]
    >([]);
    const [withdrawalStates, setWithdrawalStates] = useState<Enum[]>();
    const [currentPageWithdrawals, setCurrentPageWithdrawals] = useState(1);
    const [currentPageActions, setCurrentPageActions] = useState(1);

    const itemsPerPageCount = 5;

    const fetchReferralActionTypes = async () => {
        await getActionTypesEnum(setIsLoading, setActionTypes);
    };

    const fetchReferralWithdrawalStates = async () => {
        await getWithdrawalStatesEnum(setIsLoading, setWithdrawalStates);
    };

    const fetchReferralWithdrawals = async () => {
        await getMineReferralWithdrawals(setIsLoading, setReferralWithdrawals);
    };

    function getWithdrawalStateBadgeType(id: number): BadgeType | undefined {
        switch (id) {
            case 1:
                return BadgeType.error;
            case 2:
                return BadgeType.success;
            case 3:
                return BadgeType.info;
            case 4:
                return BadgeType.waiting;
            default:
                return undefined;
        }
    }

    useEffect(() => {
        fetchReferralWithdrawalStates();
        fetchReferralActionTypes();
        fetchReferralWithdrawals();
    }, []);

    const handlePageChangeWithdrawals = (pageNumber: number) => {
        setCurrentPageWithdrawals(pageNumber);
    };

    const handlePageChangeActions = (pageNumber: number) => {
        setCurrentPageActions(pageNumber);
    };

    return (
        <div className="tables">
            <div className="payouts">
                <Card
                    title="تسویه های من"
                    button={
                        <Link to="#">
                            مشاهده همه <LeftArrow/>
                        </Link>
                    }
                >
                    <table>
                        <thead>
                        <th>مقدار</th>
                        <th>شماره کارت</th>
                        <th>وضعیت</th>
                        <th>زمان و تاریخ سفارش</th>
                        </thead>
                        <tbody>
                        {isLoading ? (
                            Array.from({length: 5}).map((_, index) => (
                                <React.Fragment key={index}>
                                    {index !== 0 && <div className="divider"></div>}
                                    <tr>
                                        <td>
                                            <TableDataSkeleton/>
                                        </td>
                                        <td>
                                            <TableDataSkeleton width={115}/>
                                        </td>
                                        <td>
                                            <TableDataSkeleton width={95}/>
                                        </td>
                                        <td>
                                            <TableDataSkeleton width={95}/>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                        ) : referralWithdrawals.length === 0 ? (
                            <tr>
                                <td colSpan={4}>
                                    <Empty
                                        description="رکوردی یافت نشد."
                                        className="empty-box"
                                    />
                                </td>
                            </tr>
                        ) : (
                            referralWithdrawals?.slice(
                                (currentPageWithdrawals - 1) * itemsPerPageCount,
                                currentPageWithdrawals * itemsPerPageCount,
                            )
                                .map((withdrawal) => (
                                    <tr>
                                        <td>{digitSeparator(withdrawal.amount)}</td>
                                        <td>
                                            <img
                                                src={`banks/${detect_bank(
                                                    withdrawal.bank.cardNumber,
                                                ).bank_name.toLowerCase()}.svg`}
                                                className="desktop-show"
                                                alt="bank logo"
                                            />
                                            {formatCardNumber(withdrawal.bank.cardNumber)}
                                        </td>
                                        <td>
                                            <Badge
                                                text={withdrawal.state.title}
                                                type={getWithdrawalStateBadgeType(
                                                    withdrawal.state.id,
                                                )}
                                            />
                                        </td>
                                        <td>
                                            {moment
                                                .utc(withdrawal.createdOn)
                                                .locale("fa")
                                                .local()
                                                .format("HH:mm - jYYYY/jMM/jDD")}
                                        </td>
                                    </tr>
                                ))
                        )}
                        </tbody>
                    </table>
                </Card>
                {referralWithdrawals.length > 0 && (
                    <Pagination
                        totalItems={referralWithdrawals.length}
                        itemsPerPage={itemsPerPageCount}
                        currentPage={currentPageWithdrawals}
                        onPageNumberClick={handlePageChangeWithdrawals}
                    />
                )}
            </div>
            <div className="activities">
                <Card
                    title="فعالیت های دوستان"
                    button={
                        <Link to="#">
                            مشاهده همه <LeftArrow/>
                        </Link>
                    }
                >
                    <table>
                        <thead>
                        <th>کاربر دعوتی</th>
                        <th>سود</th>
                        <th>نوع</th>
                        <th>مقدار سفارش</th>
                        <th>تاریخ</th>
                        </thead>
                        <tbody>
                        {props.isLoading ? (
                            Array.from({length: 5}).map((_, index) => (
                                <React.Fragment key={index}>
                                    {index !== 0 && <div className="divider"></div>}
                                    <tr>
                                        <td>
                                            <TableDataSkeleton/>
                                        </td>
                                        <td>
                                            <TableDataSkeleton width={120}/>
                                        </td>
                                        <td>
                                            <TableDataSkeleton width={80}/>
                                        </td>
                                        <td>
                                            <TableDataSkeleton width={100}/>
                                        </td>
                                        <td>
                                            <TableDataSkeleton width={80}/>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                        ) : props.referralActions?.length === 0 ? (
                            <tr>
                                <td colSpan={4}>
                                    <Empty
                                        description="رکوردی یافت نشد."
                                        className="empty-box"
                                    />
                                </td>
                            </tr>
                        ) : (
                            props.referralActions?.slice(
                                (currentPageActions - 1) * itemsPerPageCount,
                                currentPageActions * itemsPerPageCount,
                            )
                                .map((action) => (
                                    <tr>
                                        <td>{action.invitedUserFullName}</td>
                                        <td>
                                            <Badge
                                                text={digitSeparator(action.actionAmount).toString()}
                                                type={action.type.id}
                                            />
                                        </td>
                                        <td>{action.type.title}</td>
                                        <td>{digitSeparator(action.orderAmount)}</td>
                                        <td>
                                            {moment
                                                .utc(action.createdOn)
                                                .locale("fa")
                                                .local()
                                                .format("HH:mm:ss")}{" "}
                                            - {moment(action.createdOn).format("jYYYY/jMM/jDD")}
                                        </td>
                                    </tr>
                                ))
                        )}
                        </tbody>
                    </table>
                </Card>
                {props.referralActions?.length > 0 && (
                    <Pagination
                        totalItems={props.referralActions?.length}
                        itemsPerPage={itemsPerPageCount}
                        currentPage={currentPageActions}
                        onPageNumberClick={handlePageChangeActions}
                    />
                )}
            </div>
        </div>
    );
};
