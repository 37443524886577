import * as React from "react";

import "./level-box.scss";

import { ReactComponent as Star1 } from "assets/icons/dashboard/Pro_Badge-1.svg";
import { ReactComponent as Star2 } from "assets/icons/dashboard/Pro_Badge-2.svg";
import { ReactComponent as Star3 } from "assets/icons/dashboard/Pro_Badge-3.svg";
import { ReactComponent as Star } from "assets/icons/dashboard/Pro_Badge.svg";
import person from "assets/images/dashboard/person.png";
import upBox from "assets/images/dashboard/up-box.png";
import { Button, buttonType } from "components";
import { useNavigate } from "react-router-dom";
import { readProfile } from "../../../../../core/repositores/profile";
import sathekarbari from 'assets/images/dashboard/sathekarbari.png'
import whitestar from 'assets/images/dashboard/whitestar.png'
import goldstar from 'assets/images/dashboard/goldstar.png'

interface Props {
  level?: string;
}

export const LevelBox: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  return (
    <div className="user-level-box">
      {/* <img src={sathekarbari} className="bg" /> */}
      <img src={whitestar} className="white-star" />
      <img src={goldstar} className="gold-star" />
      <img src={person} className='person' />
      <div className="buttons">
        <Button
          type={buttonType.white}
          text={`سطح ${readProfile()?.level}`}
          onClick={() => navigate("/authentication")}
        />
        <img
          className="up-button"
          src={upBox}
          onClick={() => navigate("/authentication")}
        />
      </div>
    </div>
    // <div className="level-box-container">
    //   {/* <div className="out-border"></div> */}
    //   <div className="level-box">
    //     <div className="bg"></div>
    //     {/* <Star1 className="star1" /> */}
    //     <Star2 className="star2" />
    //     {/* <Star3 className="star3" /> */}
    //     <Star className="star" />
    //     <img className="avatar" src={person} />
    //   </div>
    // </div>
  );
};
