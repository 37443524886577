import * as React from "react";
import "./orders.scss";
import { Button, buttonType, Card, Input } from "components";
import { ReactComponent as Filter } from "assets/icons/orders/Filter.svg";
import { ReactComponent as Search } from "assets/icons/orders/search.svg";
import { OrderItem } from "../../../../components/order-item/order-item";
import { OrderDetailsModal } from "../../../../components/order-details-modal/order-details-modal";
import useOnBlur from "core/utils/useOnBlur";
import { Page } from "../../../../components/page/page";
import { Order, OrdersFilter } from "core/domain/orders/orders";
import { getMineOrder, getOrderStatesType } from "core/repositores/orders";
import { Enum } from "../../../../core/domain";
import { OrderItemSkeleton } from "../../../../components/order-item/order-item-skeleton/order-item-skeleton";
import { Divider, Empty } from "antd";
import { useCallback } from "react";

export enum Tabs {
  Buy = 1,
  Sell,
  Trade,
}

const initialOrderState = {
  id: 100,
  title: "وضعیت سفارش",
};

const initialFilters: OrdersFilter = {
  orderState: initialOrderState,
};

interface Props {}

export const Orders: React.FC<Props> = (props) => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [selectedItem, setSelectedItem] = React.useState<Tabs>(Tabs.Buy);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<Order | null>(null);
  const [orders, setOrders] = React.useState<Order[]>();
  const [results, setResults] = React.useState<Order[]>();
  const [filterDropdownIsOpen, setFilterDropdownIsOpen] = React.useState(false);
  const [filterDropdownMobileIsOpen, setFilterDropdownMobileIsOpen] =
    React.useState(false);
  const [pageIsLoading, setPageIsLoading] = React.useState(false);
  const [orderStatesType, setOrderStatesType] = React.useState<Enum[]>();
  const [filters, setFilters] = React.useState<OrdersFilter>(initialFilters);
  const filterDropdownRef = React.useRef<HTMLDivElement>(null);
  const filterDropdownMobileRef = React.useRef<HTMLDivElement>(null);

  const openDetailsModal = (order: Order) => {
    setSelectedOrder(order);
    setModalIsOpen(true);
  };

  const closeFilterHandle = () => setFilterDropdownIsOpen(false);
  const closeFilterHandleMobile = () => setFilterDropdownMobileIsOpen(false);

  const toggleFilterHandle = () =>
    !filterDropdownIsOpen
      ? setFilterDropdownIsOpen(true)
      : setFilterDropdownIsOpen(false);

  const toggleFilterHandleMobile = () =>
    !filterDropdownMobileIsOpen
      ? setFilterDropdownMobileIsOpen(true)
      : setFilterDropdownMobileIsOpen(false);

  useOnBlur(filterDropdownRef, closeFilterHandle);
  useOnBlur(filterDropdownMobileRef, closeFilterHandleMobile);

  const fetchOrders = async () => {
    await getOrderStatesType(
      setPageIsLoading,
      setOrderStatesType,
      "همه وضعیت ها",
    );
    getMineOrder(setPageIsLoading, setOrders, setResults);
  };

  const fetchOrdersCallback = useCallback(fetchOrders, []);

  const onFilterHandle = (filter: Enum, field: string) => {
    setFilters({ ...filters, [field]: filter });

    applyFilters(null, filter.id);

    setFilterDropdownIsOpen(false);
  };

  const applyFilters = (tab?: Tabs, orderState?: number) => {
    let filteredOrders = orders;

    //handle filter on Tabs
    if (tab) {
      filteredOrders = filteredOrders.filter(
        (q) =>
          q?.trackingCOde?.startsWith(
            tab === Tabs.Buy ? "B" : tab === Tabs.Sell ? "S" : "T",
          ),
      );
    }

    //handle filter on state
    if (orderState) {
      filteredOrders =
        orderState !== 100
          ? filteredOrders.filter((order) => order.state.id === orderState)
          : filteredOrders;
    }

    if (searchValue) {
      const searchRegex = new RegExp(searchValue, "i");
      filteredOrders = filteredOrders.filter((order) => {
        return (
          order.sourceSymbol.match(searchRegex) ||
          order.destinationSymbol.match(searchRegex) ||
          order.trackingCOde.match(searchRegex)
        );
      });
    }

    setResults(filteredOrders);
  };

  const resetFilters = () => {
    applyFilters(Tabs.Buy, 100);
    setSelectedItem(Tabs.Buy);
    setSearchValue("");
    applyFilters();
    setFilters(initialFilters);
  };

  React.useEffect(() => {
    fetchOrdersCallback();
  }, [fetchOrdersCallback]);

  React.useEffect(() => {
    if (searchValue === "") {
      applyFilters();
    }
  }, [searchValue]);

  return (
    <Page className="pmx-orders-container">
      <Card className="pmx-orders">
        {selectedOrder && (
          <OrderDetailsModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            order={selectedOrder}
          />
        )}
        <div className="head-row">
          <div className="filter-row mobile-show" ref={filterDropdownMobileRef}>
            {orderStatesType && (
              <>
                <Button
                  text={filters.orderState.title}
                  type={buttonType.outlined}
                  icon={<Filter />}
                  onClick={() => toggleFilterHandle()}
                  className="order-state-button mobile-show"
                />
                {filterDropdownIsOpen == true && (
                  <ul className="filter-dropdown">
                    {orderStatesType?.map((state, index) => (
                      <li
                        onClick={() => onFilterHandle(state, "orderState")}
                        key={index}
                      >
                        {state.title}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>

          <div className="search">
            <Input
              value={searchValue}
              placeholder="جستجوی سفارش..."
              onChange={(e) => setSearchValue(e.target.value)}
              adornment={<Search />}
              onPressEnter={() => applyFilters()}
            />
            <div className="search-buttons">
              <Button
                text="اعمال"
                type={buttonType.primary}
                disabled={!searchValue || searchValue == ""}
                onClick={() => applyFilters()}
              />
              <Button
                type={buttonType.outlined}
                text="حذف فیلتر ها"
                onClick={resetFilters}
              />
            </div>
          </div>
          {orderStatesType && (
            <>
              <Button
                text={filters.orderState.title}
                type={buttonType.outlined}
                icon={<Filter />}
                onClick={() => toggleFilterHandle()}
                className="order-state-button desktop-show"
              />
              {filterDropdownIsOpen == true && (
                <ul className="filter-dropdown">
                  {orderStatesType?.map((state, index) => (
                    <li
                      onClick={() => onFilterHandle(state, "orderState")}
                      key={index}
                    >
                      {state.title}
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
        <Divider className="desktop-show" />
        <div className="tabs">
          <div className="select-row">
            <span
              className={`${Tabs.Buy === selectedItem ? "active" : ""}`}
              onClick={async () => {
                setSelectedItem(Tabs.Buy);
                await applyFilters(Tabs.Buy);
              }}
            >
              خرید
            </span>
            <span
              className={`${Tabs.Sell === selectedItem ? "active" : ""}`}
              onClick={async () => {
                setSelectedItem(Tabs.Sell);
                await applyFilters(Tabs.Sell);
              }}
            >
              فروش
            </span>
            <span
              className={`${Tabs.Trade === selectedItem ? "active" : ""}`}
              onClick={() => {
                setSelectedItem(Tabs.Trade);
                applyFilters(Tabs.Trade);
              }}
            >
              تبدیل
            </span>
            <div
              className={`selector-underline ${Tabs[selectedItem]}-active`}
            ></div>
          </div>
        </div>
        <div className={`divider `}></div>

        <div className="orders">
          {pageIsLoading ? (
            [...Array(6)].map((_, index) => <OrderItemSkeleton key={index} />)
          ) : results && results.length === 0 ? (
            <Empty description="سفارشی یافت نشد." className="empty-box" />
          ) : (
            results?.map((order) => (
              <OrderItem
                key={order.orderId}
                orderItem={order}
                onClickDetails={() => openDetailsModal(order)}
              />
            ))
          )}
        </div>
      </Card>
    </Page>
  );
};
