import {getIconPath} from "core/utils/getIconPath";
import "./rates.scss";
import {Rate} from "../../../../../core/domain/rate/rate";
import {FC, useEffect, useState} from "react";
import {Spin} from "antd";
import {getTRXRate} from "../../../../../core/repositores/landing";
import {digitSeparator} from "../../../../../core/utils/decimalExtentions";
import {RootState} from "../../../../../core/redux/store";
import {connect, ConnectedProps} from "react-redux";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  rates: Rate[]
  ratesLoading: boolean
}

export const Rates: FC<Props> = ({ rates }) => {
  const [ratesIsLoading, setRatesIsLoading] = useState(false);
  const [trxRate, setTRXRate] = useState<Rate>()

  async function fetchData() {
    await getTRXRate(setRatesIsLoading, setTRXRate)
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Spin spinning={ratesIsLoading}>
      <div className="pmx-rates">
        {rates?.filter(q => q.symbol !== "PM" && q.symbol !== "UUSD" && q.symbol !== "CRYPTO").map((rate) => (
          <div className="rate" key={rate.symbol}>
            <div className="currency-info">
              <img src={getIconPath(rate.symbol)} />
              <div className="col">
                <span>{rate.name}</span>
                <span className="en">{rate.symbol}</span>
              </div>
            </div>
            <div className="currency-rates">
              <div>
                <span className="buy-rate">نرخ خرید: </span>
                  {digitSeparator(rate.sellPrice)} تومان
              </div>
              <div>
                <span className="sell-rate">نرخ فروش: </span>
                  {digitSeparator(rate.buyPrice)} تومان
              </div>
            </div>
          </div>

        ))}
        {trxRate &&
          <div className="rate">
            <div className="currency-info">
              <img src={getIconPath(trxRate?.symbol)} />
              <div className="col">
                <span>{trxRate.name}</span>
                <span className="en">{trxRate?.symbol}</span>
              </div>
            </div>
            <div className="currency-rates">
              <div>
                <span className="buy-rate">نرخ خرید: </span>
                  {digitSeparator(trxRate?.sellPrice.toFixed(0))} تومان
              </div>
              <div>
                <span className="sell-rate">نرخ فروش: </span>
                  {digitSeparator(trxRate?.buyPrice.toFixed(0))} تومان
              </div>
            </div>
          </div>
        }
      </div>
    </Spin>
  );
};

export default connector(Rates);
