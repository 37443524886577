import {Button, buttonType, Card, Input, inputType} from "components";
import * as React from "react";
import "./auth-form.scss";
import congratulations from "assets/images/authentication/congratulations.png";
import {faNumToEnNum, IsNumeric,} from "../../../../../core/utils/decimalExtentions";
import {IsPersianChar} from "../../../../../core/utils/stringExtentions";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {ReactComponent as Calendar} from "assets/icons/authentication/calendar.svg";
import alert from 'assets/images/authentication/alert.png'

interface AuthenticationForm {
  firstName: string;
  lastName: string;
  nationalCode: string;
  emailAddress: string;
  birthDate: string;
  postalCode: string;
  address: string;
}

interface Props {
  level: string;
  onSubmit: (values: AuthenticationForm | {}, setLoading: any) => void;
}

export const AuthForm: React.FC<Props> = (props) => {
  const [buttonIsLoading, setButtonIsLoading] = React.useState(false);
  const [formValues, setFormValues] = React.useState<AuthenticationForm>();

  const birthDateRef = React.useRef();

  const onNumberChange = (value: string, field: string) => {
    if (value !== "") value = faNumToEnNum(value);

    if (value !== "" && !IsNumeric(value.split("")[value.split("").length - 1]))
      return;

    setFormValues({
      ...formValues,
      [field]: value,
    });
  };
  const onEmailChange = (value: string) => {
    if (
      value !== "" &&
      IsPersianChar(value.split("")[value.split("").length - 1])
    )
      return;

    setFormValues({
      ...formValues,
      emailAddress: value,
    });
  };
  const onPersianFieldChange = (value: string, field: string) => {
    if (
      value !== "" &&
      !IsPersianChar(value.split("")[value.split("").length - 1])
    )
      return;

    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  return (
    <>
      {props.level == "برنزی" ? (
        <Card title="ارتقاء به سطح نقره‌ای" className="silver-auth-form" divider>
          <form>
            <div className="row">
              <div className="col">
                <Input
                  type={inputType.text}
                  name="firstName"
                  placeholder="نام"
                  value={formValues?.firstName}
                  onChange={(e) =>
                    onPersianFieldChange(e.target.value, "firstName")
                  }
                  maxLength={20}
                />
              </div>

              <div className="col">
                <Input
                  type={inputType.text}
                  name="lastName"
                  placeholder="نام خانوادگی"
                  value={formValues?.lastName}
                  onChange={(e) =>
                    onPersianFieldChange(e.target.value, "lastName")
                  }
                  maxLength={30}
                />
              </div>
            </div>
            <div className="row">
              <Input
                type={inputType.text}
                name="nationalCode"
                placeholder="کد ملی"
                value={formValues?.nationalCode}
                onChange={(e) => onNumberChange(e.target.value, "nationalCode")}
                maxLength={10}
              />
            </div>
            <div className="row">
              <Input
                type={inputType.text}
                name="emailAddress"
                placeholder="ایمیل"
                value={formValues?.emailAddress}
                onChange={(e) => onEmailChange(e.target.value)}
                maxLength={250}
                className="email"
              />
            </div>
            <div className="row">
              <DatePicker
                className="date-picker"
                value={formValues?.birthDate}
                calendar={persian}
                ref={birthDateRef}
                locale={persian_fa}
                render={
                  <div>
                    <Input
                      type={inputType.text}
                      name="birthDate"
                      placeholder="تاریخ تولد"
                      value={formValues?.birthDate}
                      icon={<Calendar />}
                      disabled
                      maxLength={10}
                    />
                  </div>
                }
                onChange={(e: any) => {
                  setFormValues({ ...formValues, birthDate: e?.format() });
                }}
              />
            </div>
            <div className="row">
              <Input
                type={inputType.text}
                name="postalCode"
                placeholder="کد پستی"
                value={formValues?.postalCode}
                onChange={(e) => onNumberChange(e.target.value, "postalCode")}
                maxLength={10}
              />
            </div>
            <div className="row">
              <textarea
                name="address"
                value={formValues?.address}
                onChange={(e) =>
                  onPersianFieldChange(e.target.value, "address")
                }
                placeholder="آدرس محل سکونت یا محل کار"
                maxLength={1000}
              />
            </div>
            <Button
              htmlType="button"
              type={buttonType.primary}
              text="ارتقاء به سطح نقره‌ای"
              onClick={() => props.onSubmit(formValues, setButtonIsLoading)}
              disabled={
                buttonIsLoading ||
                !formValues?.emailAddress ||
                !formValues?.postalCode ||
                !formValues?.address ||
                !formValues?.birthDate ||
                !formValues?.lastName ||
                !formValues?.firstName ||
                !formValues?.nationalCode
              }
              loading={buttonIsLoading}
            />
          </form>
        </Card>
      ) : props.level == "نقره ای" ? (
        <Card title="ارتقا به سطح طلایی" className="gold-auth-form" divider>
          <div className="bg">
            <img src={alert} />
          </div>
          <form>
            <p>
              سطح طلایی: شرایط ارتقاء به این سطح برای کاربران نقره ای فعالیت ۳ الی ۶ ماه همراه با حجم خرید ۵۰۰ میلیون تومان در سطح نقره ای می باشدو لازمه  دیگر آن میانگین خرید بالای ۱۰ میلیون تومان می باشد.
              شما میتونید  در سطح طلایی بدون محدودیت زمانی خرید انجام دهید. خرید میتوانید خرید انجام دهید.
            </p>
            <Button
              type={buttonType.primary}
              text="ارتقاء به سطح طلایی"
              htmlType="button"
              onClick={() => props.onSubmit({}, setButtonIsLoading)}
              loading={buttonIsLoading}
              disabled
            />
          </form>
        </Card>
      ) : props.level == "طلایی" ? (
        <Card className="completed desktop-show">
          <div className="bg">
            <img src={congratulations} />
          </div>
          <div>
            <h4>پی ام ایکسچنج به شما افتخار میکند.</h4>
            <p>شما اکنون عضو مهمی از خانواده پی ام ایکسچنج هستید</p>
          </div>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};
