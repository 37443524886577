import * as React from "react";
import "./sidebar-item.scss";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "core/redux/store";
import { useAppDispatch } from "core/redux/hooks";
import { Link } from "react-router-dom";
import { setMenuItem, setSidebar } from "core/redux/actions";

const mapState = (state: RootState) => ({
  state: state,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SidebarItemProps extends PropsFromRedux {
  id: number;
  title: string;
  icon?: React.ReactNode;
  link?: string;
  className?: string;
  isActive?: boolean;
  onClickHandle?: () => void;
  filled: boolean;
  isExpanded?: boolean
  isLogout?: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = (props) => {
  const dispatch = useAppDispatch();
  return (
    <div
      className={`sidebar-item ${props.className ? props.className : ""} ${props.isExpanded ? 'expanded' : 'compact'}  ${
        props.filled ? "filled" : ""
      } ${props.isActive ? "active" : ""}`}
    >
      <Link
        onClick={() => {
          props.onClickHandle
            ? props.onClickHandle()
            : dispatch(setMenuItem(props.id)) && dispatch(setSidebar("close"));
        }}
        to={props.link}
      >
        <div className="content">
          {props.icon}
          {props.isExpanded && <p className="title">{props.title}</p>}
        </div>
      </Link>
    </div>
  );
};

export default connector(SidebarItem);
export {};
