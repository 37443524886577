import React from "react";
import { Link } from "react-router-dom";

interface MobileMenuItemProps {
  text: string;
  link?: string;
  icon?: any;
  active?: boolean;
}

const MobileMenuItem: React.FC<MobileMenuItemProps> = ({ text, link, icon, active }) => {
  const itemClass = active ? "mobile-menu-item active" : "mobile-menu-item";

  return (
    <Link to={link} className={itemClass}>
      {icon}
      {text}
    </Link>
  );
};

export default MobileMenuItem;
