import {FC, useEffect, useState} from "react";
import {RootState} from "core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {setOrderData} from "core/redux/actions";
import {OrderCardStages} from "core/domain/landing/landing";
import "./payment-method.scss";
import {Enum} from "core/domain";
import {AntdInput} from "components/antd-custom-input/antd-custom-input";
import {IsPersianChar} from "core/utils/stringExtentions";
import {payOrder} from "core/repositores/orders";

enum Steps {
  SelectMethod = 1,
  EnterAccount,
}

interface Method extends Enum {
  disabled?: boolean;
}

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const PaymentMethod: FC<Props> = ({ state }) => {
  const methods = [
    {
      id: 1,
      title: "درگاه پرداخت",
      description:
        "در این روش شما به درگاه پرداخت منتقل میشید و تا سقف ۲۵ میلیون میتوانید خرید کنید.",
    },

    {
      id: 2,
      title: "واریز به حساب",
      description:
        "در این روش تا سقف ۶ میلیارد تومان در روز خرید کنید.کافیه همراه بانک داشته باشید یا به بانک مراجعه کنید تا تراکنش پایا یا حساب به حساب انجام دهید.",
    },
  ];

  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<Method>(methods[0]);
    const [accountId, setAccountId] = useState<string>(null);
  const [step, setStep] = useState<Steps>(Steps.SelectMethod);

  const dispatch = useDispatch();
    useEffect(() => {
        window.history.pushState(
            window.history.state,
            document.title,
            "/payment-method"
        );
    }, [])
  const onBackClick = () =>
    dispatch(
      setOrderData({
        ...state.orderData,
        stage: OrderCardStages.RECEIVE_METHOD,
        phone: null,
      }),
    );

    const onPayComplete = (response: any) => {

        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.BILL_PREVIEW,
                paymentMethod: selectedMethod.id,
                paymentLink: response.data.paymentLink,
                cashInDetail: response.data.cashInDetail,
                fee: response?.data?.fee,
                tour: false,
            }),
        )
    };

  async function onPayHandle() {
    payOrder(
      setButtonIsLoading,
      {
        depositMethod: selectedMethod.id,
        trackingCode: state?.orderData?.trackingCode,
      },
      onPayComplete,
    );
  }

  const onEnFieldChange = (value: string) => {
    if (
      value !== "" &&
      IsPersianChar(value.split("")[value.split("").length - 1])
    )
      return;

    setAccountId(value);
  };

  const onNextStepClick = () => onPayHandle();

  return (
    <div className={"pmx-payment-method-stage"}>
      {step === Steps.SelectMethod ? (
        <>
          <h3>روش پرداخت</h3>
          <p className={"description"}>
            روش مورد نظر خود را جهت پرداخت را انتخاب نمایید.
          </p>
          <div className={"methods"}>
            {methods.map((method) => (
              <div
                className={`method-item ${
                  selectedMethod.id === method.id ? "active" : ""
                }`}
                onClick={() => setSelectedMethod(method)}
              >
                <div>
                  <span>{method.title}</span>
                  <p>{method.description}</p>
                </div>
                <span className={"circle-badge"}>
                  <span className={"inner-circle"}></span>
                </span>
              </div>
            ))}
          </div>
          <OrderCardButtons
            submitButton={{
              text: "ادامه",
              loading: buttonIsLoading,
              disabled: buttonIsLoading,
              onClick: () => onNextStepClick(),
            }}
            cancelButton={{
              text: "بازگشت",
              onClick: () => onBackClick(),
            }}
          />
        </>
      ) : (
        <>
          <h3>وارد کردن شماره حساب</h3>
          <p className={"description"}>
            شناره حساب پرکفت مانی خود که میخواهید ارز خریداری شده به آن حساب
            واریز شود را وارد نمایید.
          </p>
          <AntdInput
            className={"account-id-input en"}
            placeholder={"شماره حساب پرفکت مانی : U12345678"}
            value={accountId}
            type={"account-id"}
            onChange={(e) => onEnFieldChange(e.target.value)}
          />

          <OrderCardButtons
            submitButton={{
              text: "ارسال",
              loading: buttonIsLoading,
              disabled: buttonIsLoading || !accountId,
              onClick: onPayHandle,
            }}
            cancelButton={{
              text: "بازگشت",
              onClick: () => setStep(Steps.SelectMethod),
            }}
          />
        </>
      )}
    </div>
  );
};
export default connector(PaymentMethod);
