import "./twofa-form.scss";
import { Button, Card, Input, buttonType, inputType } from "components";
import { Circle } from "./circle/circle";
import { ReactComponent as AppStore } from "assets/icons/security/AppStore.svg";
import { ReactComponent as GooglePlay } from "assets/icons/security/GooglePlay.svg";
import { ReactComponent as QRCode } from "../../../../../assets/images/security/QRCode.svg";
import { ReactComponent as Copy } from "../../../../../assets/icons/security/Copy.svg";
import FinalStep from "../../../../../assets/images/security/FinalStep.png";
import {useEffect, useState} from "react";
import { copyToClipboard } from "core/utils/copyToClipboard";
import {
  disableTwoStep,
  setupTwoStep,
  verifySetupTwoStep,
} from "../../../../../core/repositores/security";
import { SetupTwoStepResponse } from "../../../../../core/domain/security/security";
import { QRCodeSVG } from "qrcode.react";
import {
  faNumToEnNum,
  IsNumeric, ToEn,
} from "../../../../../core/utils/decimalExtentions";
import mail from 'assets/images/security/mail.png'
import {Divider, message} from "antd";
import {KEY_IS_TWO_FACTOR_ENABLED} from "../../../../../core/constants/localStorageConstants";
import ReactCodeInput from "react-verification-code-input";
import * as React from "react";

interface Props { }

type VerifyCodes = {
  googleAuthCode: string;
  code: string;
};

export const TwoFAForm: React.FC<Props> = (props) => {
  const [twoStepButtonLoading, setTwoStepButtonLoading] = useState(false);
  const [twoStepData, setTwoStepData] = useState<SetupTwoStepResponse>();
  const [verifyCodeValues, setVerifyCodeValues] = useState<VerifyCodes>();
  const [googleCode, setGoogleCode] = useState("")
  const [step, setStep] = useState(0);

  useEffect(() => {
    localStorage.getItem(KEY_IS_TWO_FACTOR_ENABLED) === "true" && setStep(5)
    localStorage.getItem(KEY_IS_TWO_FACTOR_ENABLED) === "false" && setStep(0)
  }, [localStorage.getItem(KEY_IS_TWO_FACTOR_ENABLED)]);

  const goToNextStep = () => {
    if (step < 5) {
      setStep(step + 1);
    }
  };

  const goToPreviousStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const onCodeChange = (value: string, field: string) => {
    if (value !== "") value = faNumToEnNum(value);

    if (value !== "" && !IsNumeric(value.split("")[value.split("").length - 1]))
      return;

    setVerifyCodeValues({
      ...verifyCodeValues,
      [field]: value,
    });
  };

  const onSetupTwoStepClick = () =>
    setupTwoStep(setTwoStepButtonLoading, setTwoStepData, goToNextStep);

  const onVerifySetupTwoStepClick = () =>
      verifyCodeValues.googleAuthCode.length === 6 &&
    verifySetupTwoStep(
      setTwoStepButtonLoading,
      { code: verifyCodeValues?.googleAuthCode },
      () => {
        goToNextStep()
        localStorage.setItem(KEY_IS_TWO_FACTOR_ENABLED, "true")
      },
    );

  const onDisableTwoStepClick = () => {
      disableTwoStep(setTwoStepButtonLoading, {code: googleCode}, () => {
        message.success("تایید دو مرحله ای با موفقیت غیر فعال شد.")
        localStorage.setItem(KEY_IS_TWO_FACTOR_ENABLED, "false")
        setStep(0)
      })
  }

  return (
    <div className="twofa-form-container">
      {/* Start Step 0 */}
      {step === 0 && (
        <Card title="احراز هویت دو مرحله ای" className="step-0" divider>
          <div className="bg">
            <img src={mail} />
          </div>
          <div>
            احراز هویت دو مرحله ای روشی است که در آن کاربر برای ورود به حساب آنلاین یا اپلیکیشن موردنظر، علاوه‌بر همان رمزعبور همیشگی، باید عامل دیگری نیز برای تأیید هویت خود وارد کند.
          </div>
          <Button
            type={buttonType.primary}
            text="فعال سازی"
            onClick={onSetupTwoStepClick}
            loading={twoStepButtonLoading}
            disabled={twoStepButtonLoading}
          />
        </Card>
      )}

      {/* End Step 0 */}
      {/* Start Step 1 */}
      {step === 1 && (
        <Card title="احراز هویت دو مرحله ای" className="step-1" divider>
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" />
            <hr />
            <Circle centerText="3" bottomText="تهیه پشتیبان" />
            <hr />
            <Circle centerText="4" bottomText="ثبت نهایی" />
          </div>
          <Divider className="card-divider" />
          <p>
            {" "}
            جهت فعالسازی ابتدا نرم‌افزار{" "}
            <span className="en">Google Authenticator</span> را دانلود نمایید.
          </p>
          <section>
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noreferrer"
            >
              <span>App Store</span> <AppStore />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <span>Google Play</span> <GooglePlay />
            </a>
          </section>
          <Button
            type={buttonType.primary}
            text="مرحله ی بعد"
            onClick={goToNextStep}
            loading={twoStepButtonLoading}
            disabled={twoStepButtonLoading}
          />
        </Card>
      )}

      {/* End Step 1 */}
      {/* Start Step 2 */}
      {step === 2 && (
        <Card title="احراز هویت دو مرحله ای" className="step-2" divider>
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" reached />
            <hr className="reached" />
            <Circle centerText="3" bottomText="تهیه پشتیبان" />
            <hr />
            <Circle centerText="4" bottomText="ثبت نهایی" />
          </div>
          <Divider className="card-divider" />
          <section>
            <p>
              لطفا بارکد زیر را با تلفن همراه خود در نرم افزار
              <span className="en">Google Authenticator</span> اسکن کنید. اگر
              امکان اسکن توسط گوشی برای شما مهیا نیست، می‌توانید کد زیر بارکد را
              به صورت دستی در آن وارد نمایید.
            </p>
            <span id="key-link" className="en">{twoStepData.authenticatorKey} <Copy onClick={() => copyToClipboard(twoStepData.authenticatorKey, 'کد Authenticator Key')} /></span>

            <div className="qr-code">
              <QRCodeSVG
                style={{ margin: "16px 0" }}
                value={twoStepData.qrCodeUrl}

              />
            </div>
          </section>
          <div className="buttons">
            <Button
              type={buttonType.primary}
              text="مرحله ی بعد"
              onClick={goToNextStep}
              loading={twoStepButtonLoading}
              disabled={twoStepButtonLoading}
            />
            <Button
              type={buttonType.outlined}
              text="انصراف"
              onClick={(step) => setStep(0)}
            />
          </div>
        </Card>
      )}

      {/* End Step 2 */}
      {/* Start Step 3 */}
      {step === 3 && (
        <Card title="احراز هویت دو مرحله ای" className="step-3" divider>
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" reached />
            <hr className="reached" />
            <Circle centerText="3" bottomText="تهیه پشتیبان" reached />
            <hr className="reached" />
            <Circle centerText="4" bottomText="ثبت نهایی" />
          </div>
          <Divider className="card-divider" />
          <p>
            لطفا این رمز عبور را روی کاغذ نوشته و در جای امنی نگه‌داری نمایید.این کد در زمان حذف شدن نرم‌افزار و یا سرقت گوشی شما به بازگرداندن اکانت شما کمک خواهد کرد.
          </p>
          <div
            className="code"
            onClick={() =>
              copyToClipboard(twoStepData.recoveryCodes.join(" "), "کد امنیتی")
            }
          >
            <span className="en">{twoStepData.recoveryCodes.join(" ")}</span>{" "}
            <Copy />
          </div>
          <div className="buttons">
            <Button
              type={buttonType.primary}
              text="مرحله ی بعد"
              onClick={goToNextStep}
              loading={twoStepButtonLoading}
              disabled={twoStepButtonLoading}
            />
            <Button
              type={buttonType.outlined}
              text="انصراف"
              onClick={goToPreviousStep}
            />
          </div>
        </Card>
      )}

      {/* End Step 3 */}
      {/* Start Step 4 */}
      {step === 4 && (
        <Card title="احراز هویت دو مرحله ای" className="step-4" divider>
          <div className="stepper">
            <Circle centerText="1" bottomText="نصب اپلیکیشن" reached />
            <hr className="reached" />
            <Circle centerText="2" bottomText="ثبت کد" reached />
            <hr className="reached" />
            <Circle centerText="3" bottomText="تهیه پشتیبان" reached />
            <hr className="reached " />
            <Circle centerText="4" bottomText="ثبت نهایی" reached />
          </div>
          <Divider className="card-divider" />
          <p>
            {" "}
            برداشت و واریز وجه جهت حفظ امنیت اکانت شما پس از انجام این مرحله تا
            ۲۴ ساعت قفل خواهد شد.{" "}
          </p>
          <div className="fields">
            <div className="column">
              <Input
                type={inputType.text}
                maxLength={6}
                name="googleAuthCode"
                placeholder="کد Google Authenticator"
                value={verifyCodeValues?.googleAuthCode}
                onPressEnter={onVerifySetupTwoStepClick}
                onChange={(e) => onCodeChange(e.target.value, "googleAuthCode")}
              />
            </div>
          </div>
          <div className="buttons">
            <Button
              type={buttonType.primary}
              text="ثبت کد"
              onClick={onVerifySetupTwoStepClick}
              loading={twoStepButtonLoading}
            disabled={
              twoStepButtonLoading ||
              !verifyCodeValues?.googleAuthCode || verifyCodeValues?.googleAuthCode.length !== 6
            }
            />
            <Button
              type={buttonType.outlined}
              text="انصراف"
              onClick={goToPreviousStep}
            />
          </div>
        </Card>
      )}
      {/* End Step 4 */}
      {/* Start Step 5 */}
      {step === 5 && (
        <Card className="step-5" title='احراز هویت دو مرحله ای' divider>
          <div className="bg">
            <img src={FinalStep} alt="Success"></img>
          </div>

          <h4>ورود دو مرحله ای با موفقیت برای شما فعال شد.</h4>
          <span>از طریق گزینه ی  زیر میتوانید نسبت به غیر فعال سازی  تایید دو مرحله ای حساب خود اقدام نمایید.</span>
          <Divider className="card-divider" />
          <Button type={buttonType.primary} text="غیر فعال کردن تایید دو مرحله ای" onClick={() => setStep(6)}/>
        </Card>
      )}
      {/* End Step 5 */}
    {/* Start Step 6 */}
      {step === 6 &&
      <Card className="step-6" title='احراز هویت دو مرحله ای' divider>
        <h4>غیر فعال کردن تایید دو مرحله ای</h4>
        <span>جهت غیر فعال کردن تایید دو مرحله ای، کد 6 رقمی گوگل را وارد نمایید.</span>

        <ReactCodeInput
            className={`code-input`}
            fieldWidth={91}
            fieldHeight={80}
            values={googleCode.split("")}
            autoFocus={true}
            onChange={(value) => {
              setGoogleCode(ToEn(value));
            }}
            fields={6}
        />

        <div className="buttons">
          <Button
              type={buttonType.primary}
              text="ثبت کد"
              onClick={onDisableTwoStepClick}
              loading={twoStepButtonLoading}
              disabled={twoStepButtonLoading || googleCode.length !== 6}
          />
          <Button
              type={buttonType.outlined}
              text="بازگشت"
              onClick={() => setStep(5)}
          />
        </div>
      </Card>
      }
      {/* End Step 6 */}
    </div>
  );
};
