import { Input } from "antd";
import "./download-application.scss";
import mobilephone from "assets/images/home/mobile-phone.png";
import mobilebg from "assets/images/home/mobile-bg.png";
import mobilebgr from "assets/images/home/mobile-bgr.png";
import qrcode from "assets/images/home/qrcode.png";
import { ReactComponent as LeftArrow } from "assets/icons/home/left-arrow-circled.svg";
import sibche from "assets/images/home/sibche.png";
import appstore from "assets/images/home/apple.png";
import android from "assets/images/home/android-link.png";
import pwa from "assets/images/home/pwa.png";
import googleplay from "assets/images/home/google play.png";
import cafebazaar from "assets/images/home/cafebazar.png";
import { isDesktop, isMobile } from "react-device-detect";

interface Props {}

export const DownloadApplication = (props: Props) => {
  return (
    <div className="pmx-download-application">
      <div className="bg">
        {isMobile && <img src={mobilebgr} className="mobile-bg" />}
        {isDesktop && <img src={mobilebg} className="mobile-bg" />}

        <img src={mobilephone} className="mobile-phone" />
        <div className="qr-code">
          <img src={qrcode} />
          <span>برای دانلود کد را اسکن کنید</span>
        </div>
      </div>
      <div className="content">
        <div className="col1">
          <h4>دانلود اپلیکیشن</h4>
          <p>
            با دانلود اپلیکیشن پی ام اکسچنج به آسانی و با سرعتی بالا به انجام
            معاملات و خرید و فروش با کیفیتی بالاتر بپردازید.
          </p>
          <Input
            placeholder="برای دریافت لینک دانلود شماره خود را وارد کنید"
            dir="ltr"
            maxLength={11}
            suffix={<LeftArrow />}
          />
        </div>
        <div className="col2">
          <div className="col">
            <h5>دانلود آی او اس</h5>
            <div>
              <a href="#">
                <img src={appstore} alt="App Store" />
                <span> فروشگاه اپل</span>
              </a>
              <a href="#">
                <img src={pwa} alt="PWA" />
                <span> وب اپلیکیشن</span>
              </a>
              <a href="#">
                <img src={sibche} alt="Sibche" /> <span>سیبچه</span>
              </a>
            </div>
          </div>
          <div className="col">
            <h5>دانلود اندروید</h5>
            <div>
              <a href="#">
                <img src={googleplay} alt="Google Play" />
                <span> فروشگاه گوگل</span>
              </a>
              <a href="#">
                <img src={cafebazaar} alt="Cafe Bazaar" />
                <span> کافه بازار</span>
              </a>
              <a href="#">
                <img src={android} alt="Direct" />
                <span> دریافت مستقیم</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};