type apiParams = string | number;

export const __API = {
    enums: {
        orderStates: "Enum/OrderState",
        referralActionType: "Enum/ReferralActionType",
        referralWithdrawalState: "Enum/ReferralWidthrawalState",
    },
    market: {
        exchange: "Market/Exchange",
        priceInfos: "Market/PriceInfos",
        getAllMarket: "Market/GetAllMarket",
        getNetworks: "Market/Networks",
        depositNetworks: "Market/Networks/Deposit",
        validate: "Market/Validate",
        theMostRecent24HoursCoins: 'Market/TheMostRecent24HoursCoins',
    },
    bankAccount: {
        mine: "BankAccount/Mine",
        create: "BankAccount/Create",
        delete: "BankAccount/Delete",
    },
    rate: {
        getRates: "Rate/Rates",
    },
    referral: {
        mine: {
            info: "Referral/GetMineReferralInfo",
            actions: "Referral/GetMineRefferalActions",
            withdrawals: "Referral/GetMineReferralWithdrawals",
        },
        withdrawal: "Referral/WithdrawalReferral",
        register: "Referral/RegisterReferral",
        topUsers: "Referral/GetTopUsersReferralAction",
    },
    orders: {
        mine: "Order/Mine",
        getByTrackingCode: "Order/SearchByTrackingCode",
        createBuy: "Order/Buy/Create",
        createTrade: "Order/Trade/Create",
        buyPay: "Order/Buy/Pay",
        setTxid: "OrderTransaction/SetTxid",
        buyVerify: "Order/Buy/Verify",
        createSell: "Order/Sell/Create",
        pmDeposit: "Order/PerfectMoney/Deposit",
        cryptoDepoist: "Order/Crypto/Deposit",
    },
    authentication: {},
    security: {
        resetPassword: "User/ResetPassword",
        setup2fa: "User/SetupTwoStep",
        verify2fa: "User/VerifySetupTwoStep",
        loginHistory: "User/MineLoginHistory",
    },
    user: {
        token: "User/Token",
        profile: "User/Profile",
        sendCode: "User/Login/SendCode",
        verifyCode: "User/Login/VerifyCode",
        authenticate: "User/Authenticate",
        verifyTwoStepLogin: "User/Login/VerifyTwoStepSignIn",
        setPassword: "User/setPassword",
        uploadSelfie: "User/auth/uploadSelfie",
        disableTwoStep: "User/DisableTwoStep"
    },
    userSavedWallets: {
        add: "UserSavedWallets/Add",
        addByAdmin: "UserSavedWallets/AddByAdmin",
        edit: "UserSavedWallets/Edit",
        remove: "UserSavedWallets/Remove",
        getMine: "UserSavedWallets/GetMine",
        getById: "UserSavedWallets/GetById",
        getAll: "UserSavedWallets/GetAll",
    },
    userSavedIBans: {
        add: "UserSavedIBans/Add",
        addByAdmin: "UserSavedIBans/AddByAdmin",
        edit: "UserSavedIBans/Edit",
        remove: "UserSavedIBans/Remove",
        getMine: "UserSavedIBans/GetMine",
        getById: "UserSavedIBans/GetById",
        getAll: "UserSavedIBans/GetAll",
    },
    level: {
        levelInfo: "Level/GetLevelInfo",
        verifySetup2fa: "User/VerifySetupTwoStep",
    },
    content: {
        getCategoriesByTopicEnName: "Content/GetCategoriesByTopicEnName",
        getContentByEnglishTitle: "Content/GetContentByEnglishTitle",
        getContentByTopicEnName: "Content/ContentByTopicEnName",
        getContentByCategoryEnName: "Content/GetContentsByCategoryEnName",
    }
};
