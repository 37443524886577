import './more-income.scss'
import {ReactComponent as LeftArrow} from "assets/icons/home/left-arrow.svg";
import {ReactComponent as InfoIcon} from "assets/icons/invite/info.svg";
import {ReactComponent as CountIcon} from "assets/icons/invite/count.svg";
import moreUnion from 'assets/images/invite/more-union.png'
import {get, responseValidator} from 'core/apis/api';
import {__API} from 'core/apis/api-routes';
import {digitSeparator} from 'core/utils/decimalExtentions';
import {useState, useEffect} from 'react'
import first from 'assets/images/invite/first.png'
import second from 'assets/images/invite/second.png'
import third from 'assets/images/invite/third.png'
import coin1 from 'assets/images/invite/coin1.png'
import coin2 from 'assets/images/invite/coin2.png'
import coin3 from 'assets/images/invite/coin3.png'
import coin4 from 'assets/images/invite/coin4.png'
import {Spin} from 'antd';

interface TopUser {
    name: string,
    count: number,
    balance: number,
}

export const MoreIncome = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [topUsers, setTopUsers] = useState<TopUser[]>()

    const getAllTopUsers = async () => {
        setLoading(true)
        await get(__API.referral.topUsers + '?Count=5').then((response: any) => {
            if (responseValidator(response.status)) {
                setTopUsers(response.data.data.users)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        !topUsers && getAllTopUsers()
    }, [])

    return (
        <div className="pmx-more-income">
            <main>
                <img id='more-union' src={moreUnion} alt="More-Union"/>
                <img id='coin1' src={coin1} alt='Coin1'/>
                <img id='coin2' src={coin2} alt='Coin2'/>
                <img id='coin3' src={coin3} alt='Coin3'/>
                <img id='coin4' src={coin4} alt='Coin4'/>
                <h3>درآمد بیشتر، با دوستان بیشتر!</h3>
                <p>با اشتراک گذاری لینک دعوت خود در شبکه‌های اجتماعی، سفیر پی ام اکسچنج باشید؛<br/> دوستان بیشتری داشته
                    باشید و پاداش دریافتی خود را افزایش دهید.</p>
                <div className="buttons">
                    <a href="#">
                        شروع به کسب درآمد <LeftArrow/>
                    </a>
                    <a href="#">
                        <InfoIcon/> اطلاعات بیشتر
                    </a>
                </div>
                <div className="referral-table-container">
                    <Spin spinning={loading}>
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                <th>رتبه</th>
                                <th>حساب کاربری</th>
                                <th>مجموع پاداش ها</th>
                                <th>تعداد دوستان</th>
                            </tr>
                            </thead>

                            <tbody>
                            {topUsers?.length !== 0 && topUsers?.map((user, index) =>
                                <>
                                    <tr>
                                        <td>
                                            {index + 1 === 1 && <img src={first} alt="First"/>}
                                            {index + 1 === 2 && <img src={second} alt="Second"/>}
                                            {index + 1 === 3 && <img src={third} alt="Third"/>}
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{user.name}</td>
                                        <td>{digitSeparator(user.balance)} تومان</td>
                                        <td><CountIcon/> {user.count}</td>
                                    </tr>
                                </>
                            )}
                            </tbody>
                        </table>
                    </Spin>
                    <div className="your-position">
                        جای شما اینجا خالیست!
                    </div>
                </div>
                <div className={"referral-mobile-container"}>
                    <Spin spinning={loading}>
                        {topUsers?.length !== 0 && topUsers?.map((user, index) =>
                            <div className={"referral-item"}>
                                <div className={"row"}>
                                    <div className={"column"}>
                                        <span>رتبه {index + 1}</span>
                                        <span>{user.name}</span>
                                    </div>
                                    {index + 1 === 1 && <img src={first} alt="First"/>}
                                    {index + 1 === 2 && <img src={second} alt="Second"/>}
                                    {index + 1 === 3 && <img src={third} alt="Third"/>}
                                </div>
                                <div className={"row"}>
                                    <div className={"column"}>
                                        <span>{digitSeparator(user.balance)} تومان</span>
                                        <span>مجموع پاداش ها</span>
                                    </div>
                                    <div className={"column"}>
                                        <span>{user.count} نفر</span>
                                        <span>تعداد دوستان</span>
                                    </div>

                                </div>
                            </div>
                        )}
                    </Spin>
                    <div className="your-position">
                        جای شما اینجا خالیست!
                    </div>
                </div>
            </main>
        </div>
    )
}