import { Divider } from 'antd';
import './invite-box-skeleton.scss';

export const InviteBoxSkeleton = () => {
  return (
    <div className="invite-box-skeleton">
      <span className='title-skeleton'></span>
      <div className="list-skeleton">
        <div className="row-skeleton">
          <p></p>
          <span className="value-skeleton"></span>
        </div>
        <Divider />
        <div className="row-skeleton">
          <p></p>
          <span className="value-skeleton">
          </span>
        </div>
      </div>
      <div className='button-skeleton' />
    </div>
  )
}