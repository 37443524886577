import { BonusHelp } from "./components/bonus-help/bonus-help";
import { InviteLanding } from "./components/landing/landing";
import { MoreIncome } from "./components/more-income/more-income";
import { InviteFriends } from "./components/invite-friends/invite-friends";
import "./invite.scss";
import { FAQ } from "./components/faq/faq";
import { Footer } from "../home/components/footer/footer";

export const Invite = () => {
  return (
    <div className="pmx-invite">
      <InviteLanding />
      <BonusHelp />
      <MoreIncome />
      <InviteFriends />
      <FAQ />
      <Footer />
    </div>
  );
};
