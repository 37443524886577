import "./sidebar.scss";
import { RootState } from "core/redux/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { MenuItem } from "core/domain/commons/common";
import React from "react";
import SidebarItem from "./sidebar-item/sidebar-item";
import { ReactComponent as PMXLogoHead } from 'assets/icons/dashboard/sidebar/PMX-Logo-Head.svg'
import { ReactComponent as HamburgerMenuIcon } from 'assets/icons/dashboard/sidebar/HamburgerMenu.svg'
import { useLocation, useNavigate } from "react-router-dom";
import { Divider } from "antd";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PropTypes extends PropsFromRedux {
  items: MenuItem[];
}

const Sidebar: React.FC<PropTypes> = ({ state, items }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState<boolean>(true)


  return (
    <div className={`pmx-sidebar ${isOpen ? 'open' : 'closed'}`}
    >
      <div className="sidebar-head">
        {isOpen && <PMXLogoHead onClick={() => navigate('/')} />}
        <HamburgerMenuIcon onClick={() => setIsOpen(!isOpen)} />
      </div>
      <Divider />
      <div className="sidebar-main desktop-show">
        <div className="menu">
          <>
            {items.map((item: MenuItem) => {
              return (
                <SidebarItem
                  id={item.id}
                  title={item.title}
                  icon={item.icon}
                  link={item.link}
                  isActive={item.isActive || item.link === location.pathname}
                  filled={item.filled ? item.filled : false}
                  isExpanded={isOpen}
                />
              );
            })}
            <div className="buttons">
              <div className="download-button">
                
              </div>
              <div className="logout-button">

              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
export default connector(Sidebar);
