import "./referral-info.scss";
import {copyToClipboard} from "core/utils/copyToClipboard";
import {BankAccount, ReferralInfo as MyReferralInfo} from "core/domain";
import {ReactComponent as Copy} from "assets/icons/referral/copy.svg";
import {useEffect, useState} from "react";
import {Badge, BadgeType, Button, buttonType, Card, Dropdown, Input, Modal} from "components";
import {deleteCommaSeparator, digitSeparator, faNumToEnNum, IsNumeric,} from "core/utils/decimalExtentions";
import {getMineReferralActions, getMineReferralInfo, postWithdrawalReferral,} from "core/repositores/referral";
import {Divider} from "antd";
import {ReferralActions, ReferralPayout} from "core/domain/referral/referral";
import moment from "jalali-moment";
import {getAllBankAccounts} from "core/repositores/bank-account";
import {formatCardNumber} from "core/utils/bankDetecter";
import {ReferralInfoSkeleton} from "./referral-info-skeleton/referral-info-skeleton";
import {ReactComponent as LeftArrow} from 'assets/icons/referral/LeftArrow.svg'

interface ReferralInfoProps {
  referralInfo: MyReferralInfo,
  referralActions: ReferralActions[],
  bankAccounts: BankAccount[],
  isLoading: boolean,
}

export const ReferralInfo: React.FC<ReferralInfoProps> = (props: ReferralInfoProps) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [payoutValues, setPayoutValues] = useState<ReferralPayout>(null);

  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState(false);

  const dropdownCards = props?.bankAccounts?.map((bankAccount) => ({
    title: formatCardNumber(bankAccount.cardNumber),
    id: bankAccount.id,
    icon: (
      <img
        src={`/banks/${bankAccount.englishBankName}.svg`}
        alt={bankAccount.bankName}
      />
    ),
  }));

  const openPayoutModal = () => {
    setPayoutModalIsOpen(true);
  };

  const closePayoutModal = () => {
    setPayoutModalIsOpen(false);
    setPayoutValues(undefined);
  };

  const onAllBalanceClick = () =>
    setPayoutValues({
      ...payoutValues,
      amount: props.referralInfo?.balance.toString(),
    });

  const onCardNumberChange = (value: any) =>
    setPayoutValues({ ...payoutValues, cardNumber: value });

  const onInputChange = (value: any) => {
    if (value !== "") value = faNumToEnNum(value);
    if (value !== "" && !IsNumeric(value.split("")[value.split("").length - 1]))
      return;

    setPayoutValues({
      ...payoutValues,
        amount: digitSeparator(faNumToEnNum(value)),
    });
  };


  const handleWithdrawal = async () => {
    await postWithdrawalReferral(
      setIsButtonLoading,
      {
        amount: Number(deleteCommaSeparator(payoutValues.amount)),
        bankAccountId: payoutValues.cardNumber.id.toString(),
      },
      () => closePayoutModal(),
    );
  };

  return (
      props.isLoading ? <ReferralInfoSkeleton /> : (
      <div className="invite-friends">
        <Modal
          className="referral-payout-modal"
          isOpen={payoutModalIsOpen}
          setIsOpen={setPayoutModalIsOpen}
          title="تسویه حساب"
        >
          <span>کارت خود را از کادر زیر و مبلغ درخواستی خود برای برداشت درآمد خود را وارد نمایید.</span>
          <Dropdown
            placeholder="کارت خود را انتخاب کنید"
            dropDownButtonIcon={payoutValues?.cardNumber?.icon}
            items={dropdownCards}
            selectedOption={payoutValues?.cardNumber}
            onChangeHandle={(value) => onCardNumberChange(value)}
          />
          <Input
            placeholder="میزان مبلغ برداشتی را وارد نمایید..."
            value={payoutValues?.amount}
            onChange={(e) => onInputChange(e.target.value)}
            adornment={
              <Badge type={BadgeType.info} text="همه موجودی" onClick={() => onAllBalanceClick()} />
            }
          />
          <div className="buttons">
            <Button
              type={buttonType.primary}
              disabled={!payoutValues?.amount || !payoutValues.cardNumber}
              text="تسویه و برداشت وجه"
              loading={isButtonLoading}
              onClick={handleWithdrawal}
            />

            <Button
              type={buttonType.outlined}
              text="بازگشت"
              onClick={() => closePayoutModal()}
              className="back-button"
              icon={<LeftArrow />}
            />
          </div>
        </Modal>
        <Card title="دعوت دوستان" className="">
          <div className="stats">
            <div className="row">
              <span>تعداد فعالیت:</span>
              <p>
                {props.referralActions?.length} <span>فعالیت</span>
              </p>
            </div>
            <div className="row">
              <span>تعداد زیرمجموعه:</span>
              <p>
                {props.referralInfo?.referralCount} <span>نفر</span>
              </p>
            </div>
            <div className="row">
              <span>مجموع سود:</span>
              <p>
                  {digitSeparator(props.referralInfo?.balance)} <span>تومان</span>
              </p>
            </div>
            <div className="row">
              <span>تاریخ عضویت:</span>
              <p>{moment(props.referralInfo?.createdOn).format("jYYYY/jMM/jDD")}</p>
            </div>
          </div>
          <div className="invite-code">
            <span>لینک دعوت شما: </span>
            <Button
              type={buttonType.outlined}
              text={`${window.location.origin}/register?ref=${props.referralInfo.referralCode}`}
              icon={
                <Copy />
              }
              onClick={() =>
                copyToClipboard(
                    `${window.location.origin}/register?ref=${props.referralInfo.referralCode}`,
                  "لینک دعوت شما",
                )
              }
            />
          </div>
          <Divider />
          <Button
            type={buttonType.primary}
            text="درخواست تسویه حساب"
            onClick={() => openPayoutModal()}
            className="payout-button"
          />
        </Card>
      </div>
    )
  );
};
