import {getViaAuth, postViaAuth, responseValidator} from "../apis/api";
import {API} from "../apis";
import {Enum, Order} from "../domain";

export async function getMineOrder(
  setLoading: any,
  setData: any,
  setResults?: any,
) {
  setLoading(true);
  await getViaAuth<Order>(API.orders.mine).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData(response.data);
      setResults && setResults(response.data);
    }
  });
}

export async function getOrderByTrackingCode(
  setLoading: any,
  setData: any,
  trackingCode: string,
  onComplete?: any,
) {
  setLoading(true);
  await getViaAuth<Order>(
    `${API.orders.getByTrackingCode}/${trackingCode}`,
  ).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData(response.data);
      onComplete && onComplete();
    }
  });
}

export async function getOrderStatesType(
  setLoading: any,
  setData: any,
  initialItemTitle: string,
) {
  let initialItem = {
    id: 100,
    title: initialItemTitle,
  };
  setLoading(true);
  await getViaAuth<Enum[]>(API.enums.orderStates).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData([initialItem, ...response.data]);
    }
  });
}

export async function createBuyOrder(
  setLoading: any,
  data: any,
  onComplete: (response: any) => void,
  onError?: (response: any) => void,
) {
  setLoading(true);
  await postViaAuth(API.orders.createBuy, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      onComplete(response);
    } else {
      onError(response);
    }
  });
}

export async function createSellOrder(
  setLoading: any,
  data: any,
  onComplete: (response: any) => void,
  onError?: (response: any) => void,
) {
  setLoading(true);
  await postViaAuth(API.orders.createSell, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      onComplete(response);
    } else {
      onError(response);
    }
  });
}

export async function payOrder(
  setLoading: any,
  data: any,
  onComplete: (response: any, moneyAccount: string) => void,
) {
  setLoading(true);
  await postViaAuth(API.orders.buyPay, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      onComplete(response, data.moneyAccount);
    }
  });
}

export async function verifyOrder(
  setLoading: any,
  data: any,
  setData: any,
  onError: () => void,
  onSucceed: (response) => void,
  setTimer: (number) => void,
) {
  setLoading(true);
  await postViaAuth(API.orders.buyVerify, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData(response);
      onSucceed(response)
      setTimer(10)
    } else {
      onError();
      setTimer(10)
    }
  });
}
