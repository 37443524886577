import './socials.scss'
import { ReactComponent as InstagramIcon } from 'assets/icons/about/instagram-blue.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/about/telegram-blue.svg'
import { ReactComponent as TwitterIcon } from 'assets/icons/about/twitter-blue.svg'

export const Socials = () => {
    return (
        <div className="pmx-socials">
            <div className="col">
                <h3>شبکه های اجتماعی</h3>
                <p>پی ام ایکسچنج را در شبکه های اجتماعی مختلف دنبال کنید و در جریان اخبار روز صرافی باشید.</p>
            </div>
            <div className="socials">
                <a className="social-item" href="https://www.instagram.com/pmxchange" target='_blank'>
                    <InstagramIcon />
                </a>
                <a className="social-item" href="https://t.me/pmxchangee" target='_blank'>
                    <TelegramIcon />
                </a>
                <a className="social-item" href="https://twitter.com/PMxchangee" target='_blank'>
                    <TwitterIcon />
                </a>
            </div>
        </div>
    )
}