import "./agreement-text.scss";

export const AgreementText = () => (
  <div className="text">
    <p>
      <span>1-</span>شما با فعالیت در این بخش شریک تجاری پی ام ایکس چنج می شوید.
    </p>
    <p>
      <span>2-</span>در این بخش، شما با دریافت لینک اختصاصی و اشتراک گذاری آن
      بین دوستان خود و فضای مجازی، تمامی اشخاصی که از طریق لینک شما ثبت نام می
      کنند زیر مجموعه شما محسوب می شوند.
    </p>
    <p>
      <span>3-</span>در ازای تراکنش افرادی که از طریق شما به پی ام ایکسچنج معرفی
      شده اند، مبلغ 5.000 تومان به ازای هر تراکنش آنها به شما پاداش داده می‌شود.
    </p>
    <p>
      <span>4-</span>با رسیدن پاداش های شما به 50 هزار تومان شما امکان برداشت
      وجه به حساب بانکی خود را خواهید داشت.
    </p>
    <p>
      <span>5-</span>در ازای هر برداشت شما مبلغ 10 هزار تومان کارمزد درگاه می
      پردازید.
    </p>
    <p>
      <span>6-</span>هر شخص فقط از زیر مجموعه مستقیم خود میتواند پاداش دریافت
      کند و از اشخاصی که توسط زیر مجموعه اش به پی ام ایکس چنج معرفی شده اند
      پاداشی به آن تعلق نمی گیرد.
    </p>
    <p>
      <span>7-</span>برای برداشت پاداش خود، شما حداقل باید 2 کاربر فعال داشته
      باشید.
    </p>
    <p>
      <span>8-</span>برای شروع فعالیت باید ابتدا حساب خود را احراز هویت نقره ایی
      سطح 1 (بدون ارسال مدرک) کرده و سپس یک حساب بانکی به نام خود ثبت نمائید.
    </p>
    <p>
      <span>9-</span>برداشت درآمد شما تنها بصورت ریالی امکان پذیر می باشد.
    </p>
    <p>
      <span>10-</span>برای راهنمایی بیشتر میتوانید از صفحه اصلی به صفحه کسب
      درآمد مراجعه و با شماره 02128428299 تماس حاصل فرمائید.
    </p>
    <p>
      <span>11-</span> چنانچه شما در قسمت کسب درآمد فعالیت می کنید بدان معنا می
      باشد که تمامی قوانین مجموعه پی ام ایکس چنج که در صفحه اصلی صفحه قوانین درج
      شده است را پذیرفته اید.
    </p>
  </div>
);
