import * as React from "react";
import { ReactComponent as Close } from "assets/icons/general/Close.svg";
import { PmxComponent } from "core/components/PmxComponenet";
import "./card.scss";
import { Divider, Spin } from "antd";

export enum CardTypes {
  "primary",
  "secondary",
}

interface Props extends PmxComponent {
  isClosable?: boolean;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  type?: CardTypes;
  loading?: boolean;
  divider?: boolean;
  button?: React.ReactNode;
}

export const Card: React.FC<Props> = (props) => {
  return (
    <>
      {props.hasOwnProperty("loading") ? (
        <Spin
          spinning={props.loading == true ? true : false}
          className="card-spinner"
        >
          <div
            className={`pmx-card ${CardTypes[props.type] || ""
              } ${props?.className}`}
          >
            {props.title ? (
              <div className="head">
                <h4>{props.title}</h4>
                {props.isClosable && <Close />}
                {props?.button}
              </div>
            ) : null}
            <div className="body">{props?.children}</div>
          </div>
        </Spin>
      ) : (
        <div
        className={`pmx-card ${CardTypes[props.type] || ""
      } ${props?.className}`}
      >
          {props.title && (
            <div className="head">
              <h4>{props.title}</h4>
              {props.isClosable && <Close />}
              {props?.button}
            </div>
          )}
          {props.divider && <Divider className="card-divider" />}
          <div className="body">{props?.children}</div>
        </div>
      )}
    </>
  );
};
