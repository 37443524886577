import React from "react";
import "./order-details-modal-skeleton.scss";

export const OrderDetailsModalSkeleton: React.FC = () => {
  return (
    <div className="order-details-modal-skeleton">
      <div className="badge-skeleton"></div>
      <div className="data-skeleton">
        {Array.from({ length: 7 }).map((_, index) => (
          <div className="row-skeleton" key={index}>
            <div className="skeleton-part"></div>
            <div className="skeleton-part"></div>
          </div>
        ))}
      </div>
    </div>
  );
};
