import { MENU, ORDERDATA, PROFILE_DATA, SIDEBAR, USER_DATA } from "./actions";
import {BankAccount, CashInDetail, LoginResponse, Profile} from "../domain";
import {MarketType, OrderCardStages, Side} from "../domain/landing/landing";
import { PMResult } from "core/domain/enter-voucher/enter-voucher";
import {Rate} from "../domain/rate/rate";
interface OrderData {
  stage?: OrderCardStages,
  phone?: string,
  bankAccount?: BankAccount,
  sourceAmount?: string | number,
  sourceMarket?: MarketType | string,
  destinationAmount?: string | number,
  destinationMarket?: MarketType | string,
  destinationWallet?: string,
  network?: string,
  trackingCode?: string,
  fee?: number
  tour?: boolean,
  paymentMethod?: number,
  paymentLink?: string,
  cashInDetail?: CashInDetail,
  iBan?: string,
  side?: Side,
  pmResult?: PMResult,
  forgetPassword?: boolean,
  rates?: Rate[]
}

export const initial_state = {
  menu: -1,
  profileData: null as Profile,
  userData: null as LoginResponse,
  sidebar: "close",
  orderData: {
    stage: OrderCardStages.CALCULATOR,
    phone: null,
    bankAccount: null,
    sourceAmount: null,
    sourceMarket: null,
    destinationMarket: null,
    destinationWallet: null,
    network: null,
    trackingCode: null,
    fee: null,
    tour: false,
    paymentMethod: null,
    paymentLink: null,
    cashInDetail: null,
    iBan: null,
    side: null,
    pmResult: null,
    forgetPassword: false,
    rates: null
  } as OrderData,
};

function reducer(state = initial_state, action: any) {
  switch (action.type) {
    case MENU:
      return { ...state, menu: action.payload };
    case PROFILE_DATA:
      return { ...state, profileData: action.payload };
    case USER_DATA:
      return { ...state, userData: action.payload };
    case SIDEBAR:
      return { ...state, sidebar: action.payload };
    case ORDERDATA:
      return { ...state, orderData: action.payload };
    default:
      return state;
  }
}

export default reducer;
