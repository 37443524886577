import "./articles.scss";
import { ReactComponent as LeftArrow } from "assets/icons/home/left-arrow.svg";
import { ReactComponent as SwiperRight } from "assets/icons/home/swiper-right.svg";
import { ReactComponent as SwiperLeft } from "assets/icons/home/swiper-left.svg";
import person from "assets/images/home/bg-person.png";
import folder from "assets/images/home/bg-folder.png";
import bell from "assets/images/home/bg-bell.png";
import { isDesktop, isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/css/bundle";
import "swiper/modules";
import { useRef } from "react";

interface Props { }

export const Articles = (props: Props) => {
  const swiperRef = useRef(null);

  const slideNext = () => {
    if (swiperRef?.current && swiperRef?.current?.swiper) {
      swiperRef?.current?.swiper?.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiperRef?.current && swiperRef?.current?.swiper) {
      swiperRef?.current?.swiper?.slidePrev();
    }
  };

  return (
    <div className="pmx-articles">
      <h3>
        مقالات آموزشی{" "}
        <a href="https://pmxchange.co/blog" target="_blank">
          مشاهده همه <LeftArrow />
        </a>
      </h3>
      {isDesktop && (
        <div className="articles">
          <article>
            <img src={person} />
            <div className="body">
              <h4>آموزش و معرفی امن ترین کیف پول های دیجیتال</h4>
              <p>
                پرفکـت مانی یک سیستـم پرداخـت بین‌المللی است که توسـط آن
                می‌توانید پرداخت ‌های اینترنتی در اکثر سایت های خارجی را به
                راحتی انجام دهید...
              </p>
              <div className="footer">
                <a>
                  مشاهده مطلب
                  <LeftArrow />{" "}
                </a>
                <span>۲۷ شهریور ۱۴۰۲</span>
              </div>
            </div>
          </article>
          <article>
            <img src={folder} />
            <div className="body">
              <h4>ان اف تی NFT چیست؟</h4>
              <p>
                پرفکـت مانی یک سیستـم پرداخـت بین‌المللی است که توسـط آن
                می‌توانید پرداخت ‌های اینترنتی در اکثر سایت های خارجی را به
                راحتی انجام دهید...
              </p>
              <div className="footer">
                <a>
                  مشاهده مطلب
                  <LeftArrow />{" "}
                </a>
                <span>۲۷ شهریور ۱۴۰۲</span>
              </div>
            </div>
          </article>
          <article>
            <img src={bell} />
            <div className="body">
              <h4>آیا رمز ارز بیت کوین مناسب برای سرمایه گذاری است؟</h4>
              <p>
                پرفکـت مانی یک سیستـم پرداخـت بین‌المللی است که توسـط آن
                می‌توانید پرداخت ‌های اینترنتی در اکثر سایت های خارجی را به
                راحتی انجام دهید...
              </p>
              <div className="footer">
                <a>
                  مشاهده مطلب
                  <LeftArrow />{" "}
                </a>
                <span>۲۷ شهریور ۱۴۰۲</span>
              </div>
            </div>
          </article>
        </div>
      )}
      {isMobile && (
        <div className="articles">
          <Swiper pagination={{ clickable: true }} ref={swiperRef}>
            <SwiperSlide>
              <article>
                <img src={person} />
                <div className="body">
                  <h4>آموزش و معرفی امن ترین کیف پول های دیجیتال</h4>
                  <p>
                    پرفکـت مانی یک سیستـم پرداخـت بین‌المللی است که توسـط آن
                    می‌توانید پرداخت ‌های اینترنتی در اکثر سایت های خارجی را به
                    راحتی انجام دهید...
                  </p>
                  <div className="footer">
                    <a>
                      مشاهده مطلب
                      <LeftArrow />{" "}
                    </a>
                    <span>۲۷ شهریور ۱۴۰۲</span>
                  </div>
                </div>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              <article>
                <img src={folder} />
                <div className="body">
                  <h4>ان اف تی NFT چیست؟</h4>
                  <p>
                    پرفکـت مانی یک سیستـم پرداخـت بین‌المللی است که توسـط آن
                    می‌توانید پرداخت ‌های اینترنتی در اکثر سایت های خارجی را به
                    راحتی انجام دهید...
                  </p>
                  <div className="footer">
                    <a>
                      مشاهده مطلب
                      <LeftArrow />{" "}
                    </a>
                    <span>۲۷ شهریور ۱۴۰۲</span>
                  </div>
                </div>
              </article>
            </SwiperSlide>
            <SwiperSlide>
              <article>
                <img src={bell} />
                <div className="body">
                  <h4>آیا رمز ارز بیت کوین مناسب برای سرمایه گذاری است؟</h4>
                  <p>
                    پرفکـت مانی یک سیستـم پرداخـت بین‌المللی است که توسـط آن
                    می‌توانید پرداخت ‌های اینترنتی در اکثر سایت های خارجی را به
                    راحتی انجام دهید...
                  </p>
                  <div className="footer">
                    <a>
                      مشاهده مطلب
                      <LeftArrow />{" "}
                    </a>
                    <span>۲۷ شهریور ۱۴۰۲</span>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          </Swiper>
          <div className="next-navigation" onClick={slideNext}>
            <SwiperLeft />
          </div>
          <div className="pre-navigation" onClick={slidePrev}>
            <SwiperRight />
          </div>
        </div>
      )}
    </div>
  );
};