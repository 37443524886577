import * as React from "react";
import { useRef, useState } from "react";
import { PmxComponent } from "core/components/PmxComponenet";
import useOnBlur from "core/utils/useOnBlur";
import "./dropdown.scss";
import { DownOutlined } from "@ant-design/icons";
import { Card } from "components/card/card";

interface DropdownItem {
  id: number | string;
  title: string;
  icon?: React.ReactElement;
}

interface Props extends PmxComponent {
  disabled?: boolean;
  onChangeHandle?: (value: DropdownItem) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  items: DropdownItem[];
  defaultSelected?: DropdownItem;
  dropDownButtonIcon?: React.ReactNode;
  selectedOption?: DropdownItem;
  placeholder?: string;
}

export const Dropdown: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState<DropdownItem>();

  const dropdowRef = useRef<any>();

  useOnBlur(dropdowRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <div
      ref={dropdowRef}
      className={`pmx-dropdown ${
        props.className && props.className !== "" ? props.className : ""
      }`}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`dropdown-selector ${selectedItem ? "selected" : ""}`}
      >
        <a role="button">
          <span>
            {props.selectedOption
              ? props.selectedOption?.title
              : selectedItem?.title || (
                  <span className="placeholder">{props?.placeholder}</span>
                )}
          </span>
        </a>
        {props.dropDownButtonIcon ? props.dropDownButtonIcon : <DownOutlined />}
      </div>
      <ul className={`dropdown-items ${isOpen ? "" : "d-none"}`}>
        {props.items.length > 0 &&
          props.items
            .filter((i) =>
              props.selectedOption
                ? i?.id !== props.selectedOption?.id
                : i?.id !== selectedItem?.id,
            )
            .map((item, idx, arr) => {
              return (
                <>
                  <li
                    key={idx}
                    className={`dropdown-item ${
                      props.selectedOption
                        ? item?.id === props.selectedOption?.id
                          ? "selected"
                          : ""
                        : item?.id === selectedItem?.id
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedItem(item);
                      props.onChangeHandle && props.onChangeHandle(item);
                      setIsOpen(false);
                    }}
                  >
                    <div>
                      {" "}
                      <span>{item.title}</span>
                      {item.icon}
                    </div>
                  </li>
                </>
              );
            })}
      </ul>
    </div>
  );
};
