import { isDesktop, isMobile } from 'react-device-detect';
import './timeline.scss';

export const TimeLine = () => {
    return (
        <div className="pmx-timeline">
            <h3>پی ام ایکسچنج در گذر زمان</h3>
            <div className="timelines">
                <div className="timeline">
                    <span>سال 1397</span>
                    <div className="blue-circle"><div className="inner" /></div>
                    <p>- ایجاد نام تجاری پی ام ایکس چنج به عنوان یکی از اولین پلتفرم های خرید و فروش ارزهای دیجیتال و پرفکت مانی در ایران<br />
                        - ارائه نرخ لحظه ای خرید و فروش رمزارز ها <br />
                        - ارائه خدمات خرید و فروش آنی پرفکت مانی و ووچر پرفکت مانی
                    </p>
                </div>
                <div className="timeline">
                    <span>سال 1398</span>
                    <div className="blue-circle"><div className="inner" /></div>
                    <p>- اولین پلتفرم معاملاتی هوشمند خرید و فروش رمزارز در بستر شبکه های اجتماعی <br />
                        - انجام اپدیت های متعدد در ربات هوشمند تلگرام جهت بهبود سرعت خرید و فروش</p>
                </div>
                <div className="timeline">
                    <span>سال 1399</span>
                    <div className="blue-circle"><div className="inner" /></div>
                    <p>- رونمایی از ورژن جدید وب سایت پی ام ایکس چنج <br />
                        - ایجاد امکان فروش اتوماتیک و واریز آنی وجوه <br />
                        - راه اندازی وبلاگ پی ام ایکس چنج جهت افزایش دانش کاربران در زمینه ارزهای دیجیتال</p>
                </div>
                {isMobile &&
                    <>
                        <div className="timeline">
                            <span>سال 1400</span>
                            <div className="blue-circle"><div className="inner" /></div>
                            <p>- اضافه کردن رمزارز های متنوع جهت خرید و فروش کاربران <br />
                                - تسویه اتوماتیک رمزارز ها به کیف پول کاربران <br />
                                - ارائه نسخه دوم ربات خرید و فروش تلگرام</p>
                        </div>
                        <div className="timeline">
                            <span>سال 1401</span>
                            <div className="blue-circle"><div className="inner" /></div>
                            <p>- آپدیت جدید طراحی وب سایت <br />
                                - گسترش تیم پشتیبانی <br />
                                - ایجاد چت آنلاین در وب سایت</p>
                        </div>
                        <div className="timeline">
                            <span>سال 1402</span>
                            <div className="blue-circle"><div className="inner" /></div>
                            <p>- افزایش درگاه های پرداخت وب سایت جهت ارائه خدمات مناسب و تسویه آنی <br />
                                - افزایش بانک های تسویه ریالی آنی <br />
                                - آپدیت جدید پنل کاربری</p>
                        </div>
                    </>
                }
                {isDesktop &&
                    <>
                        <div className="timeline">
                            <span>سال 1402</span>
                            <div className="blue-circle"><div className="inner" /></div>
                            <p>- افزایش درگاه های پرداخت وب سایت جهت ارائه خدمات مناسب و تسویه آنی <br />
                                - افزایش بانک های تسویه ریالی آنی <br />
                                - آپدیت جدید پنل کاربری</p>
                        </div>
                        <div className="timeline">
                            <span>سال 1401</span>
                            <div className="blue-circle"><div className="inner" /></div>
                            <p>- آپدیت جدید طراحی وب سایت <br />
                                - گسترش تیم پشتیبانی <br />
                                - ایجاد چت آنلاین در وب سایت</p>
                        </div>
                        <div className="timeline">
                            <span>سال 1400</span>
                            <div className="blue-circle"><div className="inner" /></div>
                            <p>- اضافه کردن رمزارز های متنوع جهت خرید و فروش کاربران <br />
                                - تسویه اتوماتیک رمزارز ها به کیف پول کاربران <br />
                                - ارائه نسخه دوم ربات خرید و فروش تلگرام</p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}