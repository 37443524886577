import {Enum} from "../commons/common";

export type MarketType = {
    type: Enum;
    symbol: string;
    iconUrl: string;
    name: string;
    isSellActive: boolean;
    isBuyActive: boolean;
};

export enum Side {
    Buy,
    Sell,
    Swap,
}

export enum OrderCardStages {
    CALCULATOR,
    ENTER_PHONE,
    LOGIN,
    TWO_FA,
    VERIFY_PHONE,
    SET_PASSWORD,
    ENTER_CARD,
    ENTER_WALLET,
    ENTER_WALLET_DEPOSIT,
    BASIC_INFO_FORM,
    BILL_PREVIEW,
    PAYMENT_RESULT,
    TRACKING_ORDER,
    RECEIVE_METHOD,
    PAYMENT_METHOD,
    ENTER_VOUCHER,
    GOOGLE_AUTHENTICATION
}


