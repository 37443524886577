import * as React from "react";
import {FC, useEffect, useState} from "react";
import "./two-fa.scss";
import {ToEn} from "../../../../../../../core/utils/decimalExtentions";
import {ReactComponent as Clock} from "assets/icons/home/clock.svg";
import ReactCodeInput from "react-verification-code-input";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {RootState} from "../../../../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData, setUserData,} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {sendCode, verifyCode,} from "../../../../../../../core/repositores/login";
import {ReactComponent as InfoCircle} from "assets/icons/general/info-error.svg";
import {ConfigProvider, Spin, Tour, TourProps} from "antd";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import fa_IR from "antd/lib/locale/fa_IR";
import {getUserProfile} from "../../../../../../../core/repositores/profile";

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}


const TwoFaStage: FC<Props> = ({state}) => {
    const {orderData} = state;
    const [code, setCode] = useState("");
    const [countdown, setCountdown] = useState(120);
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [resendIsLoading, setResendIsLoading] = useState(false);

    const tourSteps: TourProps['steps'] = [
        {
            title: 'وارد کردن کد',
            description: 'کد 5 رقمی که به شماره همراه شما ارسال شده را وارد نمایید.',
            target: () => tour1.current,
        },
        {
            title: 'ارسال مجدد کد',
            description: 'در صورتی که کدی برای شما ارسال نشد، پس از یک دقیقه میتوانید از این بخش درخواست ارسال مجدد کد نمایید.',
            target: () => tour2.current,
        },
        {
            title: 'تغییر شماره همراه',
            description: 'در صورتی که تصمیم به تغییر شماره همراه جهت ارسال کد دارید، از این بخش اقدام نمایید.',
            target: () => tour3.current,
        },
        {
            title: 'ورود',
            description: 'در صورتی که کد 5 رقمی را وارد کرده اید، از این بخش میتوانید اقدام به تایید کد یا بازگشت نمایید.',
            target: () => tour4.current,
        },
    ]

    const tour1 = React.useRef(null)
    const tour2 = React.useRef(null)
    const tour3 = React.useRef(null)
    const tour4 = React.useRef(null)

    const dispatch = useDispatch();
    const onBackClick = () =>
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.ENTER_PHONE,
                phone: null,
                tour: false,
                forgetPassword: false,
            }),
        );

    async function onLoginHandle(codeValue?: any, forgetPassword?: boolean) {
        const body = {
            code: codeValue ?? code,
            phoneNumber: orderData?.phone,
        };

        await verifyCode(
            setButtonIsLoading,
            body,
            (data) => dispatch(setUserData(data)),
            async () => {
                await getUserProfile(setButtonIsLoading)
                dispatch(
                    setOrderData({
                        ...state.orderData,
                        stage: forgetPassword === true ? OrderCardStages.SET_PASSWORD : OrderCardStages.CALCULATOR,
                        tour: false,
                        forgetPassword: forgetPassword
                    }),
                )
            },
            () => dispatch(setOrderData({
                ...state.orderData,
                stage: OrderCardStages.GOOGLE_AUTHENTICATION,
                tour: false,
                forgetPassword: forgetPassword
            })),
            (response) => setError(response.message),
        );
    }

    async function onResendCode() {
        const body = {
            phoneNumber: orderData?.phone,
            forPasswordCheck: false,
        };

        setError(undefined);

        await sendCode(setResendIsLoading, body, (response) => setCountdown(120));
    }

    useEffect(() => {
        window.history.pushState(
            window.history.state,
            document.title,
            "/enter-2fa"
        );
    }, [])
    return (
        <div className={"pmx-twofa-stage"}>
            <h3>ورود با کد یکبار مصرف</h3>
            <p className={"description"}>
                کد ۵ رقمی پیامک شده به شماره {orderData?.phone} را در کادر زیر وارد
                نمایید.
            </p>
            <div className="code">
                <div ref={tour1}>
                    <ReactCodeInput
                        className={`code-input ${error?.length ? "error" : ""}`}
                        fieldWidth={91}
                        fieldHeight={80}
                        values={code.split("")}
                        autoFocus={true}
                        onChange={(value) => {
                            setCode(ToEn(value));
                        }}
                        onComplete={(codeValue) => {onLoginHandle(codeValue, orderData.forgetPassword)}}
                        fields={5}
                    />
                </div>
                <div className={"options"}>
                    {countdown === 0 ? (
                        <span ref={tour2} className={"resend"} onClick={() => onResendCode()}>
              {resendIsLoading ? <Spin/> : "ارسال مجدد"}
            </span>
                    ) : (
                        <div className={"countdown"} ref={tour2}>
                            <Clock/>
                            <div className="timer">
                                <CountdownCircleTimer
                                    duration={countdown}
                                    isPlaying
                                    onComplete={(time) => setCountdown(0)}
                                    colors={["#0072FE", "#ff4545"]}
                                    colorsTime={[120, 0.000001]}
                                    strokeWidth={0}
                                    trailStrokeWidth={0}
                                >
                                    {({remainingTime}) => remainingTime}
                                </CountdownCircleTimer>
                            </div>
                            <span>ثانیه دیگر تا ارسال مجدد</span>
                        </div>
                    )}
                    <span className={"change-number"} ref={tour3} onClick={() => onBackClick()}>
            تغییر شماره همراه
          </span>
                </div>
            </div>
            {error?.length && (
                <div className={"error-row"}>
                    <InfoCircle/>
                    <span>{error}</span>
                </div>
            )}

            <div ref={tour4}>
                <OrderCardButtons
                    submitButton={{
                        text: "تایید کد",
                        disabled: code.split("").length !== 5 || buttonIsLoading,
                        loading: buttonIsLoading,
                        onClick: () => {onLoginHandle()},
                    }}
                    cancelButton={{
                        text: "بازگشت",
                        onClick: () => onBackClick(),
                    }}
                />
            </div>

            <ConfigProvider locale={fa_IR} direction={"rtl"}>
                <Tour steps={tourSteps} open={state?.orderData?.tour}
                      onClose={() => dispatch(setOrderData({...state.orderData, tour: false}))}/>
            </ConfigProvider>
        </div>
    );
};
export default connector(TwoFaStage);
