import './terms-and-conditions.scss'

export const TermsAndConditions = () => {
    return (
        <div className="pmx-terms-and-conditions">
            <div id="authentication">
                <h3>احراز هویت:</h3>
                <p>
                    ۱- حداقل و حداکثر سن برای ثبت نام،احراز هویت و فعالیت در مجموعه پی ام ایکس چنج ۱۸ تا ۵۰ سال تمام می باشد
                    لازم به ذکر است با تشخیص کارشناسان برخی افراد که در این بازه سنی قرار دارند و مشکوک می باشند نیز اجازه فعالیت و احراز هویت نخواهند داشت.<br />
                    ۲-  پس از ثبت نام و احراز هویت در مجموعه پی ام ایکسچنج امکان تغییر مشخصات وجود ندارد و هر شخص با هویت و ملیت ایرانی فقط یک بار مجاز به ثبت نام در‌ مجموعه پی ام ایکسچنج می باشد و در صورت ثبت نام با شماره ها یا مشخصات مختلف بنا به تشخیص مجموعه تمامی حساب های کاربری متعلق به کاربر مسدود خواهد شد.<br />
                    ۳- مجموعه پی ام ایکسچنج مجاز است در صورتی که تراکنشی را مشکوک تشخیص دهد سفارش را معلق نگه داشته و پس از تعیین وضعیت سفارش و تایید هویت مجدد کاربر آن را کامل نماید.<br />
                    ۴- یکی از موارد مهم در احراز هویت اطلاع و آگاهی نسبت به حساب های اجاره ای و عواقب کیفری آن می باشد، کاربران گرامی باید توجه داشته باشند که حساب کاربری خود را در اختیار هیچ شخص ثالثی قرار ندهند و برای اشخاص دیگر خریدی انجام ندهند. در غیر این صورت تمامی عواقب قضایی و کیفری آن بصورت مستقیم متوجه کاربر مربوطه خواهد بود.<br />
                    ۵- کاربر حق ندارد اجازه استفاده از حساب کاربری خود در مجموعه پی ام ایکسچنج را به دیگران بدهد یا حساب کاربری خود را در اختیار شخص دیگری بگذارد و هرگونه خرید فروش باید با نام کاربر و توسط خود کاربر انجام شود. در غیر اینصورت مبالغ پرداختی تا تعیین تکلیف بنا به تشخیص مجموعه بلوکه خواهند شد.<br />
                    ۶- تمامی اطلاعات احراز هویتی کاربران بصورت فوق محرمانه محافظت شده می باشند و دسترسی به آن‌توسط شخص ثالث ممنوع بوده مگر این درخواست از سوی مراجع قضایی ذیصلاح باشد که در‌ این صورت مجموعه پی ام ایکسچنج موظف به ارائه اطلاعات کاربر متخلف می باشد.<br />
                    ۷- در خصوص کاربرانی که مدارک آنها برای ارتقاع سطح کاربری به دلیل شرایط سنی قابل تایید نمی باشد سفارش آنها درصورتی که مبلغ آن بالا نباشد بعد اتمام تایمر نمایش داده خواهد شد و در صورتی میتوانند در سطح فعلی فعالیت کنند که خرید های آنها مجموعا بالاتر از یک میلیون تومان(یا سقف سطح کاربری نقره ای در آن زمان) نرفته و در صورت عبور خرید از سقف مجاز تایمر ۷۲ ساعت اعمال خواهد شد و اگر مبلغ بالا باشد، وجه مسدود خواهد شد تا به وضعیت آن رسیدگی شود.<br />
                    ۸-تایید احراز هویت تنها با استفاده از مدارک هویتی معتبر و جدید ممکن می باشد؛ این مدارک شامل کارت ملی هوشمند، شناسنامه جدید و یا گواهی نامه هوشمند همراه با رسید کارت ملی می باشد.
                </p>
            </div>
            <div id="rules">
                <h3>قوانین خرید و فروش:</h3>
                <p>
                    ۱-کاربر پس از ثبت درخواست تبدیل ارز و مشاهده مقدار محاسبه شده توسط وب سایت باید دراسرع وقت نسبت به ثبت سفارش نهایی و ثبت هش تراکنش و TXID اقدام کند در غیر اینصورت درخواست کاربر منقضی شده و وضعیت سفارش باز ثبت می شود.<br />
                    ۲- در صورتی کاربر آدرس کیف پول از پی ام ایکسچنج دریافت کند و سفارش مورد نظر راتکمیل نکرده و برای واریز ارز خود سفارش جدیدی ایجاد کند؛ سفارش جدید درصورتی مورد تایید می باشد که کاربر کد پیگیری سفارشی که جهت دریافت آدرس کیف پول در پنل کاربری و سفارشات آن ایجاد شده است را در اختیار داشته باشد. این امر از طریق پشتیبانی های مجموعه قابل پیگیری است. لازم به ذکر است در صورتی که کابر مورد ذکر شده را دراختیار نداشته باشد پی ام ایکسچنج میتواند تا ۷۲ ساعت تسویه مرو نظر را به تعویق بی اندازد، زیرا ممکن است سفارش متعلق به کاربر دیگه ای بوده و بتواند مدارک مورد نیاز جهت تکمیل سفارش را به مجموعه ارائه دهد.<br />
                    ۳- فی ارسال ارز دیجیتال از جمله تتر، بیت کوین و سایر ارزها و پرداخت مبلغ ۱۵ هزار تومان(متغیر می باشد) به عنوان کارمزد و کارمزد درگاه پرداخت بر عهده کاربر می باشد که بر روی مبلغ نهایی محاسبه شده و یا از مبلغ ارز درخواستی کسر میگردد.<br />
                    لازم به ذکر است فی انتقال رمزارزها متغیر بوده و پلتفرم و شبکه های مختلف متفاوت می باشد.
                    ۴- کاربر موظف است سفارش خود را با دقت کامل ثبت نماید و در صورتی که در ثبت سفارش آدرس ولت یا مقدار ارز را اشتباه درج نماید امکان لغو، برگشت وجه یا ارز امکان پذیر نمی باشد.در صورتی که کاربر قصد تغییر آدرس ولت را داشته باش و تراکنش کاربر انجام نشده باشد میتواند از طریق پشتیبانی مجموعه نسبت به تغییر آدرس اقدام نماید.<br />
                    ۵-در صورت واریز مبلغ با مقدار متفاوت از سفارش ثبت شده از طرف مجموعه پی ام ایکسچنج یا کاربر، هر دو طرف موظف به اصلاح بدون قید و شرط و تسویه تراکنش بر طبق سفارش می باشند. در غیر اینصورت چنان چه این اشتباه از سوی کاربر رخ دهد این حق برای مجموعه پی ام ایکسچنج محفوظ است که محاسبات را مطابق با مقدار دریافتی محاسبه نموده و با کاربر تسویه نماید.<br />
                    ۶- با توجه به سابقه خرید و فروش های کاربر و مدت زمان فعالیت آن در پی ام ایکس چنج، در صورتی که به تشخیص کارشناس تعداد و حجم تراکنش های کاربرنسبت به سابقه آن غیر طبیعی باشد، پی ام ایکس چنج این اختیار را دارد که سفارش کاربر را از ۲۴ ساعت تا ۷۲ ساعت به حالت تعلیق درآورده و تایمر زمانی را برای سفارش مورد نظر لحاظ کند.<br />
                    ۷- ثبت سفارش یا خرید ارز از مجموعه پی ام ایکسچنج برای استفاده در سایت های شرط بندی ممنوع بوده و در صورت استفاده کاربران برای این گونه سایت ها حساب کاربری ایشان مسدود خواهد شد.<br />
                    ۸-  طبق دستور پلیس فتا و مقام محترم قضایی، جهت جلوگیری از پولشویی و سیاست حساب های اجاره ای، با تشخیص پی ام ایکس چنج ثبت تمام درخواست های برداشت ریال و رمزارز کاربران پس از گذشت ۷۲ ساعت از آخرین واریز ریالی کاربر، امکان پذیر خواهد بود.<br />
                    ۹-  طبق ابلاغیه پلیس فتا برخی تراکنش های کاربران به تعلیق درآمده و در صورت واریز موفق ۷۲ ساعت بعد به آنها نمایش داده یا ارسال می‌شود. لازم به ذکر است این امر فقط برای تراکنش اول نبوده و ممکن است طبق بند 6 تراکنش های بعدی کاربر با محدودیت مواجه شود، همچنین در این حالت ارز مورد نظر برای کاربر ساخته شده (در خصوص ووچر پرفکت مانی) و فرایند قابل برگشت نمی باشد.<br />
                    ۱۱- حداقل مقدار خرید رمز ارز ها برای کاربر ده دلار و برای برخی از رمز ارزها که فی شبکه آنها بالا می باشد ۵۰ الی ۱۰۰ دلار می باشد. حداقل مقدار فروش رمزارز هایی مانند تتر به پی ام ایکسچنج ۱۵ تتر می باشد .این مقدار ممکن است تغییر کند.<br />
                    ۱۲-  در صورتی که امکان تکمیل سفارش از سوی پی ام ایکسچنج به هر دلیلی از قبیل عدم تایید احراز هویت و مشکوک بودن واریزی وجود نداشته باشد مبلغ بعد از ۳ تا ۷ روز کاری تنها به حسابی که واریز از آن انجام شده است قابل برگشت می باشد و تنها با دستور پلیس فتا و یا نامه قضایی امکان واریز به حساب دیگر ممکن می باشد.<br />
                    ۱۳- مسئولیت بررسی صحت آدرس ولتی که کاربر هنگام درخواست برداشت ارز از کیف پول ارائه می دهد بر عهده کاربر می باشد و علاوه بر آن خریدار موظف است اگر انتقال به ولتی که ارائه می دهد نیازمند اطلاعات اضافه همچون تگ، ممو و موارد مشابه است آن را در قسمت مربوطه ثبت کند. بدیهی است در صورتی که آدرس اشتباه توسط کاربر ثبت شده باشد و یا به دلیل عدم ارائه اطلاعات صحیح در خصوص آدرس ارسال ارز و ارائه اطلاعات ناکافی توسط خریدار انتقال با مشکل مواجه شود مسئولیت آن با خریدار است.<br />
                    ۱۴- هر کاربر فقط مجاز است چه برای خرید از مجموعه و چه برای نقد کردن درآمدهای ارزی خود فقط شماره حساب و‌ کارت های متعلق به خود را وارد نماید و مجموعه پی ام ایکسچنج هیچ گونه مسئولیتی در خصوص واریز به حساب یا کارت افراد دیگر در حساب کاربری شخص نخواهد داشت.<br />
                    ۱۵- مسئولیت استفاده از حساب کاربری در مجموعه پی ام ایکسچنج بر عهده خود کاربر می باشد و لذا چنان چه موجودی حساب کاربری که ناشی از دسترسی اشخاص غیر به تلفن همراه کاربر به صورت غیرمجاز به سرقت رود پی ام ایکسچنج هیچ گونه مسئولیتی را در این خصوص نخواهد پذیرفت.<br />
                </p>
            </div>
            <div id="remark">
                <h3>تبصره</h3>
                <p>
                    ۱-  زمان تخمینی واریز در رسید دیجیتال ارسالی به کاربر درج شده است. این زمان دقیق نبوده و بسته به شرایط درگاه پرداخت ممکن است ساعاتی دیر تر به حساب مقصد بنشیند.<br />
                    ۲- سیکل های تسویه پایا مطابق با آخرین اعلام از طرف بانک مرکزی متغیر است.<br />
                    نکته ۱: سیکل تسویه بسته به بانک مقصد حساب کاربر ممکن است متفاوت باشد و پس از ارسال تسویه توسط پی ام ایکسچنج از عهده مجموعه خارج می باشد.<br />
                </p>
            </div>
            <div id="commitment">
                <h3>تعهدنامه</h3>
                <p>
                    با استفاده از خدمات پی ام ایکسچنج کاربر می پذیرد که:<br />
                    ۱- کلیه فعالیت های وی مطابق با قوانین جمهوری اسلامی ایران بوده و در صورت هرگونه تخلف، سواستفاده مالی و غیرمالی و هرگونه فعالیت مشکوک حساب کاربری وی مسدود و اطلاعات هویتی و مالی کاربر از طریق مراجع قضایی پیگیری خواهد شد.<br />
                    ۲- استفاده از خدمات پی ام ایکسچنج به این معنی است که کاربر قوانین را کاملاً خوانده و پذیرفته است. در غیر اینصورت، حق استفاده از مجموعه پی ام ایکسچنج را ندارد.<br />
                    ۳- در صورت تغییر در قوانین اطلاع رسانی لازم از طریق ایمیل و پیامک به کاربران صورت خواهد گرفت و کاربر نیز موظف است همواره قوانین مجموعه را مطابق با آخرین تغییرات و شرایط استفاده از سرویس ها مطالعه نموده و آن ها را بپذیرد.<br />
                    ۴- مجموعه پی ام ایکسچنج با تمام امکانات خود و با نیروهای زبده و حرفه ای در ساعات کاری تمام تلاش خود را در جهت رضایت کاربران، رفع اختلاف در‌سفارشات و‌ استفاده از خدمات متنوع مجموعه را خواهد نمود. لذا در صورت وقوع مواردی از قبیل تعلیق خدمات مجموعه به علت اقدام غیر قانونی کاربران یا اشخاص ثالث، مجموعه در تلاش است در سریع ترین زمان ممکن مشکل را برطرف نماید اما نمی‌توانیم سود و زیان ناشی از این وقفه زمانی را برای کاربران جبران نماییم.<br />
                    ۵- هرگونه پیامد های مالیاتی ناشی از تراکنش های مالی کاربر با مجموعه پی ام ایکسچنج برعهده خود کاربر می باشد.<br />
                    ۶- مسئولیت همه اقدامات و فعالیت های کاربران که ناشی از عدم رعایت قوانین جمهوری اسلامی ایران از جمله قانون مجازات اسلامی و جرائم رایانه ای و قوانین مدنی و تجارت الکترونیک باشد به صورت مستقیم بر عهده کاربر بوده و مجموعه پی ام ایکسچنج هیچ گونه مسئولیتی در مقابل اعمال فوق نخواهد داشت و در صورت مشاهده ضمن مسدودسازی حساب کاربر از طریق مراجع قانونی ذیصلاح نسبت به احقاق حقوق مجموعه اقدام خواهد نمود.<br />
                    ۷- هر گونه اقدامی از سوی کاربر که سبب وارد شدن خسارت به شهرت، اعتبار و دارایی های ریالی و ارزی مجموعه پی ام ایکسچنج شود تخلف تلقی‌شده و مجموعه این حق را برای خود محفوظ می داند که حساب‌کاربر متخلف را مسدود و اقدامات قانونی‌علیه وی را در مراجع مربوطه انجام دهد.<br />
                    ۸- تایید پرداخت ریالی یا ارزی تراکنش از طرف کاربر به این معناست که کاربر تمامی موارد ذکر شده در بخش قوانین خرید و فروش و سوالات متداول را مطالعه نموده و پذیرفته است و در صورت هر گونه تغییر در سفارش یا اشتباه که ناشی از عدم آگاهی کاربر از قوانین‌مجموعه باشد هیچ گونه مسئولیتی متوجه مجموعه پی ام ایکسچنج نخواهد بود.<br />
                    ۹- در صورت بروز هرگونه اختلال در خدمات، مجموعه موظف است حداکثر ظرف مدت 48 ساعت( به استثناء روزهای تعطیل) وجوه واریزی توسط کاربر را به همان حسابی که مبلغ از آن واریز شده است عودت دهد.<br />
                    ۱۰- مجموعه پی ام ایکسچنج این اختیار را دارد که در صورت مشکوک بودن کاربر بدون اطلاع وجه ریالی مورد نظر را تا اطلاع ثانوی مسدود کند، همچنین اگر تراکنش یا واریزی چه واریز وجه ریالی و چه واریز ارزی و دلاری خارج از سیستم مجموعه به حساب های ریالی و دلاری صورت گرفت، مجموعه میتوانید نسبت به شرایط موجود در مورد آن تراکنش تصمیم گیری کند .<br />
                    ۱۱- پی ام ایکسچنج هیچ گونه مسئولیتی در قبال سرقت رمز عبور و استفاده از حساب کاربری شما توسط اشخاص دیگر را نمی پذیرد. پس در حفظ اطلاعات حساب کاربری خود دقت فرمایید.<br />
                    چنانچه با مورد مشکوکی از جمله دسترسی و تغییرات غیرعادی در حساب کاربریتان مواجه شدید، سریعا پشتیبانی پی ام ایکسچنج را مطلع نمایید.<br />
                    ۱۲- چنان چه کاربری چندین سفارش فروش به مجموعه پی ام ایکسچنج را در یک روز ثبت نماید ممکن است تمام سفارشات در قالب یک تراکنش شبا برای کاربر واریز شود.<br />
                    ۱۳-  واریزی های ریالی به حساب کاربران  در برخی از بانک ها آنی و در برخی دیگر ممکن است به صورت پایا و مطابق با سیکل تسویه پایا توسط سیستم بانکی انجام شود.<br />
                    ۱۴- تسویه حساب های مجموعه پی ام ایکسچنج در روزهای غیر تعطیل چند نوبت در روز و از طریق سیکل پایا انجام می گردد. و در روزهای تعطیل ممکن است به خاطر تعطیلی سیستم بانکی با تاخیر و در روز کاری بعد انجام شود.<br />
                </p>
            </div>
            <div id="disclaimer">
                <h3>سلب مسئولیت</h3>
                <p>
                    ۱- تمامی فعالیت مجموعه پی ام ایکسچنج در زمینه ارزهای دیجیتال از معاملات این مجموعه با مشتریان خود در داخل کشور بوده و ارتباطی با دلار فیزیکی اسکناس یا حوالجات ارزی ندارد.<br />
                    ۲- مجموعه پی ام ایکسچنج تحت هیچ شرایطی در حوزه فعالیت مرتبط با صرافی ها از جمله خرید و فروش و اعلام نرخ ارز اسکناس و دلار کاغذی یا حواله ورود پیدا نکرده و نخواهد کرد.<br />
                    ۳- با توجه به قانون تجارت الکترونیک مصوب سال 1382 قرارداد فروش یا خرید از طریق ارسال ایمیل‌یا پیامک به کاربر مربوطه ارسال خواهد شد که در این صورت حکم ابلاغ و امضا قانونی قرارداد توسط کاربر مربوطه را دارد و قابل استناد در محاکم قضایی و صالح به رسیدگی دعوی می باشد.<br />
                    آدرس های مجموعه : <br />
                    آیدی ربات‌ تلگرامی : @Pmxchange_bot <br />
                    آدرس وب سایت : PMXchange.co <br />
                    به غیر از موارد فوق هر گونه آدرسی یا تلفن مشابه مورد تایید مجموعه پی ام ایکسچنج نبوده و جعلی می باشند و مسئولیت هر گونه تراکنش کاربر با آیدی های مشابه و جعلی بصورت مستقیم یه عهده خود کاربر می باشد.<br />
                    فعالیت‌های این مجموعه کاملا تابع قوانین و مقررات جمهوری اسلامی ایران می‌باشد.<br />
                </p>
            </div>
        </div>
    )
}