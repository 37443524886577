import "./price-card-skeleton.scss";

export const PriceCardSkeleton = () => {
  return (
    <div className="price-card-skeleton">
      <div className="list-skeleton">
        <div className="row-skeleton">
          <div className="currency-info">
            <div className="circle-skeleton"></div>
            <div className="info-skeleton">
              <span />
              <p />
            </div>

          </div>
          <div className="currency-info mobile-show">
            <div className="info-skeleton">
              <span className="rate" />
              <p />
            </div>
          </div>
        </div>
        <div className="row-skeleton">
          <div className="button-skeleton" />
        </div>
      </div>
    </div>
  );
};
