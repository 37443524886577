import {ConfigProvider, Divider, Spin, Tour, TourProps} from "antd";
import "./calculator.scss";
import {ReactComponent as BuyIcon} from "assets/icons/home/buy-arrow.svg";
import {ReactComponent as SellIcon} from "assets/icons/home/sell-arrow.svg";
import {ReactComponent as SwapIcon} from "assets/icons/home/swap-arrow.svg";
import {ReactComponent as RightArrow} from "assets/icons/home/right-arrow.svg";
import {ReactComponent as SearchIcon} from "assets/icons/home/search.svg";
import {ReactComponent as ActiveIcon} from "assets/icons/home/active.svg";
import {ReactComponent as BlueIndicator} from "assets/icons/home/blue-indicator.svg";
import React, {FC, useEffect, useRef, useState} from "react";
import {getIconPath} from "core/utils/getIconPath";
import {Button, buttonType, Modal} from "components";
import {exchangePair, getMarkets, validatePair} from "core/repositores/landing";
import {deleteCommaSeparator, digitSeparator, faNumToEnNum, IsNumericText} from "core/utils/decimalExtentions";
import {MarketType, OrderCardStages, Side} from "core/domain/landing/landing";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "../../../../../../../core/redux/actions";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import {RootState} from "../../../../../../../core/redux/store";
import fa_IR from "antd/lib/locale/fa_IR";
import {KEY_IS_AUTHORIZED} from "../../../../../../../core/constants/localStorageConstants";
import {readProfile} from "../../../../../../../core/repositores/profile";
import {isCustomer} from "../../../../../../../core/utils/setLocalStorageItems";
import {LoadingOutlined} from "@ant-design/icons";
import {Rate} from "../../../../../../../core/domain/rate/rate";

interface Props {
}

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    rates: Rate[]
    ratesLoading: boolean
}

enum Market {
    Source,
    Destination,
}

const initial_markets = [
    {
        isBuyActive: false,
        isSellActive: false,
        symbol: "IRT",
        name: "تومان",
        iconUrl: "",
        type: {
            id: 1,
            title: "",
        },
    },
    {
        isBuyActive: false,
        isSellActive: false,
        symbol: "USDT",
        name: "تتر",
        iconUrl: "",
        type: {
            id: 2,
            title: "",
        },
    },
];

const Calculator: FC<Props> = ({state, ratesLoading, rates}) => {
    const tour1 = useRef(null);
    const tour2 = useRef(null);
    const tour3 = useRef(null);
    const tour4 = useRef(null);
    const tour5 = useRef(null);
    const tour6 = useRef(null);

    const tourSteps: TourProps["steps"] = [
        {
            title: "انتخاب سمت معامله",
            description: "از بین خرید، فروش یا مبادله، یکی را انتخاب کنید ",
            target: () => tour1.current,
        },
        {
            title: "مقدار پرداختی",
            description: "مقدار پرداختی را از این قسمت وارد کنید",
            target: () => tour2.current,
        },
        {
            title: "ارز پرداختی",
            description: "ارز پرداختی را از این قسمت انتخاب کنید",
            target: () => tour3.current,
        },
        {
            title: "مقدار دریافتی",
            description: "مقدار دریافتی را از این قسمت وارد کنید",
            target: () => tour4.current,
        },
        {
            title: "ارز دریافتی",
            description: "ارز دریافتی را از این قسمت انتخاب کنید",
            target: () => tour5.current,
        },
        {
            title: "اقدام به معامله",
            description: "با فشردن این دکمه، اقدام به معامله نمایید",
            target: () => tour6.current,
        },
    ];

    var PM = {
        isBuyActive: false,
        isSellActive: false,
        symbol: "PM",
        name: "پرفکت مانی",
        iconUrl: "",
        type: {
            id: 2,
            title: "",
        },
    }
    const [side, setSide] = useState<Side>(Side.Buy);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [marketsIsLoading, setMarketsIsLoading] = useState(false);
    const [submitButtonIsLoading, setButtonIsLoading] = useState(false);
    let queryParams = new URLSearchParams(window.location.search);

    const [sourceMarket, setSourceMarket] = useState<MarketType>(
        initial_markets[0],
    );
    const [destinationMarket, setDestinationMarket] = useState<MarketType>(
        (queryParams.get("destinationSymbol") === "PM" || isCustomer()) ? PM : initial_markets[1],
    );
    const [sourceFieldIsLoading, setSourceFieldIsLoading] =
        useState<boolean>(false);
    const [destinationFieldIsLoading, setDestinationFieldIsLoading] =
        useState<boolean>(false);
    const [sourceAmount, setSourceAmount] = useState("0");
    const [destinationAmount, setDestinationAmount] = useState("1");
    const [exchangeIsLoading, setExchangeIsLoading] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState<Market>();
    const [searchMarketValue, setSearchMarketValue] = useState<string>("");
    const [markets, setMarkets] = useState<MarketType[]>();
    const [marketResults, setMarketResults] = useState<MarketType[]>();

    const [dollarEquivalent, setDollarEquivalent] = useState<number>(0)

    const dispatch = useDispatch();
    const intervalRef = useRef(null);

    function clearInputs() {
        setSourceAmount("");
        setDestinationAmount("");
    }


    function exchangeCalcHandler(value: string, side: "SOURCE" | "DESTINATION") {
        // Clear the existing timeout to prevent unnecessary exchange calculations.
        if (intervalRef.current) clearTimeout(intervalRef.current);

        // Remove multiple consecutive zeros and leading zeros except for the case where the value is '0.'
        // Remove leading zeros, except for single zero.
        value = value.replace(/^0+(?!\.|$)/, '');


        // Remove multiple consecutive periods and keep only the first one.
        value = value.replace(/(\.\d*)\./g, '$1');

        // Ensure there is only one period in the input.
        const periodsCount = value.split('.').length - 1;
        if (periodsCount > 1) {
            // If there are multiple periods, remove all but the first one.
            value = value.replace(/\./g, (match, offset) => offset ? '' : match);
        }

        // Update the amount based on the side of the transaction.
        const updateAmount = side === "SOURCE" ? setSourceAmount : setDestinationAmount;
        updateAmount(value);

        // Set a timeout to delay execution, reducing the frequency of expensive operations like API calls.
        intervalRef.current = setTimeout(() => {
            // Determine the last character of the input value, or clear the destination amount if the value is empty.
            const lastChar = value?.length !== 0 ? value?.slice(-1) : setDestinationAmount("");

            // Clear the source amount as part of the delayed operation.
            setSourceAmount("");

            // Check if the last character is numeric or a decimal point before proceeding with the exchange operation.
            if (IsNumericText(faNumToEnNum(lastChar)) || lastChar === ".") {
                // Update amounts and invoke the exchange operation based on the specified side.
                updateAmount(value);

                const exchangeParams = {
                    fromSource: side === "SOURCE" ? 1 : 2,
                    sourceMarket: sourceMarket?.symbol,
                    destinationMarket: destinationMarket?.symbol,
                    amount: deleteCommaSeparator(faNumToEnNum(value)),
                };

                // The isLoading state is toggled only for the destination side.
                if (side === "DESTINATION") setSourceFieldIsLoading(true);

                exchangePair(
                    setSourceFieldIsLoading,
                    setSourceAmount,
                    setDestinationAmount,
                    exchangeParams,
                );
            }

            // Clear the timeout reference after execution to prevent memory leaks.
            intervalRef.current = null;
        }, 500);
    }

    const onSourceMarketSelection = () => {
        if (sourceMarket?.symbol !== "IRT") {
            setSelectedMarket(Market.Source);
            setIsModalOpen(true);
        }
    };

    const onDestinationMarketSelection = () => {
        if (destinationMarket?.symbol !== "IRT") {
            setSelectedMarket(Market.Destination);
            setIsModalOpen(true);
        }
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchMarketValue(value);
        const filtered =
            value !== ""
                ? markets?.filter(
                    (market) =>
                        market?.name?.toLowerCase().includes(value.toLowerCase()) ||
                        market?.symbol?.toLowerCase().includes(value.toLowerCase()),
                )
                : markets;
        setMarketResults(filtered);
    };

    const onCurrencyPick = (currency: MarketType, setLoading: any) => {
        if (selectedMarket === Market.Source) {
            setSourceMarket(currency);
            exchangePair(setLoading, setSourceAmount, setDestinationAmount, {
                fromSource: side === Side.Buy ? 2 : 1,
                sourceMarket: currency?.symbol,
                destinationMarket: destinationMarket?.symbol,
                amount: 1,
            });
        } else if (selectedMarket === Market.Destination) {
            setDestinationMarket(currency);
            exchangePair(setLoading, setSourceAmount, setDestinationAmount, {
                fromSource: side === Side.Buy ? 2 : 1,
                sourceMarket: sourceMarket?.symbol,
                destinationMarket: currency?.symbol,
                amount: 1,
            });
        }
        setIsModalOpen(false);
    };

    async function fetchData(fromSource?: number, isResetValue?: boolean) {
        fromSource === 1 ? setSourceAmount("1") : setDestinationAmount("1")
        await exchangePair(
            setExchangeIsLoading,
            setSourceAmount,
            setDestinationAmount,
            {
                fromSource: fromSource || 2,
                sourceMarket: sourceMarket?.symbol,
                destinationMarket: destinationMarket?.symbol,
                amount: isResetValue ? 1 : destinationAmount,
            },
        );
        !markets && getMarkets(setMarketsIsLoading, setMarkets, setMarketResults);
    }

    const onSelectSide = (selectedSide: Side) => {
        const irt = markets?.find((e) => e?.symbol?.toLowerCase() === "irt");
        const sourceIsIRT = sourceMarket?.symbol === "IRT";
        const destinationIsIRT = destinationMarket?.symbol === "IRT";

        if (
            submitButtonIsLoading ||
            destinationFieldIsLoading ||
            sourceFieldIsLoading ||
            exchangeIsLoading ||
            marketsIsLoading
        )
            return;

        if (selectedSide === Side.Buy) {
            !sourceIsIRT && setDestinationMarket(sourceMarket);
            setSourceMarket(irt);
        } else if (selectedSide === Side.Sell) {
            !destinationIsIRT && setSourceMarket(destinationMarket);
            setDestinationMarket(irt);
        } else {
            marketResults &&
            setDestinationMarket(
                marketResults.find((market) => market.symbol === "TRX"),
            );
            marketResults &&
            setSourceMarket(marketResults.find((market) => market.symbol === "USDT"));
        }

        setSide(selectedSide);
    };

    const onTradeClick = async () => {
        await validatePair(
            setButtonIsLoading,
            {
                sourceMarket: sourceMarket.symbol,
                destinationMarket: destinationMarket.symbol,
                sourceAmount,
                destinationAmount,
            },
            (response: any) =>
                destinationMarket.symbol === "PM"
                    ? dispatch(
                        setOrderData({
                            ...state.orderData,
                            stage: OrderCardStages.RECEIVE_METHOD,
                            destinationMarket: destinationMarket,
                            sourceMarket: sourceMarket,
                            sourceAmount: deleteCommaSeparator(sourceAmount),
                            destinationAmount: deleteCommaSeparator(destinationAmount),
                            fee: response.data.fee,
                            side: side,
                            tour: false,
                        }),
                    )
                    : dispatch(
                        setOrderData({
                            ...state.orderData,
                            stage: OrderCardStages.ENTER_WALLET,
                            destinationMarket: destinationMarket,
                            sourceMarket: sourceMarket,
                            sourceAmount: deleteCommaSeparator(sourceAmount),
                            destinationAmount: deleteCommaSeparator(destinationAmount),
                            fee: response.data.fee,
                            side: side,
                            tour: false,
                        }),
                    ),
        );
    };

    const onCreateOrderClick = async () => {
        dispatch(
            setOrderData({
                stage: OrderCardStages.CALCULATOR,
                tour: false,
                trackingCode: null,
            }),
        );

        if (localStorage.getItem("accessToken")) {

            await validatePair(
                setButtonIsLoading,
                {
                    sourceMarket: sourceMarket.symbol,
                    destinationMarket: destinationMarket.symbol,
                    sourceAmount: deleteCommaSeparator(faNumToEnNum(sourceAmount)),
                    destinationAmount: deleteCommaSeparator(faNumToEnNum(destinationAmount)),
                }, () => {
                    if (side === Side.Buy) {
                        if (readProfile() ? readProfile().isAuthorized === false : localStorage.getItem(KEY_IS_AUTHORIZED).toString() === "false") {
                            dispatch(
                                setOrderData({
                                    ...state.orderData,
                                    stage: OrderCardStages.BASIC_INFO_FORM,
                                    destinationMarket: destinationMarket,
                                    sourceMarket: sourceMarket,
                                    sourceAmount: deleteCommaSeparator(faNumToEnNum(sourceAmount)),
                                    destinationAmount: deleteCommaSeparator(faNumToEnNum(destinationAmount)),
                                    side: side,
                                    tour: false,
                                    trackingCode: null,
                                }),
                            );
                        } else
                            dispatch(
                                setOrderData({
                                    ...state.orderData,
                                    stage: OrderCardStages.ENTER_CARD,
                                    destinationMarket: destinationMarket,
                                    sourceMarket: sourceMarket,
                                    sourceAmount: deleteCommaSeparator(faNumToEnNum(sourceAmount)),
                                    destinationAmount: deleteCommaSeparator(faNumToEnNum(destinationAmount)),
                                    side: side,
                                    tour: false,
                                    trackingCode: null,
                                }),
                            );
                    } else if (side === Side.Sell) {
                        dispatch(
                            setOrderData({
                                ...state.orderData,
                                stage: OrderCardStages.RECEIVE_METHOD,
                                destinationMarket: destinationMarket,
                                sourceMarket: sourceMarket,
                                sourceAmount: deleteCommaSeparator(faNumToEnNum(sourceAmount)),
                                destinationAmount: deleteCommaSeparator(faNumToEnNum(destinationAmount)),
                                side: side,
                                tour: false,
                                trackingCode: null,
                            }),
                        );

                    } else if (side === Side.Swap) {
                        destinationMarket.symbol === "PM"
                            ? dispatch(
                                setOrderData({
                                    ...state.orderData,
                                    stage: OrderCardStages.RECEIVE_METHOD,
                                    destinationMarket: destinationMarket,
                                    sourceMarket: sourceMarket,
                                    sourceAmount: deleteCommaSeparator(faNumToEnNum(sourceAmount)),
                                    destinationAmount: deleteCommaSeparator(faNumToEnNum(destinationAmount)),
                                    side: side,
                                    tour: false,
                                }),
                            )
                            : dispatch(
                                setOrderData({
                                    ...state.orderData,
                                    stage: OrderCardStages.ENTER_WALLET,
                                    destinationMarket: destinationMarket,
                                    sourceMarket: sourceMarket,
                                    sourceAmount: deleteCommaSeparator(faNumToEnNum(sourceAmount)),
                                    destinationAmount: deleteCommaSeparator(faNumToEnNum(destinationAmount)),
                                    side: side,
                                    tour: false,
                                }),
                            )
                    }
                });
        } else {
            dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.ENTER_PHONE,
                    destinationMarket: destinationMarket,
                    sourceMarket: sourceMarket,
                    sourceAmount: deleteCommaSeparator(faNumToEnNum(sourceAmount)),
                    destinationAmount: deleteCommaSeparator(faNumToEnNum(destinationAmount)),
                    tour: false,
                    trackingCode: null,
                }),
            );
        }
    };

    useEffect(() => {
        dispatch(setOrderData({...state.orderData, tour: false}));
    }, []);

    useEffect(() => {
        side == Side.Buy ? fetchData(2, true) : fetchData(1, true);
    }, [side]);

    useEffect(() => {
        handleDollarEquivalentCalculation()
    }, [sourceAmount, destinationAmount, sourceMarket, destinationMarket, rates])

    const handleDollarEquivalentCalculation = () => {
        if (side === Side.Buy && rates) {
            setDollarEquivalent(Number(deleteCommaSeparator(sourceAmount)) / rates?.find(rate => rate.symbol.toUpperCase() === "CRYPTO")?.sellPrice)
        }
    }

    const handleButtonClick = (amount: number) => {
        if (side === Side.Buy && rates) {
            if (destinationMarket.symbol.toUpperCase() !== "USDT" && destinationMarket.symbol.toUpperCase() !== "PM") {
                if (dollarEquivalent === 0) {
                    exchangeCalcHandler("1", "DESTINATION")
                }
                if (dollarEquivalent !== 0) {
                    const value = amount / dollarEquivalent
                    setDestinationAmount(value.toFixed(5).toString())
                    exchangeCalcHandler(value.toFixed(5).toString(), "DESTINATION")
                }
            }
            else {
                setDestinationAmount(amount.toString())
                exchangeCalcHandler(amount.toString(), "DESTINATION")
            }
        }
    };

    return (
        <div className={"pmx-calculator"}>
            <Modal
                className="pmx-currency-selection-modal"
                title="انتخاب رمز ارز"
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <div className="modal-content">
                    <AntdInput
                        placeholder="نام یا نماد ارز مورد نظر خود را وارد کنید"
                        prefix={<SearchIcon/>}
                        value={searchMarketValue}
                        onChange={onInputChange}
                        allowClear
                    />
                    <div className="popular-currencies">
                        {markets
                            ?.filter((market) => {
                                const isPMAndCustomer = market?.symbol === "PM" && isCustomer();
                                const isDesiredSymbol = market?.symbol === "TRX" || market?.symbol === "BTC";
                                const isNotSelectedMarket = selectedMarket === Market.Source
                                    ? market?.symbol !== destinationMarket?.symbol
                                    : market?.symbol !== sourceMarket?.symbol;
                                const isActiveSide = (side === Side.Sell && market.isBuyActive) ||
                                    (side === Side.Buy && market.isSellActive) ||
                                    (side === Side.Swap && market.isBuyActive);

                                return (isPMAndCustomer || isDesiredSymbol) && isNotSelectedMarket && isActiveSide;
                            })
                            .map((market) => (
                                <div
                                    key={market?.symbol}
                                    className="popular-currency"
                                    onClick={() =>
                                        onCurrencyPick(
                                            market,
                                            side === Side.Buy
                                                ? setDestinationFieldIsLoading
                                                : setSourceFieldIsLoading,
                                        )
                                    }
                                >
                                    <span className="en">{market?.symbol}</span>
                                    <img src={getIconPath(market?.symbol ?? "")}/>
                                </div>
                            ))}
                    </div>
                    <Divider/>
                    <div className="currencies">
                        {marketResults
                            ?.filter((market) => {
                                const isNotExcludedSymbol = market?.symbol !== "TRX" &&
                                    market?.symbol !== "BTC" &&

                                    market?.symbol !== "IRT";
                                const isNotSelectedMarket = market?.symbol !== destinationMarket?.symbol &&
                                    market?.symbol !== sourceMarket?.symbol;
                                const isActiveSide = (side === Side.Sell && market.isBuyActive) ||
                                    (side === Side.Buy && market.isSellActive) ||
                                    (side === Side.Swap && market.isSellActive);
                                const isPMAndCustomer = market?.symbol === "PM" && isCustomer();

                                return isNotExcludedSymbol && isNotSelectedMarket && isActiveSide && (market?.symbol !== "PM" || isPMAndCustomer);
                            })
                            .map((market) => (
                                <div
                                    key={market?.symbol}
                                    className="currency"
                                    onClick={() =>
                                        onCurrencyPick(
                                            market,
                                            side === Side.Sell
                                                ? setDestinationFieldIsLoading
                                                : setSourceFieldIsLoading,
                                        )
                                    }
                                >
                                    <div className="currency-info">
                                        <img src={getIconPath(market?.symbol ?? "")}/>
                                        <div className="col">
                                            <span>{market.name}</span>
                                            <span className="en">{market?.symbol}</span>
                                        </div>
                                    </div>
                                    {(selectedMarket === Market.Source &&
                                        market?.symbol === sourceMarket?.symbol) ||
                                    (selectedMarket === Market.Destination &&
                                        market?.symbol === destinationMarket?.symbol) ? (
                                        <ActiveIcon/>
                                    ) : null}
                                </div>
                            ))}
                    </div>
                </div>
            </Modal>
            <div className="side-selection" ref={tour1}>
                <div
                    className={`side ${side === Side.Buy ? "active" : ""}`}
                    onClick={() => onSelectSide(Side.Buy)}
                >
                    <BuyIcon/> خرید
                </div>
                <div
                    className={`side ${side === Side.Sell ? "active" : ""}`}
                    onClick={() => onSelectSide(Side.Sell)}
                >
                    <SellIcon/> فروش
                </div>
                <div
                    className={`side ${side === Side.Swap ? "active" : ""}`}
                    onClick={() => onSelectSide(Side.Swap)}
                >
                    <SwapIcon/> مبادله
                </div>
            </div>

            <div className="inputs">
                <Spin
                    spinning={
                        sourceFieldIsLoading || marketsIsLoading || exchangeIsLoading
                    }
                >
                    <div ref={tour2}>
                        <AntdInput
                            dir="ltr"
                            suffix={
                                <div>
                                    <span className="unit">واحد</span>
                                    <span className="type">پرداختی</span>
                                </div>
                            }
                            prefix={
                                <div
                                    ref={tour3}
                                    className={`currency-selection ${
                                        sourceMarket?.symbol === "IRT" ? "disabled" : ""
                                    }`}
                                    onClick={onSourceMarketSelection}
                                >
                                    <RightArrow/>
                                    <div className="col">
                                        <span className="en">{sourceMarket?.symbol}</span>
                                        <span>{sourceMarket?.name}</span>
                                    </div>
                                    <img src={getIconPath(sourceMarket?.symbol ?? "")}/>
                                </div>
                            }
                            value={
                                sourceMarket?.symbol === "IRT"
                                    ? digitSeparator(sourceAmount)
                                    : sourceAmount
                            }
                            onChange={(e) => exchangeCalcHandler(e.target.value, "SOURCE")}
                        />
                    </div>
                </Spin>
                <Spin
                    spinning={
                        destinationFieldIsLoading || marketsIsLoading || exchangeIsLoading
                    }
                >
                    <div ref={tour4}>
                        <AntdInput
                            dir="ltr"
                            suffix={
                                <div>
                                    <span className="unit">واحد</span>
                                    <span className="type">دریافتی</span>
                                </div>
                            }
                            infix={
                            side === Side.Buy &&
                                <>
                                    <button onClick={() => handleButtonClick(10)}>$10</button>
                                    <button onClick={() => handleButtonClick(5)}>$5</button>
                                    <button onClick={() => handleButtonClick(1)}>$1</button>
                                </>
                            }
                            prefix={
                                <div
                                    ref={tour5}
                                    className={`currency-selection ${
                                        destinationMarket?.symbol === "IRT" ? "disabled" : ""
                                    }`}
                                    onClick={onDestinationMarketSelection}
                                >
                                    <RightArrow/>
                                    <div className="col">
                                        <span className="en">{destinationMarket?.symbol}</span>
                                        <span>{destinationMarket?.name}</span>
                                    </div>
                                    <img src={getIconPath(destinationMarket?.symbol ?? "")}/>
                                </div>
                            }
                            value={
                                destinationMarket?.symbol === "IRT"
                                    ? digitSeparator(destinationAmount)
                                    : destinationAmount
                            }
                            onChange={(e) =>
                                exchangeCalcHandler(e.target.value, "DESTINATION")
                            }
                        />
                    </div>
                </Spin>
                {side === Side.Buy && destinationMarket.symbol.toUpperCase() !== "PM" && destinationMarket.symbol.toUpperCase() !== "USDT" &&
                    <div className={"dollar-equivalent"}>
                        <p>
                            <BlueIndicator/> معادل دلاری:
                        </p>
                        <p>
                            {sourceFieldIsLoading || destinationFieldIsLoading || ratesLoading ?
                                <LoadingOutlined/> :
                                <>
                                    {digitSeparator(dollarEquivalent?.toFixed(2))} دلار
                                </>
                            }
                        </p>
                    </div>
                }
                <div ref={tour6}>
                    <Button
                        type={buttonType.primary}
                        text={
                            side === 0
                                ? "خرید"
                                : side === 1
                                    ? "فروش"
                                    : side === 2
                                        ? "مبادله"
                                        : ""
                        }
                        onClick={() => onCreateOrderClick()}
                        loading={submitButtonIsLoading}
                        disabled={
                            submitButtonIsLoading ||
                            !sourceAmount ||
                            !destinationAmount ||
                            !sourceMarket ||
                            !destinationMarket ||
                            exchangeIsLoading
                        }
                    />
                </div>
            </div>
            <ConfigProvider locale={fa_IR} direction={"rtl"}>
                <Tour
                    open={state?.orderData?.tour}
                    onClose={() =>
                        dispatch(setOrderData({...state.orderData, tour: false}))
                    }
                    steps={tourSteps}
                />
            </ConfigProvider>
        </div>
    );
};

export default connector(Calculator);
