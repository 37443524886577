import "./landing.scss";
import OrderCard from "../order-card/order-card";
import Navbar from 'routes/pages/home/components/navbar/navbar';
import { ReactComponent as LTR_Arrow } from "assets/icons/home/ltr-arrow.svg";
import { ReactComponent as RTL_Arrow } from "assets/icons/home/rtl-arrow.svg";
import landingmask from 'assets/images/home/landing-mask.png'
import { ConnectedProps, connect, useDispatch } from "react-redux";
import { setOrderData } from "core/redux/actions";
import { FC } from "react";
import { RootState } from "core/redux/store";
import {Rate} from "../../../../../core/domain/rate/rate";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    rates: Rate[]
    ratesLoading: boolean
}

const HomeLanding: FC<Props> = ({ state, ratesLoading, rates }) => {
  const dispatch = useDispatch()

  return (
    <div className="pmx-landing-home">
      <Navbar />
      <OrderCard rates={rates} ratesLoading={ratesLoading}/>
      <img id='landing-mask' src={landingmask} alt="Mask" draggable={false} />
      <section className="instruction" onClick={() => dispatch(setOrderData({ ...state.orderData, tour: state.orderData.tour === false && true }))}>
        <RTL_Arrow />
        مشاهده آموزش تصویری
        <LTR_Arrow />
      </section>
    </div>
  );
};

export default connector(HomeLanding)