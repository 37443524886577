import { FC, useEffect, useState } from "react";
import { Input } from "antd";
import { Modal } from "../modal/modal";
import { ReactComponent as CarrotDown } from "assets/icons/general/carrot-down.svg";
import { Enum } from "../../core/domain";
import { PmxComponent } from "../../core/components/PmxComponenet";
import moment from "jalali-moment";
import { MONTHS } from "../../core/domain/commons";
import { ReactComponent as CalendarIcon } from "../../assets/icons/general/calendar.svg";
import "./date-picker.scss";
import { Button, buttonType } from "../button/button";
import { ReactComponent as Back } from "assets/icons/general/back.svg";
import { AntdInput } from "../antd-custom-input/antd-custom-input";

interface Props extends PmxComponent {
  onChange?: (value: string) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

enum Periods {
  YEAR,
  MONTH,
  DAY,
}

const min_year = parseInt(moment(moment.now()).format("jYYYY")) - 70;
const max_year = parseInt(moment(moment.now()).format("jYYYY"));

export const DatePicker: FC<Props> = (props) => {
  const [currentPeriod, setCurrentPeriod] = useState<Periods>(Periods.YEAR);

  var years: Enum[] = renderYears();
  var months: Enum[] = renderMonths();
  var days: Enum[] = renderDays();

  function renderMonths() {
    var list: Enum[] = [];
    for (let i = 0; i <= 11; i++) {
      let month = MONTHS[i];
      list.push({
        id: i + 1,
        title: month,
      });
    }
    return list;
  }

  function renderYears() {
    var list: Enum[] = [];

    for (let year = min_year; year <= max_year - 18; year++) {
      list.push({
        id: year,
        title: year.toString(),
      });
    }
    return list;
  }

  function renderDays() {
    var list: Enum[] = [];

    let monthDays = 31;

    for (let day = 1; day <= monthDays; day++) {
      list.push({ id: day, title: day.toString() });
    }
    return list;
  }

  const [year, setYear] = useState<number>(0);
  const [day, setDay] = useState<number>(0);
  const [month, setMonth] = useState<number>(0);

  useEffect(() => {
    renderMonths();
    renderYears();
    renderDays();
  }, []);

  useEffect(() => {
    props.onChange &&
      year !== 0 &&
      month !== 0 &&
      day !== 0 &&
      props.onChange(`${year}/${month}/${day}`);
  }, [day, month, year]);

  return (
    <Modal
      className={"pmx-datepicker"}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    >
      <AntdInput
        placeholder={"تاریخ"}
        suffix={<CalendarIcon />}
        value={`${year === 0 ? "0000" : year}/${month === 0 ? "00" : month}/${
          day === 0 ? "00" : day
        }`}
      />
      <div className={"options-row"}>
        <div className={"dropdowns"}>
          <div
            onClick={() => setCurrentPeriod(Periods.DAY)}
            className={"dropdown-selector"}
          >
            <span>{day === 0 ? "روز" : day}</span>
            <CarrotDown />
          </div>

          <div
            onClick={() => setCurrentPeriod(Periods.MONTH)}
            className={"dropdown-selector"}
          >
            <span>{month === 0 ? "ماه" : MONTHS[month - 1]}</span>
            <CarrotDown />
          </div>
          <div
            onClick={() => setCurrentPeriod(Periods.YEAR)}
            className={"dropdown-selector"}
          >
            <span>{year === 0 ? "سال" : year}</span>
            <CarrotDown />
          </div>
        </div>
      </div>
      {currentPeriod === Periods.MONTH ? (
        <div className={"month-list"}>
          {months.map((m) => (
            <span
              onClick={() => {
                setMonth(m.id);
                setCurrentPeriod(Periods.DAY);
              }}
              className={`month ${month === m.id ? "active" : ""}`}
            >
              {m.title}
            </span>
          ))}
        </div>
      ) : currentPeriod === Periods.YEAR ? (
        <div className={"year-list"}>
          {years.map((y) => (
            <span
              onClick={() => {
                setYear(y.id);
                setCurrentPeriod(Periods.MONTH);
              }}
              className={`year ${year === y.id ? "active" : ""}`}
            >
              {y.title}
            </span>
          ))}
        </div>
      ) : (
        <div className={"day-list"}>
          {days.map((d) => (
            <span
              className={`day ${day === d.id ? "active" : ""}`}
              onClick={() => {
                setDay(d.id);
              }}
            >
              {d.title}
            </span>
          ))}
        </div>
      )}
      <div className={"buttons"}>
        <Button
          type={buttonType.primary}
          text={"تایید تاریخ تولد"}
          disabled={
            year < min_year || year > max_year || day === 0 || month === 0
          }
          onClick={() => {
            props.onChange(
              `${year === 0 ? "0000" : year}/${month === 0 ? "00" : month}/${
                day === 0 ? "00" : day
              }`,
            );
            props.setIsOpen(false);
          }}
        />
        <Button
          icon={<Back />}
          type={buttonType.ordercard_outlined}
          text={"بازگشت"}
          onClick={() => props.setIsOpen(false)}
        />
      </div>
    </Modal>
  );
};
