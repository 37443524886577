import './licenses.scss';
import senfi from 'assets/images/about/senfi.png'
import fintech from 'assets/images/about/fintech.png'
import anjoman from 'assets/images/about/anjoman.png'
import { ReactComponent as SwiperRight } from 'assets/icons/home/swiper-right.svg'
import { ReactComponent as SwiperLeft } from 'assets/icons/home/swiper-left.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import 'swiper/scss';
import 'swiper/css/bundle';
import 'swiper/modules';
import { useRef } from 'react';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export const Licenses = () => {
    const swiperRef = useRef(null);

    const slideNext = () => {
        if (swiperRef?.current && swiperRef?.current?.swiper) {
            swiperRef?.current?.swiper?.slideNext();
        }
    };

    const slidePrev = () => {
        if (swiperRef?.current && swiperRef?.current?.swiper) {
            swiperRef?.current?.swiper?.slidePrev();
        }
    };


    return (
        <div className="pmx-licenses">
            <h3>مجوز ها</h3>
            <div className="licenses">
                <div className="license"><img src={senfi} /></div>
                <div className="license"><img src={fintech} /></div>
                <div className="license"><img src={anjoman} /></div>
            </div>
            <Swiper
                slidesPerView={2}
                // pagination={{ clickable: true }}
                ref={swiperRef}
            >
                <SwiperSlide>
                    <div className="license"><img src={senfi} /></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="license"><img src={fintech} /></div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="license"><img src={anjoman} /></div>
                </SwiperSlide>
            </Swiper>
            <div
                className="next-navigation"
                onClick={slideNext}
            >
                <SwiperLeft />
            </div>
            <div
                className="pre-navigation"
                onClick={slidePrev}
            >
                <SwiperRight />
            </div>
        </div>
    )
}