import { Button, buttonType } from "../../../../../../components";
import { RootState } from "../../../../../../core/redux/store";
import { connect, ConnectedProps } from "react-redux";
import { FC, ReactNode } from "react";
import { ReactComponent as Back } from "assets/icons/general/back.svg";
import "./order-card-buttons.scss";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SubmitButtonProps = {
  loading: boolean;
  disabled: boolean;
  text: string;
  onClick: () => void;
  icon?: ReactNode;
};

type CancelButtonProps = {
  text: string;
  onClick: () => void;
  noIcon?: boolean;
};

interface Props extends PropsFromRedux {
  submitButton: SubmitButtonProps;
  cancelButton: CancelButtonProps;
}

const OrderCardButtons: FC<Props> = ({ state, submitButton, cancelButton }) => {
  const stage = state.orderData.stage;
  return (
    <div className={"pmx-ordercard-buttons"}>
      <Button
        type={buttonType.primary}
        text={submitButton.text}
        loading={submitButton.loading}
        disabled={submitButton.disabled}
        onClick={submitButton.onClick}
      />
      <Button
        icon={!cancelButton.noIcon ? <Back /> : null}
        type={buttonType.ordercard_outlined}
        text={cancelButton.text}
        onClick={cancelButton.onClick}
      />
    </div>
  );
};
export default connector(OrderCardButtons);
