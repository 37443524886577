import './remove-iban-modal.scss'
import {Dispatch, FC, SetStateAction, useState} from "react";
import {Button, buttonType, Modal} from "../../../../../../../../components";
import {UserIBanResponse} from "../../../../../../../../core/domain/user-saved-ibans/user-saved-ibans";
import {removeIBan} from "../../../../../../../../core/repositores/user-saved-ibans";

interface Props {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    item: UserIBanResponse
    onComplete: () => void
}

export const RemoveIBanModal: FC<Props> = ({isOpen, setIsOpen, item, onComplete}) => {
    const [loading, setLoading] = useState<boolean>(false)

    const handleRemove = () => {
        removeIBan(setLoading, {id: item.id}, onComplete)
    }

    return (
        <Modal className={"pmx-remove-iban-modal"} isOpen={isOpen} setIsOpen={setIsOpen} noHead>
            <h3>آیا از حذف شماره شبا اطمینان دارید؟</h3>
            <p>در صورت حذف شماره شبا، شماره شبای شما حذف میشود اما امکان اضافه ی مجدد آن را دارید.</p>
            <div>
                <span>
                    {item?.label}
                </span>
                <span>
                    {item?.iBan}
                </span>
            </div>
            <section>
                <Button type={buttonType.error} text={"بله، حذف شود"} onClick={handleRemove} loading={loading}/>
                <Button type={buttonType.ordercard_outlined} text={"خیر، منصرف شدم"} onClick={() => setIsOpen(false)}/>
            </section>
        </Modal>
    )
}