import {FC, useEffect, useRef, useState} from "react";
import {ConfigProvider, Tour, TourProps} from "antd";
import "./basic-info-form.scss";
import {ReactComponent as CalendarIcon} from "assets/icons/general/calendar.svg";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData,} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {RootState} from "../../../../../../../core/redux/store";
import {DatePicker} from "../../../../../../../components/date-picker/date-picker";
import {IsPersianChar} from "../../../../../../../core/utils/stringExtentions";
import {faNumToEnNum, IsNumeric,} from "../../../../../../../core/utils/decimalExtentions";
import {authenticateUser} from "../../../../../../../core/repositores/authentication";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import successBadge from "../../../../../../../assets/icons/home/success-badge.png";
import errorBadge from "../../../../../../../assets/icons/home/auth-error-badge.png";
import {ReactComponent as LeftArrow} from "assets/icons/home/left-arrow-white.svg";
import fa_IR from "antd/lib/locale/fa_IR";
import moment from "jalali-moment";
import {getUserProfile} from "../../../../../../../core/repositores/profile";
import {KEY_IS_AUTHORIZED} from "../../../../../../../core/constants/localStorageConstants";

enum Steps {
    Form,
    SuccessResult,
    ErrorResult,
}

type InputType = {
    firstName?: string;
    lastName?: string;
    nationalCode?: string;
    emailAddress?: string;
    birthDate?: string;
    address?: string;
    postalCode?: string;
};

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

const BasicInfoForm: FC<Props> = ({state}) => {
    const {orderData} = state;
    const [formData, setFormData] = useState<InputType>();
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);
    const [step, setStep] = useState<Steps>(Steps.Form);

    const tour1 = useRef(null);
    const tour2 = useRef(null);
    const tour3 = useRef(null);
    const tour4 = useRef(null);
    const tour5 = useRef(null);
    const tour6 = useRef(null);
    const tour7 = useRef(null);
    const tour8 = useRef(null);

    const tourSteps: TourProps["steps"] = [
        {
            title: "نام",
            description: "نام خود را به فارسی در این بخش وارد نمایید.",
            target: () => tour1.current,
        },
        {
            title: "نام خانوادگی",
            description: "نام خانوادگی خود را به فارسی در این بخش وارد نمایید.",
            target: () => tour2.current,
        },
        {
            title: "کد ملی",
            description: "کد ملی 10 رقمی خود را در این بخش وارد نمایید.",
            target: () => tour3.current,
        },
        {
            title: "ایمیل",
            description: "ایمیل معتبر خود را در این بخش وارد نمایید.",
            target: () => tour4.current,
        },
        {
            title: "تاریخ تولد",
            description: "تاریخ تولد خود را از این بخش انتخاب نمایید.",
            target: () => tour5.current,
        },
        {
            title: "تایید احراز هویت",
            description:
                "در صورتی که فرم را کامل کرده اید، از این بخش میتوانید اقدام به تایید احراز هویت یا بازگشت نمایید.",
            target: () => tour6.current,
        },
    ];

    const dispatch = useDispatch();
    const onBackClick = () =>
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.CALCULATOR,
                phone: null,
                tour: false,
            }),
        );

    const onBackToAuth = () => setStep(Steps.Form);

    async function onAuthenticateHandle() {
        const body = {
            ...formData,
            birthDate: moment(formData.birthDate, "jYYYY/jMM/jDD")
                .locale("gb")
                .format("YYYY/MM/DD"),
        };

        await authenticateUser(
            setButtonIsLoading,
            body,
            async () => {
                localStorage.setItem(KEY_IS_AUTHORIZED, "true");
                await getUserProfile(setButtonIsLoading);
                setStep(Steps.SuccessResult)
            },
        );
        setOrderData({...state.orderData, tour: false});
    }

    const onNumberChange = (value: string, field: string) => {
        if (value !== "") value = faNumToEnNum(value);

        if (value !== "" && !IsNumeric(value.split("")[value.split("").length - 1]))
            return;

        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const onEmailChange = (value: string) => {
        if (
            value !== "" &&
            IsPersianChar(value.split("")[value.split("").length - 1])
        )
            return;

        setFormData({
            ...formData,
            emailAddress: value,
        });
    };

    const onPersianFieldChange = (value: string, field: string) => {
        if (
            value !== "" &&
            !IsPersianChar(value.split("")[value.split("").length - 1])
        )
            return;

        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const onBackStepClick = () =>
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.CALCULATOR,
                phone: null,
                tour: false,
            }),
        );

    const onContinueClick = () =>
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.CALCULATOR,
                tour: false,
            }),
        );

    useEffect(() => {
        if (calendarIsOpen) document.documentElement.classList.add("modal-open")
        else document.documentElement.classList.remove("modal-open")
    }, [calendarIsOpen]);

    return (
        <>
            {step === Steps.Form ? (
                <div className={"pmx-basic-info-form"}>
                    <DatePicker
                        isOpen={calendarIsOpen}
                        setIsOpen={setCalendarIsOpen}
                        onChange={(date) => setFormData({...formData, birthDate: date})}
                    />
                    <h3>ثبت اطلاعات</h3>
                    <p className={"description"}>اطلاعات هویتی خود را تکمیل نمایید.</p>
                    <div className={"row"}>
                        <div ref={tour1} style={{width: "100%"}}>
                            <AntdInput
                                placeholder={"نام"}
                                value={formData?.firstName}
                                onChange={(e) =>
                                    onPersianFieldChange(e.target.value, "firstName")
                                }
                            />
                        </div>
                        <div ref={tour2} style={{width: "100%"}}>
                            <AntdInput
                                placeholder={"نام خانوادگی"}
                                value={formData?.lastName}
                                onChange={(e) =>
                                    onPersianFieldChange(e.target.value, "lastName")
                                }
                            />
                        </div>
                    </div>
                    <div ref={tour3}>
                        <AntdInput
                            placeholder={"کد ملی"}
                            value={formData?.nationalCode}
                            onChange={(e) => onNumberChange(e.target.value, "nationalCode")}
                            maxLength={10}
                        />
                    </div>
                    <div ref={tour4}>
                        <AntdInput
                            id="email"
                            placeholder={"ایمیل"}
                            value={formData?.emailAddress}
                            onChange={(e) => onEmailChange(e.target.value)}
                        />
                    </div>
                    <div ref={tour5}>
                        <AntdInput
                            placeholder={"تاریخ تولد"}
                            value={formData?.birthDate}
                            onClick={() => setCalendarIsOpen(true)}
                            suffix={<CalendarIcon/>}
                        />
                    </div>
                    <div ref={tour7}>
                        <AntdInput
                            placeholder={"کد پستی"}
                            value={formData?.postalCode}
                            onChange={(e) => onNumberChange(e.target.value, "postalCode")}
                            maxLength={10}
                        />
                    </div>
                    <div ref={tour8}>
                        <AntdInput
                            placeholder={"آدرس محل سکونت"}
                            value={formData?.address}
                            onChange={(e) =>
                                setFormData({...formData, address: e.target.value})
                            }
                        />
                    </div>
                    <div ref={tour6}>
                        <OrderCardButtons
                            submitButton={{
                                text: "تایید احراز هویت",
                                loading: buttonIsLoading,
                                disabled:
                                    buttonIsLoading ||
                                    !formData?.lastName ||
                                    !formData?.firstName ||
                                    !formData?.emailAddress ||
                                    !formData?.nationalCode ||
                                    formData?.birthDate === "0000/00/00" ||
                                    !formData?.birthDate,
                                onClick: onAuthenticateHandle,
                            }}
                            cancelButton={{
                                text: "بازگشت",
                                onClick: onBackClick,
                            }}
                        />
                    </div>
                    <ConfigProvider locale={fa_IR} direction={"rtl"}>
                        <Tour
                            open={state?.orderData?.tour}
                            onClose={() =>
                                dispatch(setOrderData({...state.orderData, tour: false}))
                            }
                            steps={tourSteps}
                        />
                    </ConfigProvider>
                </div>
            ) : (
                <div
                    className={`pmx-auth-result-stage ${
                        step === Steps.SuccessResult ? "" : "error"
                    }`}
                >
                    {step === Steps.SuccessResult ? (
                        <>
                            <img src={successBadge} alt={"success"}/>
                            <h3>تبریک، احراز هویت شما با موفقیت انجام شد!</h3>
                            <p className={"description"}>
                                احراز هویت شما با موفقیت به اتمام رسید و سطح شما به سطح برنزی
                                ارتقا یافت، از این پس میتوانید به سادگی و با اطمینان خاطر
                                سفارشات خود را تا سقف سطح کاربری خود ثبت و انجام دهید
                            </p>
                            <OrderCardButtons
                                submitButton={{
                                    text: "تایید و ادامه",
                                    onClick: () => onContinueClick(),
                                    loading: false,
                                    disabled: false,
                                }}
                                cancelButton={{
                                    text: "بستن",
                                    onClick: () => onBackClick(),
                                }}
                            />
                        </>
                    ) : step === Steps.ErrorResult ? (
                        <>
                            <img src={errorBadge} alt={"error"}/>
                            <h3>خطا، احراز هویت شما به مشکل خورد!</h3>
                            <p className={"description"}>
                                احراز هویت شما با موفقیت به اتمام رسید و سطح شما به سطح برنزی
                                ارتقا یافت، از این پس میتوانید به سادگی و با اطمینان خاطر
                                سفارشات خود را تا سقف سطح کاربری خود ثبت و انجام دهید
                            </p>

                            <OrderCardButtons
                                submitButton={{
                                    text: "بازگشت به احراز هویت",
                                    onClick: () => onBackToAuth(),
                                    icon: <LeftArrow/>,
                                    loading: false,
                                    disabled: false,
                                }}
                                cancelButton={{
                                    text: "بستن",
                                    onClick: () => onBackClick(),
                                    noIcon: true,
                                }}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </>
    );
};

export default connector(BasicInfoForm);
