import * as React from "react";
import "./recent-transactions.scss";
import { Card } from "components";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "assets/icons/dashboard/Arrow_Right_MD.svg";
import { Empty, Spin } from "antd";
import { Order } from "../../../../../core/domain";
import { OrderItem } from "../../../../../components/order-item/order-item";
import { OrderDetailsModal } from "../../../../../components/order-details-modal/order-details-modal";
import { OrderItemSkeleton } from "../../../../../components/order-item/order-item-skeleton/order-item-skeleton";

interface Props {
  isLoading: boolean;
  transactions: Order[];
}

export const DashboardRecentTransactions: React.FC<Props> = ({
  isLoading,
  transactions,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    React.useState<Order | null>(null);

  const openModalHandle = () => setModalIsOpen(true);
  const closeModalHandle = () => setModalIsOpen(false);

  const openDetailsModal = (transaction: Order) => {
    setSelectedTransaction(transaction);
    setModalIsOpen(true);
  };

  return (
    <div className="recent-transactions-container">
      <OrderDetailsModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        order={selectedTransaction}
      />
      <Card className="dashboard-recent-transactions">
        <div className="head-row">
          <h3>تراکنش های اخیر</h3>
          <Link to={"/orders"}>
            مشاهده همه <ArrowLeft />
          </Link>
        </div>
        <div className="divider"></div>
        <div className="transactions">
          {isLoading ? (
            [...Array(6)].map((_, index) => <OrderItemSkeleton key={index} />)
          ) : transactions && transactions.length === 0 ? (
            <Empty
              description="تراکنشی یافت نشد."
              className="empty-box"
            />
          ) : (
            transactions
              ?.slice(0, 6)
              .map((transaction) => (
                <OrderItem
                  key={transaction.orderId}
                  orderItem={transaction}
                  onClickDetails={() => openDetailsModal(transaction)}
                />
              ))
          )}
        </div>
      </Card>
    </div>
  );
};
