import { Footer } from '../home/components/footer/footer';
import { FAQLanding } from './components/landing/landing';
import { FAQMain } from './components/main/main';
import './faq.scss';

export const FAQ = () => {
    return (
        <div className="pmx-faq">
            <FAQLanding />
            <FAQMain />
            <Footer />
        </div>
    )
}