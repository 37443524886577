import {FC, useEffect, useRef, useState} from "react";
import {ConfigProvider, Tour, TourProps} from "antd";
import "./login.scss";
import {Button, buttonType} from "../../../../../../../components";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData,} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {sendCode, verifyCode,} from "../../../../../../../core/repositores/login";
import {RootState} from "../../../../../../../core/redux/store";
import {ReactComponent as InfoCircle} from "assets/icons/general/info-error.svg";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import fa_IR from "antd/lib/locale/fa_IR";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import {setLocalStorageItems} from "../../../../../../../core/utils/setLocalStorageItems";
import {getUserProfile} from "../../../../../../../core/repositores/profile";

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

const LoginStage: FC<Props> = ({state}) => {

    const {orderData} = state;
    const [password, setPassword] = useState("");
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [sendCodeButtonIsLoading, setSendCodeButtonIsLoading] = useState(false);
    const [forgetPasswordLoading, setForgetPasswordLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>();

    const tour1 = useRef(null);
    const tour2 = useRef(null);
    const tour3 = useRef(null);
    const tour4 = useRef(null);

    const tourSteps: TourProps["steps"] = [
        {
            title: "رمز عبور",
            description: "رمز عبور حساب کاربری خود را وارد نمایید.",
            target: () => tour1.current,
        },
        {
            title: "ورود با کد یکبار مصرف",
            description:
                "شما نیز میتوانید از طریق کد یکبار مصرف که به شماره همراهتان ارسال میشود وارد شوید.",
            target: () => tour2.current,
        },
        {
            title: "فراموشی / تنظیم رمز",
            description:
                "در صورت فراموشی یا تصمیم به تنظیم رمز، میتوانید از این دکمه استفاده کنید.",
            target: () => tour3.current,
        },
        {
            title: "ورود",
            description:
                "در صورتی که رمز عبور خود را وارد کرده اید، از این بخش میتوانید اقدام به ورود نمایید..",
            target: () => tour4.current,
        },
    ];
    useEffect(() => {

        window.history.pushState(
            window.history.state,
            document.title,
            "/enter-password"
        );
    })
    const dispatch = useDispatch();
    const onBackClick = () =>
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.ENTER_PHONE,
                tour: false,
                phone: null,
            }),
        );

    const onGoToTwoFaHandle = () =>
        dispatch(
            setOrderData({
                ...orderData,
                stage: OrderCardStages.TWO_FA,
                tour: false,
                forgetPassword: false,
            }),
        );

    async function onLoginHandle() {
        const body = {
            password,
            phoneNumber: orderData?.phone,
        };

        await verifyCode(
            setButtonIsLoading,
            body,
            (data) => setLocalStorageItems(data),
            async () => {
                (window as any).dataLayer = (window as any).dataLayer || [];

                (window as any).dataLayer.push({
                    event: 'login',
                });
                await getUserProfile(setButtonIsLoading)
                dispatch(
                    setOrderData({
                        ...state.orderData,
                        stage: OrderCardStages.CALCULATOR,
                        tour: false,
                    }),
                )
            },
            () => dispatch(setOrderData({...state.orderData, stage: OrderCardStages.GOOGLE_AUTHENTICATION})),
            (response) => setError(response.message),
        );
    }

    const onSendCode = async () => {
        const body = {
            phoneNumber: orderData?.phone,
            forPasswordCheck: false,
        };

        setError(undefined);

        await sendCode(setSendCodeButtonIsLoading, body, (response) =>
            onGoToTwoFaHandle(),
        );
    };

    const onForgetPasswordHandle = async () => {
        const body = {
            phoneNumber: orderData?.phone,
            forPasswordCheck: false,
        };

        setError(undefined);

        await sendCode(setForgetPasswordLoading, body, (response) => dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.TWO_FA,
                forgetPassword: true,
                tour: false,
            }),
        ))
    }

    return (
        <div className={"pmx-login-stage"}>
            <h3>ورود</h3>
            <p className={"description"}>جهت ورود رمز عبور خود را وارد نمایید.</p>
            <div ref={tour1}>
                <AntdInput
                    placeholder={"رمز عبور خود را وارد کنید"}
                    className={`${error?.length ? "error" : ""}`}
                    value={password}
                    type={"password"}
                    onChange={(e) => setPassword(e.target.value)}
                    onPressEnter={() => onLoginHandle()}
                />
            </div>
            {error?.length && (
                <div className={"error-row"}>
                    <InfoCircle/>
                    <span>{error}</span>
                </div>
            )}
            <div ref={tour2} style={{position: "relative"}}>
                <Button
                    onClick={onSendCode}
                    type={buttonType.ordercard_outlined}
                    text={"ورود با کد یکبار مصرف"}
                    loading={sendCodeButtonIsLoading}
                    disabled={sendCodeButtonIsLoading}
                />
            </div>

            <div ref={tour3} style={{position: "relative"}}>
                <Button
                    onClick={onForgetPasswordHandle}
                    type={buttonType.ordercard_outlined}
                    text={"فراموشی / تنظیم رمز"}
                    loading={forgetPasswordLoading}
                    disabled={forgetPasswordLoading}
                />
            </div>

            <div ref={tour4}>
                <OrderCardButtons
                    submitButton={{
                        text: "ورود",
                        loading: buttonIsLoading,
                        disabled: buttonIsLoading || !password,
                        onClick: () => onLoginHandle(),
                    }}
                    cancelButton={{
                        text: "بازگشت",
                        onClick: () => onBackClick(),
                    }}
                />
            </div>

            <ConfigProvider locale={fa_IR} direction={"rtl"}>
                <Tour
                    steps={tourSteps}
                    open={state?.orderData?.tour}
                    onClose={() =>
                        dispatch(setOrderData({...state.orderData, tour: false}))
                    }
                />
            </ConfigProvider>
        </div>
    );
};

export default connector(LoginStage);
