import * as React from "react";
import "./input.scss";
import { PmxComponent } from "core/components/PmxComponenet";
import { formatCardNumber } from "core/utils/bankDetecter";
import { ReactComponent as EyeOpen } from "../../assets/icons/input/Eye_Open.svg";
import { ReactComponent as EyeHidden } from "../../assets/icons/input/Eye_Hide.svg";
import { buttonType } from "../button/button";

export enum inputType {
  "text",
  "cardNumber",
  "password",
}

interface Props extends PmxComponent {
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  icon?: React.ReactNode;
  adornment?: React.ReactNode;
  placeholder: string;
  htmlType?: any;
  maxLength?: number;
  type?: inputType;
  onPressEnter?: () => void;
  dir?: "ltr" | "rtl";
  required?: boolean;
  label?: string;
  name?: string;
}

export const Input: React.FC<Props> = (props) => {
  const isPasswordType = props.type === inputType.password;
  const isCardNumberType = props.type === inputType.cardNumber;

  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={`pmx-input ${props.className ?? ""}`}>
      {props.label && (
        <label htmlFor={props.className || ""}>{props.label}</label>
      )}
      <input
        type={
          isPasswordType
            ? passwordVisible
              ? "text"
              : "password"
            : props.htmlType ?? "text"
        }
        value={
          isCardNumberType ? formatCardNumber(props.value || "") : props.value
        }
        onKeyUp={(event) =>
          event.key === "Enter" &&
          props.onPressEnter &&
          !props.disabled &&
          !props.disabled &&
          props.onPressEnter()
        }
        onChange={props.onChange}
        maxLength={props.maxLength}
        required={props.required}
        placeholder={props.placeholder || ""}
        dir={props.dir ?? "rtl"}
      />
      {(isPasswordType || props.icon) && !isCardNumberType ? (
        <div className="adornments">
          {isPasswordType && (
            <span
              className="password-toggle"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <EyeOpen /> : <EyeHidden />}
            </span>
          )}
        </div>
      ) : null}
      {props.type ? (
        props?.type == inputType.cardNumber && props.icon ? (
          <div className="adornments right">{props.icon}</div>
        ) : (
          <></>
        )
      ) : props.icon ? (
        <div className="adornments">{props.icon}</div>
      ) : (
        <></>
      )}
      {props?.adornment ? (
        <div className="adornments">{props.adornment}</div>
      ) : (
        <></>
      )}

      {}
    </div>
  );
};
