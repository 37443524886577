import {Dispatch, SetStateAction} from "react";
import {
    AddUserWalletBody,
    EditUserWalletBody,
    RemoveUserWalletBody
} from "../domain/user-saved-wallets/user-saved-wallets";
import {getViaAuth, postViaAuth, put, responseValidator} from "../apis/api";
import {API} from "../apis";

export const addWallet = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: AddUserWalletBody,
    onComplete: () => void
) => {
    setLoading(true)
    await postViaAuth(API.userSavedWallets.add, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const getMyWallets = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: any,
    onComplete: (e: any) => void
)=> {
    setLoading(true)
    await getViaAuth(API.userSavedWallets.getMine).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setData(response.data)
            onComplete(response.data)
        }
    })
}

export const removeWallet = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: RemoveUserWalletBody,
    onComplete: () => void
)=> {
    setLoading(true)
    await postViaAuth(API.userSavedWallets.remove, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const editWallet = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: EditUserWalletBody,
    onComplete: () => void
) => {
    setLoading(true)
    await put(API.userSavedWallets.edit, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}
