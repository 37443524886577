import './referral-info-skeleton.scss'

export const ReferralInfoSkeleton = () => {
    return (
        <div className='referral-info-skeleton'>
            <div className='referral-card-skeleton'>
                <div className="title-skeleton" />
                <div className="stats-skeleton">
                    <div className="row-skeleton">
                        <span />
                        <p />
                    </div>
                    <div className="row-skeleton">
                        <span />
                        <p />
                    </div>
                    <div className="row-skeleton">
                        <span />
                        <p />
                    </div>
                    <div className="row-skeleton">
                        <span />
                        <p />
                    </div>
                    
                    <div className="invite-code-skeleton">
                    <span />
                    <div />
                </div>
                </div>
                
            <div
                className="payout-button-skeleton"
            />
            </div>
        </div>
    )
}