import './telegram-bot.scss'
import { ReactComponent as GreenTick } from 'assets/icons/home/green-tick.svg'
import pmxbot from 'assets/images/home/pmx-bot.png'
import { ReactComponent as SecurityIcon } from 'assets/icons/home/security.svg'
import { ReactComponent as ChatIcon } from 'assets/icons/home/chat.svg'
import { ReactComponent as HeartIcon } from 'assets/icons/home/heart.svg'
import { ReactComponent as GoldenStarIcon } from 'assets/icons/home/golden-star.svg'
import { ReactComponent as YellowCircleIcon } from 'assets/icons/home/yellow-circle.svg'
import { ReactComponent as BlueStarIcon } from 'assets/icons/home/blue-star.svg'
import { ReactComponent as BlueCircleIcon } from 'assets/icons/home/blue-circle.svg'
import { ReactComponent as SwapIcon } from 'assets/icons/home/swap-green.svg'
import { ReactComponent as AnalyticsIcon } from 'assets/icons/home/analytics.svg'
import { isDesktop, isMobile } from 'react-device-detect'

interface Props {

}

export const TelegramBot = (props: Props) => {
    return (
        <div className="pmx-telegram-bot">
            <div className="description">
                <h3>ربات هوشمند تلگرام پی ام ایکسچنج</h3>
                <p>به کمک ربات پی ام اکسچنج با سهولت و سرعت بیشتری معاملات خود را انجام دهید.</p>
                <ul>
                    {isDesktop &&
                        <>
                            <li><GreenTick />استفاده از ووچر برای ایرانی‌ها برخلاف دیگر حساب‌های ارزی بین‌المللی تحریم نیست.</li>
                            <li><GreenTick />استفاده از ووچر و انتقال پول خیلی ساده‌ و راحته.</li>
                            <li><GreenTick />انتقال ارز از طریق ووچر با سرعت خیلی زیادی انجام می‌شه.</li>
                            <li><GreenTick />ووچرها تاریخ انقضا ندارن و امکان استفاده لحظه‌ای از اون‌ها در هر مکانی وجود داره.</li>
                        </>
                    }
                    {isMobile &&
                        <>
                            <li><GreenTick />رابط کاربری سریع و آسان</li>
                            <li><GreenTick />خرید و فروش ارز های دیجیتال</li>
                            <li><GreenTick />قیمت لحظه ای رمز ارز ها</li>
                            <li><GreenTick />آموزش ورود به بازار ارز دیجیتال</li>
                        </>
                    }
                </ul>
                <a href='https://t.me/PMxchange_bot' target='_blank'>اتصال به ربات هوشمند</a>
                <BlueStarIcon className='blue-star-icon' />
                <BlueCircleIcon className='blue-circle-icon' />
                <YellowCircleIcon className='yellow-circle-icon' />
            </div>
            {isDesktop &&
                <div className="banner-bg">
                    <div className="banner">
                        <img src={pmxbot} id='robot' />
                        <div className="security-icon"><SecurityIcon /></div>
                        <div className="chat-icon"><ChatIcon /></div>
                        <div className="heart-icon"><HeartIcon /></div>
                        {isMobile && <div className="analytics-icon"><AnalyticsIcon /></div>}
                        <GoldenStarIcon className='golden-star-icon' />
                        <a><SwapIcon /> خرید و فروش</a>
                        <a><AnalyticsIcon /> استعلام قیمت</a>
                    </div>
                </div>
            }
            {isMobile &&
                <div className="banner">
                    <img src={pmxbot} id='robot' />
                    <div className="security-icon"><SecurityIcon /></div>
                    <div className="chat-icon"><ChatIcon /></div>
                    <div className="heart-icon"><HeartIcon /></div>
                    {isMobile && <div className="analytics-icon"><AnalyticsIcon /></div>}
                    <GoldenStarIcon className='golden-star-icon' />
                    <a><SwapIcon /> خرید و فروش</a>
                    <a><AnalyticsIcon /> استعلام قیمت</a>
                </div>
            }
        </div>
    )
}