import * as React from "react";

import "./invite-box.scss";
import {Button, buttonType} from "components";
import {ReactComponent as Copy} from "assets/icons/dashboard/copy.svg";
import {copyToClipboard} from "core/utils/copyToClipboard";
import {ReferralInfo} from "core/domain";
import {InviteBoxSkeleton} from "./invite-box-skeleton/invite-box-skeleton";
import {Divider} from "antd";
import {digitSeparator} from "../../../../../core/utils/decimalExtentions";

interface Props {
  isLoading: boolean;
  referralInfo: ReferralInfo;
}

export const InviteBox: React.FC<Props> = ({ isLoading, referralInfo }) => {
  return (
    <>
      {isLoading ? (
        <InviteBoxSkeleton />
      ) : (
        <div className="invite-box">
          <span className="title">دعوت دوستان</span>
          <div className="list">
            <div className="row">
              <p>پاداش معرفی دوستان</p>
              <span className="value">۳۰ ٪</span>
            </div>
            <Divider />
            <div className="row">
              <p>درآمد شما</p>
              <span className="value">
                {digitSeparator(referralInfo?.balance)} <span>تومان</span>
              </span>
            </div>
          </div>
          <Button
            onClick={() =>
              copyToClipboard(
                referralInfo?.referralCode?.toUpperCase(),
                "کد دعوت شما",
              )
            }
            text={referralInfo?.referralCode}
            type={buttonType.outlined}
            icon={<Copy />}
          />
        </div>
      )}
    </>
  );
};
