import {FC, useEffect, useRef, useState} from "react";
import {ConfigProvider, Tour, TourProps} from "antd";
import "./set-password.scss";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData, setUserData,} from "../../../../../../../core/redux/actions";
import {OrderCardStages} from "../../../../../../../core/domain/landing/landing";
import {setPasswordPost,} from "../../../../../../../core/repositores/login";
import {RootState} from "../../../../../../../core/redux/store";
import {ReactComponent as InfoCircle} from "assets/icons/general/info-error.svg";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import fa_IR from "antd/lib/locale/fa_IR";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";

type InputType = {
  password?: string;
  repeatPassword?: string;
};

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

const SetPasswordStage: FC<Props> = ({ state }) => {
  const { orderData } = state;
  const [input, setInput] = useState<InputType>();
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const tour1 = useRef(null)
  const tour2 = useRef(null)
  const tour3 = useRef(null)

  const tourSteps: TourProps['steps'] = [
    {
      title: 'وارد کردن رمز عبور',
      description: 'جهت تعیین رمز عبور، میبایست در این بخش رمز عبور خود را وارد نمایید.',
      target: () => tour1.current,
    },
    {
      title: 'تکرار رمز عبور',
      description: 'جهت اطمینان از رمز عبور وارد شده، در این بخش آن را تکرار نمایید.',
      target: () => tour2.current,
    },
    {
      title: 'تنظیم رمز عبور',
      description: 'در صورتی که رمز عبور را وارد و تکرار کرده اید، جهت تنظیم رمز عبور یا بازگشت از این بخش اقدام نمایید.',
      target: () => tour3.current,
    }
  ]

  const dispatch = useDispatch();
    useEffect(() => {

        window.history.pushState(
            window.history.state,
            document.title,
            "/set-password"
        );
    })
  const onBackClick = () =>
    dispatch(
      setOrderData({
        ...state.orderData,
        stage: OrderCardStages.ENTER_PHONE,
        phone: null,
        tour: false,
          forgetPassword: false,
      }),
    );

  async function onSetPasswordHandle() {
    if (!input.password.match(input.repeatPassword))
      return setError(
        "رمز عبور های وارد شده با هم مطابقت ندارد، لطفا مجددا رمز عبور خود را تنظیم نمایید.",
      );

    const body = {
      password: input.password,
    };

    await setPasswordPost(
      setButtonIsLoading,
      body,
      (data) => dispatch(setUserData(data)),
        () => {
            (window as any).dataLayer = (window as any).dataLayer || [];

            (window as any).dataLayer.push({
                event: 'sign_up'
            })
            dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.CALCULATOR,
                    tour: false,
                    forgetPassword: false,
                })
                ,
            )
        },
      () => null,
      (response) => setError(response.message),
    );
  }

  return (
    <div className={"pmx-set-password-stage"}>
      <h3>تنظیم رمز عبور</h3>
      <p className={"description"}>
        رمز عبور خود را تنظیم کنید، با استفاده از اعداد و علامت و حروف انگلیسی
        امنیت خود را بالا ببرید.
      </p>
      <div ref={tour1}>
        <AntdInput
          placeholder={"رمز عبور"}
          className={`${error?.length ? "error" : ""}`}
          value={input?.password}
          type={"password"}
          onChange={(e) => setInput({ ...input, password: e.target.value })}
        />
      </div>
      <div ref={tour2}>
        <AntdInput
          placeholder={"تکرار رمز عبور"}
          className={`${error?.length ? "error" : ""}`}
          value={input?.repeatPassword}
          type={"password"}
          onChange={(e) => setInput({ ...input, repeatPassword: e.target.value })}
          onPressEnter={() => onSetPasswordHandle()}
        />
      </div>
      {error?.length && (
        <div className={"error-row"}>
          <InfoCircle />
          <span>{error}</span>
        </div>
      )}

      <div ref={tour3}>
        <OrderCardButtons
          submitButton={{
            text: "تنظیم رمز عبور",
            loading: buttonIsLoading,
            disabled:
              buttonIsLoading || !input?.password || !input?.repeatPassword,
            onClick: () => onSetPasswordHandle(),
          }}
          cancelButton={{
            text: "بازگشت",
            onClick: () => onBackClick(),
          }}
        />
      </div>

      <ConfigProvider locale={fa_IR} direction={"rtl"}>
        <Tour steps={tourSteps} open={state?.orderData?.tour} onClose={() => dispatch(setOrderData({ ...state.orderData, tour: false, forgetPassword: false, }))} />
      </ConfigProvider>
    </div>
  );
};

export default connector(SetPasswordStage);
