import { getViaAuth, postViaAuth, responseValidator } from "../apis/api";
import { API } from "../apis";
import {
  AuthenticateRequestBody,
  LevelInfo,
} from "../domain/authentication/authentication";
import { BankAccount } from "../domain";

export async function authenticateUser(
  setLoading,
  data: AuthenticateRequestBody,
  onComplete?: () => void,
) {
  setLoading(true);
  await postViaAuth(API.user.authenticate, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    } else {
    }
  });
}

export async function getLevelInfo(
  setLoading: any,
  setData: any,
  level: number,
) {
  setLoading(true);
  await getViaAuth<LevelInfo>(API.level.levelInfo + `/${level}`).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        setData(response.data);
      }
    },
  );
}
