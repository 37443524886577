import { MenuItem } from "./common";
import { ReactComponent as User } from "assets/icons/dashboard/sidebar/User.svg";
import { ReactComponent as ShoppingCard } from "assets/icons/dashboard/sidebar/Shopping_Cart.svg";
import { ReactComponent as TextDocument } from "assets/icons/dashboard/sidebar/Text_Documet.svg";
import { ReactComponent as MenuIcon } from "assets/icons/dashboard/mobile-menu/Apps.svg";

//New Icons
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard/sidebar/Dashboard.svg'
import { ReactComponent as AuthenticationIcon } from 'assets/icons/dashboard/sidebar/Authentication.svg'
import { ReactComponent as BuyAndSellIcon } from 'assets/icons/dashboard/sidebar/BuyAndSell.svg'
import { ReactComponent as OrdersIcon } from 'assets/icons/dashboard/sidebar/Orders.svg'
import { ReactComponent as BankAccountIcon } from 'assets/icons/dashboard/sidebar/BankAccount.svg'
import { ReactComponent as ReferralIcon } from 'assets/icons/dashboard/sidebar/Referral.svg'
import { ReactComponent as SecurityIcon } from 'assets/icons/dashboard/sidebar/Security.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/dashboard/sidebar/DownloadApplication.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/dashboard/sidebar/Logout.svg'


import * as React from "react";

export const mobileMenuItems = [
  {
    text: "منو",
    icon: <MenuIcon />,
    isDrawer: true,
  },
  {
    text: "خرید و فروش",
    icon: <ShoppingCard />,
    link: "/buy-sell",
  },
  {
    text: "سوابق",
    icon: <TextDocument />,
    link: "/orders",
  },
  {
    text: "پیشخوان",
    icon: <User />,
    link: "/dashboard",
  },
];

export const SidebarItems: MenuItem[] = [
  {
    id: 1,
    isActive: false,
    title: "پیشخوان",
    icon: <DashboardIcon />,
    link: "/dashboard",
  },
  {
    id: 2,
    isActive: false,
    title: "احراز هویت",
    icon: <AuthenticationIcon />,
    link: "/authentication",
  },
  {
    id: 3,
    isActive: false,
    title: "خرید و فروش",
    icon: <BuyAndSellIcon />,
    link: "/create-order  ",
  },
  {
    id: 4,
    isActive: false,
    title: "سوابق",
    icon: <OrdersIcon />,
    link: "/orders",
  },
  {
    id: 5,
    isActive: false,
    title: "اطلاعات بانکی",
    icon: <BankAccountIcon />,
    link: "/bank-account",
  },
  {
    id: 6,
    isActive: false,
    title: "کسب درآمد",
    icon: <ReferralIcon />,
    link: "/referral",
  },
  {
    id: 7,
    isActive: false,
    title: "امنیت",
    icon: <SecurityIcon />,
    link: "/security",
  },
  {
    id: 8,
    isActive: false,
    title: "دانلود اپلیکیشن",
    icon: <DownloadIcon />,
    link: "/download-application",
  },
  {
    id: 9,
    isActive: false,
    title: "خروج",
    icon: <LogoutIcon />,
    link: "/login",
  },
];
