import toast from "react-hot-toast";
import {
  get,
  getViaAuth,
  postGeneric,
  postViaAuth,
  responseValidator,
} from "../apis/api";
import { API } from "../apis";
import {
  PostWithdrawalReferral,
  ReferralActions,
  ReferralInfo,
  ReferralWithdrawals,
} from "../domain/referral/referral";
import { Enum } from "core/domain";

export async function getMineReferralInfo(setLoading: any, setData: any) {
  setLoading(true);
  await getViaAuth<ReferralInfo>(API.referral.mine.info).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData(response.data);
    }
  });
}

export async function getMineReferralActions(setLoading: any, setData: any) {
  setLoading(true);
  await getViaAuth<ReferralActions>(API.referral.mine.actions).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        setData(response.data);
      }
    }
  );
}

export async function getActionTypesEnum(setLoading: any, setData: any) {
  setLoading(true);
  await getViaAuth<Enum>(API.enums.referralActionType).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        setData(response.data)
      }
    }
  )
}

export async function getMineReferralWithdrawals(
  setLoading: any,
  setData: any
) {
  setLoading(true);
  await getViaAuth<ReferralWithdrawals>(API.referral.mine.withdrawals).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        setData(response.data);
      }
    }
  );
}

export async function getWithdrawalStatesEnum(setLoading: any, setData: any) {
  setLoading(true);
  await getViaAuth<Enum>(API.enums.referralWithdrawalState).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        setData(response.data)
      }
    }
  )
}

export async function postWithdrawalReferral(
  setLoading: any,
  data: PostWithdrawalReferral,
  onComplete: () => void
) {
  setLoading(true);
  await postViaAuth(API.referral.withdrawal, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success("درخواست شما در انتظار تایید مدیریت قرار گرفت.");
      onComplete();
    }
  });
}

export async function postRegisterReferral(
  setLoading: any,
  onComplete: () => void
) {
  setLoading(true);
  await postViaAuth(API.referral.register, {}).then((response) => {
    setLoading(false);
    if (responseValidator(response.status)) onComplete();
  });
}