import * as React from "react";
import "./badge.scss";

export enum BadgeType {
  "error",
  "success",
  "info",
  "secondary",
  "primary",
  "waiting",
}

interface Props {
  className?: string;
  type: BadgeType;
  text: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export const Badge: React.FC<Props> = (props) => {
  return (
    <div
      className={`pmx-badge${
        props.className && props.className !== "" ? props.className : ""
      }`}
      onClick={props.onClick}
    >
      <div className={`${BadgeType[props.type]}`}>
        {props?.icon}
        <span>{props.text}</span>
      </div>
    </div>
  );
};
