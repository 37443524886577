import { Input, InputProps } from "antd";
import {FC, ReactNode, useState} from "react";
import "./antd-custom-input.scss";

interface AntdCustomInputProps extends InputProps {
  infix?: ReactNode;
}

const AntdCustomInput: FC<AntdCustomInputProps> = ({ infix, ...props }) => {
  return (
      <>
        {infix && (
            <div className="ant-input-infix">
                {infix}
            </div>
        )}
        <Input
            {...props}
            className={`pmx-antd-custom-input ${props.className ? props.className : ""}`}
        />
      </>
  );
};

export { AntdCustomInput as AntdInput };
