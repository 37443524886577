import "./enter-wallet-deposit.scss";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "core/redux/actions";
import {OrderCardStages, Side} from "core/domain/landing/landing";
import {FC, useCallback, useEffect, useRef, useState} from "react";
import {RootState} from "../../../../../../../core/redux/store";
import {ReactComponent as DropdownIcon} from "assets/icons/home/dropdown.svg";
import {ReactComponent as Back} from "assets/icons/general/back.svg";
import {LoadingOutlined} from "@ant-design/icons";
import {ConfigProvider, Tour, TourProps} from "antd";
import {getViaAuth, postViaAuth, responseValidator,} from "core/apis/api";
import {__API} from "core/apis/api-routes";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import fa_IR from "antd/lib/locale/fa_IR";
import {AddressWallet, DepositNetowrk,} from "../../../../../../../core/domain";
import {ReactComponent as Copy} from "assets/icons/home/copy.svg";
import {ReactComponent as QrCodeIcon} from "assets/icons/general/qr-code.svg";
import {copyToClipboard} from "../../../../../../../core/utils/copyToClipboard";
import {Button, buttonType, Modal} from "../../../../../../../components";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

enum STEP {
  CHOOSE_WALLET,
  CHOOSE_NETWORK,
}

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

const EnterWalletDeposit: FC<Props> = ({ state }) => {
  const orderData = state?.orderData;
  const [step, setStep] = useState<STEP>(STEP.CHOOSE_WALLET);
  const [networks, setNetworks] = useState<AddressWallet[]>();
  const [selectedNetwork, setSelectedNetwork] = useState<AddressWallet>();
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);
  const [chooseButtonIsLoading, setChooseButtonIsLoading] =
    useState<boolean>(false);
  const [qrCodeModalIsOpen, setQrCodeModalIsOpen] = useState(false);
  const [memo, setMemo] = useState<string>("");
  const [txId, setTxId] = useState<string>("")

  const navigate = useNavigate();

  const tour1 = useRef(null);
  const tour2 = useRef(null);
  const tour3 = useRef(null);
  const tour4 = useRef(null);
  const tour5 = useRef(null);
  const tour6 = useRef(null);
  const tour7 = useRef(null);
  const tour8 = useRef(null);

  const tourSteps: TourProps["steps"] = [
    {
      title: "انتخاب شبکه",
      description:
        "ابتدا از این بخش، شبکه ی آدرس کیف پول خود را انتخاب نمایید.",
      target: () => tour1.current,
    },
    {
      title: "آدرس کیف پول",
      description:
        "پس از اینکه شبکه ی مورد نظر خود را انتخاب نمودید، در این بخش یک آدرس کیف پول مختص شما ساخته و نمایش داده میشود.",
      target: () => tour2.current,
    },
    {
      title: "کپی",
      description:
        "میتوانید با کلیک بر روی این دکمه، آدرس کیف پول را کپی نمایید.",
      target: () => tour3.current,
    },
    {
      title: "کیو آر کد",
      description:
        "میتوانید با کلیک بر روی این دکمه، کیو آر کدِ کیف پول را نیز مشاهده نمایید.",
      target: () => tour4.current,
    },
    {
      title: "آدرس ممو",
      description:
          "پس از اینکه شبکه ی مورد نظر خود را انتخاب نمودید، در این بخش یک ممو مختص شما ساخته و نمایش داده میشود.",
      target: () => tour5.current,
    },
    {
      title: "کپی",
      description:
          "میتوانید با کلیک بر روی این دکمه، ممو را کپی نمایید.",
      target: () => tour6.current,
    },
    {
      title: "وارد کردن هش",
      description: "در این بخش، هش یا TXID تراکنش انجام شده را وارد نمایید.",
      target: () => tour7.current,
    },
    {
      title: "ارسال",
      description:
        "در صورتی که هش یا TXID را وارد نمودید، این بخش میتوانید اقدام به ارسال یا بازگشت نمایید.",
      target: () => tour8.current,
    },
  ];

  const fetchNetworks = async (isOnClick?: boolean) => {
    setChooseButtonIsLoading(true);
    await getViaAuth<DepositNetowrk>(
      `${__API.market.depositNetworks}/${orderData.sourceMarket?.symbol ?? "USDT"
      }`,
    ).then((response: any) => {
      setChooseButtonIsLoading(false);
      if (responseValidator(response.status)) {
        setNetworks(response.data?.addressWallets);
        setSelectedNetwork(response?.data?.addressWallets[0]);
      }
    });
  };

  const createDepositOrder = async () => {
    setButtonIsLoading(true);
    const body = {
      trackingCode: orderData?.trackingCode,
      txId: txId,
    };
    await postViaAuth(__API.orders.cryptoDepoist, body).then(
      (response: any) => {
        setButtonIsLoading(false);

        if (responseValidator(response.status)) {
          toast.success("در حال انتقال به صفحه نتیجه...");
          window.open(`/tracking/${orderData?.trackingCode}`, "_self");
        }
      },
    );
  };

  const handleSubmit = () => createDepositOrder();

  const dispatch = useDispatch();
  const onBackClick = () =>
    state.orderData.side === Side.Swap
      ? dispatch(
        setOrderData({
          ...state.orderData,
          stage: OrderCardStages.ENTER_WALLET,
          tour: false,
        }),
      )
      : dispatch(
        setOrderData({
          ...state.orderData,
          stage: OrderCardStages.RECEIVE_METHOD,
          tour: false,
        }),
      );

  const fetchNetworksMemo = useCallback(fetchNetworks, []);

  useEffect(() => {
    fetchNetworksMemo();
  }, [fetchNetworksMemo]);
  return (
    <>
      <Modal
        isOpen={qrCodeModalIsOpen}
        setIsOpen={setQrCodeModalIsOpen}
        title={`آدرس کیف پول ${selectedNetwork?.name}`}
        className="qr-modal"
      >
        {selectedNetwork?.qrCodeUrl && (
          <img
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
            src={`data:image/jpeg;base64,${selectedNetwork?.qrCodeUrl}`}
          />
        )}
      </Modal>
      <div className="pmx-enter-wallet-deposit-stage">
        {step === STEP.CHOOSE_WALLET && (
          <>
            <div className="choose-wallet-content">
              <h3>واریز از مبدا</h3>
              <p>
                لطفا مقدار {orderData.sourceAmount}{" "}
                {orderData.sourceMarket.name} به کیف پول زیر ارسال نمایید.
              </p>
              <div
                  className="choose-network-button"
                  onClick={() => setStep(STEP.CHOOSE_NETWORK)}
                  ref={tour1}
              >
                <span>
                  {selectedNetwork?.name ?? "شبکه انتقال ارز را انتخاب کنید"}
                </span>{" "}
                {chooseButtonIsLoading ? <LoadingOutlined/> : <DropdownIcon/>}
              </div>
              <div className={"network-field"}>
                {chooseButtonIsLoading ? (
                    <LoadingOutlined/>
                ) : (
                    <div
                        ref={tour4}
                        className={"network-option"}
                        onClick={() =>
                            !chooseButtonIsLoading && setQrCodeModalIsOpen(true)
                        }
                    >
                      <QrCodeIcon/>
                    </div>
                )}
                <div
                    ref={tour3}
                    className={"network-option"}
                    onClick={() =>
                        copyToClipboard(
                            selectedNetwork.walletAddress ?? "",
                            "آدرس کیف پول",
                        )
                    }
                >
                  <Copy/>
                </div>
                <div ref={tour2} className={"tour-el"}>
                  <AntdInput
                      value={selectedNetwork?.walletAddress ?? ""}
                      placeholder={`آدرس کیف پول در شبکه ${selectedNetwork?.name ?? ""
                      } را وارد کنید...`}
                  />
                </div>
              </div>
              {selectedNetwork?.walletTag !== null && selectedNetwork?.walletTag !== "" &&
              <div className={"network-field"}>
                <div
                    ref={tour6}
                    className={"network-option"}
                    onClick={() =>
                        copyToClipboard(
                            selectedNetwork.walletTag ?? "",
                            "ممو",
                        )
                    }
                >
                  <Copy/>
                </div>
                <div ref={tour5} className={"tour-el"}>
                  <AntdInput
                      value={selectedNetwork?.walletTag ?? ""}
                      placeholder={`ممو در شبکه ${selectedNetwork?.name ?? ""
                      } را وارد کنید...`}
                  />
                </div>
              </div>
              }
              <div ref={tour7}>
                <AntdInput
                    value={txId ?? ""}
                    placeholder={`پس از ارسال ${orderData.sourceMarket.name} به ولت ما، TXID را اینجا وارد نمایید...`}
                    onChange={(e) => setTxId(e.target.value)}
                />
              </div>
              <div ref={tour8}>
                <OrderCardButtons
                    submitButton={{
                      text: "ارسال",
                      loading: buttonIsLoading,
                      disabled:
                          buttonIsLoading ||
                          selectedNetwork?.walletAddress === "" ||
                          txId === "" ||
                          chooseButtonIsLoading,
                      onClick: handleSubmit,
                    }}
                    cancelButton={{
                      text: "بازگشت",
                      onClick: () => onBackClick(),
                    }}
                />
              </div>
            </div>
          </>
        )}
        {step === STEP.CHOOSE_NETWORK && (
            <>
              <div className="choose-network-content">
                <Button text={"بازگشت"} icon={<Back/>} type={buttonType.ordercard_outlined}
                        onClick={() => setStep(STEP.CHOOSE_WALLET)}/>
                <ul>
                  {networks?.map((network) => (
                      <li
                          onClick={() => {
                            setSelectedNetwork(network);
                            setStep(STEP.CHOOSE_WALLET);
                          }}
                      >
                        <span>{network?.name.toUpperCase()} - {network.network.toUpperCase()}</span>
                        <Back/>
                      </li>
                  ))}
                </ul>
              </div>
            </>
        )}
        <ConfigProvider locale={fa_IR} direction={"rtl"}>
          <Tour
              steps={tourSteps}
              open={state?.orderData?.tour && step === STEP.CHOOSE_WALLET}
              onClose={() =>
                  dispatch(setOrderData({...state.orderData, tour: false}))
              }
          />
        </ConfigProvider>
      </div>
    </>
  );
};

export default connector(EnterWalletDeposit);
