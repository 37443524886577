import {FC, useEffect, useRef, useState} from "react";
import {RootState} from "../../../../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {setOrderData} from "../../../../../../../core/redux/actions";
import {OrderCardStages, Side,} from "../../../../../../../core/domain/landing/landing";
import "./receive-method.scss";
import {Enum, PaginationList} from "../../../../../../../core/domain";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import {IsPersianChar} from "../../../../../../../core/utils/stringExtentions";
import {createBuyOrder, createSellOrder,} from "../../../../../../../core/repositores/orders";
import fa_IR from "antd/lib/locale/fa_IR";
import {ConfigProvider, Divider, message, Radio, RadioChangeEvent, Tour, TourProps} from "antd";
import {Button, buttonType} from "../../../../../../../components";
import {ReactComponent as PlusIcon} from 'assets/icons/general/plus.svg'
import {ReactComponent as DownCircleIcon} from 'assets/icons/home/down-circle.svg'
import {ReactComponent as CrossCircleIcon} from 'assets/icons/home/cross-circle.svg'
import {AddUserIBanBody, UserIBanResponse} from "../../../../../../../core/domain/user-saved-ibans/user-saved-ibans";
import {ReactComponent as BinIcon} from 'assets/icons/home/bin.svg'
import {RemoveIBanModal} from "./remove-iban-modal/remove-iban-modal";
import {addIBan, getMyIBans} from "../../../../../../../core/repositores/user-saved-ibans";
import toast from "react-hot-toast";
import {LoadingOutlined} from "@ant-design/icons";

enum Steps {
    SelectMethod = 1,
    EnterAccount,
    EnterIBan,
}

interface Method extends Enum {
    disabled?: boolean;
}

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

enum IBAN_STEPS {
    ENTER,
    ADD,
    CHOOSE
}

enum RADIO {
    MANUAL,
    SAVED
}

const ReceiveMethod: FC<Props> = ({state}) => {
    const methodsData = [
        {
            id: 1,
            title: "شارژ کیف پول",
            description:
                "در این روش پس از پرداخت، ارز به صورت خودکار به کیف پول موجود در پنل کاربری شما اضافه خواهد شد.",
            disabled: true,
        },
        {
            id: 2,
            title: "دریافت ووچر",
            description:
                "در این روش پس از پرداخت، کد های ووچر خود را دریافت میکنید.",
        },
        {
            id: 3,
            title: "حساب به حساب",
            description:
                "در این روش پس از وارد کردن شماره حساب پرفکت مانی خود،ارز مقصد را دریافت می کنید.",
        },
        {
            id: 4,
            title: "برداشت مستقیم",
            description:
                "در این روش پس از وارد کردن لینک کیف پول رمزارز خود،ارز مقصد را دریافت می کنید",
        },
        {
            id: 5,
            title: "دریافت مستقیم با شماره شبا",
            description:
                "در این روش پس از وارد کردن شماره شبای خود،معادل تومانی رمز ارز فروخته شده را دریافت می کنید.",
        },
    ];

    const tour1 = useRef(null)
    const tour2 = useRef(null)

    const tourSteps: TourProps['steps'] = [
        {
            title: 'وارد کردن شماره حساب پرفکت مانی',
            description: 'شماره حساب پرفکت مانی خود را وارد نمایید.',
            target: () => tour1.current,
        },
        {
            title: 'ارسال یا بازگشت',
            description: "از این بخش میتوانید اقدام به ارسال شماره حساب یا بازگشت نمایید.",
            target: () => tour2.current,
        },
    ]

    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState<Method>(state?.orderData?.sourceMarket?.symbol === "PM" ? methodsData[1] : state?.orderData?.destinationMarket?.symbol === "PM" ? methodsData[1] : methodsData[3]);
    const [accountId, setAccountId] = useState("");
    const [step, setStep] = useState<Steps>(Steps.SelectMethod);
    const [iBanStep, setIBanStep] = useState<IBAN_STEPS>(IBAN_STEPS.ENTER)
    const [methods, setMethods] = useState(methodsData);

    const [iBan, setIBan] = useState("");
    const [newIBan, setNewIBan] = useState<string>("");
    const [newIBanTitle, setNewIBanTitle] = useState<string>("");
    const [savedIBans, setSavedIBans] = useState<PaginationList<UserIBanResponse>>()
    const [savedIBansLoading, setSavedIBansLoading] = useState<boolean>(false);
    const [selectedIBan, setSelectedIBan] = useState<UserIBanResponse>()
    const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false)
    const [addButtonLoading, setAddButtonLoading] = useState<boolean>(false)

    const [selectedRadio, setSelectedRadio] = useState<RADIO>(RADIO.SAVED)

    const dispatch = useDispatch();

    const onBackClick = () =>
        state?.orderData?.side === Side.Buy
            ? dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.ENTER_CARD,
                }),
            )
            : dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.CALCULATOR,
                }),
            );

    const onBuyComplete = (response: any) =>
        dispatch(
            setOrderData({
                ...state.orderData,
                stage: OrderCardStages.PAYMENT_METHOD,
                trackingCode: response.data.trackingCode,
                fee: response.data.fee,
                moneyAccount: accountId,
            }),
        );

    const onSellComplete = (response: any) =>
        state?.orderData?.sourceMarket?.symbol === "PM"
            ? dispatch(
                setOrderData({
                    ...state.orderData,
                    trackingCode: response.data.trackingCode,
                    fee: response.data.fee,
                    iBan: iBan,
                    stage: OrderCardStages.ENTER_VOUCHER,
                }),
            )
            : dispatch(
                setOrderData({
                    ...state.orderData,
                    trackingCode: response.data.trackingCode,
                    fee: response.data.fee,
                    iBan: iBan,
                    stage: OrderCardStages.ENTER_WALLET_DEPOSIT,
                }),
            );

    const onBuyError = (response: any) => {
        if (response.message.includes("احراز"))
            dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.BASIC_INFO_FORM,
                }),
            );
    };

    const onSellError = (response: any) => {
        if (response.message.includes("احراز"))
            dispatch(
                setOrderData({
                    ...state.orderData,
                    stage: OrderCardStages.BASIC_INFO_FORM,
                }),
            );
    };

    const onEnterIBanStep = () => {
        setStep(Steps.EnterIBan)
        fetchIBans()
    }

    async function onBuyHandle() {
        const side = state?.orderData?.side;
        const destination = state?.orderData?.destinationMarket;
        side === Side.Buy
            ? destination?.symbol === "PM"
                ? await createBuyOrder(
                    setButtonIsLoading,
                    {
                        bankAccount: state.orderData.bankAccount.id,
                        destinationMarket: state.orderData.destinationMarket.symbol,
                        sourceAmount: state.orderData.sourceAmount,
                        moneyAccount: accountId ? accountId : "",
                    },
                    onBuyComplete,
                    onBuyError,
                )
                : dispatch(
                    setOrderData({
                        ...state.orderData,
                        stage: OrderCardStages.ENTER_WALLET,
                    }),
                )
            : side === Side.Sell
                ? createSellOrder(
                    setButtonIsLoading,
                    {
                        iBan: selectedRadio === RADIO.MANUAL ? iBan : selectedIBan.iBan,
                        sourceMarket: state.orderData.sourceMarket.symbol,
                        sourceAmount: state.orderData.sourceAmount,
                    },
                    onSellComplete,
                    onSellError,
                )
                : step === Steps.EnterAccount
                    ? dispatch(
                        setOrderData({
                            ...state.orderData,
                            stage: OrderCardStages.BILL_PREVIEW,
                            moneyAccount: accountId ? accountId : "",
                        }),
                    )
                    : dispatch(
                        setOrderData({
                            ...state.orderData,
                            stage: OrderCardStages.BILL_PREVIEW,
                            moneyAccount: null,
                        }),
                    );
    }

    const onEnFieldChange = (value: string) => {
        if (
            value !== "" &&
            IsPersianChar(value.split("")[value.split("").length - 1])
        )
            return;

        setAccountId(value);
    };

    const onNumberChange = (value: string) => {
        // Remove any non-numeric characters from the beginning of the value
        const trimmedValue = value.replace(/^\D+/, "");

        // If the trimmed value is empty or contains only non-numeric characters:
        if (!trimmedValue || !trimmedValue.match(/^\d+$/)) {
            setIBan(""); // Set IBAN to an empty string
        } else {
            // If the trimmed value contains only numeric characters:
            setIBan(trimmedValue);
        }
    };

    const onNewIBanChange = (value: string) => {
        // Remove any non-numeric characters from the beginning of the value
        const trimmedValue = value.replace(/^\D+/, "");

        // If the trimmed value is empty or contains only non-numeric characters:
        if (!trimmedValue || !trimmedValue.match(/^\d+$/)) {
            setNewIBan(""); // Set IBAN to an empty string
        } else {
            // If the trimmed value contains only numeric characters:
            setNewIBan(trimmedValue);
        }
    };

    const handleAddIBan = () => {
        const data: AddUserIBanBody = {
            label: newIBanTitle,
            iBan: newIBan
        }
        const onComplete = async () => {
            toast.success("شماره شبای جدید با موفقیت اضافه شد")
            await getMyIBans(setAddButtonLoading, setSavedIBans, () => setIBanStep(IBAN_STEPS.ENTER))
            // handleChooseIBan({
            //     walletAddress: newWalletAddress,
            //     network: selectedNetwork.network,
            //     label: newWalletTitle,
            //     createdOn: "",
            // })

        }
        addIBan(setAddButtonLoading, data, onComplete)
    }

    const handleChooseIBan = (selectedIBan: UserIBanResponse) => {
        setIBanStep(IBAN_STEPS.ENTER)
        if (selectedIBan) {
            setSelectedIBan(selectedIBan)
            setIBan(selectedIBan.label)
        }
    }

    useEffect(() => {
        window.history.pushState(
            window.history.state,
            document.title,
            "/receive-method"

        );
        const sourceMarket = state?.orderData?.sourceMarket;
        const destinationMarket = state?.orderData?.destinationMarket;
        if (state?.orderData?.side === Side.Buy) {
            setSelectedMethod(state?.orderData?.sourceMarket?.symbol === "PM" ? methods[1] : methods[3]);
            if (destinationMarket.symbol === "PM") {
                setMethods(methodsData.filter((e) => e.id !== 4 && e.id !== 5));
                setSelectedMethod(methodsData[1])
            } else {
                setMethods(methodsData.filter((e) => e.id === 1 || e.id === 4));
            }
        } else if (state?.orderData?.side === Side.Sell) {
            setSelectedMethod(methods[4]);
            setMethods(methodsData.filter((e) => e.id === 1 || e.id === 5));
        } else {
            if (destinationMarket.symbol === "PM") {
                setMethods(methodsData.filter((e) => e.id !== 4 && e.id !== 5));
            }
        }
    }, []);

    const onNextStepClick = () =>
        selectedMethod.id === 3
            ? setStep(Steps.EnterAccount)
            : selectedMethod.id === 5
                ? onEnterIBanStep()
                : onBuyHandle();

    const handleRadioChange = (e: RadioChangeEvent) => {
        setSelectedRadio(e.target.value);
        setIBan('')
    };

    const fetchIBans = async () => {
        await getMyIBans(setSavedIBansLoading, setSavedIBans, (e: any) => {
            if (e.items.length > 0) {
                handleChooseIBan(e.items[0])
                setSelectedRadio(RADIO.SAVED)

            } else {
                setSelectedRadio(RADIO.MANUAL)
            }
        })
    }

    const handleRemoveIBanModal = (selectedIBan: UserIBanResponse) => {
        if (selectedIBan) {
            setSelectedIBan(selectedIBan)
            setRemoveModalOpen(true)
        }
    }

    const handleRemoveIBanConfirm = () => {
        message.success("شماره شبا با موفقیت حذف شد")
        setRemoveModalOpen(false)
        fetchIBans()
    }

    return (
        <div className={"pmx-receive-method-stage"}>
            <RemoveIBanModal isOpen={removeModalOpen} setIsOpen={setRemoveModalOpen} item={selectedIBan} onComplete={handleRemoveIBanConfirm} />
            {step === Steps.SelectMethod ? (
                <>
                    <h3>
                        {state?.orderData?.side === Side.Buy ||
                        state?.orderData?.side === Side.Swap
                            ? `روش دریافت ${
                                state?.orderData?.destinationMarket?.symbol === "PM"
                                    ? "پرفکت مانی"
                                    : state?.orderData?.destinationMarket?.name
                            }`
                            : state?.orderData?.side === Side.Sell}
                    </h3>
                    <p className={"description"}>
                        {state?.orderData?.side === Side.Buy ||
                        state?.orderData?.side === Side.Swap
                            ? `روش مورد نظر خود را جهت دریافت ${
                                state?.orderData?.destinationMarket?.symbol === "PM"
                                    ? "پرفکت مانی"
                                    : state?.orderData?.destinationMarket?.name
                            } را انتخاب نمایید.`
                            : "روش مورد نظر خود را جهت دریافت معادل تومانی رمز ارز فروخته شده انتخاب نمایید."}
                    </p>
                    <div className={"methods"}>
                        {methods.map((method) => (
                            <div
                                className={`method-item ${
                                    selectedMethod?.id === method.id ? "active" : ""
                                } ${method?.disabled ? "disabled" : ""}`}
                                onClick={() => !method.disabled && setSelectedMethod(method)}
                            >
                                <div>
                                    <span>{method.title}</span>
                                    <p>{method.description}</p>
                                </div>
                                <span className={"circle-badge"}>
                  <span className={"inner-circle"}></span>
                </span>
                            </div>
                        ))}
                    </div>
                    <OrderCardButtons
                        submitButton={{
                            text: "ادامه",
                            loading: buttonIsLoading,
                            disabled: buttonIsLoading,
                            onClick: () => onNextStepClick(),
                        }}
                        cancelButton={{
                            text: "بازگشت",
                            onClick: () => onBackClick(),
                        }}
                    />
                </>
            ) : step === Steps.EnterAccount ? (
                <>
                    <h3>وارد کردن شماره حساب</h3>
                    <p className={"description"}>
                        شماره حساب پرفکت مانی خود که میخواهید ارز خریداری شده به آن حساب
                        واریز شود را وارد نمایید.
                    </p>
                    <div ref={tour1}>
                        <AntdInput
                            className={"account-id-input en"}
                            placeholder={"شماره حساب پرفکت مانی : U12345678"}
                            value={accountId}
                            type={"account-id"}
                            onChange={(e) => onEnFieldChange(e.target.value)}
                        />
                    </div>

                    <div ref={tour2}>
                        <OrderCardButtons
                            submitButton={{
                                text: "ارسال",
                                loading: buttonIsLoading,
                                disabled: buttonIsLoading || !accountId,
                                onClick: onBuyHandle,
                            }}
                            cancelButton={{
                                text: "بازگشت",
                                onClick: () => setStep(Steps.SelectMethod),
                            }}
                        />
                    </div>

                    <ConfigProvider locale={fa_IR} direction={"rtl"}>
                        <Tour
                            open={state?.orderData?.tour}
                            onClose={() =>
                                dispatch(setOrderData({...state.orderData, tour: false}))
                            }
                            steps={tourSteps}
                        />
                    </ConfigProvider>
                </>
            ) : (
                <div>
                    {iBanStep === IBAN_STEPS.ENTER &&
                        <>
                            <h3>اطلاعات تکمیلی سفارش</h3>
                            <p className={"description"}>
                                شماره شبایی که میخواهید مبلغ دریافتی به آن واریز شود را وارد نمایید.
                            </p>

                            <Radio.Group onChange={handleRadioChange} value={selectedRadio}>
                                <Radio value={RADIO.SAVED}>انتخاب از شماره شبا های ثبت شده</Radio>
                                <Radio value={RADIO.MANUAL}>ورود دستی شماره شبا</Radio>
                            </Radio.Group>

                            {selectedRadio === RADIO.MANUAL &&
                                <AntdInput
                                    placeholder={"شماره شبا بدون IR"}
                                    className={"iban-input"}
                                    value={iBan}
                                    type={"text"}
                                    onChange={(e) => onNumberChange(e.target.value)}
                                    maxLength={24}
                                    suffix={iBan.length > 0 && <CrossCircleIcon onClick={() => setIBan('')} style={{cursor: 'pointer'}}/>}
                                />
                            }
                            {selectedRadio === RADIO.SAVED &&
                                <AntdInput
                                    className={"iban-input"}
                                    value={selectedIBan?.label}
                                    type={"text"}
                                    onClick={() => setIBanStep(IBAN_STEPS.CHOOSE)}
                                    style={{cursor: 'pointer'}}
                                    suffix={savedIBansLoading ? <LoadingOutlined/> :
                                        savedIBans && savedIBans.items && savedIBans.items.length !== 0 &&
                                        <DownCircleIcon onClick={() => setIBanStep(IBAN_STEPS.CHOOSE)} />
                                    }
                                />
                            }

                            <Button type={buttonType.ordercard_outlined} className={"add-iban-button"}
                                    onClick={() => setIBanStep(IBAN_STEPS.ADD)} icon={<PlusIcon/>}
                                    text={"افزودن شماره شبا"}/>

                            <OrderCardButtons
                                submitButton={{
                                    text: "ادامه",
                                    loading: buttonIsLoading,
                                    disabled: buttonIsLoading ||
                                        (selectedRadio === RADIO.MANUAL && (!iBan || iBan.length !== 24)) ||
                                        (selectedRadio === RADIO.SAVED && !selectedIBan),
                                    onClick: onBuyHandle,
                                }}
                                cancelButton={{
                                    text: "بازگشت",
                                    onClick: () => setStep(Steps.SelectMethod),
                                }}
                            />
                        </>
                    }
                    {iBanStep === IBAN_STEPS.ADD &&
                        <>
                            <h3>ذخیره شماره شبا</h3>
                            <p className={"description"}>
                                لطفا عنوان و آدرس شماره شبا خود را جهت ذخبره برای استفاده ی مجدد وارد نمایید.
                            </p>

                            <AntdInput
                                placeholder={"عنوان شماره شبا"}
                                className={"new-iban-title-input"}
                                value={newIBanTitle}
                                type={"text"}
                                onChange={(e) => setNewIBanTitle(e.target.value)}
                            />

                            <AntdInput
                                placeholder={"شماره شبا بدون IR"}
                                className={"new-iban-input"}
                                value={newIBan}
                                type={"text"}
                                onChange={(e) => setNewIBan(e.target.value)}
                                maxLength={24}
                            />

                            <OrderCardButtons
                                submitButton={{
                                    text: "ذخیره و ادامه",
                                    loading: addButtonLoading,
                                    disabled: !newIBanTitle || newIBan.length !== 24,
                                    onClick: handleAddIBan,
                                }}
                                cancelButton={{
                                    text: "بازگشت",
                                    onClick: () => setIBanStep(IBAN_STEPS.ENTER),
                                }}
                            />
                        </>
                    }
                    {iBanStep === IBAN_STEPS.CHOOSE &&
                        <>
                            <h3>انتخاب شماره شبا</h3>
                            <p className={"description"}>
                                لطفا شماره حساب شبا را انتخاب نمایید.
                            </p>

                            <ul>
                                {savedIBans?.items.map((item, i) => (
                                    <>
                                        {i !== 0 && <Divider type={"horizontal"}/>}
                                        <li key={i} onClick={() => {
                                            setSelectedIBan(item);
                                            handleChooseIBan(item)
                                        }}
                                            className={`${item === selectedIBan ? "active" : ""}`}>
                                            <div>
                                                <p>{item.label}</p>
                                                <p className={"en"}>{item.iBan}</p>
                                            </div>
                                            <div onClick={(e) => {
                                                e.stopPropagation()
                                                handleRemoveIBanModal(item)
                                            }}>
                                                <BinIcon/>
                                            </div>
                                        </li>
                                    </>
                                ))}
                            </ul>

                            <OrderCardButtons
                                submitButton={{
                                    text: "انتخاب و ادامه",
                                    loading: false,
                                    disabled: !selectedIBan,
                                    onClick: () => {
                                        selectedIBan && handleChooseIBan(selectedIBan)
                                    },
                                }}
                                cancelButton={{
                                    text: "بازگشت",
                                    onClick: () => setIBanStep(IBAN_STEPS.ENTER),
                                }}
                            />
                        </>
                    }
                </div>
            )}
        </div>
    );
};
export default connector(ReceiveMethod);
