import Navbar from 'routes/pages/home/components/navbar/navbar';
import "./landing.scss";
import helpbanner from 'assets/images/help/helpbanner.png'
import helpbannerr from 'assets/images/help/helpbannerr.png'
import { isDesktop, isMobile } from "react-device-detect";
import { ReactComponent as SearchIcon } from 'assets/icons/home/search.svg'
import {AntdInput} from "../../../../../components/antd-custom-input/antd-custom-input";
import {useEffect} from "react";

interface Props {}

export const HelpLanding = (props: Props) => {
  return (
    <div className="pmx-landing-help">
      <Navbar />
      {isDesktop && <img id='banner' src={helpbanner} />}      
      {isMobile && <img id='banner' src={helpbannerr} />}
      <div className="hint-box">
        <h2>راهنمای استفاده از پی ام اکسچنج</h2>
        <AntdInput suffix={<SearchIcon />} placeholder="آموزش و یا عبارت مورد نظر خود را جست و جو کنید..." dir='ltr' />
      </div>
    </div>
  );
};
