import { Button, Card, Input, buttonType, inputType } from "components";
import * as React from "react";
import "./password-form.scss";
import { ResetPasswordForm } from "../../../../../core/domain/security/security";
import { useState } from "react";
import { resetPassword } from "../../../../../core/repositores/security";
import { faNumToEnNum } from "core/utils/decimalExtentions";

interface Props {}

export const PasswordForm: React.FC<Props> = (props) => {
  const [resetPasswordButtonIsLoading, setResetPasswordButtonIsLoading] =
    useState(false);
  const [formValues, setFormValues] = React.useState<ResetPasswordForm>({currentPassword: "", newPassword: "", confirmPassword: ""});

  const onFormChange = (value: string, field: string) => {
    setFormValues({
      ...formValues,
      [field]: faNumToEnNum(value),
    });
  };

  const onSubmitClick = async () => {
    await resetPassword(setResetPasswordButtonIsLoading, {
      oldPassword: formValues?.currentPassword,
      newPassword: formValues?.newPassword,
    });
  };

  return (
    <div className="password-form-container">
      <Card title="تغییر رمز عبور" className="password-form" divider>
        <form>
          <div className="row">
            <Input
              type={inputType.password}
              name="currentPassword"
              placeholder="رمز عبور فعلی"
              value={formValues?.currentPassword}
              onChange={(e) => onFormChange(e.target.value, "currentPassword")}
            />
          </div>
          <div className="row">
            <Input
              type={inputType.password}
              name="newPassword"
              placeholder="رمز عبور جدید"
              value={formValues?.newPassword}
              onChange={(e) => onFormChange(e.target.value, "newPassword")}
            />
          </div>
          <div className="row">
            <Input
              type={inputType.password}
              name="confirmPassword"
              placeholder="تکرار رمز عبور"
              value={formValues?.confirmPassword}
              onChange={(e) => onFormChange(e.target.value, "confirmPassword")}
            />
          </div>
          <Button
            disabled={
              !formValues?.confirmPassword ||
              !formValues?.currentPassword ||
              !formValues?.newPassword ||
              formValues?.newPassword !== formValues?.confirmPassword
            }
            type={buttonType.primary}
            text="تغییر رمز عبور"
            loading={resetPasswordButtonIsLoading}
            onClick={onSubmitClick}
          />
        </form>
      </Card>
    </div>
  );
};
