import {Dispatch, FC, SetStateAction} from "react";
import {Button, buttonType, Modal} from "../../../../../../../../components";
import popup from 'assets/images/home/pm-popup.png'
import './pm-popup-modal.scss'

interface Props {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const PmPopupModal: FC<Props> = ({isOpen, setIsOpen}) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} noHead className={"pmx-pm-popup-modal"}>
            <p className={"title"}><span>فوری؛</span> روش جدید خرید ووچر پرفکت مانی</p>
            <img src={popup} alt={"popup"} />
            <div className={"description"}>
                <p>شما کاربر ویژه پی ام ایکس چنج هستید؛ حتما مطالعه کنید!</p>
                <p>باتوجه به محدودیـت های پیش روی پرفکــت مــانی حتمـا روش جایگزین خریـد ووچـر را روی اکانـت خـود فعال
                    نمایید.</p>
            </div>
            <div className={"buttons"}>
                <Button onClick={() => {
                    window.open("https://virgool.io/@00-slotted.matrons/آموزش-خرید-ووچر-پرفکت-مانی-از-صرافی-pmxchange-hnk7knhkzwus", "_blank")
                }} type={buttonType.primary} text={"مشاهده راهنما"}/>
                <Button type={buttonType.ordercard_outlined} text={"بستن"} onClick={() => setIsOpen(false)} />
            </div>
        </Modal>
    )
}
