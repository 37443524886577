import {get, post, responseValidator} from "../apis/api";
import {API} from "../apis";
import {deleteCommaSeparator, digitSeparator} from "../utils/decimalExtentions";

export async function exchangePair(
    setLoading: any,
    setSource: any,
    setDestination: any,
    data: any,
    onComplete?: () => void,
) {
    setLoading(true);
    await get(
        `${API.market.exchange}/${data.fromSource}/${data.amount}/${data.sourceMarket}/${data.destinationMarket}`,
    ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status) && response.data) {
            data.fromSource == 2 ? setSource(digitSeparator(response?.data?.data.sourceAmount)) :
                setDestination(digitSeparator(response?.data?.data.destinationAmount));
        }
    });
}

export async function validatePair(
    setLoading: any,
    data: any,
    onComplete: (response: any) => any,
) {
    setLoading(true);
    await post(
        `${API.market.validate}/${data.sourceMarket}/${data.destinationMarket}/${deleteCommaSeparator(data.sourceAmount)}/${deleteCommaSeparator(data.destinationAmount)}`,
        {},
    ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status) && response.data) {
            onComplete(response);
        }
    });
}

export async function getRates(setLoading: any, setData: any, onComplete?: () => void) {
    setLoading(true);
    await get(API.rate.getRates).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status) && response.data) {
            setData(response.data.data);
            onComplete && onComplete();
        }
    });
}

export async function getTRXRate(setLoading: any, setData: any) {
    setLoading(true);
    await get(`Market/TRX/Price`).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status) && response.data) {
            setData({
                name: "ترون",
                symbol: "TRX",
                sellPrice: response.data.data.cryptoIrtSellPrice,
                buyPrice: response.data.data.cryptoIrtBuyPrice,
            });
        }
    });
}

export async function getMarkets(
    setLoading: any,
    setData: any,
    setResults: any,
) {
    setLoading(true);
    await get(API.market.getAllMarket).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status) && response.data) {
            setData(response.data.data);
            setResults(response.data.data);
        }
    });
}

export async function getLastMarkets(
    setLoading: any,
    setData: any,
) {
    setLoading(true);
    await get(API.market.theMostRecent24HoursCoins).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status) && response.data) {
            setData(response.data.data);
        }
    });
}

export async function getPriceInfos(
    setLoading: any,
    setData: any,
    count?: number,
) {
    setLoading(true);
    await get(`${API.market.priceInfos}/${count ?? 5}`).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status) && response.data) {
            setData(response.data.data);
        }
    });
}
