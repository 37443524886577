import { getViaAuth, postViaAuth, responseValidator } from "../apis/api";
import { API } from "../apis";
import moment from "moment/moment";
import { LoginResponse } from "../domain";
import toast from "react-hot-toast";
import {
  KEY_ACCESS_TOKEN,
  KEY_DATE,
  KEY_EXPIRES_IN,
  KEY_IS_AUTHORIZED,
  KEY_LEVEL,
  KEY_REFRESH,
  KEY_ROLES,
} from "core/constants/localStorageConstants";
import { LoginHistory } from "core/domain/security/security";
import { setLocalStorageItems } from "../utils/setLocalStorageItems";

export async function resetPassword(setLoading, data: any) {
  setLoading(true);
  await postViaAuth<LoginResponse>(API.security.resetPassword, data).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setLocalStorageItems(response);
        toast.success("رمز عبور با موفقیت تغییر یافت.");
      }
    },
  );
}

export async function setupTwoStep(
  setLoading: any,
  setData: any,
  onComplete: () => void,
) {
  setLoading(true);
  await postViaAuth(API.security.setup2fa, {}).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData(response.data);
      onComplete();
    }
  });
}

export async function verifySetupTwoStep(
  setLoading,
  data: {},
  onComplete: () => void,
) {
  setLoading(true);
  await postViaAuth(API.security.verify2fa, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
}

export async function disableTwoStep(
    setLoading,
    data,
    onComplete: () => void,
) {
  setLoading(true);
  await postViaAuth(API.user.disableTwoStep, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status)){
      onComplete()
    }
  })
}

export async function getLoginHistory(setLoading: any, setData: any) {
  setLoading(true);
  await getViaAuth<LoginHistory>(API.security.loginHistory + "/1/5").then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data)
        setData(response.data);
    },
  );
}
