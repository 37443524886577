import {Divider} from "antd";
import "./spot-rates.scss";
import {getIconPath} from "core/utils/getIconPath";
import {digitSeparator} from "core/utils/decimalExtentions";
import {isDesktop, isMobile} from "react-device-detect";
import {useEffect, useState} from "react";
import {PriceInfo} from "../../../../../core/domain/price-info/price-info";
import {getPriceInfos} from "../../../../../core/repositores/landing";
import {Chart} from "components/chart/chart";
import {LoadingOutlined} from "@ant-design/icons";

interface Props {}

export const SpotRates = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [prices, setPrices] = useState<PriceInfo[]>([]);
  const [count, setCount] = useState<number>(8);

  useEffect(() => {
    prices.length === 0 && getPriceInfos(setLoading, setPrices, count);
  }, [count]);

  return (
    <div className="pmx-spot-rates">
      <h2>نرخ لحظه ای رمزارز ها</h2>
      <div className="spot-rates-table">
        <div className="table-header">
          <div className="header-cell">نام رمز ارز</div>
          <div className="header-cell">قیمت دلاری</div>
          <div className="header-cell">قیمت خرید (تومان)</div>
          <div className="header-cell">قیمت فروش (تومان)</div>
          <div className="header-cell">تغییرات (24 ساعت)</div>
          <div className="header-cell">نمودار (24 ساعت)</div>
          <div className="header-cell">معامله</div>
        </div>
        <Divider className="header-divider" />
        {isDesktop && (
          <>
            {prices.map((currency) => (
              <div className="row" key={currency.id}>
                <div className="currency-name">
                  <img src={getIconPath(currency.symbol)} />
                  <div className="col">
                    <span>{currency.name}</span>
                    <span className="en">{currency.symbol}</span>
                  </div>
                </div>
                <div className="dollar-price">
                    {digitSeparator(currency.dollarPrice.toFixed(2))} دلار
                </div>
                <div className="sell-price">
                    {digitSeparator(currency.sellPrice.toFixed(0))} تومان
                </div>
                <div className="buy-price">
                    {digitSeparator(currency.buyPrice.toFixed(0))} تومان
                </div>
                <div
                  className={`changes ${
                    currency.changeOf24HourPercent > 0 ? "bullish" : "bearish"
                  }`}
                >
                  {currency.changeOf24HourPercent > 0 ? "↑" : "↓"}{" "}
                  ٪<span dir="ltr">{currency.changeOf24HourPercent}</span>
                </div>
                <div className="chart-data">
                  <Chart data={currency?.weeklyPriceLog} />
                </div>
                <div className="buttons">
                  <a
                    className="buy-button"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    خرید
                  </a>
                  <a
                    className="sell-button"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    فروش
                  </a>
                </div>
              </div>
            ))}
          </>
        )}
        {isMobile && (
          <>
            {prices.map((currency) => (
              <>
                <div className="mobile-row" key={currency.id}>
                  <div className="currency-name">
                    <img src={getIconPath(currency.symbol)} />
                    <div className="col">
                      <span>{currency.name}</span>
                      <span className="en">{currency.symbol}</span>
                    </div>
                  </div>
                  <div className="col end">
                      <span>{digitSeparator(currency.sellPrice)} دلار</span>
                    <span
                      className={`changes ${
                        currency.changeOf24HourPercent > 0
                          ? "bullish"
                          : "bearish"
                      }`}
                    >
                      {currency.changeOf24HourPercent > 0 ? "↑" : "↓"}{" "}
                  ٪<span dir="ltr">{currency.changeOf24HourPercent}</span>
                    </span>
                  </div>
                  <div className="col start">
                      <span>{digitSeparator(currency.sellPrice)} تومان</span>
                    <span>قیمت خرید</span>
                  </div>
                  <div className="col end">
                      <span>{digitSeparator(currency.buyPrice)} تومان</span>
                    <span>قیمت فروش</span>
                  </div>
                  <a
                    className="buy-button"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    خرید
                  </a>
                  <a
                    className="sell-button"
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    فروش
                  </a>
                </div>
                <Divider />
              </>
            ))}
          </>
        )}
      </div>
      <div
        className="more"
        onClick={() => {
          setCount((count) => (count += 5));
          getPriceInfos(setLoading, setPrices, count + 5);
        }}
      >
        {!loading ? "نمایش ۵ رمز ارز بیشتر" : <LoadingOutlined />}
      </div>
    </div>
  );
};
