const MENU = "MENU";
const SIDEBAR = "SIDEBAR";
const ORDERDATA = "ORDERDATA";
const PROFILE_DATA = "PROFILE_DATA";

const USER_DATA = "USERDATA";

const setMenuItem = (payload: number) => {
  return { type: MENU, payload: payload };
};

const setSidebar = (payload: "close" | "open") => {
  return { type: SIDEBAR, payload: payload };
};

const setProfileData = (payload: any) => {
  return { type: PROFILE_DATA, payload: payload };
};
const setUserData = (payload: any) => {
  return { type: USER_DATA, payload: payload };
};

const setOrderData = (payload: any) => {
  return { type: ORDERDATA, payload: payload };
};

export {
  MENU,
  setMenuItem,
  SIDEBAR,
  setSidebar,
  setProfileData,
  PROFILE_DATA,
  USER_DATA,
  setUserData,
  setOrderData,
  ORDERDATA,
};
