import './services.scss'
import { ReactComponent as SwapIcon } from 'assets/icons/home/service-swap.svg'
import { ReactComponent as ChatIcon } from 'assets/icons/home/service-chat.svg'
import { ReactComponent as AuthenticationIcon } from 'assets/icons/home/service-authentication.svg'

interface Props {

}

export const Services = (props: Props) => {
    return (
        <div className="pmx-services">
            <h3>خدمات ما</h3>
            <p>به کمک خدمات پی ام اکسچنج با آرامش خاطر خرید کنید.</p>
            <div className="services">
                <div className="service-info">
                    <div className="swap-icon"><SwapIcon /></div>
                    <h4>خرید و فروش آنی</h4>
                    <p>در کمترین زمان ممکن در پنل کاربری به خرید و فروش و یا به تبادل رمزارز ها ارز بپردازید.</p>
                    <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        خرید و فروش
                    </a>
                </div>
                <div className="service-info">
                    <div className="chat-icon"><ChatIcon /></div>
                    <h4>پشتیبانی 24 ساعته</h4>
                    <p>در هر ساعتی از شبانه روز با تماس با  پشتیبانی پی ام ایکس چنج مشکل خود را در سریع ترین زمان ممکن برطرف کنید.</p>
                    <a href='https://t.me/pmxsupport' target='_blank'>تماس با پشتیبانی</a>
                </div>
                <div className="service-info">
                    <div className="authentication-icon"><AuthenticationIcon /></div>
                    <h4>احراز هویت اتوماتیک</h4>
                    <p>در کمتر از یک دقیقه به صورت اتوماتیک و آنی احراز هویت شوید و به انجام معاملات خود بدون محدودیت بپردازید.</p>
                    <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>ثبت نام و احراز هویت</a>
                </div>
            </div>
        </div>
    )
}