import * as React from "react";
import {useEffect} from "react";
import "./navbar.scss";
import {ReactComponent as ChevronDown} from "assets/icons/dashboard/navbar/Chevron_Down.svg";
import {ReactComponent as ProfileIcon} from "assets/icons/dashboard/navbar/Profile.svg";
import {ReactComponent as Shop} from "assets/icons/dashboard/sidebar/Shopping_Cart.svg";
import {ReactComponent as Logout} from "assets/icons/dashboard/logout.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useOnBlur from "core/utils/useOnBlur";
import {SidebarItems} from "../../core/domain/commons/menu";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../core/redux/store";
import {Modal} from "components/modal/modal";
import {Button, buttonType} from "components/button/button";
import {ReactComponent as AuthenticationIcon} from 'assets/icons/dashboard/sidebar/Authentication.svg'
import {readProfile} from "core/repositores/profile";
import moment from "jalali-moment";
import {clearTokenFromLocalStorage} from "../../core/utils/setLocalStorageItems";
import {KEY_PHONE} from "../../core/constants/localStorageConstants";

const mapState = (state: RootState) => ({ state });

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

const dropdownItems = [
  { link: "/create-order", title: "خرید و فروش", icon: <Shop /> },
  {link: "/authentication", title: "احراز هویت", icon: <AuthenticationIcon/>},
  { title: "خروج", isLogout: true, icon: <Logout /> },
];

const Navbar: React.FC<Props> = (props) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
  const [menuTitle, setMenuTitle] = React.useState("");
  const [showLogoutModal, setShowLogoutModal] = React.useState(false);

  const navigate = useNavigate();

  const dropdownParentRef = React.useRef<HTMLDivElement>(null);

  const location = useLocation();
  const currentPage = location.pathname;

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogoutConfirm = () => {
    clearTokenFromLocalStorage()
    setShowLogoutModal(false);
    navigate("/login");
  };

  const handleLogoutCancel = () => {
    //
    setShowLogoutModal(false);
  };

  useEffect(() => {
    setMenuTitle(
      SidebarItems.filter((item) =>
        currentPage.split("/")[1].includes(item.link.split("/")[1]),
      )[0]?.title,
    );
  }, [currentPage]);
  useOnBlur(dropdownParentRef, () => setDropdownIsOpen(false));
  return (
    <nav className="pmx-navbar">
      <div className="navbar-welcome">
        <h4>
          {readProfile() ? readProfile()?.fullName !== " " && readProfile()?.fullName !== "" ? readProfile().fullName : readProfile()?.phoneNumber : localStorage.getItem(KEY_PHONE)} عزیز، خوش آمدی.
        </h4>
        <span>
        {moment().locale('fa').format('dddd jD jMMMM jYYYY، ساعت HH:mm')}        </span>
      </div>
      <div
        className="pmx-navbar-dropdown"
        onClick={() =>
          dropdownIsOpen ? setDropdownIsOpen(false) : setDropdownIsOpen(true)
        }
        ref={dropdownParentRef}
      >
        <ChevronDown />
        <p className="username">{readProfile()?.phoneNumber}</p>
        <ProfileIcon />
        <ul className="dropdown-content">
          {dropdownIsOpen ? (
            dropdownItems.map((item, index) => (
              <li className={`${item.isLogout ? "red" : ""}`} key={index}>
                {item.isLogout ? (
                  <a onClick={handleLogoutClick}>
                    {item.icon}
                    <span>{item.title}</span>
                  </a>
                ) : (
                  <Link to={item.link}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                )}
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
        <Modal
          title="آیا از خارج شدن از حسابتان اطمینان دارید؟"
          className="logout-modal"
          isOpen={showLogoutModal}
          setIsOpen={setShowLogoutModal}
        >
          <div className="buttons">
            <Button
              type={buttonType.primary}
              text="بله"
              onClick={handleLogoutConfirm}
            />
            <Button
              type={buttonType.error}
              text="خیر"
              onClick={handleLogoutCancel}
            />
          </div>
        </Modal>
      </div>
    </nav>
  );
};
export default connector(Navbar);
