import Navbar from 'routes/pages/home/components/navbar/navbar';
import './landing.scss'
import aboutbanner from 'assets/images/about/banner-about-desktop.png'
import aboutbannermobile from 'assets/images/about/banner-about.png'
import { isDesktop, isMobile } from 'react-device-detect';

interface Props {

}

export const AboutLanding = (props: Props) => {
    return (
        <div className="pmx-landing-about">
            <Navbar />
            {isDesktop && <img src={aboutbanner} id='about-banner' />}
            {isMobile && <img src={aboutbannermobile} id='about-banner' />}
        </div>  
    )
}