import {Dispatch, SetStateAction} from "react";
import {getViaAuth, postViaAuth, put, responseValidator} from "../apis/api";
import {API} from "../apis";
import {AddUserIBanBody, EditUserIBanBody, RemoveUserIBanBody} from "../domain/user-saved-ibans/user-saved-ibans";

export const addIBan = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: AddUserIBanBody,
    onComplete: () => void
) => {
    setLoading(true)
    await postViaAuth(API.userSavedIBans.add, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const getMyIBans = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: any,
    onComplete: (e: any) => void
)=> {
    setLoading(true)
    await getViaAuth(API.userSavedIBans.getMine).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setData(response.data)
            onComplete(response.data)
        }
    })
}

export const removeIBan = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: RemoveUserIBanBody,
    onComplete: () => void
)=> {
    setLoading(true)
    await postViaAuth(API.userSavedIBans.remove, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const editIBan = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: EditUserIBanBody,
    onComplete: () => void
) => {
    setLoading(true)
    await put(API.userSavedIBans.edit, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}
