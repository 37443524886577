import * as React from "react";
import {useEffect, useState} from "react";
import "./authentication.scss";
import {Badge, BadgeType, Card} from "components";
import {ReactComponent as Checked} from "assets/icons/authentication/checked-green.svg";
import {AuthForm} from "./auth-form/auth-form";
// import goldMedal from "../../../../assets/images/authentication/goldmedal.jpg";
import bronze from "assets/images/authentication/bronze.png";
import silver from "assets/images/authentication/silver.png";
import gold from "assets/images/authentication/gold.png";
import {Page} from "../../../../components/page/page";
import {RootState} from "../../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {digitSeparator, faNumToEnNum} from "core/utils/decimalExtentions";
import {authenticateUser, getLevelInfo,} from "../../../../core/repositores/authentication";
import {LevelInfo} from "../../../../core/domain/authentication/authentication";
import {getUserProfile, readProfile,} from "../../../../core/repositores/profile";
import {AuthenticationSkeleton} from "./authentication-skeleton/authentication-skeleton";
import {Divider} from "antd";
import moment from "jalali-moment";
import toast from "react-hot-toast";

const mapState = (state: RootState) => ({ state });

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

const Authentication: React.FC<Props> = ({ state }) => {
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [levelInfo, setLevelInfo] = useState<LevelInfo>();
  const dispatch = useDispatch();

  const onUpgradeLevel = async (data, setLoading) => {
    const body = {
      ...data,
      birthDate: moment(faNumToEnNum(data.birthDate), "jYYYY/jMM/jDD").format("YYYY/MM/DD")
    };
    await authenticateUser(setLoading, body, () => toast.success("سطح شما با موفقیت به نقره ای ارتقا یافت."));
    await getUserProfile(setLoading);
  };

  const fetchData = async () => {
    await getLevelInfo(
      setPageIsLoading,
      setLevelInfo,
      readProfile()?.level == "برنزی"
        ? 1
        : readProfile()?.level == "نقره ای"
          ? 2
          : 3,
    );
  };

  useEffect(() => {
    readProfile()?.level && fetchData();
  }, []);

  return (
    <Page className="pmx-authentication">
      <AuthForm level={readProfile()?.level} onSubmit={onUpgradeLevel} />
      <Card title='سطوح کاربری' className="helper-side" divider>
        <div className="medals">
          <div className="medal-info">
            <img src={bronze} />
            <span>سطح برنزی</span>
            <Badge type={BadgeType.success} text="ارتقا یافته" />
          </div>
          <Divider />
          <div className="medal-info">
            <img src={silver} />
            <span>سطح نقره ای</span>
            {(readProfile()?.level === 'نقره ای' || readProfile()?.level === 'طلایی') &&
              <Badge type={BadgeType.success} text="ارتقا یافته" />
            }
            {readProfile()?.level === 'برنزی' &&
              <Badge type={BadgeType.waiting} text="در انتظار 1 سطح ارتقا" />
            }
          </div>
          <Divider />
          <div className="medal-info">
            <img src={gold} />
            <span>سطح طلایی</span>
            {readProfile()?.level === 'طلایی' &&
              <Badge type={BadgeType.success} text="ارتقا یافته" />
            }
            {readProfile()?.level === 'نقره ای' &&
              <Badge type={BadgeType.waiting} text="در انتظار 1 سطح ارتقا" />
            }
            {readProfile()?.level === 'برنزی' &&
              <Badge type={BadgeType.error} text="در انتظار 2 سطح ارتقا" />
            }
          </div>
        </div>
        <Divider className="card-divider" />
        {readProfile()?.level == "طلایی" && (
          <Card className="completed mobile-show">
            {/* <img alt="goldmedal" src={goldMedal} /> */}
            <div>
              <h4>پی ام ایکسچنج به شما افتخار میکند.</h4>
              <p>شما اکنون عضو مهمی از خانواده پی ام ایکسچنج هستید</p>
            </div>
          </Card>
        )}
        {pageIsLoading ? (
          <AuthenticationSkeleton />
        ) : (
          <Card
            className="pros"
            loading={pageIsLoading}
          >
            <div className="head-row">
              <h4>مزایای سطح {readProfile()?.level}</h4>
              <div>
                <span>سقف واریز ریالی:</span>
                  <p>{digitSeparator(levelInfo?.irtDepositLimitInDay)} تومان</p>
              </div>
            </div>
            <p>{levelInfo?.description}</p>
          </Card>
        )}
        {readProfile()?.level === 'برنزی' &&
          <Card title='نکات مهم در ارتقا سطح' className="notes" divider>
            <ul>
              <li>
                <Checked />
                <p>کدملی وارد شده می بایست مالک شماره تماس باشد.</p>
              </li>
              <li>
                <Checked />
                <p>نام و نام خانوادگی خود را به فارسی و کامل وارد نمایید.</p>
              </li>
              <li>
                <Checked />
                <p>آدرس وارد شده آدرس محل سکونت یا محل کارتان باشد!</p>
              </li>
            </ul>
          </Card>
        }
      </Card>
    </Page>
  );
};

export default connector(Authentication);
