import * as React from "react";
import "./modal.scss";
import ReactModal from "react-modal";
import {ReactComponent as CloseIcon} from "assets/icons/Close.svg";

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  onClose?: () => void;
  noHead?: boolean
}

const customStyles = {
  backdropFilter: "blur(2.5px);",
  background: "rgba(18, 18, 18, 0.05);",
};

export const Modal: React.FC<Props> = (props) => {
  const onCloseHandle = () => {
    props.setIsOpen(false);
    props.onClose && props.onClose();
  };
  return (
    <ReactModal
        shouldFocusAfterRender={true}
      onAfterClose={onCloseHandle}
      onRequestClose={onCloseHandle}
      isOpen={props.isOpen}
      className={`pmx-modal ${props.className ? props.className : ""}`}
      shouldCloseOnEsc
      style={customStyles}
    >
      {!props.noHead &&
      <div className={`modal-head`}>
        {props.title && <h4>{props.title}</h4>}
        <CloseIcon className="close" onClick={onCloseHandle} />
      </div>
      }

      {props.children}
    </ReactModal>
  );
};
