import './contact-us.scss'
import { ReactComponent as CallIcon } from 'assets/icons/about/call.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/about/email.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/about/telegram.svg'
import { ReactComponent as LocationIcon } from 'assets/icons/about/location.svg'
import { ReactComponent as ChatIcon } from 'assets/icons/about/chat.svg'

interface Props {

}

export const ContactUs = (props: Props) => {
    return (
        <div className="pmx-contact-us">
            <h3>با ما در تماس باشید</h3>
            <p>تیم پشتیبانی پی ام اکسچنج به صورت ۲۴ ساعته در خدمت شماست تا مشکلات شما را در سریع ترین زمان و ساده ترین شکل ممکن حل کند!</p>
            <section className="communication">
                <div className="communication-item">
                    <div className="call-icon">
                        <CallIcon />
                    </div>
                    <h4>تماس با پشتیبانی</h4>
                    <a href='tel:+982128428299' dir='ltr'>۰۲۱ - ۲۸۴۲ ۸۲۹۹</a>
                </div>
                <div className="communication-item">
                    <div className="email-icon">
                        <EmailIcon />
                    </div>
                    <h4>ایمیل به پشتیبانی</h4>
                    <a href='mailto:pmxchang@gmail.com'>Pmxchang@gmail.com</a>
                </div>
                <div className="communication-item">
                    <div className="telegram-icon">
                        <TelegramIcon />
                    </div>
                    <h4>اکانت پشتیبانی تلگرام</h4>
                    <a href='https://t.me/pmxsupport' target='_blank'>@PmxSupport</a>
                </div>
                <div className="communication-item">
                    <div className="location-icon">
                        <LocationIcon />
                    </div>
                    <h4>آدرس دفتر</h4>
                    <a>گرگانپارس، شهریور غربی، پلاک ۱۷</a>
                </div>
                <div className="communication-item">
                    <div className="chat-icon">
                        <ChatIcon />
                    </div>
                    <h4>چت با پشتیبانی</h4>
                    <a href='https://t.me/pmxsupport' target='_blank'>اینجا کلیک کنید</a>
                </div>
            </section>
        </div>
    )
}