import * as React from "react";
import "./input-material.scss";
import { PmxComponent } from "core/components/PmxComponenet";
import flag from "assets/icons/login/ir-logo.png";
import { ReactComponent as EyeOpen } from "../../assets/icons/input/Eye_Open.svg";
import { ReactComponent as EyeHidden } from "../../assets/icons/input/Eye_Hide.svg";

export enum inputMaterialType {
  "text",
  "passowrd",
}

interface Props extends PmxComponent {
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  icon?: React.ReactNode;
  placeholder: string;
  htmlType?: any;
  maxLength?: number;
  type?: inputMaterialType;
  onPressEnter?: () => void;
  required?: boolean;
  label?: string;
  name?: string;
  hasCountryCode?: boolean;
  dir?: "rtl" | "ltr";
}

export const InputMaterial: React.FC<Props> = (props) => {
  const isPasswordType = props.type === inputMaterialType.passowrd;

  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={`pmx-input-material ${props.className ?? ""}`}>
      {props.label && (
        <label htmlFor={props.className || ""}>{props.label}</label>
      )}
      <input
        type={
          props.htmlType
            ? props.htmlType
            : props.type === inputMaterialType.passowrd
            ? !passwordVisible
              ? "password"
              : "text"
            : "text"
        }
        value={props.value}
        onKeyUp={(event) =>
          event.key === "Enter" &&
          props.onPressEnter &&
          !props.disabled &&
          !props.disabled &&
          props.onPressEnter()
        }
        onChange={props.onChange}
        maxLength={props.maxLength}
        required={props.required}
        placeholder={props.placeholder || ""}
        dir={props.dir || "rtl"}
      />
      {isPasswordType || props.icon ? (
        <div className="adornments">
          {isPasswordType && (
            <span
              className="password-toggle"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <EyeOpen /> : <EyeHidden />}
            </span>
          )}
        </div>
      ) : null}
      {props.hasCountryCode && (
        <div className="country">
          <span>+98</span>
          <img src={"/assets/coins/irt.svg"} alt={"iran"} />
        </div>
      )}
    </div>
  );
};
