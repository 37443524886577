import {get, responseValidator} from "../apis/api";
import {API} from "../apis";
import {Dispatch, SetStateAction} from "react";

export const getCategoriesByTopicEnName = (
    setLoading: Dispatch<SetStateAction<boolean>>,
    topicEnName: string,
    setCategories: Dispatch<SetStateAction<any>>,
) => {
    setLoading(true)
    get(`${API.content.getCategoriesByTopicEnName}/${topicEnName}`).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setCategories(response.data.data)
        }
    })
}

export const getContentByTopicEnName = (
    setLoading: Dispatch<SetStateAction<boolean>>,
    topicEnName: string,
    setCategories: Dispatch<SetStateAction<any>>,
) => {
    setLoading(true)
    get(`${API.content.getContentByTopicEnName}/${topicEnName}`).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setCategories(response.data.data)
        }
    })
}

export const getContentByCategoryEnName = (
    setLoading: Dispatch<SetStateAction<boolean>>,
    categoryEnName: string,
    setCategoryContent: Dispatch<SetStateAction<any>>,
    onComplete: () => void
) => {
    setLoading(true)
    get(`${API.content.getContentByCategoryEnName}/${categoryEnName}`).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setCategoryContent(response.data.data)
            onComplete()
        }
    })
}

export const getContentByEnName = (
    setLoading: Dispatch<SetStateAction<boolean>>,
    contentEnName: string,
    setCurrentContent: Dispatch<SetStateAction<any>>,
    onComplete: () => void
) => {
    setLoading(true)
    get(`${API.content.getContentByEnglishTitle}/${contentEnName}`).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setCurrentContent(response.data.data)
            onComplete()
        }
    })
}
