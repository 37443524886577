import { Divider } from 'antd';
import './order-item-skeleton.scss';

export const OrderItemSkeleton = () => {
    return (
        <div className="order-item-skeleton">
            <div className="head-skeleton">
                <div className="pair-skeleton">
                    <div className="title-skeleton">
                    </div>
                    <div className="icons-skeleton">
                        {/* <div className='img-skeleton'
                        />
                        <div className='img-skeleton'
                        /> */}
                    </div>
                </div>
                <div className='badge-skeleton' />
            </div>
            <Divider />
            <div className="data-skeleton">
                <div className="col-skeleton">
                    <div>
                        <span></span>
                        <p>

                        </p>
                    </div>
                    <div>
                        <span></span>
                        <p>

                        </p>
                    </div>
                </div>
                <div className="col-skeleton">
                    <div>
                        <span></span>
                        <p className="text-overflow en"></p>
                    </div>
                    <div>
                        <span></span>
                        <p></p>
                    </div>
                </div>
                <div className="col-skeleton">
                    <div>
                        <span></span>
                        <p>
                        </p>
                    </div>
                    <section
                        className='details-button-skeleton'
                    />
                </div>
            </div>
        </div>
    )
}