import "./navigation.scss";
import {ReactComponent as CloseIcon} from "assets/icons/navigation/close.svg";
import {ReactComponent as LeftArrow} from "assets/icons/navigation/arrow-left.svg";
import {ReactComponent as InstagramIcon} from "assets/icons/navigation/instagram-blue.svg";
import {ReactComponent as TelegramIcon} from "assets/icons/navigation/telegram-blue.svg";
import {ReactComponent as TwitterIcon} from "assets/icons/navigation/twitter-blue.svg";
import {Divider} from "antd";
import {Link} from "react-router-dom";
import {KEY_PROFILE_DATA} from "../../../core/constants/localStorageConstants";
import {setOrderData} from "../../../core/redux/actions";
import {OrderCardStages} from "../../../core/domain/landing/landing";
import {RootState} from "../../../core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

export const Navigation = (props: Props) => {
  const dispatch = useDispatch();
  return (
    <div className={`pmx-navigation ${props.isOpen ? "active" : ""}`}>
      <h3>
        منو{" "}
        <div className="close-icon">
          <CloseIcon onClick={() => props.setIsOpen(false)} />
        </div>
      </h3>
      <Divider />
      <div className="buttons">
        <Link
          to="/tracking"
          onClick={() => {
              dispatch(
                  setOrderData({
                    ...props.state.orderData,
                    stage: OrderCardStages.TRACKING_ORDER,
                  }),
                )
            props.setIsOpen(false);
          }}
        >
          پیگیری سفارش
        </Link>
        <Link
          to={`${localStorage.getItem(KEY_PROFILE_DATA) ? "/dashboard" : ""}`}
          onClick={() => {
            !localStorage.getItem(KEY_PROFILE_DATA) &&
              dispatch(
                setOrderData({
                  ...props.state.orderData,
                  stage: OrderCardStages.ENTER_PHONE,
                }),
              );
            props.setIsOpen(false);
          }}
        >
          {localStorage.getItem(KEY_PROFILE_DATA)
            ? "پنل کاربری"
            : "ورود یا ثبت نام"}
        </Link>
      </div>
      <Divider />
      <ul>
        <li>
          <Link
            to="/"
            onClick={() => {
              dispatch(
                setOrderData({
                  ...props.state.orderData,
                  stage: OrderCardStages.CALCULATOR,
                }),
              );
              props.setIsOpen(false);
            }}
          >
            صفحه اصلی <LeftArrow />
          </Link>
        </li>
        <li>
          <a href="https://pmxchange.co/blog/">
            وبلاگ <LeftArrow />
          </a>
        </li>
        <li>
          <Link to="/help">
            راهنما و آموزش <LeftArrow />
          </Link>
        </li>
        <li>
          <Link to="/faq">
            سوالات پر تکرار <LeftArrow />
          </Link>
        </li>
        <li>
          <Link to="/about">
            درباره ما <LeftArrow />
          </Link>
        </li>
        <li>
          <Link to="/invite">
            کسب درآمد <LeftArrow />
          </Link>
        </li>
        <li>
          <Link to="/terms">
            شرایط و قوانین <LeftArrow />
          </Link>
        </li>
      </ul>
      <Divider />
      <div className="socials">
        <h4>شبکه های اجتماعی </h4>
        <div className="social-items">
          <InstagramIcon
            onClick={() =>
              window.open("https://instagram.com/pmxchange", "_blank")
            }
          />
          <TelegramIcon
            onClick={() => window.open("https://t.me/PMxchange_bot", "_blank")}
          />

          <TwitterIcon onClick={() => window.open("https://twitter.com/PMxchangee", "_blank")} />
        </div>
      </div>
    </div>
  );
};

export default connector(Navigation);
