import "./footer.scss";
import pmxfooter from "assets/images/home/pmx-footer.png";
import {ReactComponent as CallIcon} from "assets/icons/home/call.svg";
import {ReactComponent as ChatIcon} from "assets/icons/home/chat-footer.svg";
import {ReactComponent as EmailIcon} from "assets/icons/home/email.svg";
import {ReactComponent as TelegramIcon} from "assets/icons/home/telegram.svg";
import {ReactComponent as LocationIcon} from "assets/icons/home/location.svg";
import {ReactComponent as LeftArrow} from "assets/icons/home/left-arrow-circled.svg";
import {ReactComponent as InstagramIcon} from "assets/icons/home/instagram.svg";
import {ReactComponent as TelegramSocialIcon} from "assets/icons/home/telegram-social.svg";
import {ReactComponent as TwitterIcon} from "assets/icons/home/twitter.svg";
import {Input} from "antd";
import {useNavigate} from "react-router-dom";
import {VERSION} from "../../../../../core/constants/constants";

interface Props {}

export const Footer = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className="pmx-footer">
      <div className="main">
        <div className="about-us">
          <img
            src={pmxfooter}
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          />
          <h4>درباره ما</h4>
          <p>
            پی ام اکسچنج را شما انتخاب کنید، سرعت و امنیت را ما هدیه می‌کنیم.
            کارآفرینی و افزایش منابع درآمد داخلی و حس دلنشین کسب درآمد در قالب
            پول الکترونیک و ارز دیجیتال را با ما تجربه کنید. پی ام اکسچنج هم‌پای
            همیشگی کاربران بوده و بدون محدودیتهای بین‌المللی، سرویس‌دهنده
            فعالیت‌های ارزی شما خواهد بود.
          </p>
          {/*<div className="badges">*/}
          {/*  <img src={fintech} />*/}
          {/*  <img src={computer} />*/}
          {/*  <img src={ecommerce} />*/}
          {/*</div>*/}
        </div>

        <div className="info-list">
          <ul>
            <h4>دسترسی سریع</h4>
            <li>
              <a
                href={"/"}
              >
                ثبت نام
              </a>
            </li>
            <li>
              <a
                  href={"/"}
              >
                خرید و فروش
              </a>
            </li>
            <li>
              <a href="/about">درباره ما</a>
            </li>
            <li>
              <a href="tel:+982128428299">تماس با ما</a>
            </li>
            <li>
              <a href="/terms">شرایط و قوانین</a>
            </li>
            <li>
              <a href="/referral">دعوت دوستان</a>
            </li>
          </ul>
          <ul>
            <h4>راهنما و آموزش</h4>
            <li>
              <a href="/help">ثبت نام</a>
            </li>
            <li>
              <a href="/help">احراز هویت</a>
            </li>
            <li>
              <a href="/help">خرید و فروش</a>
            </li>
            <li>
              <a href="/help">واریز و برداشت</a>
            </li>
            <li>
              <a href="/help">معامله ی رمز ارز ها</a>
            </li>
            <li>
              <a href="/help">کسب درآمد</a>
            </li>
          </ul>
          <ul>
            <h4>منابع</h4>
            <li>
              <a href="https://pmxchange.co/blog">وبلاگ</a>
            </li>
            <li>
              <a href="#">دانلود اپلیکیشن</a>
            </li>
            <li>
              <a href="https://t.me/PMxchange_bot">اتصال به ربات</a>
            </li>
            <li>
              <a href="#">کارمزد معاملات</a>
            </li>
            <li>
              <a href="/faq">سوالات پرتکرار</a>
            </li>
            <li>
              <a href="#">نقشه سایت</a>
            </li>
          </ul>
          <ul>
            <h4>راه های تماس</h4>
            <li>
              <a href="tel:+982128428299">
                <CallIcon />
                021-28428299
              </a>
            </li>
            <li>
              <a
                href="https://t.me/pmxsupport"
                target="_blank"
                rel="noreferrer"
              >
                <ChatIcon />
                پشتیبانی آنلاین
              </a>
            </li>
            <li>
              <a className="en" href="mailto:pmxchang@gmail.com">
                <EmailIcon />
                Pmxchang@Gmail.Com
              </a>
            </li>
            <li>
              <a
                className="en"
                href="https://t.me/pmxsupport"
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon />
                @PmxSupport
              </a>
            </li>
            <li className={"address"}>
              <a>
                <LocationIcon />
                گرگانپارس، شهریور غربی، پلاک 17
              </a>
            </li>
          </ul>
        </div>
        <img src={pmxfooter} id="mobile-logo" onClick={() => navigate("/")} />
      </div>
      <hr />
      <div className="newsletter">
        <p>جهت دریافت جدیدترین اخبار و تخفیفات در خبرنامه ما عضو شوید.</p>
        <Input
          placeholder="ایمیل خود را وارد کنید..."
          dir="rtl"
          type="email"
          suffix={<LeftArrow />}
        />
      </div>
      <hr />
      <div className="copyright">
        <div className="socials">
          <a
            href="https://www.instagram.com/pmxchange"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a href="https://t.me/pmxchangee" target="_blank" rel="noreferrer">
            <TelegramSocialIcon />
          </a>
          <a
            href="https://twitter.com/PMxchangee"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>
        <p>
          تمامی حقوق مادی و معنوی برای مجموعه <span>شرکت آسان معامله پیام</span> محفوظ
          است.
          <span>
   {VERSION}
  </span>
        </p>

      </div>
    </div>
  );
};
