import "./navbar.scss";
import logo from "assets/images/home/pmx-logo.png";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as ContactIcon} from "assets/icons/home/contact.svg";
import {ReactComponent as NavbarLogo} from "assets/icons/home/pmx-navbar-logo.svg";
import {ReactComponent as HamburgerIcon} from "assets/icons/home/hamburger.svg";
import {ReactComponent as PersonIcon} from "assets/icons/home/person.svg";
import {ReactComponent as CallIcon} from "assets/icons/home/call-navbar.svg";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "core/redux/actions";
import {RootState} from "core/redux/store";
import {FC, useState} from "react";
import {OrderCardStages} from "core/domain/landing/landing";
import Navigation from "../../../navigation/navigation";
import {isLogin} from "../../../../../core/apis/api";
import {SearchOutlined} from "@ant-design/icons"

interface Props {
}

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
}

const Navbar: FC<Props> = ({state}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const NavigationItems = [
        {
            id: 0,
            title: "صفحه اصلی",
            link: "/",
            dispatch: () =>
                dispatch(
                    setOrderData({
                        ...state.orderData,
                        stage: OrderCardStages.CALCULATOR,
                    }),
                ),
        },
        {
            id: 1,
            title: "وبلاگ",
            link: "https://pmxchange.co/blog/",
        },
        {
            id: 2,
            title: "راهنما و آموزش",
            link: "/help",
        },
        {
            id: 3,
            title: "کسب درآمد",
            link: "/invite",
        },
    ];

    const handleProfileClick = () => {
        if (isLogin()) {
            navigate('/dashboard')
        } else {
            if (location.pathname === "/") {
                dispatch(setOrderData({...state.orderData, stage: OrderCardStages.ENTER_PHONE}))
            } else {
                navigate('/login')
            }
        }
    }

    return (
        <>
            <Navigation isOpen={menuIsOpen} setIsOpen={setMenuIsOpen}/>
            <div className="pmx-navbar-home">
                <Link to={"/"} onClick={() => dispatch(
                    setOrderData({
                        ...state.orderData,
                        stage: OrderCardStages.CALCULATOR,
                    }),
                )}>
                <img src={logo} alt="PMX" onClick={() => {
                    if (location.pathname === "/") {
                        dispatch(setOrderData({...state.orderData, stage: OrderCardStages.CALCULATOR}))
                    } else {
                        navigate("/")
                    }
                }}/>
                </Link>
                <hr/>
                <ul className="navigation">
                    {NavigationItems.map((item) => (
                        <li key={item.id}>
                            <Link
                                to={item.link}
                                target={item.link === "https://pmxchange.co/blog/" ? "_blank" : ""}
                                className={`${item.link === location.pathname ? "active" : ""}`}
                                onClick={item?.dispatch}
                            >
                                {item.title}
                                <span/>
                            </Link>
                        </li>
                    ))}
                </ul>
                <ul className="actions">
                    <a className={"phone"} href="tel:+982128428299">
                        <ContactIcon/>
                        <div className="col">
                            <span>تماس با پشتیبانی</span>
                            <span>۰۲۱ - ۲۸۴۲ ۸۲۹۹</span>
                        </div>
                    </a>
                    <a
                        onClick={() => {
                            if (location.pathname === '/' || location.pathname.startsWith('/tracking')) {
                                dispatch(
                                    setOrderData({
                                        ...state.orderData,
                                        stage: OrderCardStages.TRACKING_ORDER,
                                    }),
                                )

                            } else {
                                navigate('/tracking')
                            }
                        }
                        }
                    >
                        پیگیری سفارش
                    </a>
                    <a
                        onClick={handleProfileClick}
                    >
                        {isLogin()
                            ? "پنل کاربری"
                            : "ورود یا ثبت نام"}
                    </a>
                </ul>
            </div>
            <div className="pmx-mobile-navbar-home">
                <Link to="" onClick={() => setMenuIsOpen(true)}>
                    <HamburgerIcon/>
                </Link>
                <NavbarLogo className="pmx-navbar-logo" onClick={() => {
                    if (location.pathname === "/") {
                        dispatch(setOrderData({...state.orderData, stage: OrderCardStages.CALCULATOR}))
                    } else {
                        navigate("/")
                    }
                }}/>
                <div className="actions">
                    <SearchOutlined onClick={() => {
                        if (location.pathname === "/" || location.pathname.startsWith("/tracking"))
                            dispatch(setOrderData({
                                ...state.orderData,
                                stage: OrderCardStages.TRACKING_ORDER,
                                tour: false
                            }))
                        else navigate("/tracking")
                    }}/>
                    <PersonIcon
                        onClick={handleProfileClick}
                    />
                    <CallIcon onClick={() => window.open("tel:+982128428299", "_self")}/>
                </div>
            </div>
        </>
    );
};

export default connector(Navbar);
