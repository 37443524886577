import "./enter-voucher.scss";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setOrderData} from "core/redux/actions";
import {OrderCardStages, Side} from "core/domain/landing/landing";
import {FC, useRef, useState} from "react";
import {RootState} from "../../../../../../../core/redux/store";
import {Checkbox, ConfigProvider, Tour, TourProps,} from "antd";
import {postViaAuth, responseValidator} from "core/apis/api";
import {__API} from "core/apis/api-routes";
import OrderCardButtons from "../../order-card-buttons/order-card-buttons";
import {AntdInput} from "../../../../../../../components/antd-custom-input/antd-custom-input";
import fa_IR from "antd/lib/locale/fa_IR";
import {useNavigate} from "react-router-dom";
import {IsPersianChar} from "../../../../../../../core/utils/stringExtentions";
import toast from "react-hot-toast";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux { }

const EnterVoucher: FC<Props> = ({ state }) => {
  const orderData = state?.orderData;
  const [voucher, setVoucer] = useState("");
  const [voucherActivation, setVoucerActivation] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false);

  const tourSteps: TourProps["steps"] = [
    {
      title: "کد ووچر",
      description: "کد 10 رقمی ووچر را در این بخش وارد نمایید.",
      target: () => tour1.current,
    },
    {
      title: "کد فعالساز ووچر",
      description: "کد 16 رقمی فعالساز ووچر را در این بخش وارد نمایید.",
      target: () => tour2.current,
    },
    {
      title: "چکباکس",
      description:
        "لطفاً به این بخش توجه نموده و در صورت موافقت، چِکباکس را علامت بزنید.",
      target: () => tour3.current,
    },
    {
      title: "ادامه",
      description:
        "در صورتی که کد ووچر و کد فعالساز ووچر را وارد کرده اید و تیکِ چکباکس را علامت زده اید، برای ادامه یا بازگشت از این بخش اقدام نمایید.",
      target: () => tour4.current,
    },
  ];

  const navigate = useNavigate();

  const tour1 = useRef(null);
  const tour2 = useRef(null);
  const tour3 = useRef(null);
  const tour4 = useRef(null);

  const handleSubmit = async () => {
    setButtonIsLoading(true);
    const body = {
      voucherCode: voucher,
      voucherActivationCode: voucherActivation,
      trackingCode: orderData?.trackingCode,
    };
    await postViaAuth(__API.orders.pmDeposit, body).then((response: any) => {
      setButtonIsLoading(false);

      if (responseValidator(response.status)) {
        toast.success("در حال انتقال به صفحه نتیجه...");
        window.open(`/tracking/${orderData?.trackingCode}`, "_self");
      }
    });
  };

  const dispatch = useDispatch();

  const onEnFieldChange = (value: string, setField: any) => {
    if (
      value !== "" &&
      IsPersianChar(value.split("")[value.split("").length - 1])
    )
      return;

    setField(value);
  };
  const onBackClick = () =>
    state?.orderData?.side === Side.Swap
      ? dispatch(
        setOrderData({
          ...state.orderData,
          stage: OrderCardStages.ENTER_WALLET,
          phone: null,
          destinationWallet: null,
          network: null,
          tour: false,
        }),
      )
      : dispatch(
        setOrderData({
          ...state.orderData,
          stage: OrderCardStages.RECEIVE_METHOD,
          phone: null,
          destinationWallet: null,
          network: null,
          tour: false,
        }),
      );

  return (
    <div className="pmx-enter-voucher-stage">
      <>
        <h3>واریز از مبدا</h3>
        <p className={"description"}>
          لطفا مقدار {state.orderData.sourceAmount} ووچر پرفکت مانی را وارد نمایید.
        </p>

        <div ref={tour1}>
          <AntdInput
            placeholder={"کد ووچر"}
            value={voucher}
            type={"voucher"}
            maxLength={10}
            minLength={10}
            onChange={(e) => onEnFieldChange(e.target.value, setVoucer)}
          />
        </div>

        <div ref={tour2}>
          <AntdInput
            maxLength={16}
            minLength={16}
            placeholder={"کد فعالساز ووچر"}
            value={voucherActivation}
            type={"voucher-activation"}
            onChange={(e) =>
              onEnFieldChange(e.target.value, setVoucerActivation)
            }
          />
        </div>

        <div className={"checkbox"} ref={tour3}>
          <Checkbox
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <p>
            چنانچه ارزش واقعی ووچر پرفکت مانی شما با مقداری که در مرحله قبل وارد
            شده است متفاوت باشد، ارزش واقعی ووچر برای پرداخت محاسبه خواهد شد.
          </p>
        </div>

        <div ref={tour4}>
          <OrderCardButtons
            submitButton={{
              text: "ادامه",
              loading: buttonIsLoading,
              disabled:
                buttonIsLoading || !voucherActivation || !voucher || !isChecked || voucher.length !== 10 || voucherActivation.length !== 16,
              onClick: handleSubmit,
            }}
            cancelButton={{
              text: "بازگشت",
              onClick: () => onBackClick(),
            }}
          />
        </div>
        <ConfigProvider locale={fa_IR} direction={"rtl"}>
          <Tour
            steps={tourSteps}
            open={state?.orderData?.tour}
            onClose={() =>
              dispatch(setOrderData({ ...state.orderData, tour: false }))
            }
          />
        </ConfigProvider>
      </>
    </div>
  );
};

export default connector(EnterVoucher);
