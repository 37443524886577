import * as React from "react";
import {useEffect} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";
import slider1 from "assets/images/login/buy-sell.png";
import {ReactComponent as ArrowRight} from "assets/icons/login/Arrow_Right.svg";

import "./login.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {KEY_PHONE} from "core/constants/localStorageConstants";

interface Props {
  children: React.ReactNode;
}

export const Login: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/login/";

  useEffect(() => {
    !localStorage.getItem(KEY_PHONE) && navigate("/login");
  }, []);

  return (
    <div className="pmx-login">
      <div className="slider-container">
        <Swiper
          className="slider"
          modules={[Pagination, Autoplay]}
          autoplay
          slidesPerView={1}
          spaceBetween={100}
          centeredSlides
          centeredSlidesBounds
          centerInsufficientSlides
        >
          <SwiperSlide key={"1"}>
            <div className="card">
              <img alt='slider' src={slider1}/>

              <span>خرید و فروش</span>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
                استفاده از طراحان گرافیک اس و در زبان فارسی طراحی اساسا مورد
                استفاده قرار گیرد.
              </p>
            </div>
          </SwiperSlide>

          <ArrowRight
            className={`${isLoginPage ? "disabled" : ""}`}
            onClick={() => (isLoginPage ? null : navigate(-1))}
          />
        </Swiper>
      </div>
      {children}
    </div>
  );
};
