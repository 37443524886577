import * as React from "react";
import "./dashboard.scss";
import Sidebar from "components/sidebar/sidebar";
import { mobileMenuItems, SidebarItems } from "core/domain/commons/menu";
import Navbar from "components/navbar/navbar";
import MobileMenu from "components/mobile-menu/mobile-menu";

import { connect, ConnectedProps, useDispatch } from "react-redux";
import { RootState } from "../../../core/redux/store";
import { setOrderData, setProfileData } from "../../../core/redux/actions";
import { Profile } from "../../../core/domain";
import { isLogin } from "../../../core/apis/api";
import { useNavigate } from "react-router-dom";
import { KEY_PROFILE_DATA } from "core/constants/localStorageConstants";
import { OrderCardStages } from "core/domain/landing/landing";
import {getUserProfile} from "../../../core/repositores/profile";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  children: React.ReactNode;
}

export const Dashboard: React.FC<Props> = ({ children, state }) => {
  const [pageIsLoading, setPageIsLoading] = React.useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onSetProfileData = (data: Profile) => {
    dispatch(setProfileData(data));
  };

  React.useEffect(() => {
    !localStorage.getItem(KEY_PROFILE_DATA) && getUserProfile(setPageIsLoading)
  }, []);

  return (
    <div className="pmx-dashboard">
      <Sidebar items={SidebarItems} />
      <div className="panel">
        <Navbar />
        <main>{children}</main>
      </div>
      <MobileMenu items={mobileMenuItems} />
    </div>
  );
};
export default connector(Dashboard);
