import React, { useState } from "react";
import "./pagination.scss";
import { ReactComponent as ChevronRight } from "../../assets/icons/pagination/ChevronRight.svg";
import { ReactComponent as ChevronLeft } from "../../assets/icons/pagination/ChevronLeft.svg";
import { Button, buttonType } from "../button/button";

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageNumberClick: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageNumberClick,
}) => {
  const [totalPage, setTotalPage] = useState<number>(0);
  const [minPageLimit, setMinPageLimit] = useState<number>(0);
  const [maxPageLimit, setMaxPageLimit] = useState<number>(0);

  React.useEffect(() => {
    setTotalPage(Math.ceil(totalItems / itemsPerPage));
  }, []);

  const onNextButtonClick = () => {
    if (currentPage < totalPage) {
      onPageNumberClick(currentPage + 1);
    }
  };

  const onPrevButtonClick = () => {
    if (currentPage > 1) {
      onPageNumberClick(currentPage - 1);
    }
  };

  React.useEffect(() => {
    totalPage && currentPage > totalPage - 4
      ? setMinPageLimit(totalPage - 4)
      : currentPage < 5
      ? setMinPageLimit(1)
      : setMinPageLimit(currentPage);

    totalPage && currentPage > totalPage - 4
      ? setMaxPageLimit(totalPage)
      : currentPage < 5
      ? setMaxPageLimit(5)
      : setMaxPageLimit(currentPage + 4);
  }, [currentPage]);

  return (
    <div className="pmx-pagination">
      <button className="prev" onClick={onPrevButtonClick}>
        <ChevronRight />
      </button>
      <div className="pages">
        {currentPage > 5 && (
          <button
            className={`${currentPage === 1 ? "active" : ""}`}
            onClick={() => onPageNumberClick(1)}
          >
            {1}
          </button>
        )}
        {minPageLimit > 1 && currentPage > 5 && <button>...</button>}
        {Array.from({
          length:
            currentPage === totalPage ? 1 : totalPage <= 5 ? totalPage : 5,
        }).map((_, index) =>
          currentPage <= 5 ? (
            <button
              key={index}
              className={`${currentPage === index + 1 ? "active" : ""}`}
              onClick={() => onPageNumberClick(index + 1)}
            >
              {index + 1}
            </button>
          ) : totalPage > currentPage + index ? (
            <button
              key={index}
              className={`${
                currentPage === currentPage + index ? "active" : ""
              }`}
              onClick={() => onPageNumberClick(currentPage + index)}
            >
              {currentPage + index}
            </button>
          ) : (
            <></>
          ),
        )}
        {totalPage > maxPageLimit && <button>...</button>}
        {currentPage <= totalPage && currentPage >= 5 && (
          <button
            className={`${currentPage === totalPage ? "active" : ""}`}
            onClick={() => onPageNumberClick(totalPage)}
          >
            {totalPage}
          </button>
        )}
      </div>
      <button className="next" onClick={onNextButtonClick}>
        <ChevronLeft />
      </button>
    </div>
  );
};

export default Pagination;
