import toast from "react-hot-toast";
import {getViaAuth, postViaAuth, responseValidator} from "../apis/api";
import {API} from "../apis";
import {BankAccount, BankAccountCreateBody} from "../domain";

export async function getAllBankAccounts(
  setLoading: any,
  setData: any,
  setSelectedItem?: any,
) {
  setLoading(true);
  await getViaAuth<BankAccount[]>(API.bankAccount.mine).then((response) => {
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData(response.data);
      response.data.length !== 0 && setSelectedItem &&
        setSelectedItem(response.data[0]);
    }
  });
}

export async function createBankAccount(
  setLoading: any,
  data: BankAccountCreateBody,
  onComplete: () => void,
) {
  setLoading(true);
  await postViaAuth(API.bankAccount.create, data).then((response) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success("کارت بانکی شما با موفقیت اضافه شد");
      onComplete();
    }
  });
}

export async function deleteBankAccount(
  setLoading: any,
  id: string,
  onComplete: () => void,
) {
  setLoading(true);
  await postViaAuth(API.bankAccount.delete + `/${id}`, {}).then((response) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success("کارت بانکی شما حذف شد");
      onComplete();
    }
  });
}
